.ReportSuccessContent__root__ifNSi {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 0;
}
.ReportSuccessContent__root__ifNSi > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-YOOgp3GSpDYosY));
  height: calc(100% + var(--token-YOOgp3GSpDYosY));
}
.ReportSuccessContent__root__ifNSi > .__wab_flex-container > *,
.ReportSuccessContent__root__ifNSi > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessContent__root__ifNSi > .__wab_flex-container > picture > img,
.ReportSuccessContent__root__ifNSi
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-YOOgp3GSpDYosY);
}
@media (max-width: 480px) {
  .ReportSuccessContent__root__ifNSi > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  .ReportSuccessContent__root__ifNSi > .__wab_flex-container > *,
  .ReportSuccessContent__root__ifNSi > .__wab_flex-container > .__wab_slot > *,
  .ReportSuccessContent__root__ifNSi > .__wab_flex-container > picture > img,
  .ReportSuccessContent__root__ifNSi
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
.ReportSuccessContent__freeBox___6R7Gg {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-HUUwrpE5bq87rt);
  border: 1px solid var(--token-O8yxnKF8d);
}
.ReportSuccessContent__freeBox___6R7Gg > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.ReportSuccessContent__freeBox___6R7Gg > .__wab_flex-container > *,
.ReportSuccessContent__freeBox___6R7Gg
  > .__wab_flex-container
  > .__wab_slot
  > *,
.ReportSuccessContent__freeBox___6R7Gg > .__wab_flex-container > picture > img,
.ReportSuccessContent__freeBox___6R7Gg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.ReportSuccessContent__freeBox__lnJjQ {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ReportSuccessContent__freeBox__lnJjQ {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .ReportSuccessContent__freeBox__lnJjQ {
    grid-template-columns: 1fr;
  }
}
.ReportSuccessContent__freeBox___9DgSb {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.ReportSuccessContent__freeBox___9DgSb > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.ReportSuccessContent__freeBox___9DgSb > .__wab_flex-container > *,
.ReportSuccessContent__freeBox___9DgSb
  > .__wab_flex-container
  > .__wab_slot
  > *,
.ReportSuccessContent__freeBox___9DgSb > .__wab_flex-container > picture > img,
.ReportSuccessContent__freeBox___9DgSb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.ReportSuccessContent__text__yh7MO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .ReportSuccessContent__text__yh7MO {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.ReportSuccessContent__addressFormGroup__cvEh9.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ReportSuccessContent__portfolioQuestionCard__oHqq.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ReportSuccessContent__freeBox__zsxPa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ReportSuccessContent__freeBox__zsxPa > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.ReportSuccessContent__freeBox__zsxPa > .__wab_flex-container > *,
.ReportSuccessContent__freeBox__zsxPa > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessContent__freeBox__zsxPa > .__wab_flex-container > picture > img,
.ReportSuccessContent__freeBox__zsxPa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
.ReportSuccessContent__checkboxUsageAgreement__hjj4E.__wab_instance.__wab_instance {
  position: relative;
  align-self: auto;
}
.ReportSuccessContent__button__j3FSy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ReportSuccessContent__svg__mTuIl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__text__wLajp {
  height: auto;
}
.ReportSuccessContent__svg__dq9Ca {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__banner__h5LQk.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.ReportSuccessContent__bannerstate_lastReport__h5LQkr5Rhm.__wab_instance {
  display: flex;
}
.ReportSuccessContent__bannerstate_reportsUsedUp__h5LQKnzeN7.__wab_instance {
  display: flex;
}
.ReportSuccessContent__bannerstate_linkExpired__h5LQKwuSuX.__wab_instance {
  display: flex;
}
.ReportSuccessContent__button__meAgC.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ReportSuccessContent__svg__quD5B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__svg__kk8H0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__freeBox__zAmKk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ReportSuccessContent__freeBox__zAmKk > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.ReportSuccessContent__freeBox__zAmKk > .__wab_flex-container > *,
.ReportSuccessContent__freeBox__zAmKk > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessContent__freeBox__zAmKk > .__wab_flex-container > picture > img,
.ReportSuccessContent__freeBox__zAmKk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.ReportSuccessContent__freeBoxstate_lastReport__zAmKkr5Rhm {
  display: flex;
}
.ReportSuccessContent__successBanner__cuuSe.__wab_instance {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.ReportSuccessContent__button__qm8Sf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ReportSuccessContent__svg__hfd1D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__svg__pt9CN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__freeBox__uoWzo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.ReportSuccessContent__freeBox__uoWzo > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.ReportSuccessContent__freeBox__uoWzo > .__wab_flex-container > *,
.ReportSuccessContent__freeBox__uoWzo > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessContent__freeBox__uoWzo > .__wab_flex-container > picture > img,
.ReportSuccessContent__freeBox__uoWzo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.ReportSuccessContent__button__iZk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ReportSuccessContent__svg__zv2Cx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportSuccessContent__svg__qwkiz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
