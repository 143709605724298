.PortfolioDetailRow__root__mLe1F {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  padding-bottom: var(--token-P3nFMsZz2JMXx8);
  width: 100%;
  min-height: var(--token-qecgvVQwVo7jFR);
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  border-top: 1px solid #c1cec924;
}
.PortfolioDetailRow__freeBox__ds1Ln {
  display: grid;
  position: relative;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  width: 100%;
  grid-row-gap: var(--token-P3nFMsZz2JMXx8);
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
.PortfolioDetailRow__freeBox__rpEe {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 6;
}
.PortfolioDetailRow__freeBox__rpEe > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.PortfolioDetailRow__freeBox__rpEe > .__wab_flex-container > *,
.PortfolioDetailRow__freeBox__rpEe > .__wab_flex-container > .__wab_slot > *,
.PortfolioDetailRow__freeBox__rpEe > .__wab_flex-container > picture > img,
.PortfolioDetailRow__freeBox__rpEe
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.PortfolioDetailRow__freeBox__iAJh2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 22px;
  height: 22px;
  padding-right: var(--token-a0wt3E9LJjO_Nh);
  flex-shrink: 0;
}
.PortfolioDetailRow__checkbox__zcG7Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioDetailRow__checkboxhasCheckbox__zcG7Q8Llbt.__wab_instance {
  display: flex;
}
.PortfolioDetailRow__text__km1Mt {
  display: none;
}
.PortfolioDetailRow__freeBox__z9QZ7 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioDetailRow__slotTargetAddress__f2H2M {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
  line-height: var(--token-lWY7kGeXV7C_yD);
}
.PortfolioDetailRow__freeBox__gNtC {
  display: flex;
  flex-direction: row;
  height: auto;
}
.PortfolioDetailRow__freeBox__gNtC > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.PortfolioDetailRow__freeBox__gNtC > .__wab_flex-container > *,
.PortfolioDetailRow__freeBox__gNtC > .__wab_flex-container > .__wab_slot > *,
.PortfolioDetailRow__freeBox__gNtC > .__wab_flex-container > picture > img,
.PortfolioDetailRow__freeBox__gNtC
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.PortfolioDetailRow__slotTargetRefNr__i3LLj {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailRow__text__j4ZM {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-NfHg4McmY);
  display: none;
}
.PortfolioDetailRow__texthasReferenceNr__j4ZMzpp95 {
  display: none;
}
.PortfolioDetailRow__texthasVersion_hasReferenceNr__j4ZM1R7XEZpp95 {
  display: block;
}
.PortfolioDetailRow__slotTargetVersion__kcZib {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailRow__freeBox__cZcWb {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.PortfolioDetailRow__slotTargetVerbrauch__nzan {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.PortfolioDetailRow__freeBox__tDbaM {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.PortfolioDetailRow__slotTargetEmission___1202 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.PortfolioDetailRow__freeBox__txDGy {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 4;
  min-width: 0;
}
.PortfolioDetailRow__freeBox__txDGy > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioDetailRow__freeBox__txDGy > .__wab_flex-container > *,
.PortfolioDetailRow__freeBox__txDGy > .__wab_flex-container > .__wab_slot > *,
.PortfolioDetailRow__freeBox__txDGy > .__wab_flex-container > picture > img,
.PortfolioDetailRow__freeBox__txDGy
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioDetailRow__text__gOopo {
  padding-right: 0px;
}
.PortfolioDetailRow__text__vxkUq {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.PortfolioDetailRow__text__sv1Lv {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.PortfolioDetailRow__freeBox__l2QvC {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.PortfolioDetailRow__slotTargetDataDate__wgqtO {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.PortfolioDetailRow__freeBox___1WmsU {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioDetailRow__freeBox___1WmsU > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.PortfolioDetailRow__freeBox___1WmsU > .__wab_flex-container > *,
.PortfolioDetailRow__freeBox___1WmsU > .__wab_flex-container > .__wab_slot > *,
.PortfolioDetailRow__freeBox___1WmsU > .__wab_flex-container > picture > img,
.PortfolioDetailRow__freeBox___1WmsU
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.PortfolioDetailRow__text__eU49 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailRow__slotTargetModelDate__cbiWd {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailRow__freeBox__krtel {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.PortfolioDetailRow__pdfButton__xLSp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioDetailRow__svg__yPwCl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioDetailRow__svg__yFRxE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
