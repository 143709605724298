.PortfolioEntryList__root__drBlb {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.PortfolioEntryList__root__drBlb > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PortfolioEntryList__root__drBlb > .__wab_flex-container > *,
.PortfolioEntryList__root__drBlb > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__root__drBlb > .__wab_flex-container > picture > img,
.PortfolioEntryList__root__drBlb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .PortfolioEntryList__root__drBlb {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .PortfolioEntryList__root__drBlb > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .PortfolioEntryList__root__drBlb > .__wab_flex-container > *,
  .PortfolioEntryList__root__drBlb > .__wab_flex-container > .__wab_slot > *,
  .PortfolioEntryList__root__drBlb > .__wab_flex-container > picture > img,
  .PortfolioEntryList__root__drBlb
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 12px;
  }
}
.PortfolioEntryList__freeBox__j5UIw {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .PortfolioEntryList__freeBox__j5UIw {
    overflow: auto;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .PortfolioEntryList__freeBox__j5UIw {
    padding: 0px;
  }
}
.PortfolioEntryList__freeBox__gxMV {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .PortfolioEntryList__freeBox__gxMV {
    min-width: 1240px;
    max-width: auto;
  }
}
.PortfolioEntryList__freeBox__w9Ss {
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-A-OXrd0RfsEv85);
  background: #183630;
  width: auto;
  left: 0px;
  top: 0px;
  z-index: 1;
  right: 0px;
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  margin-left: -2px;
  margin-right: -2px;
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .PortfolioEntryList__freeBox__w9Ss {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.PortfolioEntryList__freeBox__l3OdC {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 5;
}
.PortfolioEntryList__freeBox__l3OdC > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntryList__freeBox__l3OdC > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__l3OdC > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__l3OdC > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__l3OdC
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntryList__text___6Pxt {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntryList__freeBox__inSmC {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.PortfolioEntryList__text__eOxfu {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntryList__freeBox__m7G27 {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  padding: 0px;
}
.PortfolioEntryList__freeBox__m7G27 > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntryList__freeBox__m7G27 > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__m7G27 > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__m7G27 > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__m7G27
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntryList__text__o4Cph {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: left;
  min-width: 0;
}
.PortfolioEntryList__freeBox__kQn6Z {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.PortfolioEntryList__text__a7WY {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntryList__freeBox__h0Jc {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.PortfolioEntryList__freeBox__h0Jc > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntryList__freeBox__h0Jc > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__h0Jc > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__h0Jc > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__h0Jc
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntryList__text__gcYhI {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.PortfolioEntryList__freeBox__cakXc {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.PortfolioEntryList__text__hHk85 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntryList__text__oetU {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.PortfolioEntryList__freeBox__yCAbY {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 3;
}
.PortfolioEntryList__freeBox__yCAbY > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntryList__freeBox__yCAbY > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__yCAbY > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__yCAbY > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__yCAbY
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntryList__text__tO3Df {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: right;
  min-width: 0;
}
.PortfolioEntryList__freeBox__fTw4K {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioEntryList__freeBox__fTw4K > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PortfolioEntryList__freeBox__fTw4K > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__fTw4K > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__fTw4K > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__fTw4K
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.PortfolioEntryList__portfolioEntry__fvqUe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntryList__portfolioIndexButton___7BecM.__wab_instance {
  max-width: 100%;
}
@media (max-width: 480px) {
  .PortfolioEntryList__portfolioIndexButton___7BecM.__wab_instance {
    width: auto;
  }
}
.PortfolioEntryList__svg___1Uhlp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__textoldPortfoliosDisplayed__ayzroTcFfw {
  padding-right: 0px;
}
.PortfolioEntryList__svg__wtIWf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__portfolioEntry___94V2Y.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntryList__emptyState__sfQmf {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: var(--token-ti5ui7Y6hnPLfa);
}
.PortfolioEntryList__emptyState__sfQmf > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.PortfolioEntryList__emptyState__sfQmf > .__wab_flex-container > *,
.PortfolioEntryList__emptyState__sfQmf
  > .__wab_flex-container
  > .__wab_slot
  > *,
.PortfolioEntryList__emptyState__sfQmf > .__wab_flex-container > picture > img,
.PortfolioEntryList__emptyState__sfQmf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.PortfolioEntryList__emptyStateisEmpty_step1__sfQmf01Xff {
  display: flex;
}
.PortfolioEntryList__emptyStateisEmpty_step2__sfQmf3G5Ef {
  display: flex;
}
.PortfolioEntryList__freeBox__yWouw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioEntryList__freeBox__yWouw > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PortfolioEntryList__freeBox__yWouw > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__yWouw > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__yWouw > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__yWouw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.PortfolioEntryList__svg__i8IB {
  object-fit: cover;
  max-width: 100%;
  width: var(--token-qecgvVQwVo7jFR);
  height: var(--token-qecgvVQwVo7jFR);
  color: var(--token-SkTYvRtmE);
  flex-shrink: 0;
}
.PortfolioEntryList__text___2KSy7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
.PortfolioEntryList__text__wnro {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioEntryList__textisEmpty_step1__wnro01Xff {
  display: none;
}
.PortfolioEntryList__textisEmpty_step2__wnro3G5Ef {
  margin-bottom: 0px;
  text-align: center;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
}
.PortfolioEntryList__freeBox__e6CHd {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.PortfolioEntryList__freeBox__e6CHd > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.PortfolioEntryList__freeBox__e6CHd > .__wab_flex-container > *,
.PortfolioEntryList__freeBox__e6CHd > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntryList__freeBox__e6CHd > .__wab_flex-container > picture > img,
.PortfolioEntryList__freeBox__e6CHd
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
.PortfolioEntryList__esgButton__cjNx.__wab_instance {
  max-width: 100%;
  align-self: flex-end;
}
.PortfolioEntryList__svg__lmZsZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__svg__jvQxC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__portfolioButton__nOkm.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntryList__portfolioButtonisEmpty_step2__nOkm3G5Ef.__wab_instance {
  display: flex;
}
.PortfolioEntryList__svg___35LrS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__svg___1WZ0K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntryList__freeBox__cgkX {
  display: block;
  position: relative;
  width: 60px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .PortfolioEntryList__freeBox__cgkX {
    display: block;
  }
}
.PortfolioEntryList__freeBox__nuEFk {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 80px;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #203e3700 0%, #203e37 36%) 0% 0% / 100%
    100% repeat;
  pointer-events: none;
  margin-right: -2px;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .PortfolioEntryList__freeBox__nuEFk {
    display: block;
  }
}
@media (max-width: 480px) {
  .PortfolioEntryList__freeBox__nuEFk {
    max-width: 60px;
  }
}
