.PortfolioEntry__root__sIc0N {
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-left: var(--token-P3nFMsZz2JMXx8);
  padding-right: var(--token-P3nFMsZz2JMXx8);
  overflow: hidden;
  min-width: 0;
  border-radius: 2px;
  border: 1px solid var(--token-O8yxnKF8d);
}
.PortfolioEntry__freeBox__dxYYo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-HUUwrpE5bq87rt);
  padding-bottom: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  min-width: 0;
}
.PortfolioEntry__freeBoxisExpanded__dxYYoY7QWh {
  padding-bottom: 0px;
}
.PortfolioEntry__freeBox__lgGnV {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 5;
}
.PortfolioEntry__freeBox__lgGnV > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.PortfolioEntry__freeBox__lgGnV > .__wab_flex-container > *,
.PortfolioEntry__freeBox__lgGnV > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__lgGnV > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__lgGnV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.PortfolioEntry__portfolioCollapseButton__ujqpT.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.PortfolioEntry__portfolioCollapseButtonisExpanded__ujqpTy7QWh.__wab_instance {
  flex-shrink: 0;
}
.PortfolioEntry__svg__nySdt {
  position: relative;
  object-fit: cover;
  width: var(--token-C4KuLs5uqsxF8L);
  height: var(--token-C4KuLs5uqsxF8L);
}
.PortfolioEntry__svgisExpanded__nySdtY7QWh {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(180deg);
}
.PortfolioEntry__svg__yvZrR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntry__freeBox__htSWx {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.PortfolioEntry__freeBox__htSWx > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.PortfolioEntry__freeBox__htSWx > .__wab_flex-container > *,
.PortfolioEntry__freeBox__htSWx > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__htSWx > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__htSWx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.PortfolioEntry__freeBox__koRk {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioEntry__slotTargetPortfolioName__vm2G6 {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
  line-height: var(--token-97-B3t__LugLsk);
}
.PortfolioEntry__slotTargetObjectCount___3OeQr {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioEntry__coverage__sGode {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.PortfolioEntry__text__c3FLw {
  color: var(--token--h6FHSrgUAQsDe);
}
.PortfolioEntry__freeBox__rPiRj {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 4;
  min-width: 0;
}
.PortfolioEntry__freeBox__rPiRj > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntry__freeBox__rPiRj > .__wab_flex-container > *,
.PortfolioEntry__freeBox__rPiRj > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__rPiRj > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__rPiRj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntry__text___2Nx6V {
  padding-right: 0px;
}
.PortfolioEntry__text__yzfNg {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.PortfolioEntry__text__flCaC {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.PortfolioEntry__freeBox__aqcHi {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.PortfolioEntry__slotTargetCreationDate__fBb46 {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.PortfolioEntry__freeBox__m2YOi {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
.PortfolioEntry__freeBox__m2YOi > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.PortfolioEntry__freeBox__m2YOi > .__wab_flex-container > *,
.PortfolioEntry__freeBox__m2YOi > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__m2YOi > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__m2YOi
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.PortfolioEntry__freeBoxhasEsgDate__m2YOiErYQm {
  display: flex;
}
.PortfolioEntry__slotTargetEsgDate__pdUm8 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioEntry__text__yozYz {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioEntry__freeBox__gw4Me {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 4;
}
.PortfolioEntry__freeBox__gw4Me > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.PortfolioEntry__freeBox__gw4Me > .__wab_flex-container > *,
.PortfolioEntry__freeBox__gw4Me > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__gw4Me > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__gw4Me
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.PortfolioEntry__downloadButton__o3N1J.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntry__svg___46MZo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntry__svg__iahZf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntry__deleteButton__j4Vk0.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.PortfolioEntry__svg___8VmkS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-s9gzWmEFR);
}
.PortfolioEntry__svg__ldOku {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioEntry__freeBox__kXihU {
  display: grid;
  position: relative;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 20;
  grid-row-gap: var(--token-P3nFMsZz2JMXx8);
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
.PortfolioEntry__portfolioDetailInfoGroup__ld8Ri.__wab_instance {
  position: relative;
  grid-column-end: span 10;
}
.PortfolioEntry__portfolioDetailInfo__pLkBx.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__kI4I.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__eXaOq.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfoGroup__o6Aav.__wab_instance {
  position: relative;
  grid-column-end: span 10;
}
.PortfolioEntry__portfolioDetailInfo__ovMhf.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__aNclk.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__f4QDz.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfoGroup__s9YuR.__wab_instance {
  position: relative;
  grid-column-end: span 10;
}
.PortfolioEntry__portfolioDetailInfo__faJkI.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__q79X.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__qqk5V.__wab_instance {
  position: relative;
}
.PortfolioEntry__pfSc12EmissM2VmfLabel__twoE1 {
  padding-right: 0px;
}
.PortfolioEntry__portfolioDetailInfoGroup__kdqGg.__wab_instance {
  position: relative;
  grid-column-end: span 10;
}
.PortfolioEntry__portfolioDetailInfo__nuhLk.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__ffn4U.__wab_instance {
  position: relative;
}
.PortfolioEntry__portfolioDetailInfo__u6M5D.__wab_instance {
  position: relative;
}
.PortfolioEntry__freeBox__sgM7U {
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  grid-column-end: span 20;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  display: none;
  border-top: 1px solid #c1cec924;
}
.PortfolioEntry__freeBoxisExpanded__sgM7UY7QWh {
  display: flex;
}
.PortfolioEntry__freeBox__ic4Pw {
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-P3nFMsZz2JMXx8);
  background: #203e37;
  padding-top: var(--token-7uzqW3y-2Tgb_s);
  padding-bottom: var(--token-7uzqW3y-2Tgb_s);
  width: auto;
  left: 0px;
  top: 0px;
  z-index: 1;
  right: 0px;
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .PortfolioEntry__freeBox__ic4Pw {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.PortfolioEntry__freeBox__shLcv {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 6;
  padding-left: var(--token-HUUwrpE5bq87rt);
}
.PortfolioEntry__freeBox__shLcv > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.PortfolioEntry__freeBox__shLcv > .__wab_flex-container > *,
.PortfolioEntry__freeBox__shLcv > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__shLcv > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__shLcv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
}
.PortfolioEntry__text___47IU {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__freeBox___1Q6Bf {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.PortfolioEntry__text__fHn4O {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__text__nQt8I {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__freeBox__qvwxI {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.PortfolioEntry__text__ozsx2 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__text__y6Dh {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__freeBox__u8X0H {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.PortfolioEntry__text___6SN {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__freeBox__iUspI {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.PortfolioEntry__freeBox__iUspI > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntry__freeBox__iUspI > .__wab_flex-container > *,
.PortfolioEntry__freeBox__iUspI > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__iUspI > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__iUspI
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntry__text__mki3P {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.PortfolioEntry__freeBox__pGn6L {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.PortfolioEntry__text__vH2Z5 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.PortfolioEntry__freeBox__fsz3D {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 3;
}
.PortfolioEntry__freeBox__fsz3D > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.PortfolioEntry__freeBox__fsz3D > .__wab_flex-container > *,
.PortfolioEntry__freeBox__fsz3D > .__wab_flex-container > .__wab_slot > *,
.PortfolioEntry__freeBox__fsz3D > .__wab_flex-container > picture > img,
.PortfolioEntry__freeBox__fsz3D
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.PortfolioEntry__text__vMy8C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: right;
  min-width: 0;
}
.PortfolioEntry__snapshotList__hq8Jr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PortfolioEntry__portfolioDetailRow___20IDx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntry__portfolioDetailRow__s8Q6K.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntry__portfolioDetailRow__vFwoA.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioEntry__portfolioDetailRow__jtDig.__wab_instance {
  max-width: 100%;
  position: relative;
}
