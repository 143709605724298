.Divider__root__rhUkA {
  display: block;
  width: 100%;
  background: var(--token-CkHZor_7deI9A3);
  height: 1px;
  position: relative;
  opacity: 0.12;
  min-width: 0;
}
.Divider__rootisVertical__rhUkAz3KL3 {
  height: 100%;
  width: 1px;
  min-height: 0;
}
