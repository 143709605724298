.FileUploadField__root__rxvnm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-niMfN0aK_);
  position: relative;
  cursor: pointer;
  transition-property: background, border-color;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  min-width: 0;
  -webkit-transition-property: background, border-color;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 2px;
  padding: 20px 16px 16px;
  border: 1px dashed var(--token-KVbFqNIPJ);
}
.FileUploadField__rootsize_medium__rxvnmARvV {
  height: 36px;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
}
.FileUploadField__root__rxvnm:hover {
  background: var(--token-4_CeG-8iBJgDtW);
  border-color: var(--token-WEnykX89Xj8V-V);
}
.FileUploadField__contentWrapper__yIsdq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.FileUploadField__contentWrapper__yIsdq > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.FileUploadField__contentWrapper__yIsdq > .__wab_flex-container > *,
.FileUploadField__contentWrapper__yIsdq
  > .__wab_flex-container
  > .__wab_slot
  > *,
.FileUploadField__contentWrapper__yIsdq > .__wab_flex-container > picture > img,
.FileUploadField__contentWrapper__yIsdq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
.FileUploadField__contentWrapperisLoading__yIsdqa0Ld6 {
  opacity: 0;
}
.FileUploadField__contentWrappersize_medium__yIsdqaRvV {
  display: flex;
  flex-direction: row;
}
.FileUploadField__contentWrappersize_medium__yIsdqaRvV > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.FileUploadField__contentWrappersize_medium__yIsdqaRvV
  > .__wab_flex-container
  > *,
.FileUploadField__contentWrappersize_medium__yIsdqaRvV
  > .__wab_flex-container
  > .__wab_slot
  > *,
.FileUploadField__contentWrappersize_medium__yIsdqaRvV
  > .__wab_flex-container
  > picture
  > img,
.FileUploadField__contentWrappersize_medium__yIsdqaRvV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.FileUploadField__slotTargetIcon__uhy3S {
  font-size: 24px;
  color: var(--token-SkTYvRtmE);
}
.FileUploadField__slotTargetIconsize_medium__uhy3SaRvV {
  font-size: 20px;
}
.FileUploadField__root__rxvnm:hover .FileUploadField__slotTargetIcon__uhy3S {
  color: var(--token-i2qCvUVknsIZp2);
}
.FileUploadField__svg___5YcE {
  object-fit: cover;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  height: 1em;
  flex-shrink: 0;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.FileUploadField__text__lFflZ {
  position: relative;
  text-align: center;
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.FileUploadField__textsize_medium__lFflZaRvV {
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-c8ADL42ppeG1sf);
}
.FileUploadField__root__rxvnm:hover .FileUploadField__text__lFflZ {
  color: var(--token-WEnykX89Xj8V-V);
}
.FileUploadField__slotTargetInfo___0Wuq2 {
  font-size: var(--token-1lkMHpovBhH3r-);
  font-weight: 500;
  color: var(--token-yDpImGgAX);
  text-align: center;
}
.FileUploadField__slotTargetInfo___0Wuq2 > *,
.FileUploadField__slotTargetInfo___0Wuq2 > .__wab_slot > *,
.FileUploadField__slotTargetInfo___0Wuq2 > .__wab_slot > .__wab_slot > *,
.FileUploadField__slotTargetInfo___0Wuq2
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.FileUploadField__slotTargetInfo___0Wuq2 > picture > img,
.FileUploadField__slotTargetInfo___0Wuq2 > .__wab_slot > picture > img,
.FileUploadField__slotTargetInfo___0Wuq2
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.FileUploadField__slotTargetInfo___0Wuq2
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.FileUploadField__root__rxvnm:hover .FileUploadField__slotTargetInfo___0Wuq2 {
  color: var(--token-BqwcOUIRst2GEg);
}
.FileUploadField__loadingWrapper__mj8QG {
  position: absolute;
  flex-direction: column;
  width: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  display: none;
  padding: 24px;
}
.FileUploadField__loadingWrapper__mj8QG > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.FileUploadField__loadingWrapper__mj8QG > .__wab_flex-container > *,
.FileUploadField__loadingWrapper__mj8QG
  > .__wab_flex-container
  > .__wab_slot
  > *,
.FileUploadField__loadingWrapper__mj8QG > .__wab_flex-container > picture > img,
.FileUploadField__loadingWrapper__mj8QG
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
.FileUploadField__loadingWrapperisLoading__mj8QGa0Ld6 {
  display: flex;
}
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV {
  left: 0;
  top: 0;
  flex-direction: row;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px 8px;
}
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV > .__wab_flex-container {
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV
  > .__wab_flex-container
  > *,
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV
  > .__wab_flex-container
  > .__wab_slot
  > *,
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV
  > .__wab_flex-container
  > picture
  > img,
.FileUploadField__loadingWrappersize_medium__mj8QGaRvV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.FileUploadField__text__pOpHx {
  position: relative;
  text-align: center;
  font-weight: 500;
  font-size: var(--token-1lkMHpovBhH3r-);
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.FileUploadField__textsize_medium__pOpHxaRvV {
  display: none;
}
.FileUploadField__root__rxvnm:hover .FileUploadField__text__pOpHx {
  color: var(--token-WEnykX89Xj8V-V);
}
.FileUploadField__freeBox__o8VSz {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 6px;
  background: var(--token-KVbFqNIPJ);
  overflow: hidden;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 4px;
}
.FileUploadField__freeBoxsize_medium__o8VSzARvV {
  max-width: 60%;
}
.FileUploadField__progressBar__hu0Yy {
  display: block;
  position: absolute;
  width: 40%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: auto;
  background: var(--token-QI60SWB06gBJ5K);
  min-height: 0;
}
.FileUploadField__cancelButton___6K1Hk.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
.FileUploadField__svg__pwQP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FileUploadField__svg__zf4Pq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
