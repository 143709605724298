.ScrollNavGroup__root__aEmxC {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.ScrollNavGroup__root__aEmxC > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.ScrollNavGroup__root__aEmxC > .__wab_flex-container > *,
.ScrollNavGroup__root__aEmxC > .__wab_flex-container > .__wab_slot > *,
.ScrollNavGroup__root__aEmxC > .__wab_flex-container > picture > img,
.ScrollNavGroup__root__aEmxC
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__root__aEmxC {
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    width: auto;
    padding-right: 24px;
    border-right: 1px solid #c1cec924;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__root__aEmxC > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__root__aEmxC > .__wab_flex-container > *,
  .ScrollNavGroup__root__aEmxC > .__wab_flex-container > .__wab_slot > *,
  .ScrollNavGroup__root__aEmxC > .__wab_flex-container > picture > img,
  .ScrollNavGroup__root__aEmxC
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.ScrollNavGroup__freeBox___9Xp7 {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__freeBox___9Xp7 {
    margin-bottom: 0px;
    width: auto;
    flex-shrink: 1;
  }
}
.ScrollNavGroup__slotTargetTitle__i1LUd {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
.ScrollNavGroup__freeBox___3LwXa {
  display: flex;
  position: relative;
  flex-direction: column;
}
.ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > *,
.ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > .__wab_slot > *,
.ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > picture > img,
.ScrollNavGroup__freeBox___3LwXa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__freeBox___3LwXa {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container {
    flex-direction: row;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > *,
  .ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > .__wab_slot > *,
  .ScrollNavGroup__freeBox___3LwXa > .__wab_flex-container > picture > img,
  .ScrollNavGroup__freeBox___3LwXa
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.ScrollNavGroup__navButton__ykyc.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
.ScrollNavGroup__text__nKwXk {
  height: auto;
}
.ScrollNavGroup__svg__wDnz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
