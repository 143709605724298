.TermsOfUseForm__root__z9RRp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.TermsOfUseForm__root__z9RRp > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.TermsOfUseForm__root__z9RRp > .__wab_flex-container > *,
.TermsOfUseForm__root__z9RRp > .__wab_flex-container > .__wab_slot > *,
.TermsOfUseForm__root__z9RRp > .__wab_flex-container > picture > img,
.TermsOfUseForm__root__z9RRp
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.TermsOfUseForm__freeBox__lnPgr {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.TermsOfUseForm__freeBox__lnPgr > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.TermsOfUseForm__freeBox__lnPgr > .__wab_flex-container > *,
.TermsOfUseForm__freeBox__lnPgr > .__wab_flex-container > .__wab_slot > *,
.TermsOfUseForm__freeBox__lnPgr > .__wab_flex-container > picture > img,
.TermsOfUseForm__freeBox__lnPgr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.TermsOfUseForm__text__ju2Vi {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
}
.TermsOfUseForm__formGroup__wkEu6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox___6Z0Zz.__wab_instance {
  max-width: 100%;
}
.TermsOfUseForm__symbolCounter__i2V9U.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__rhZr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox2__xrwqj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__x6Wgk.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__dIkaH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox3___6EXp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__h09G.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__rtXh8.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox4__q8Kfs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__lUJy.__wab_instance {
  position: relative;
}
.TermsOfUseForm__divider__iaD51.__wab_instance {
  max-width: 100%;
  position: relative;
  margin-top: calc(0px + var(--token-HUUwrpE5bq87rt)) !important;
  flex-shrink: 0;
}
.TermsOfUseForm__freeBox__z4EFj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.TermsOfUseForm__freeBox__z4EFj > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.TermsOfUseForm__freeBox__z4EFj > .__wab_flex-container > *,
.TermsOfUseForm__freeBox__z4EFj > .__wab_flex-container > .__wab_slot > *,
.TermsOfUseForm__freeBox__z4EFj > .__wab_flex-container > picture > img,
.TermsOfUseForm__freeBox__z4EFj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.TermsOfUseForm__text___3GzdA {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
}
.TermsOfUseForm__freeBox__hefAw {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 6px;
  padding: var(--token-TJik2L8OcSV6kD);
  border: 1px solid var(--token-O8yxnKF8d);
}
.TermsOfUseForm__freeBox__hefAw > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.TermsOfUseForm__freeBox__hefAw > .__wab_flex-container > *,
.TermsOfUseForm__freeBox__hefAw > .__wab_flex-container > .__wab_slot > *,
.TermsOfUseForm__freeBox__hefAw > .__wab_flex-container > picture > img,
.TermsOfUseForm__freeBox__hefAw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.TermsOfUseForm__formGroup__zTsOy.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.TermsOfUseForm__radio2__oOn6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__v7Ivx.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__xtQab.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.TermsOfUseForm__radio2___0VRli.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__rFkFp.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__tGLs4.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.TermsOfUseForm__radio2__iiEfZ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter__k6OQm.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__wvXZk.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
}
.TermsOfUseForm__radio2___6G5Fj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter___9LWre.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup__yYdHg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox11__m3JuI.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter___8HoqT.__wab_instance {
  position: relative;
}
.TermsOfUseForm__formGroup___160VB.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__checkbox12__fC8.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseForm__symbolCounter___5GgY1.__wab_instance {
  position: relative;
}
.TermsOfUseForm__text__ez15N {
  position: relative;
  color: var(--token-zqrEV3Z8z);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
