._010ErfassungSimpel__root__sKqsn {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._010ErfassungSimpel__processHeader__xujQ3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__processBreadCrumb__wv3NA.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
._010ErfassungSimpel__freeBox__kWfGy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__kWfGy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__kWfGy {
    display: flex;
    flex-direction: column;
  }
}
._010ErfassungSimpel__scrollNav__gaHAh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__scrollNavGroup__phWW.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__navButton___13V4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__zsXk {
  height: auto;
}
._010ErfassungSimpel__svg__gJu8G {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010ErfassungSimpel__navButton__nMtz7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__l9Jb2 {
  height: auto;
}
._010ErfassungSimpel__svg__fiUwa {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010ErfassungSimpel__navButton__nvR15.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__vww71 {
  height: auto;
}
._010ErfassungSimpel__svg__ocgkU {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010ErfassungSimpel__navButton__cIqV9.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__sknbZ {
  height: auto;
}
._010ErfassungSimpel__svg__bg6PS {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010ErfassungSimpel__navButton__e8HIs.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__jKAuZ {
  height: auto;
}
._010ErfassungSimpel__svg__sy9YY {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010ErfassungSimpel__processPageContent__e7Rbx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__l0J6I {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__l0J6I > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010ErfassungSimpel__freeBox__l0J6I > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__l0J6I > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__l0J6I > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__l0J6I
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__l0J6I {
    padding-left: 24px;
    padding-right: 24px;
  }
}
._010ErfassungSimpel__freeBox__fkRGk {
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-7uzqW3y-2Tgb_s)
    var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
  border: 1px solid var(--token-O8yxnKF8d);
}
._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__fkRGk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__fkRGk {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container > *,
  ._010ErfassungSimpel__freeBox__fkRGk
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._010ErfassungSimpel__freeBox__fkRGk > .__wab_flex-container > picture > img,
  ._010ErfassungSimpel__freeBox__fkRGk
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
._010ErfassungSimpel__text__pmowE {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__freeBox___9Wkpw {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
._010ErfassungSimpel__freeBox___9Wkpw > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
._010ErfassungSimpel__freeBox___9Wkpw > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox___9Wkpw > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox___9Wkpw > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox___9Wkpw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
._010ErfassungSimpel__svg__jbLZc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__lkPp9 {
  height: auto;
}
._010ErfassungSimpel__svg__vgtGj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__processPageActions__ow0V2.__wab_instance {
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__trPu8 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__trPu8 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010ErfassungSimpel__freeBox__trPu8 > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__trPu8 > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__trPu8 > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__trPu8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__trPu8 {
    display: flex;
  }
}
._010ErfassungSimpel__text__glUtY {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__freeBox__ppHoh {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
._010ErfassungSimpel__freeBox__ppHoh > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._010ErfassungSimpel__freeBox__ppHoh > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__ppHoh > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__ppHoh > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__ppHoh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
._010ErfassungSimpel__text__pUzKz {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__text__hlxEl {
  position: relative;
  height: auto;
  color: var(--token-HHAuBKYL3pvmHz);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__freeBox___8GjZ6 {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
._010ErfassungSimpel__freeBox___8GjZ6 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._010ErfassungSimpel__freeBox___8GjZ6 > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox___8GjZ6 > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox___8GjZ6 > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox___8GjZ6
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
._010ErfassungSimpel__text__s9HYg {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__text__nDdus {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010ErfassungSimpel__processSection___4N7Uy.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__processCard___424N.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCardSection___2DU7X.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__aeYpI {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__aeYpI {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__aeYpI {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010ErfassungSimpel__formGroup__bPQo.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__bPQo.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__textInput47__bow2V.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___5YrUv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__q6Ocv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text___87DgJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text___2TvGa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text___6RAIx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__ri73I.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__hn07T.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__select9___8U2QC.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__xZckJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option___48QyE.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___3XxJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___2LaZt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__myNB.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__jmWrF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__lq4Fe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__eLdPf.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__il35H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__iiiL3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__borM7.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__y4Fae {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__dFzZz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__l1HbW.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___1Irab {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__knFed {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vqe3H.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__g4SZj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__juqrO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__ev6WA.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__yocEp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ztg12 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__ucjXq.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__riOe3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__wNgjs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__r0DU.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__uuckc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__k0Mad {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__xJmp1.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__uPVlZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__kypDy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__pX6N4.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___3GJol {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__jATrB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___85Pa.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__ciwWm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__rso7A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__fqOj.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__vWbDv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__sFp6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__seEn.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__pBl8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__or5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__paNHx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__dgLm0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ywDQg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__bJn80.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__f1436 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__sLUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__jhZYb.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__znGn5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___17ZW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__igXmM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___1MqvQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__pQ1Ev {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__noRD.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__axRcl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__m4Lgc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__zPiB.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__dlEEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__gsNug {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__gzXo5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__jN6Q1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__jDhE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vnlJx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__f9QJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__tDjlt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__rl0Uo.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__hbyKd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ouK6C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__rJs2.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__jBOq8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__gvTn7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__xmPzR.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__ybyqK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___0Ntz4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__g3KX.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__zRoCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___72Kc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__euRlY.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__y3Hpm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__es7Dj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__yXg0O.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__w6N5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__hf3Pf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__abA5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__khwgL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ie31K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__gvFBn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___3U3Tz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__jBsEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___7TgnW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___7DVzz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__bbykL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__aAnuk.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__cO27E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__nz5Jt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___1YKx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__krsEx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__zDoV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__bfs8G.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__mEDy3.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__objStandLzy__beSv.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__bsAq7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__z7Pmm.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___8Lq6F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ch7Ss {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__bJ0Ij.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__ilTiX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__iYc3J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vp9U9.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__rpXz5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__cOdKh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__c3ZSv.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__o9W7W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__x2CAf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__u1B9V.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__text__l1LM4 {
  padding-bottom: 0px;
}
._010ErfassungSimpel__svg___6H5Fb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__oaQx6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__fBbM9.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__jfSnv.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__jfSnv.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__text__pYbaO {
  padding-right: 0px;
}
._010ErfassungSimpel__version__nqVw.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg___0Zch7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__lrIx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__bz1E0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text___4AlB7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text___2Ff8L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__aVvDa.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup___2GbRe.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__text__h04Nr {
  padding-right: 0px;
}
._010ErfassungSimpel__wohnfl__iAh0J.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__iQBit {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__c9YDs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__x7Ghy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__t3NPp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__cxe32 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__mE3Fs.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__hECp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__wohnflc__mmaIf.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__eYjD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__obwt5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__iKb5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__u5Cur {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__b4Dte.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__rqANz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__rii9H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__eJjfr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__rdZe9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__r8TH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__iv34E.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__vM0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__hBjJu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___1ORn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__szeZt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___3T2Pf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__lGiq6.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__u3FSd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__eh3Qy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter___7Y1HG.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup___5SkWf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__text__fqsOt {
  padding-right: 0px;
}
._010ErfassungSimpel__realtyDataReferralDate__priy.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__gfWw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___7ZHo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__dcr2K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__aNy39 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__pm8Cq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter___7TTaF.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__k0XjI.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__k0XjI.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__heizHauptEnerTrKat__fJsZd.__wab_instance {
  max-width: none;
  position: relative;
  width: 100%;
  align-self: auto;
  min-width: 0;
}
._010ErfassungSimpel__svg__yZmxc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__button___8SYd0.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__gVIib {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__xl0Qq {
  height: auto;
}
._010ErfassungSimpel__svg__ySGuW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__tXro.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___0X3Da {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__vFkOd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__brEvp.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__zVXd1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__cNaFv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__xwBg2.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__r1Vae {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ilQMb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__ont52.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__ilfUp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__kyEfw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__oNsfP.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__qNv4Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__wMmmk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__pRttn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__pfXsH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__kzR6V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__dP2Px.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__scd4X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___1BHqM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__oRplx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__byrDo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__n5FsE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__sVg5G.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___7SPub {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__lhOs3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__xa3BB.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__o5FHw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___14JcQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__bcpmJ.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__freeBox__qxv7B {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-TJik2L8OcSV6kD);
  border: 1px solid var(--token-O8yxnKF8d);
}
._010ErfassungSimpel__freeBox__qxv7B > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010ErfassungSimpel__freeBox__qxv7B > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__qxv7B > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__qxv7B > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__qxv7B
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__qxv7B {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__freeBox__adpoH {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__adpoH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__adpoH {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
    height: calc(100% + var(--token-TJik2L8OcSV6kD));
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container > *,
  ._010ErfassungSimpel__freeBox__adpoH
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._010ErfassungSimpel__freeBox__adpoH > .__wab_flex-container > picture > img,
  ._010ErfassungSimpel__freeBox__adpoH
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-TJik2L8OcSV6kD);
  }
}
._010ErfassungSimpel__svg__ms9C6 {
  object-fit: cover;
  color: var(--token-QI60SWB06gBJ5K);
  width: var(--token-YOOgp3GSpDYosY);
  height: var(--token-YOOgp3GSpDYosY);
  flex-shrink: 0;
}
._010ErfassungSimpel__freeBox__cHDog {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__cHDog > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._010ErfassungSimpel__freeBox__cHDog > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__cHDog > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__cHDog > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__cHDog
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._010ErfassungSimpel__text__wISy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-zqrEV3Z8z);
  min-width: 0;
}
._010ErfassungSimpel__freeBox__e5GZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__e5GZ > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._010ErfassungSimpel__freeBox__e5GZ > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__e5GZ > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__e5GZ > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__e5GZ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._010ErfassungSimpel__submitButton___9Tt0C.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__mGfLe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___2QAd1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__freeBox__rY0Sx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__rY0Sx > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
._010ErfassungSimpel__freeBox__rY0Sx > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__rY0Sx > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__rY0Sx > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__rY0Sx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
._010ErfassungSimpel__freeBox__m5Jj9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__m5Jj9 > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010ErfassungSimpel__freeBox__m5Jj9 > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__m5Jj9 > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__m5Jj9 > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__m5Jj9
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
._010ErfassungSimpel__formGroup__uTzx.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__checkbox__u8Sov.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__symbolCounter__adoM.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__text__kiiwH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-SkTYvRtmE);
  align-self: center;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._010ErfassungSimpel__formGroup__ksmdg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__textInput85___7GsOe.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010ErfassungSimpel__svg__lOhWe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__w3WVj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__uCNnU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__zjyo4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__r32Yp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__byy6S.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCard__n6SgF.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCardSection___1Sq9H.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__p9ENr {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__p9ENr {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__p9ENr {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010ErfassungSimpel__banner__l5Cv2.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__banner__l5Cv2.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__button__xugQk.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__qb2Cr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___9Bn3G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__formGroup__lkb7H.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__textInput49__jpFqG.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__obBv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___8NoaX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__mxTvO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__x8YpX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__aXRxT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__xubHf.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__xxHpZ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__textInput108__iuKzq.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___4NdWk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__oPSk9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text___9Hhm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__rw3H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__lUrAb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter___8X5At.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__osxTs.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010ErfassungSimpel__textInput57__wTasX.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__q6GmX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__wXyoA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__xZaku {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__je00X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__zhjP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__yUbf1.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__pzFz8.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__anzwge___14AZ8.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__ha6KC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__fou5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__zhuot {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__w6Ws {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__e2LSs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__t9Dex.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__ppsjZ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__anzug__cd9Ey.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__odfGy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__dgZlw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text___65T7E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__bm2Ul {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__nUwBx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__weKnj.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__rrUlg.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__rrUlg.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__text___9DaUg {
  padding-right: 0px;
}
._010ErfassungSimpel__gebkon__qmiXb.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__swt1K {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__r1Gpu.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__ifXrw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__cdTpD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__pAHo.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__b2Oth {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__yqNju {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__es7DQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___8SkO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___3EZhv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vWqsM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__lMBm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__xbrEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__gaOr6.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__wmWpR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___8OAwc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vpkRe.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__pwxZf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___8AHv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__jVVui.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__dnnaZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__gPw94 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__cxbtp.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__kv8KU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__jd0T7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__miDni.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__paWIo.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__paWIo.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__text__bQaPx {
  padding-right: 0px;
}
._010ErfassungSimpel__dachc__xN1Ws.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__hZanJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__wOjm4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__kMiWk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__dP8S3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__n6WIi.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__sM34T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__u7Olm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__ixOyA.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__y710J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__nAc8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__dlZql.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__kDhXo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__nVrhg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___2OeQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__eRo4L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__jnQtt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__tY3Ti.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__riO1H.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__riO1H.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__text__q1Mbq {
  padding-right: 0px;
}
._010ErfassungSimpel__fassadArt__uEV4.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__ykCgp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__x8QDj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___9KTl1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__oaOG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__tvpIz.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__fV16W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__gwQnG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__z8Hvq.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__tHlzf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__sgXdz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__zflQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__rfPlx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__i0BIa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__mIw6X.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___5QuCj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__lM9L6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___6UOlx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__mVOrX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__hx1Ei {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__uYqn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg___8EkIu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__sv6Lf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__bgWKx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__gacEi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__xlyhv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__x2YKp.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__fOxR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__i6OWs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__d18Sl.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__va4FW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__sErJw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__rXzXo.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__r1GYm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__textInput71__z48Zl.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010ErfassungSimpel__svg___2VQh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__b1Wu3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__bWk0A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__scSg7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__yVYb3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__sqi6Y.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCard__vY5Sw.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__text__eWJpE {
  padding-bottom: 0px;
}
._010ErfassungSimpel__processCardSection__roAeW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__bWq4R {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__bWq4R {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
._010ErfassungSimpel__formGroup__ypQwB.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__ypQwB.__wab_instance {
    grid-column-end: span 6;
  }
}
._010ErfassungSimpel__text__hP4Cd {
  padding-right: 0px;
}
._010ErfassungSimpel__energie__sSkxd.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__i4GpK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__bQdl4.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__rzEwu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___8MpUg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vGwYl.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__mg9YW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___420Zc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option___069V8.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__oKelv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__hsNQk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__q241.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___6Tjbi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___4UIfI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__uzUvD.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__jc5VC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__cClYg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__nn68.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___8ZHdR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__dlVGd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__pXz5G.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__t3SLr.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__t3SLr.__wab_instance {
    grid-column-end: span 6;
  }
}
._010ErfassungSimpel__ebf__z7B1R.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__cJqfl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__qreXv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__w5KC3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__tCabG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text___5Cf3L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__riok0.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__b8Stc.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._010ErfassungSimpel__singleSelectButtonGroup__xy0F.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__button__oTCe5.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__rfIoy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__o2WQ {
  width: auto;
}
._010ErfassungSimpel__svg__n37So {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__aq2M8.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__fmvNw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__csOsl {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__l6Q61 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__oFjg.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__x1YUg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__o7P2Z {
  padding-left: 0px;
}
._010ErfassungSimpel__svg___5KiFf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter___068Z.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__freeBox__v2Hox {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-column-end: span 6;
  padding-top: var(--token-HUUwrpE5bq87rt);
  border-top: 1px solid #c1cec924;
}
._010ErfassungSimpel__freeBox__v2Hox > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._010ErfassungSimpel__freeBox__v2Hox > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__v2Hox > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__v2Hox > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__v2Hox
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._010ErfassungSimpel__text__xJfve {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 600;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__vgxm {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__vgxm {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__vgxm {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010ErfassungSimpel__formGroup__gOoeI.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__booleanSelectButtonGroup__cDgYy.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__symbolCounter___2RPdu.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup___1IkKx.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__geakArt___9EzNm.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__z46Z9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__y7Yn6.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___3Pq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__ddoug {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__vQRze.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__hBny3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___6Vllb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__t417W.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___9QfKj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___4MqUf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__cmuX2.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___3D1Rs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__xWvvn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__l0Yi6.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__dgpe6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg___1RNhu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__ssAek.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__aRx2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__mUiHw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__v0A2W.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__mR99S.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010ErfassungSimpel__geakDokumentennummer__i6X9H.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__hLIfT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__xxzMs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__mv5P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__cNuEc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__gmPZh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__fyTPv.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__iYo9L.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__textInput109__s7808.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___2Oqm7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__z67Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__tcX1G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__ymuqJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__kxZfe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__xlII.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__e88Sg.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__textInput110__rLvQ1.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__hhmu0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___0PWcx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__t2Oyn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__gn1Aj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__e7XJv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__mf06U.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__wg0S5.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__booleanSelectButtonGroup__cauxW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__symbolCounter___6BEz.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__uHVlF.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__uHVlF.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__singleSelectButtonGroup__kdhqW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__button__dWbTp.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___74SXf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__yxTg8 {
  width: auto;
}
._010ErfassungSimpel__svg__hY6A1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__suOle.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__vujsk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__nXcpI {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__fN0XD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__prFfD.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__rAa9L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__i6XWj {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__xflUc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__fXe6R.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__s2QPj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__zuYRc {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__g9SVc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__rFEu.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__cNjV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__b7ZBn {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__axnHb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button___5Oz4.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__hWuEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__tiRvx {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__oXw2Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__sKyHv.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___0M8Fi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__r6ZOp {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__oJxig {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__qCiDa.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__wUe1C.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__geakGeEff___7KjUg.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__gGnaq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__y3Amh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__bna3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__o7Pt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__mY9Yx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__fKXj9.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__uAOeP.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__uAOeP.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__singleSelectButtonGroup__aZuu3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__button__oShr7.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__aySzL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__hdCaZ {
  width: auto;
}
._010ErfassungSimpel__svg__zf12U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__dapEb.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___3P00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text___3Xcac {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__u5OKf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__qkrP.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__hsNUh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__heCq6 {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__sie3M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__dFzOw.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__bwXhK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__dRgM8 {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__bBxH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__xy1Zp.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__b2GUp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__wkUpn {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__f6U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__jmaJ.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___9Zb0E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__gsI7M {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__g9Z6B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button___79YoM.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__mEy5S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__vlV8U {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__saShD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__vYXnU.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup___3DPa9.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__geakGhEff___3AT1V.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__unJxk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__gExFv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__oxXud {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__eRec3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__cQmfR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__qxER.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__vGuNe.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__formGroup__vGuNe.__wab_instance {
    grid-column-end: span 2;
  }
}
._010ErfassungSimpel__singleSelectButtonGroup__lHeeO.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__button__vKJi6.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___3Gm47 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__xopA {
  width: auto;
}
._010ErfassungSimpel__svg__mqIx1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__uLet.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___0QSgn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__mleLg {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__hPqdt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__fYaAm.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__cnjQw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__ccaU {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__tMr4G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__w0VLq.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__v34Ig {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__s4Ue {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__lw9P5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__qujTt.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___2MKSk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__iQevn {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__zSPpA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__ofsOr.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg___97Vv1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__eeenS {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__drnPi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__jUcE.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__yTypm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__s489E {
  padding-left: 0px;
}
._010ErfassungSimpel__svg__o22Zo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter___9X1K.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup___9Bhe5.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__geakCo2Aeq__rNhOi.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__nnubB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__ux2KO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__b3BzE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010ErfassungSimpel__text__oW2El {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__kpnte {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__gj8.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__kRjgw.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__geakEbf__pY4S.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__vjjRc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__jc9E1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__oyJtS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__f2Cbc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text___1H8Y6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__i2VE1.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__freeBox__o7BLq {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-column-end: span 6;
  padding-top: var(--token-HUUwrpE5bq87rt);
  border-top: 1px solid #c1cec924;
}
._010ErfassungSimpel__freeBox__o7BLq > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._010ErfassungSimpel__freeBox__o7BLq > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__o7BLq > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__o7BLq > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__o7BLq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._010ErfassungSimpel__text__qogny {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 600;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__aGfOj {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__aGfOj {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__aGfOj {
    grid-template-columns: 1fr;
  }
}
._010ErfassungSimpel__formGroup___7ADwM.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010ErfassungSimpel__booleanSelectButtonGroup__k9PBm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__symbolCounter__hDQmZ.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__g0KU.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__minergArtDekl__mz3Ro.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__button__vGOmx.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__gVkhl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__rBfHl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__button__dvtLp.__wab_instance {
  max-width: 100%;
}
._010ErfassungSimpel__svg__aup84 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text___3UlMr {
  padding-left: 0px;
}
._010ErfassungSimpel__svg___2EWpg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__sDTx.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__n0Nmg.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__standardMinergie__qA87.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__svg__yHuEa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010ErfassungSimpel__option__rlm08.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__gNgm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__phF8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__clj4Q.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__w7Lr2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__stmp4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__gUqT.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__ipjG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__eAzrp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__b9C3N.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__cS7H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__kL8O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__hTM.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__duhdq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__maOh9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__option__uzBmS.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg__m1NFw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010ErfassungSimpel__svg__gmWDj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__symbolCounter__bpNmd.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__k0U2J.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010ErfassungSimpel__minergieNummer__mIIeS.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___51NJg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__ecLgv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__xvK6Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__jKor {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__cEmBj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__dx3AH.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__formGroup__lHwAp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010ErfassungSimpel__textInput111__mseN.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__svg___0FHy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg___4U8C0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__lb8J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__apUz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__u1EBb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__lbDSt.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCardSection__fuBCh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox___05QM {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox___05QM {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox___05QM {
    grid-template-columns: 1fr;
  }
}
._010ErfassungSimpel__dynamicEnergyList__g4Kd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__vIBU.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__lleOw.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem___4R0Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__viTmp.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem___93Naw.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__ydQ7I.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__yWcq.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem___3Mxr4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem___6BlA.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicEnergyListItem__nGEm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__knVv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__freeBox__knVv > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
._010ErfassungSimpel__freeBox__knVv > .__wab_flex-container > *,
._010ErfassungSimpel__freeBox__knVv > .__wab_flex-container > .__wab_slot > *,
._010ErfassungSimpel__freeBox__knVv > .__wab_flex-container > picture > img,
._010ErfassungSimpel__freeBox__knVv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
._010ErfassungSimpel__freeBox__by9Kr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
._010ErfassungSimpel__text__gGa6 {
  padding-right: 0px;
}
._010ErfassungSimpel__text__kwiT {
  position: relative;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-HHAuBKYL3pvmHz);
}
._010ErfassungSimpel__button__viZva.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__svg__uwRc7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__mUpl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__processCardSection___2UnmD.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__freeBox__knBj8 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010ErfassungSimpel__freeBox__knBj8 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010ErfassungSimpel__freeBox__knBj8 {
    grid-template-columns: 1fr;
  }
}
._010ErfassungSimpel__dynamicPowerList__bOmDi.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicPowerListItem__hNvsg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicPowerListItem__nxJRa.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__dynamicPowerListItem__cfvXh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__text__ldefm {
  padding-right: 0px;
}
._010ErfassungSimpel__formGroup__gB4UC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010ErfassungSimpel__textInput53__miN3Q.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010ErfassungSimpel__svg__ngu2I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__svg__pVxca {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010ErfassungSimpel__text__u909L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010ErfassungSimpel__text__y8Jqf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__text__oyTBp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010ErfassungSimpel__symbolCounter__ik2Gv.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__cardMakroMikrolage__iYyXf.__wab_instance.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__processCardSocialQuestionnaire__dUMpR.__wab_instance.__wab_instance {
  position: relative;
}
._010ErfassungSimpel__text__oawyd {
  padding-bottom: 0px;
}
._010ErfassungSimpel__processStickyNav__ro3Gr.__wab_instance {
  max-width: 100%;
  position: fixed;
  left: 0;
  top: auto;
  z-index: 3;
  right: 0;
  bottom: 0;
}
._010ErfassungSimpel__footer__eIfYo.__wab_instance {
  max-width: 100%;
  position: relative;
}
