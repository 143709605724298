.ReportSuccessHeader__root__aktbk {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 0;
}
.ReportSuccessHeader__root__aktbk > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-HaxgWLsrVFdD87));
  height: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ReportSuccessHeader__root__aktbk > .__wab_flex-container > *,
.ReportSuccessHeader__root__aktbk > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessHeader__root__aktbk > .__wab_flex-container > picture > img,
.ReportSuccessHeader__root__aktbk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HaxgWLsrVFdD87);
}
.ReportSuccessHeader__freeBox___3ZzJ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ReportSuccessHeader__freeBox___3ZzJ > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.ReportSuccessHeader__freeBox___3ZzJ > .__wab_flex-container > *,
.ReportSuccessHeader__freeBox___3ZzJ > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessHeader__freeBox___3ZzJ > .__wab_flex-container > picture > img,
.ReportSuccessHeader__freeBox___3ZzJ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.ReportSuccessHeader__freeBox__mnziI {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
}
.ReportSuccessHeader__slotTargetEyebrowText___45PrO {
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 400;
  color: var(--token-s9gzWmEFR);
  line-height: var(--token-lWY7kGeXV7C_yD);
}
@media (max-width: 480px) {
  .ReportSuccessHeader__slotTargetEyebrowText___45PrO {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.ReportSuccessHeader__freeBox__uxRyp {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-IaDlhQPgR);
  border-radius: 200px;
  padding: var(--token-a0wt3E9LJjO_Nh) var(--token-7uzqW3y-2Tgb_s);
}
.ReportSuccessHeader__freeBoxhasInfo__uxRyp4FeFw {
  display: flex;
}
.ReportSuccessHeader__text__gLcp {
  width: 100%;
  height: auto;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  text-align: center;
  white-space: pre;
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  .ReportSuccessHeader__text__gLcp {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.ReportSuccessHeader__freeBox__ypiR {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ReportSuccessHeader__freeBox__ypiR > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HaxgWLsrVFdD87));
  height: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ReportSuccessHeader__freeBox__ypiR > .__wab_flex-container > *,
.ReportSuccessHeader__freeBox__ypiR > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessHeader__freeBox__ypiR > .__wab_flex-container > picture > img,
.ReportSuccessHeader__freeBox__ypiR
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HaxgWLsrVFdD87);
}
.ReportSuccessHeader__freeBox__kOvqr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.ReportSuccessHeader__slotTargetAddress__mX3Lg {
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
}
@media (max-width: 480px) {
  .ReportSuccessHeader__slotTargetAddress__mX3Lg {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.ReportSuccessHeader__freeBox__yh7Jr {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.ReportSuccessHeader__freeBox__yh7Jr > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ReportSuccessHeader__freeBox__yh7Jr > .__wab_flex-container > *,
.ReportSuccessHeader__freeBox__yh7Jr > .__wab_flex-container > .__wab_slot > *,
.ReportSuccessHeader__freeBox__yh7Jr > .__wab_flex-container > picture > img,
.ReportSuccessHeader__freeBox__yh7Jr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.ReportSuccessHeader__freeBoxhasAddon_referenceNumber__yh7JRe4JXk {
  display: flex;
}
.ReportSuccessHeader__freeBoxhasAddon_version__yh7JrpCr1W {
  display: flex;
}
.ReportSuccessHeader__slotTargetRefNr__kzxCz {
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
.ReportSuccessHeader__text__wGr0O {
  position: relative;
  color: var(--token-NfHg4McmY);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.ReportSuccessHeader__texthasAddon_version_hasAddon_referenceNumber__wGr0OPCr1WE4JXk {
  display: block;
}
.ReportSuccessHeader__slotTargetVersion__eLc1T {
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
