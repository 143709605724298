.ProcessPageContent__root__rFr6I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.ProcessPageContent__freeBox__wvse0 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  max-width: 1000px;
  min-width: 0;
  padding: 72px 40px 104px;
}
.ProcessPageContent__freeBox__wvse0 > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-qecgvVQwVo7jFR));
  height: calc(100% + var(--token-qecgvVQwVo7jFR));
}
.ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > *,
.ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > .__wab_slot > *,
.ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > picture > img,
.ProcessPageContent__freeBox__wvse0
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-qecgvVQwVo7jFR);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessPageContent__freeBox__wvse0 {
    max-width: none;
    padding-top: 56px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 72px);
    height: calc(100% + 72px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > *,
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > .__wab_slot > *,
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > picture > img,
  .ProcessPageContent__freeBox__wvse0
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 72px;
  }
}
@media (max-width: 480px) {
  .ProcessPageContent__freeBox__wvse0 {
    padding: 0px 0px 72px;
  }
}
@media (max-width: 480px) {
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 48px);
    height: calc(100% + 48px);
  }
}
@media (max-width: 480px) {
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > *,
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > .__wab_slot > *,
  .ProcessPageContent__freeBox__wvse0 > .__wab_flex-container > picture > img,
  .ProcessPageContent__freeBox__wvse0
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 48px;
  }
}
.ProcessPageContent__freeBoxnoPaddingTop__wvse0MQTp {
  padding-top: 0px;
}
