.NavProfileDropdown__select__a3IIo.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.NavProfileDropdown__freeBox__eMl1A {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 200px;
  padding: var(--token-a0wt3E9LJjO_Nh);
}
.NavProfileDropdown__freeBox__eMl1A > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.NavProfileDropdown__freeBox__eMl1A > .__wab_flex-container > *,
.NavProfileDropdown__freeBox__eMl1A > .__wab_flex-container > .__wab_slot > *,
.NavProfileDropdown__freeBox__eMl1A > .__wab_flex-container > picture > img,
.NavProfileDropdown__freeBox__eMl1A
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.NavProfileDropdown__freeBox__rCkez {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NfHg4McmY);
  min-width: 0;
  border-radius: 200px;
  padding: var(--token-A-OXrd0RfsEv85);
}
.NavProfileDropdown__svg__hezr {
  position: relative;
  object-fit: cover;
  width: var(--token-P3nFMsZz2JMXx8);
  height: var(--token-P3nFMsZz2JMXx8);
  color: var(--token-vvi_7r45uYs8w7);
  flex-shrink: 0;
}
.NavProfileDropdown__svg__ggy6X {
  position: relative;
  object-fit: cover;
  width: var(--token-C4KuLs5uqsxF8L);
  height: var(--token-C4KuLs5uqsxF8L);
  color: var(--token-vvi_7r45uYs8w7);
  flex-shrink: 0;
}
.NavProfileDropdown__option__fUeMd.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__cEKpC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg__mwDjt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.NavProfileDropdown__option__a9IWs.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__r6R9N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg__j2PdK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.NavProfileDropdown__option__fZn8L.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__bjtKy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg___2N9Vl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.NavProfileDropdown__option__s0Wsr.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__u5Dqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg__fXHgQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.NavProfileDropdown__option__wZuNd.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__gVgk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg__lStiv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.NavProfileDropdown__optionGroup___5J5H.__wab_instance {
  max-width: 100%;
  position: relative;
}
.NavProfileDropdown__option__ac7M9.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__zihWm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.NavProfileDropdown__svg__dEmri {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__option__oPkDg.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__vDHvH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.NavProfileDropdown__svg__hUhAf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__option__aaKh2.__wab_instance {
  position: relative;
}
.NavProfileDropdown__svg__jmMyS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.NavProfileDropdown__svg__m5SS2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__option__pwGaN.__wab_instance {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .NavProfileDropdown__option__pwGaN.__wab_instance {
    height: 100%;
    min-height: 0;
  }
}
.NavProfileDropdown__svg__f0Pn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.NavProfileDropdown__svg__sW8Jq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavProfileDropdown__svg___8NTIa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
