.PortfolioDetailInfoGroup__root__rlDZ {
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  padding: var(--token-C4KuLs5uqsxF8L);
  border: 1px solid var(--token-O8yxnKF8d);
}
.PortfolioDetailInfoGroup__root__rlDZ > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.PortfolioDetailInfoGroup__root__rlDZ > .__wab_flex-container > *,
.PortfolioDetailInfoGroup__root__rlDZ > .__wab_flex-container > .__wab_slot > *,
.PortfolioDetailInfoGroup__root__rlDZ > .__wab_flex-container > picture > img,
.PortfolioDetailInfoGroup__root__rlDZ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
.PortfolioDetailInfoGroup__freeBox__fPsbC {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.PortfolioDetailInfoGroup__slotTargetTitle__ptQi {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailInfoGroup__freeBox__pzuoo {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.PortfolioDetailInfoGroup__freeBox__pzuoo > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.PortfolioDetailInfoGroup__freeBox__pzuoo > .__wab_flex-container > *,
.PortfolioDetailInfoGroup__freeBox__pzuoo
  > .__wab_flex-container
  > .__wab_slot
  > *,
.PortfolioDetailInfoGroup__freeBox__pzuoo
  > .__wab_flex-container
  > picture
  > img,
.PortfolioDetailInfoGroup__freeBox__pzuoo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
.PortfolioDetailInfoGroup__portfolioDetailInfo__evTFk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioDetailInfoGroup__portfolioDetailInfo__kEaor.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioDetailInfoGroup__portfolioDetailInfo___3Uf7Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
