.NavLinkGroup__root__jIpHd {
  display: inline-flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
  width: auto;
  position: relative;
}
.NavLinkGroup__root__jIpHd > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.NavLinkGroup__root__jIpHd > .__wab_flex-container > *,
.NavLinkGroup__root__jIpHd > .__wab_flex-container > .__wab_slot > *,
.NavLinkGroup__root__jIpHd > .__wab_flex-container > picture > img,
.NavLinkGroup__root__jIpHd
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .NavLinkGroup__root__jIpHd {
    width: auto;
    display: inline-flex;
  }
}
@media (max-width: 480px) {
  .NavLinkGroup__root__jIpHd > .__wab_flex-container {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .NavLinkGroup__root__jIpHd > .__wab_flex-container > *,
  .NavLinkGroup__root__jIpHd > .__wab_flex-container > .__wab_slot > *,
  .NavLinkGroup__root__jIpHd > .__wab_flex-container > picture > img,
  .NavLinkGroup__root__jIpHd
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.NavLinkGroup__freeBox__sLnqH {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.NavLinkGroup__freeBox__sLnqH > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.NavLinkGroup__freeBox__sLnqH > .__wab_flex-container > *,
.NavLinkGroup__freeBox__sLnqH > .__wab_flex-container > .__wab_slot > *,
.NavLinkGroup__freeBox__sLnqH > .__wab_flex-container > picture > img,
.NavLinkGroup__freeBox__sLnqH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
.NavLinkGroup__navLink___2S8L8.__wab_instance {
  position: relative;
}
.NavLinkGroup__navLinkisLoggedIn___2S8L8DxLmr.__wab_instance {
  display: flex;
}
.NavLinkGroup__svg__aGq73 {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__navLink__f7N7Z.__wab_instance {
  position: relative;
}
.NavLinkGroup__svg___2CgH {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__navLink__ePgaW.__wab_instance {
  position: relative;
  display: none;
}
.NavLinkGroup__navLinkisLoggedIn__ePgaWdxLmr.__wab_instance {
  display: flex;
}
.NavLinkGroup__svg__idtCy {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__navLink__w5YF1.__wab_instance {
  position: relative;
}
.NavLinkGroup__navLinkisLoggedIn__w5YF1DxLmr.__wab_instance {
  display: none;
}
.NavLinkGroup__svg__ksArd {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__divider__s5Wr.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.NavLinkGroup__navLink__lv2EF.__wab_instance {
  position: relative;
}
.NavLinkGroup__svg__h9QDa {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__navLink__xjpb0.__wab_instance {
  position: relative;
}
.NavLinkGroup__svg__c7COa {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLinkGroup__freeBox__m9Ix {
  display: flex;
  position: relative;
  flex-direction: row;
}
.NavLinkGroup__freeBox__m9Ix > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.NavLinkGroup__freeBox__m9Ix > .__wab_flex-container > *,
.NavLinkGroup__freeBox__m9Ix > .__wab_flex-container > .__wab_slot > *,
.NavLinkGroup__freeBox__m9Ix > .__wab_flex-container > picture > img,
.NavLinkGroup__freeBox__m9Ix
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.NavLinkGroup__freeBoxisLoggedIn__m9IxdxLmr {
  display: flex;
}
