._000Login__root___7Fx00 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-hEzPFeV5Y);
  padding-top: var(--token-3K3yS7MMaSUwfX);
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._000Login__root___7Fx00 {
    padding-top: var(--token-qecgvVQwVo7jFR);
  }
}
@media (max-width: 480px) {
  ._000Login__root___7Fx00 {
    padding-top: var(--token-a0wt3E9LJjO_Nh);
  }
}
._000Login__freeBox__xeWxf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__freeBox__x0Mg6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 72px 24px;
}
@media (max-width: 480px) {
  ._000Login__freeBox__x0Mg6 {
    max-width: none;
  }
}
._000Login__freeBox__dEmgz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 320px;
  min-width: 0;
}
._000Login__freeBox__dEmgz > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 56px);
  height: calc(100% + 56px);
}
._000Login__freeBox__dEmgz > .__wab_flex-container > *,
._000Login__freeBox__dEmgz > .__wab_flex-container > .__wab_slot > *,
._000Login__freeBox__dEmgz > .__wab_flex-container > picture > img,
._000Login__freeBox__dEmgz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 56px;
}
@media (max-width: 480px) {
  ._000Login__freeBox__dEmgz {
    max-width: none;
  }
}
._000Login__freeBox__b9RyK {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
._000Login__freeBox__b9RyK > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._000Login__freeBox__b9RyK > .__wab_flex-container > *,
._000Login__freeBox__b9RyK > .__wab_flex-container > .__wab_slot > *,
._000Login__freeBox__b9RyK > .__wab_flex-container > picture > img,
._000Login__freeBox__b9RyK
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
._000Login__freeBox__etr10 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__text__mz4X0 {
  text-align: left;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-SkTYvRtmE);
}
._000Login__text__kuXf {
  text-align: left;
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
}
._000Login__formWrapperComponent__f8YmH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__formWrapperComponent__f8YmH > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
._000Login__formWrapperComponent__f8YmH > .__wab_flex-container > *,
._000Login__formWrapperComponent__f8YmH
  > .__wab_flex-container
  > .__wab_slot
  > *,
._000Login__formWrapperComponent__f8YmH > .__wab_flex-container > picture > img,
._000Login__formWrapperComponent__f8YmH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
._000Login__formGroup__aTj5.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: stretch;
}
._000Login__textInput__lqf2.__wab_instance {
  position: relative;
}
._000Login__svg__l38D5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__svg__coEXr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__text__uvOzI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._000Login__text__fe8C8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._000Login__text__zsWgv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._000Login__symbolCounter___27FyT.__wab_instance {
  position: relative;
}
._000Login__freeBox__wPRq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: stretch;
}
._000Login__freeBox__wPRq > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
._000Login__freeBox__wPRq > .__wab_flex-container > *,
._000Login__freeBox__wPRq > .__wab_flex-container > .__wab_slot > *,
._000Login__freeBox__wPRq > .__wab_flex-container > picture > img,
._000Login__freeBox__wPRq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
._000Login__formGroup__u7Bel.__wab_instance {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
._000Login__text__uwNt {
  height: auto;
}
._000Login__textInput2___2KdJw.__wab_instance {
  position: relative;
}
._000Login__svg__bFFrO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__svg__wxVyV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__text___0PRuW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._000Login__text__pIneT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._000Login__text__pas0Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._000Login__symbolCounter__jX89.__wab_instance {
  position: relative;
}
._000Login__button__onn2W.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: stretch;
  margin-top: calc(12px + var(--token-Wf1GGJSbrxsgS-)) !important;
}
._000Login__svg__sysQo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__svg__m3OP3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._000Login__freeBox___9G7Xb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__freeBox___9G7Xb > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._000Login__freeBox___9G7Xb > .__wab_flex-container > *,
._000Login__freeBox___9G7Xb > .__wab_flex-container > .__wab_slot > *,
._000Login__freeBox___9G7Xb > .__wab_flex-container > picture > img,
._000Login__freeBox___9G7Xb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
._000Login__freeBox__bzVx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__text__fVp6J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-c8ADL42ppeG1sf);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._000Login__link__tNh6V {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
._000Login__freeBox__h6G5J {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__text___3IheI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-c8ADL42ppeG1sf);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._000Login__link__g9Bh2 {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
._000Login__freeBox__ahlPf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._000Login__text__zuJ96 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-c8ADL42ppeG1sf);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._000Login__link__ql8Zy {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
._000Login__footer__xghse.__wab_instance {
  max-width: 100%;
  position: relative;
}
