.ProcessCard__root__hmQ26 {
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#ffffff08, #ffffff08), var(--token-hEzPFeV5Y);
  position: relative;
  transition-property: all;
  transition-duration: 1.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1.2s;
  border-radius: 6px;
}
@media (max-width: 480px) {
  .ProcessCard__root__hmQ26 {
    box-shadow: none;
    border-right-style: none;
    border-left-style: none;
    padding-bottom: 16px;
    border-radius: 0px;
  }
}
.ProcessCard__freeBox__bkWCv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-x_jRp3a5FpON8z) var(--token-fkHbhUQaFbf-Tk)
    var(--token-P3nFMsZz2JMXx8);
}
.ProcessCard__freeBox__bkWCv > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.ProcessCard__freeBox__bkWCv > .__wab_flex-container > *,
.ProcessCard__freeBox__bkWCv > .__wab_flex-container > .__wab_slot > *,
.ProcessCard__freeBox__bkWCv > .__wab_flex-container > picture > img,
.ProcessCard__freeBox__bkWCv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCard__freeBox__bkWCv {
    padding-left: var(--token-HUUwrpE5bq87rt);
    padding-right: var(--token-HUUwrpE5bq87rt);
  }
}
@media (max-width: 480px) {
  .ProcessCard__freeBox__bkWCv {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessCard__freeBox__bkWCv > .__wab_flex-container {
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ProcessCard__freeBox__bkWCv > .__wab_flex-container > *,
  .ProcessCard__freeBox__bkWCv > .__wab_flex-container > .__wab_slot > *,
  .ProcessCard__freeBox__bkWCv > .__wab_flex-container > picture > img,
  .ProcessCard__freeBox__bkWCv
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.ProcessCard__freeBoxisClosed__bkWCvVrXj {
  padding-bottom: var(--token-x_jRp3a5FpON8z);
}
.ProcessCard__freeBox__gOj3 {
  position: relative;
  padding-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.ProcessCard__freeBox__gOj3 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.ProcessCard__freeBox__gOj3 > .__wab_flex-container > *,
.ProcessCard__freeBox__gOj3 > .__wab_flex-container > .__wab_slot > *,
.ProcessCard__freeBox__gOj3 > .__wab_flex-container > picture > img,
.ProcessCard__freeBox__gOj3
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.ProcessCard__slotTargetCardTitle__h9Pdu {
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 500;
  color: var(--token-CkHZor_7deI9A3);
}
.ProcessCard__cardHelpLink__xQrg.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.ProcessCard__cardHelpLinkhasHelpLink__xQrg6YTcS.__wab_instance {
  flex-shrink: 0;
  display: flex;
}
.ProcessCard__svg___3E5Pd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__dOfmm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__freeBox__mki {
  position: relative;
  padding-top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
}
.ProcessCard__freeBox__mki > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.ProcessCard__freeBox__mki > .__wab_flex-container > *,
.ProcessCard__freeBox__mki > .__wab_flex-container > .__wab_slot > *,
.ProcessCard__freeBox__mki > .__wab_flex-container > picture > img,
.ProcessCard__freeBox__mki
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.ProcessCard__freeBoxhasPercentageValue__mkIgKlf5 {
  display: flex;
}
.ProcessCard__formGroup__pzvUb.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCard__textInput___5Kt1L.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.ProcessCard__svg__jhVcm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__oFa6M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text__nQeb0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCard__text__lRy0N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__text__ajm8G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__symbolCounter__gaKc.__wab_instance {
  position: relative;
}
.ProcessCard__toggleButton__iqpD6.__wab_instance {
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .ProcessCard__toggleButton__iqpD6.__wab_instance {
    height: 36px;
    flex-shrink: 0;
  }
}
.ProcessCard__toggleButtonisClosed__iqpD6VrXj.__wab_instance {
  flex-shrink: 0;
}
.ProcessCard__toggleButtonhasPercentageValue__iqpD6GKlf5.__wab_instance {
  flex-shrink: 0;
}
.ProcessCard__toggleButtonhasNoExpandButton__iqpD6Q8Is7.__wab_instance {
  flex-shrink: 0;
}
.ProcessCard__svg__owf2V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text___7UKyF {
  height: auto;
}
.ProcessCard__svg___3VGgY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__freeBox___1Cg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .ProcessCard__freeBoxhasPercentageValue___1CggKlf5 {
    display: flex;
  }
}
.ProcessCard__percentageInput2__m4SKq.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.ProcessCard__textInput2__uZum.__wab_instance {
  position: relative;
}
.ProcessCard__svg__gd2Fn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__zllTt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text___4MmsK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCard__text__iLc2V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__text__cp8Xn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__symbolCounter__xl2Ch.__wab_instance {
  position: relative;
}
.ProcessCard__freeBox__oAvTz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.ProcessCard__freeBoxisClosed__oAvTzVrXj {
  display: none;
}
.ProcessCard__processCardSection__p6Q5U.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCard__freeBox__fq8FX {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.ProcessCard__formGroup__fMsgI.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCard__textInput__ctbow.__wab_instance {
  position: relative;
}
.ProcessCard__svg__fdaLn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__qVgRk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text__xBvJk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCard__text__aBhkU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__text__bsoKg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__symbolCounter__klAc7.__wab_instance {
  position: relative;
}
.ProcessCard__formGroup__bgMm3.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCard__text___76YA6 {
  height: auto;
}
.ProcessCard__textInput__bbrob.__wab_instance {
  position: relative;
}
.ProcessCard__svg__jO8Bz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__uRyfe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text__sbm2N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCard__text__ji2Ii {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__text___7B0Rw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__symbolCounter__tkTlF.__wab_instance {
  position: relative;
}
.ProcessCard__formGroup__aDln.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCard__textInput__pvSpT.__wab_instance {
  position: relative;
}
.ProcessCard__svg__r2Nko {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__svg__g9Bom {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCard__text__uKsl3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCard__text__bmm8B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__text__qc1HQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCard__symbolCounter__dqu0V.__wab_instance {
  position: relative;
}
.ProcessCard__freeBox___23JeG {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-P3nFMsZz2JMXx8) var(--token-fkHbhUQaFbf-Tk);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCard__freeBox___23JeG {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessCard__freeBox___23JeG {
    padding-bottom: 8px;
  }
}
.ProcessCard__freeBoxisClosed___23JeGVrXj {
  display: none;
}
.ProcessCard__freeBoxhasOptionalContent___23JeG3Ihzj {
  display: flex;
}
.ProcessCard__toggleOptionalFieldsButton__y8JLn.__wab_instance {
  position: relative;
  width: 100%;
  min-width: 0;
}
.ProcessCard__svg__jlrtL {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ProcessCard__svg__wg5Cn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
