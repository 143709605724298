.DynamicEnergyList__root__nHpbV {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  position: relative;
  min-width: 0;
}
.DynamicEnergyList__freeBox__h6HE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  grid-column-end: span 6;
  min-width: 0;
}
.DynamicEnergyList__freeBox__h6HE > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.DynamicEnergyList__freeBox__h6HE > .__wab_flex-container > *,
.DynamicEnergyList__freeBox__h6HE > .__wab_flex-container > .__wab_slot > *,
.DynamicEnergyList__freeBox__h6HE > .__wab_flex-container > picture > img,
.DynamicEnergyList__freeBox__h6HE
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.DynamicEnergyList__freeBoxisEmpty__h6HEDjP7J {
  display: none;
}
.DynamicEnergyList__dynamicEnergyListItem__jBuBl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__dynamicEnergyListItem__e7Th4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__freeBox__vTkcY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.DynamicEnergyList__freeBoxhiddenAddButton__vTkcY9IRuf {
  display: none;
}
.DynamicEnergyList__freeBox__mjhW4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DynamicEnergyList__freeBox__mjhW4 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.DynamicEnergyList__freeBox__mjhW4 > .__wab_flex-container > *,
.DynamicEnergyList__freeBox__mjhW4 > .__wab_flex-container > .__wab_slot > *,
.DynamicEnergyList__freeBox__mjhW4 > .__wab_flex-container > picture > img,
.DynamicEnergyList__freeBox__mjhW4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.DynamicEnergyList__formGroup__z3BX.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__select___8UZ5G.__wab_instance {
  max-width: none;
  position: relative;
  width: auto;
  align-self: auto;
}
.DynamicEnergyList__svg__yJndU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyList__button__xi7Pu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg___1U8If {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__text__unIyA {
  height: auto;
}
.DynamicEnergyList__svg___0ICpg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__rQ2D.__wab_instance {
  position: relative;
}
.DynamicEnergyList__svg__ceAu9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__lPzW7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__qjpxs.__wab_instance {
  position: relative;
}
.DynamicEnergyList__svg___0OjZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__adzV1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__qtX1V.__wab_instance {
  position: relative;
}
.DynamicEnergyList__svg__eObFp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__rAxdm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__reLe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg___8U22 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__je9Ui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option___7Zye0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg___5UIor {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg___2Zuy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__wxfzi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg___1UhrM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__lvZyK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__oIlHs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg__zShMa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__drhrB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option___8Icvx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg__x9SPk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__d4Kbc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__x6Xm5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg__rpCwN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__qySy1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__option__tP2MK.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicEnergyList__svg__iotEt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyList__svg__vZApl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyList__symbolCounter___0OAcy.__wab_instance {
  position: relative;
}
