.DashboardBanner__root___82TCu.__wab_instance {
  max-width: 100%;
  width: 100%;
  position: relative;
  min-width: 0;
}
.DashboardBanner__button___9QSgN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardBanner__svg__rUj87 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardBanner__svg__kLjKc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
