.DynamicPowerListItem__root__xdWBn {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-P3nFMsZz2JMXx8) var(--token-TJik2L8OcSV6kD)
    var(--token-TJik2L8OcSV6kD);
  border: 1px solid var(--token-O8yxnKF8d);
}
.DynamicPowerListItem__root__xdWBn > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.DynamicPowerListItem__root__xdWBn > .__wab_flex-container > *,
.DynamicPowerListItem__root__xdWBn > .__wab_flex-container > .__wab_slot > *,
.DynamicPowerListItem__root__xdWBn > .__wab_flex-container > picture > img,
.DynamicPowerListItem__root__xdWBn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.DynamicPowerListItem__freeBox__j1AsY {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__freeBox__j1AsY > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.DynamicPowerListItem__freeBox__j1AsY > .__wab_flex-container > *,
.DynamicPowerListItem__freeBox__j1AsY > .__wab_flex-container > .__wab_slot > *,
.DynamicPowerListItem__freeBox__j1AsY > .__wab_flex-container > picture > img,
.DynamicPowerListItem__freeBox__j1AsY
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.DynamicPowerListItem__freeBox___0WTe4 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
}
.DynamicPowerListItem__freeBox___0WTe4 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.DynamicPowerListItem__freeBox___0WTe4 > .__wab_flex-container > *,
.DynamicPowerListItem__freeBox___0WTe4
  > .__wab_flex-container
  > .__wab_slot
  > *,
.DynamicPowerListItem__freeBox___0WTe4 > .__wab_flex-container > picture > img,
.DynamicPowerListItem__freeBox___0WTe4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
.DynamicPowerListItem__text__f32 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-Bl-7HCzgz);
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 600;
  align-self: auto;
  min-width: 0;
}
.DynamicPowerListItem__itemDeleteButton___9RgL9.__wab_instance {
  max-width: 100%;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__itemDeleteButton___9RgL9.__wab_instance {
    flex-shrink: 0;
  }
}
.DynamicPowerListItem__itemDeleteButtonhasNoDeleteButton___9RgL9Ty1Q.__wab_instance {
  flex-shrink: 0;
}
.DynamicPowerListItem__svg__h4Sag {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__svg__nAoei {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__stromEigentumer___0BQOi {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicPowerListItem__stromEigentumerenergyType_stromEigentuemer___0BQOi442M {
  display: grid;
}
.DynamicPowerListItem__formGroup__tiMh5.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__tiMh5.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__tiMh5.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__ocQTs {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmegtKat__zwRn9.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg__qCpyU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option__ilfD.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__tyBwV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__mhpuH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__vceWp.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__dp7Yx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__boVla {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__m2WqD.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__qKvzm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg___5ScTt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__eWnra.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__safiq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__raD9O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__c0BPl.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__ysT4Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__tsAuJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__fiaTk.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__uishp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__ttfH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__yQwac.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup__m1Oku.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__m1Oku.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__m1Oku.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__lZdKa {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmegtVerb__gbFby.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__yo0V3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__svg__pz4O1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__text__zCbX3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicPowerListItem__text__r5Px {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__text__oc8Fc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__symbolCounter__gRfdT.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup__wk5Pm.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__wk5Pm.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__wk5Pm.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__nFzBu {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmegtEinh__hzH7S.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg__jCma {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option__zBuO.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg___8Gxj7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__ho7Lt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option___535Oe.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__mEjve {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__qnNdy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__hPn2D.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__stromMieter__jh4Qa {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicPowerListItem__stromMieterenergyType_stromMieter__jh4QarsPhj {
  display: grid;
}
.DynamicPowerListItem__formGroup__miWoi.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__miWoi.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__miWoi.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__qOci {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmietKat__yXMbB.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg___9BwWg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option___1DlA3.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg___6F5Vh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__dd5P1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__luA5.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__kEdFf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__eokRa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__bl0Ny.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__q7Rzr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__qAg5C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__uZ1K3.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__eUnkO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__qUs35 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__dIlPx.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__ccZe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__cvOdA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__eCh8H.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__fiFah {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg___41HN4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__i12Lw.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup___9CKk6.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup___9CKk6.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup___9CKk6.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__qidIa {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmietVerb__sLdwV.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__wlWlv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__svg__iIIvU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__text__rHduJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicPowerListItem__text__hf0Ot {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__text__t1Rkd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__symbolCounter__mLy31.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup__lj0W1.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__lj0W1.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__lj0W1.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text___3CsEq {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrStrmietEinh__m6Vr6.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg___136G {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option___7I2Ph.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__ki7Zx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__iWwrY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__u4341.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__tKXxm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__qkL3Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__toCiX.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__photovoltaik__lvNwK {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicPowerListItem__photovoltaikenergyType_photovoltaik__lvNwKEg1Kn {
  display: grid;
}
.DynamicPowerListItem__formGroup__vzyMx.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__vzyMx.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__vzyMx.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__nrQoD {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrPvstromKat___4WqSs.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg__piKuj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option__ciqNt.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__b4Q9E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__leUje {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__jla9X.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__tYaSm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__vuMiw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__mJrZ.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup__eRM5.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup__eRM5.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup__eRM5.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text___780Lp {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrPvstromVerb__erMh9.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__miN0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__svg__lnj8N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__text__kubw1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicPowerListItem__text__v2Fzf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__text__wp1S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicPowerListItem__symbolCounter__z77IX.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__formGroup___3QgI3.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicPowerListItem__formGroup___3QgI3.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicPowerListItem__formGroup___3QgI3.__wab_instance {
    width: 100%;
  }
}
.DynamicPowerListItem__text__uJUaR {
  padding-right: 0px;
}
.DynamicPowerListItem__enrEntrPvstromEinh__jGmR1.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicPowerListItem__svg__dh4Ah {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerListItem__option__mjyMf.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg___2GbAp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg___9VjJ6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__option__kPMsZ.__wab_instance {
  position: relative;
}
.DynamicPowerListItem__svg__hWjP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerListItem__svg__mGKch {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerListItem__symbolCounter__qiJz6.__wab_instance {
  position: relative;
}
