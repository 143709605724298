@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-CkHZor_7deI9A3: #ffffff;
  --plasmic-token-white: var(--token-CkHZor_7deI9A3);
  --token-W3D32h7jWJpwP2: #f6f7f7;
  --plasmic-token-black-050: var(--token-W3D32h7jWJpwP2);
  --token-aacnERCHvPoybg: #e0e7e4;
  --plasmic-token-black-100: var(--token-aacnERCHvPoybg);
  --token-RkiaL8IPSKlGAr: #c1cec9;
  --plasmic-token-black-200: var(--token-RkiaL8IPSKlGAr);
  --token-D5PfQGq_p2o95u: #9aaea8;
  --plasmic-token-black-300: var(--token-D5PfQGq_p2o95u);
  --token-c8ADL42ppeG1sf: #758c86;
  --plasmic-token-black-400: var(--token-c8ADL42ppeG1sf);
  --token-8D7PsQmKPjCWdA: #5b716c;
  --plasmic-token-black-500: var(--token-8D7PsQmKPjCWdA);
  --token-_RKWiZNMAdxvNg: #4f645f;
  --plasmic-token-black-600: var(--token-_RKWiZNMAdxvNg);
  --token-kwZ4IqsH_bumEk: #3b4a47;
  --plasmic-token-black-700: var(--token-kwZ4IqsH_bumEk);
  --token-FbjX2dQyRXMDtR: #323d3a;
  --plasmic-token-black-800: var(--token-FbjX2dQyRXMDtR);
  --token-k1rOgIeOLTB8L1: #2c3533;
  --plasmic-token-black-900: var(--token-k1rOgIeOLTB8L1);
  --token-4_CeG-8iBJgDtW: #f0f6ff;
  --plasmic-token-blue-050: var(--token-4_CeG-8iBJgDtW);
  --token-1wSt5dJHZL9Eyo: #ddebfe;
  --plasmic-token-blue-100: var(--token-1wSt5dJHZL9Eyo);
  --token-stSp0Rv36gxavp: #c0dafc;
  --plasmic-token-blue-200: var(--token-stSp0Rv36gxavp);
  --token-i2qCvUVknsIZp2: #96c4f8;
  --plasmic-token-blue-300: var(--token-i2qCvUVknsIZp2);
  --token-WEnykX89Xj8V-V: #66a6f4;
  --plasmic-token-blue-400: var(--token-WEnykX89Xj8V-V);
  --token-BqwcOUIRst2GEg: #4487e4;
  --plasmic-token-blue-500: var(--token-BqwcOUIRst2GEg);
  --token-uafO86Amhx2CAZ: #2e72e0;
  --plasmic-token-blue-600: var(--token-uafO86Amhx2CAZ);
  --token-r4TPVLIqmSzG1h: #2a61cf;
  --plasmic-token-blue-700: var(--token-r4TPVLIqmSzG1h);
  --token-N2WqWxZ34OGI8T: #2851a4;
  --plasmic-token-blue-800: var(--token-N2WqWxZ34OGI8T);
  --token-73C_KCa5JvrUv3: #224087;
  --plasmic-token-blue-900: var(--token-73C_KCa5JvrUv3);
  --token-1lkMHpovBhH3r-: 0.75rem;
  --plasmic-token-text-12-px-xs: var(--token-1lkMHpovBhH3r-);
  --token-Mt-ps-Nmgx1ErQ: 0.88rem;
  --plasmic-token-text-14-px-sm: var(--token-Mt-ps-Nmgx1ErQ);
  --token-lwXLVsVbV9nDvt: 1rem;
  --plasmic-token-text-16-px-base: var(--token-lwXLVsVbV9nDvt);
  --token-xowVQ_ic8i1X0_: 1.13rem;
  --plasmic-token-text-18-px-lg: var(--token-xowVQ_ic8i1X0_);
  --token-WA_NCXKiXv5f4n: 1.25rem;
  --plasmic-token-text-20-px-xl: var(--token-WA_NCXKiXv5f4n);
  --token-JJM6LNeA-0eMML: 1.5rem;
  --plasmic-token-text-24-px-2-xl: var(--token-JJM6LNeA-0eMML);
  --token-rX_DHtXlxFDVF-: 1.88rem;
  --plasmic-token-text-30-px-3-xl: var(--token-rX_DHtXlxFDVF-);
  --token-iRie9MHRquxJOV: 2.25rem;
  --plasmic-token-text-36-px-4-xl: var(--token-iRie9MHRquxJOV);
  --token-nonP3-xC866Qmt: 3rem;
  --plasmic-token-text-48-px-5-xl: var(--token-nonP3-xC866Qmt);
  --token-T1qIehhj4TBvLB: 3.75rem;
  --plasmic-token-text-60-px-6-xl: var(--token-T1qIehhj4TBvLB);
  --token-GajlQG0ccUTNae: 1;
  --plasmic-token-leading-none: var(--token-GajlQG0ccUTNae);
  --token-lWY7kGeXV7C_yD: 1.25;
  --plasmic-token-leading-tight: var(--token-lWY7kGeXV7C_yD);
  --token-97-B3t__LugLsk: 1.38;
  --plasmic-token-leading-snug: var(--token-97-B3t__LugLsk);
  --token-FvQuxC04RZW1hi: 1.5;
  --plasmic-token-leading-normal: var(--token-FvQuxC04RZW1hi);
  --token-7jQoojlaKDe6v7: 1.65;
  --plasmic-token-leading-relaxed: var(--token-7jQoojlaKDe6v7);
  --token-92LjCWNImEhz79: 2;
  --plasmic-token-leading-loose: var(--token-92LjCWNImEhz79);
  --token-iOXFRSAwZrWdi2: #fcf6ee;
  --plasmic-token-gold-050: var(--token-iOXFRSAwZrWdi2);
  --token-im9ygHZ7u_gX0C: #f9eedc;
  --plasmic-token-gold-100: var(--token-im9ygHZ7u_gX0C);
  --token-3XfQdrAjqc1oI9: #efdbb3;
  --plasmic-token-gold-200: var(--token-3XfQdrAjqc1oI9);
  --token-fMOFOdWlIwmyMR: #e3c382;
  --plasmic-token-gold-300: var(--token-fMOFOdWlIwmyMR);
  --token-3XSPC6fTlZxc0L: #d9ad54;
  --plasmic-token-gold-400: var(--token-3XSPC6fTlZxc0L);
  --token-HHAuBKYL3pvmHz: #c99521;
  --plasmic-token-gold-500: var(--token-HHAuBKYL3pvmHz);
  --token-gjAKHzQ_0d9Weq: #ab811f;
  --plasmic-token-gold-600: var(--token-gjAKHzQ_0d9Weq);
  --token-h5K_VQkkzQMizO: #8d6e1d;
  --plasmic-token-gold-700: var(--token-h5K_VQkkzQMizO);
  --token-m1f-RcQESaPRuE: #70591a;
  --plasmic-token-gold-800: var(--token-m1f-RcQESaPRuE);
  --token-vmRjBGgeQdkb50: #4c3f15;
  --plasmic-token-gold-900: var(--token-vmRjBGgeQdkb50);
  --token--h6FHSrgUAQsDe: #f7fff7;
  --plasmic-token-green-050: var(--token--h6FHSrgUAQsDe);
  --token-Ga5PN1FRCoAB6A: #e9feeb;
  --plasmic-token-green-100: var(--token-Ga5PN1FRCoAB6A);
  --token-WqiMp9lH7V64qa: #cdfad5;
  --plasmic-token-green-200: var(--token-WqiMp9lH7V64qa);
  --token-plIc-sUNu2-k5-: #b5f3c4;
  --plasmic-token-green-300: var(--token-plIc-sUNu2-k5-);
  --token-xgado2J7YlFS13: #a0e9b7;
  --plasmic-token-green-400: var(--token-xgado2J7YlFS13);
  --token-6_9iE0w_5TGI8j: #86d4a5;
  --plasmic-token-green-500: var(--token-6_9iE0w_5TGI8j);
  --token-QI60SWB06gBJ5K: #6cb790;
  --plasmic-token-green-600: var(--token-QI60SWB06gBJ5K);
  --token-vvi_7r45uYs8w7: #54997b;
  --plasmic-token-green-700: var(--token-vvi_7r45uYs8w7);
  --token-NfSc1CXMklYEK2: #30735c;
  --plasmic-token-green-800: var(--token-NfSc1CXMklYEK2);
  --token-ObRRGpZ-v1PQfN: #1e4f42;
  --plasmic-token-green-900: var(--token-ObRRGpZ-v1PQfN);
  --token-ATEWP6hIq5CbNw: #fdf2f2;
  --plasmic-token-red-050: var(--token-ATEWP6hIq5CbNw);
  --token-fKmQxzFxQYEI3S: #fce4e3;
  --plasmic-token-red-100: var(--token-fKmQxzFxQYEI3S);
  --token-ZPfWHFliL43P_r: #fbcccb;
  --plasmic-token-red-200: var(--token-ZPfWHFliL43P_r);
  --token-4U0t8o9I_jm3I_: #f8adab;
  --plasmic-token-red-300: var(--token-4U0t8o9I_jm3I_);
  --token-SNDFNRi9ingNbb: #f17d79;
  --plasmic-token-red-400: var(--token-SNDFNRi9ingNbb);
  --token-d6Fhz0OrC2AHcn: #e4534e;
  --plasmic-token-red-500: var(--token-d6Fhz0OrC2AHcn);
  --token-nBkEYAcG_o3k6g: #d03a35;
  --plasmic-token-red-600: var(--token-nBkEYAcG_o3k6g);
  --token-1EW9WFy_Sby7ig: #b02b27;
  --plasmic-token-red-700: var(--token-1EW9WFy_Sby7ig);
  --token-9Zm0PFeo1rK0Eh: #8f2724;
  --plasmic-token-red-800: var(--token-9Zm0PFeo1rK0Eh);
  --token-NFXZ__zPMi8_rv: #792825;
  --plasmic-token-red-900: var(--token-NFXZ__zPMi8_rv);
  --token-YGkzw-IqGKzMqr: #fff7ed;
  --plasmic-token-orange-050: var(--token-YGkzw-IqGKzMqr);
  --token-P5Q55lfBPHP7gh: #ffedd5;
  --plasmic-token-orange-100: var(--token-P5Q55lfBPHP7gh);
  --token-9Yz2i4T7zqOj1c: #fed7aa;
  --plasmic-token-orange-200: var(--token-9Yz2i4T7zqOj1c);
  --token-GiX5g5XVDVSbZB: #fdba74;
  --plasmic-token-orange-300: var(--token-GiX5g5XVDVSbZB);
  --token-UQb389MFjCYuwK: #fb923c;
  --plasmic-token-orange-400: var(--token-UQb389MFjCYuwK);
  --token-6VQ__RqwTsOFsq: #f97316;
  --plasmic-token-orange-500: var(--token-6VQ__RqwTsOFsq);
  --token-Va8fP8SDJXmUCM: #ea580c;
  --plasmic-token-orange-600: var(--token-Va8fP8SDJXmUCM);
  --token-dF6--250OOKgt2: #c2410c;
  --plasmic-token-orange-700: var(--token-dF6--250OOKgt2);
  --token-ucQuGlkCRySdTX: #9a3412;
  --plasmic-token-orange-800: var(--token-ucQuGlkCRySdTX);
  --token-rT8-IAfiNlr-EF: #7c2d12;
  --plasmic-token-orange-900: var(--token-rT8-IAfiNlr-EF);
  --token-a0wt3E9LJjO_Nh: 0.25rem;
  --plasmic-token-1: var(--token-a0wt3E9LJjO_Nh);
  --token-u4z6Uru_YfAR62: 1px;
  --plasmic-token-1-px: var(--token-u4z6Uru_YfAR62);
  --token-uC-_WkHfVJTm0T: 0.125rem;
  --plasmic-token-0-5: var(--token-uC-_WkHfVJTm0T);
  --token-HaxgWLsrVFdD87: 0.375rem;
  --plasmic-token-1-5: var(--token-HaxgWLsrVFdD87);
  --token-A-OXrd0RfsEv85: 0.5rem;
  --plasmic-token-2: var(--token-A-OXrd0RfsEv85);
  --token-sAYB8IqtelB_J-: 0.625rem;
  --plasmic-token-2-5: var(--token-sAYB8IqtelB_J-);
  --token-7uzqW3y-2Tgb_s: 0.75rem;
  --plasmic-token-3: var(--token-7uzqW3y-2Tgb_s);
  --token-TuNigM0cRbp1Tz: 0.875rem;
  --plasmic-token-3-5: var(--token-TuNigM0cRbp1Tz);
  --token-P3nFMsZz2JMXx8: 1rem;
  --plasmic-token-4: var(--token-P3nFMsZz2JMXx8);
  --token-C4KuLs5uqsxF8L: 1.25rem;
  --plasmic-token-5: var(--token-C4KuLs5uqsxF8L);
  --token-TJik2L8OcSV6kD: 1.5rem;
  --plasmic-token-6: var(--token-TJik2L8OcSV6kD);
  --token-Wf1GGJSbrxsgS-: 1.75rem;
  --plasmic-token-7: var(--token-Wf1GGJSbrxsgS-);
  --token-HUUwrpE5bq87rt: 2rem;
  --plasmic-token-8: var(--token-HUUwrpE5bq87rt);
  --token-x_jRp3a5FpON8z: 2.5rem;
  --plasmic-token-10: var(--token-x_jRp3a5FpON8z);
  --token-76-ojDPNB2wcuq: 2.75rem;
  --plasmic-token-11: var(--token-76-ojDPNB2wcuq);
  --token-YOOgp3GSpDYosY: 3rem;
  --plasmic-token-12: var(--token-YOOgp3GSpDYosY);
  --token-fkHbhUQaFbf-Tk: 3.5rem;
  --plasmic-token-14: var(--token-fkHbhUQaFbf-Tk);
  --token-ti5ui7Y6hnPLfa: 4rem;
  --plasmic-token-16: var(--token-ti5ui7Y6hnPLfa);
  --token-qecgvVQwVo7jFR: 5rem;
  --plasmic-token-20: var(--token-qecgvVQwVo7jFR);
  --token-6rZumor6mC3fF5: 6rem;
  --plasmic-token-24: var(--token-6rZumor6mC3fF5);
  --token-ew2vDb1S1Vm4F-: 7rem;
  --plasmic-token-28: var(--token-ew2vDb1S1Vm4F-);
  --token-9Z8YcysjO1Mxmw: 8rem;
  --plasmic-token-32: var(--token-9Z8YcysjO1Mxmw);
  --token-nxn_GzdZNbElfW: 9rem;
  --plasmic-token-36: var(--token-nxn_GzdZNbElfW);
  --token-3K3yS7MMaSUwfX: 10rem;
  --plasmic-token-40: var(--token-3K3yS7MMaSUwfX);
  --token-0xWzK8f0I-k60k: 11rem;
  --plasmic-token-44: var(--token-0xWzK8f0I-k60k);
  --token-7v2UIibMzJog1w: 12rem;
  --plasmic-token-48: var(--token-7v2UIibMzJog1w);
  --token-xKzRapqMqHd_G6: 13rem;
  --plasmic-token-52: var(--token-xKzRapqMqHd_G6);
  --token-zDJiJqymByMGjf: 14rem;
  --plasmic-token-56: var(--token-zDJiJqymByMGjf);
  --token-_YzvmHI6MRIxkO: 15rem;
  --plasmic-token-60: var(--token-_YzvmHI6MRIxkO);
  --token-LPtIhe60_ObfSA: 16rem;
  --plasmic-token-64: var(--token-LPtIhe60_ObfSA);
  --token-vUDvPckoVTsHcM: 18rem;
  --plasmic-token-72: var(--token-vUDvPckoVTsHcM);
  --token-60l-SP04uxf_ml: 20rem;
  --plasmic-token-80: var(--token-60l-SP04uxf_ml);
  --token-NOJfn1pjliQPXY: 2.25rem;
  --plasmic-token-9: var(--token-NOJfn1pjliQPXY);
  --token-9x4bA1L_y-Njej: 24rem;
  --plasmic-token-96: var(--token-9x4bA1L_y-Njej);
  --token-gBTCEyDxU: #f4f9f5;
  --plasmic-token-dark-green-050: var(--token-gBTCEyDxU);
  --token-Bl-7HCzgz: #dfeee3;
  --plasmic-token-dark-green-100: var(--token-Bl-7HCzgz);
  --token-L1EeQgMLm: #cae2d1;
  --plasmic-token-dark-green-200: var(--token-L1EeQgMLm);
  --token-7xB3ITuN8: #90bc9f;
  --plasmic-token-dark-green-300: var(--token-7xB3ITuN8);
  --token-s9gzWmEFR: #6b9f81;
  --plasmic-token-dark-green-400: var(--token-s9gzWmEFR);
  --token-zw8MjMyAW: #387055;
  --plasmic-token-dark-green-500: var(--token-zw8MjMyAW);
  --token-umjgs5uqk: #2c624c;
  --plasmic-token-dark-green-600: var(--token-umjgs5uqk);
  --token-SqSK5Lk3X: #215242;
  --plasmic-token-dark-green-700: var(--token-SqSK5Lk3X);
  --token-IaDlhQPgR: #184137;
  --plasmic-token-dark-green-800: var(--token-IaDlhQPgR);
  --token-hEzPFeV5Y: #0f2e29;
  --plasmic-token-dark-green-900: var(--token-hEzPFeV5Y);
  --token-niMfN0aK_: #e7ffef14;
  --plasmic-token-white-050-a: var(--token-niMfN0aK_);
  --token-KVbFqNIPJ: #e7ffef1f;
  --plasmic-token-white-100-a: var(--token-KVbFqNIPJ);
  --token-NfHg4McmY: #e7ffef33;
  --plasmic-token-white-200-a: var(--token-NfHg4McmY);
  --token-yDpImGgAX: #e7ffef4d;
  --plasmic-token-white-300-a: var(--token-yDpImGgAX);
  --token-31C7iZ0eD: #e7ffef66;
  --plasmic-token-white-400-a: var(--token-31C7iZ0eD);
  --token-rqm9BSu09: #e7ffef80;
  --plasmic-token-white-500-a: var(--token-rqm9BSu09);
  --token-ZviHPuuzk: #e7ffef99;
  --plasmic-token-white-600-a: var(--token-ZviHPuuzk);
  --token--EHWRjPIh: #e7ffefb3;
  --plasmic-token-white-700-a: var(--token--EHWRjPIh);
  --token-zqrEV3Z8z: #e7ffefcc;
  --plasmic-token-white-800-a: var(--token-zqrEV3Z8z);
  --token-SkTYvRtmE: var(--token-ZviHPuuzk);
  --plasmic-token-text-muted: var(--token-SkTYvRtmE);
  --token-O8yxnKF8d: #e7ffef0a;
  --plasmic-token-white-025-a: var(--token-O8yxnKF8d);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-S3WiINcMlaJbww_font-family: "Inter", sans-serif;
  --mixin-S3WiINcMlaJbww_font-size: 16px;
  --mixin-S3WiINcMlaJbww_font-weight: 400;
  --mixin-S3WiINcMlaJbww_font-style: normal;
  --mixin-S3WiINcMlaJbww_color: var(--token-gBTCEyDxU);
  --mixin-S3WiINcMlaJbww_text-align: left;
  --mixin-S3WiINcMlaJbww_text-transform: none;
  --mixin-S3WiINcMlaJbww_line-height: 1.5;
  --mixin-S3WiINcMlaJbww_letter-spacing: normal;
  --mixin-S3WiINcMlaJbww_white-space: pre-wrap;
  --mixin-S3WiINcMlaJbww_user-select: text;
  --mixin-S3WiINcMlaJbww_text-decoration-line: none;
  --mixin-S3WiINcMlaJbww_text-overflow: clip;
  --mixin-YUJischK61kmVo_font-weight: 600;
  --mixin-YUJischK61kmVo_font-size: var(--token-T1qIehhj4TBvLB);
  --mixin-YUJischK61kmVo_line-height: var(--token-lWY7kGeXV7C_yD);
  --mixin-YUJischK61kmVo_letter-spacing: -1px;
  --mixin-YUJischK61kmVo_white-space: pre-wrap;
  --mixin-4_lFmndW31IT6F_font-size: var(--token-nonP3-xC866Qmt);
  --mixin-4_lFmndW31IT6F_font-weight: 600;
  --mixin-4_lFmndW31IT6F_letter-spacing: -1px;
  --mixin-4_lFmndW31IT6F_line-height: var(--token-lWY7kGeXV7C_yD);
  --mixin-4_lFmndW31IT6F_white-space: pre-wrap;
  --mixin-g_idphWGM0IcfC_color: var(--token-xgado2J7YlFS13);
  --mixin-g_idphWGM0IcfC_transition-property: color;
  --mixin-g_idphWGM0IcfC_transition-duration: 0.2s;
  --mixin-g_idphWGM0IcfC_transition-timing-function: ease-in-out;
  --mixin-g_idphWGM0IcfC_text-decoration-line: none;
  --mixin-g_idphWGM0IcfC_white-space: pre-wrap;
  --mixin-md9ydSzZJ9zxUs_font-size: var(--token-iRie9MHRquxJOV);
  --mixin-md9ydSzZJ9zxUs_font-weight: 600;
  --mixin-md9ydSzZJ9zxUs_letter-spacing: -0.8px;
  --mixin-md9ydSzZJ9zxUs_line-height: var(--token-lWY7kGeXV7C_yD);
  --mixin-md9ydSzZJ9zxUs_white-space: pre-wrap;
  --mixin-hdlOtfuGiJidXd_font-size: var(--token-JJM6LNeA-0eMML);
  --mixin-hdlOtfuGiJidXd_font-weight: 600;
  --mixin-hdlOtfuGiJidXd_letter-spacing: -0.5px;
  --mixin-hdlOtfuGiJidXd_line-height: var(--token-97-B3t__LugLsk);
  --mixin-hdlOtfuGiJidXd_white-space: pre-wrap;
  --mixin-mJy4qNbu-Z54cv_background: linear-gradient(
    var(--token-aacnERCHvPoybg),
    var(--token-aacnERCHvPoybg)
  );
  --mixin-mJy4qNbu-Z54cv_border-bottom-color: var(--token-D5PfQGq_p2o95u);
  --mixin-mJy4qNbu-Z54cv_border-bottom-style: solid;
  --mixin-mJy4qNbu-Z54cv_border-bottom-width: 1px;
  --mixin-mJy4qNbu-Z54cv_border-left-color: var(--token-D5PfQGq_p2o95u);
  --mixin-mJy4qNbu-Z54cv_border-left-style: solid;
  --mixin-mJy4qNbu-Z54cv_border-left-width: 1px;
  --mixin-mJy4qNbu-Z54cv_border-right-color: var(--token-D5PfQGq_p2o95u);
  --mixin-mJy4qNbu-Z54cv_border-right-style: solid;
  --mixin-mJy4qNbu-Z54cv_border-right-width: 1px;
  --mixin-mJy4qNbu-Z54cv_border-top-color: var(--token-D5PfQGq_p2o95u);
  --mixin-mJy4qNbu-Z54cv_border-top-style: solid;
  --mixin-mJy4qNbu-Z54cv_border-top-width: 1px;
  --mixin-mJy4qNbu-Z54cv_border-bottom-left-radius: 4px;
  --mixin-mJy4qNbu-Z54cv_border-bottom-right-radius: 4px;
  --mixin-mJy4qNbu-Z54cv_border-top-left-radius: 4px;
  --mixin-mJy4qNbu-Z54cv_border-top-right-radius: 4px;
  --mixin-mJy4qNbu-Z54cv_font-family: "Inconsolata";
  --mixin-mJy4qNbu-Z54cv_padding-bottom: 4px;
  --mixin-mJy4qNbu-Z54cv_padding-left: 8px;
  --mixin-mJy4qNbu-Z54cv_padding-right: 8px;
  --mixin-mJy4qNbu-Z54cv_padding-top: 4px;
  --mixin-mJy4qNbu-Z54cv_font-size: var(--token-lwXLVsVbV9nDvt);
  --mixin-mJy4qNbu-Z54cv_color: var(--token-_RKWiZNMAdxvNg);
  --mixin-mJy4qNbu-Z54cv_white-space: pre-wrap;
  --mixin-PZJfDR9PLq8fKJ_border-left-color: var(--token-D5PfQGq_p2o95u);
  --mixin-PZJfDR9PLq8fKJ_border-left-style: solid;
  --mixin-PZJfDR9PLq8fKJ_border-left-width: 3px;
  --mixin-PZJfDR9PLq8fKJ_color: var(--token-kwZ4IqsH_bumEk);
  --mixin-PZJfDR9PLq8fKJ_padding-left: 10px;
  --mixin-PZJfDR9PLq8fKJ_font-size: var(--token-lwXLVsVbV9nDvt);
  --mixin-PZJfDR9PLq8fKJ_white-space: pre-wrap;
  --mixin-VSzA6M2qHKklO0_background: linear-gradient(
    var(--token-aacnERCHvPoybg),
    var(--token-aacnERCHvPoybg)
  );
  --mixin-VSzA6M2qHKklO0_border-bottom-color: var(--token-D5PfQGq_p2o95u);
  --mixin-VSzA6M2qHKklO0_border-bottom-style: solid;
  --mixin-VSzA6M2qHKklO0_border-bottom-width: 1px;
  --mixin-VSzA6M2qHKklO0_border-left-color: var(--token-D5PfQGq_p2o95u);
  --mixin-VSzA6M2qHKklO0_border-left-style: solid;
  --mixin-VSzA6M2qHKklO0_border-left-width: 1px;
  --mixin-VSzA6M2qHKklO0_border-right-color: var(--token-D5PfQGq_p2o95u);
  --mixin-VSzA6M2qHKklO0_border-right-style: solid;
  --mixin-VSzA6M2qHKklO0_border-right-width: 1px;
  --mixin-VSzA6M2qHKklO0_border-top-color: var(--token-D5PfQGq_p2o95u);
  --mixin-VSzA6M2qHKklO0_border-top-style: solid;
  --mixin-VSzA6M2qHKklO0_border-top-width: 1px;
  --mixin-VSzA6M2qHKklO0_border-bottom-left-radius: 3px;
  --mixin-VSzA6M2qHKklO0_border-bottom-right-radius: 3px;
  --mixin-VSzA6M2qHKklO0_border-top-left-radius: 3px;
  --mixin-VSzA6M2qHKklO0_border-top-right-radius: 3px;
  --mixin-VSzA6M2qHKklO0_font-family: "Inconsolata";
  --mixin-VSzA6M2qHKklO0_padding-bottom: 4px;
  --mixin-VSzA6M2qHKklO0_padding-left: 8px;
  --mixin-VSzA6M2qHKklO0_padding-right: 8px;
  --mixin-VSzA6M2qHKklO0_padding-top: 4px;
  --mixin-VSzA6M2qHKklO0_font-size: var(--token-lwXLVsVbV9nDvt);
  --mixin-VSzA6M2qHKklO0_white-space: pre-wrap;
  --mixin-OTNK8XO825RqLf_display: flex;
  --mixin-OTNK8XO825RqLf_flex-direction: column;
  --mixin-OTNK8XO825RqLf_align-items: stretch;
  --mixin-OTNK8XO825RqLf_justify-content: flex-start;
  --mixin-OTNK8XO825RqLf_list-style-position: outside;
  --mixin-OTNK8XO825RqLf_position: relative;
  --mixin-OTNK8XO825RqLf_list-style-type: disc;
  --mixin-OTNK8XO825RqLf_padding-left: 16px;
  --mixin-OTNK8XO825RqLf_white-space: pre-wrap;
  --mixin-kZrimlkU00RPeo_display: flex;
  --mixin-kZrimlkU00RPeo_flex-direction: column;
  --mixin-kZrimlkU00RPeo_align-items: stretch;
  --mixin-kZrimlkU00RPeo_justify-content: flex-start;
  --mixin-kZrimlkU00RPeo_list-style-position: outside;
  --mixin-kZrimlkU00RPeo_padding-left: 40px;
  --mixin-kZrimlkU00RPeo_position: relative;
  --mixin-kZrimlkU00RPeo_list-style-type: decimal;
  --mixin-kZrimlkU00RPeo_font-size: var(--token-lwXLVsVbV9nDvt);
  --mixin-kZrimlkU00RPeo_white-space: pre-wrap;
  --mixin-Dtj4trBpZ1IZlY_font-family: "Inter", sans-serif;
  --mixin-Dtj4trBpZ1IZlY_font-size: var(--token-WA_NCXKiXv5f4n);
  --mixin-Dtj4trBpZ1IZlY_font-weight: 600;
  --mixin-Dtj4trBpZ1IZlY_line-height: var(--token-FvQuxC04RZW1hi);
  --mixin-Dtj4trBpZ1IZlY_white-space: pre-wrap;
  --mixin-t6BCPHBP_PJHX9_font-size: var(--token-lwXLVsVbV9nDvt);
  --mixin-t6BCPHBP_PJHX9_font-weight: 600;
  --mixin-t6BCPHBP_PJHX9_line-height: var(--token-FvQuxC04RZW1hi);
  --mixin-t6BCPHBP_PJHX9_white-space: pre-wrap;
  --mixin-QB6G_5ZRBqsFTL_color: var(--token-QI60SWB06gBJ5K);
  --mixin-QB6G_5ZRBqsFTL_text-decoration-line: none;
  --mixin-QB6G_5ZRBqsFTL_white-space: pre-wrap;
  --mixin-MpgxQLiCzvMj8V_white-space: pre-wrap;
  --mixin-7G92PnukbLp26-_white-space: pre-wrap;
  --mixin-o_amFD9umV_DEy_white-space: pre-wrap;
  --mixin-h9OHjm8W0BZyY9_white-space: pre-wrap;
  --mixin-dprNhGWP1aUniV_white-space: pre-wrap;
  --mixin-plIwu8eXMjQ3a1_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-2HEkQVljub4o_U_white-space: pre-wrap;
  --plasmic-mixin-border-top_white-space: var(
    --mixin-2HEkQVljub4o_U_white-space
  );
  --mixin-7VaW-GHj5zULPg_white-space: pre-wrap;
  --plasmic-mixin-border-bottom_white-space: var(
    --mixin-7VaW-GHj5zULPg_white-space
  );
  --mixin-rRssE7PBxlwMcQ_white-space: pre-wrap;
  --plasmic-mixin-border-left_white-space: var(
    --mixin-rRssE7PBxlwMcQ_white-space
  );
  --mixin-jRgoZwSl2x-aGN_white-space: pre-wrap;
  --plasmic-mixin-border-right_white-space: var(
    --mixin-jRgoZwSl2x-aGN_white-space
  );
  --mixin-C52NRHLk3_white-space: pre-wrap;
  --plasmic-mixin-radius-xs_white-space: var(--mixin-C52NRHLk3_white-space);
  --mixin-PwQkx31bbq6JX6_white-space: pre-wrap;
  --plasmic-mixin-radius-sm_white-space: var(
    --mixin-PwQkx31bbq6JX6_white-space
  );
  --mixin-NWp0lITmM-FMs-_white-space: pre-wrap;
  --plasmic-mixin-radius_white-space: var(--mixin-NWp0lITmM-FMs-_white-space);
  --mixin-8lu3F4ijASXINi_white-space: pre-wrap;
  --plasmic-mixin-radius-md_white-space: var(
    --mixin-8lu3F4ijASXINi_white-space
  );
  --mixin-M0ee7-MdqiPiFr_white-space: pre-wrap;
  --plasmic-mixin-radius-lg_white-space: var(
    --mixin-M0ee7-MdqiPiFr_white-space
  );
  --mixin--VqUPPeuAM6q7X_white-space: pre-wrap;
  --plasmic-mixin-radius-rounded_white-space: var(
    --mixin--VqUPPeuAM6q7X_white-space
  );
  --mixin-EjIkA-bP4oA2Yw_white-space: pre-wrap;
  --plasmic-mixin-card_white-space: var(--mixin-EjIkA-bP4oA2Yw_white-space);
  --mixin-5dMrvYnoz8YPms_box-shadow: 0px 1px 3px -1px #00000014;
  --plasmic-mixin-shadow-sm_box-shadow: var(--mixin-5dMrvYnoz8YPms_box-shadow);
  --mixin-5dMrvYnoz8YPms_white-space: pre-wrap;
  --plasmic-mixin-shadow-sm_white-space: var(
    --mixin-5dMrvYnoz8YPms_white-space
  );
  --mixin-q4Ap7dZFdj88UZ_box-shadow: 0px 1px 3px 0px #0000001a,
    0px 1px 2px -1px #0000001a;
  --plasmic-mixin-shadow_box-shadow: var(--mixin-q4Ap7dZFdj88UZ_box-shadow);
  --mixin-q4Ap7dZFdj88UZ_white-space: pre-wrap;
  --plasmic-mixin-shadow_white-space: var(--mixin-q4Ap7dZFdj88UZ_white-space);
  --mixin-VIMgZiXxCL4fmj_box-shadow: 0px 4px 6px -1px #0000001a,
    0px 2px 4px -2px #0000001a;
  --plasmic-mixin-shadow-md_box-shadow: var(--mixin-VIMgZiXxCL4fmj_box-shadow);
  --mixin-VIMgZiXxCL4fmj_white-space: pre-wrap;
  --plasmic-mixin-shadow-md_white-space: var(
    --mixin-VIMgZiXxCL4fmj_white-space
  );
  --mixin-e274pFgcZAAFhI_box-shadow: 0px 10px 15px -4px #0000001a,
    0px 4px 6px -4px #0000001a;
  --plasmic-mixin-shadow-lg_box-shadow: var(--mixin-e274pFgcZAAFhI_box-shadow);
  --mixin-e274pFgcZAAFhI_white-space: pre-wrap;
  --plasmic-mixin-shadow-lg_white-space: var(
    --mixin-e274pFgcZAAFhI_white-space
  );
  --mixin-cU6ePNweeZ8rVo_box-shadow: 0px 20px 25px -5px #0000001a,
    0px 8px 10px -6px #0000001a;
  --plasmic-mixin-shadow-xl_box-shadow: var(--mixin-cU6ePNweeZ8rVo_box-shadow);
  --mixin-cU6ePNweeZ8rVo_white-space: pre-wrap;
  --plasmic-mixin-shadow-xl_white-space: var(
    --mixin-cU6ePNweeZ8rVo_white-space
  );
  --mixin-jkzf9gY8OBgXXY_white-space: pre-wrap;
  --plasmic-mixin-caps-title-small_white-space: var(
    --mixin-jkzf9gY8OBgXXY_white-space
  );
  --mixin-uEMyx1xqh_white-space: pre-wrap;
  --plasmic-mixin-container-xl_white-space: var(--mixin-uEMyx1xqh_white-space);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) {
  font-family: var(--mixin-S3WiINcMlaJbww_font-family);
  font-size: var(--mixin-S3WiINcMlaJbww_font-size);
  font-weight: var(--mixin-S3WiINcMlaJbww_font-weight);
  font-style: var(--mixin-S3WiINcMlaJbww_font-style);
  color: var(--mixin-S3WiINcMlaJbww_color);
  text-align: var(--mixin-S3WiINcMlaJbww_text-align);
  text-transform: var(--mixin-S3WiINcMlaJbww_text-transform);
  line-height: var(--mixin-S3WiINcMlaJbww_line-height);
  letter-spacing: var(--mixin-S3WiINcMlaJbww_letter-spacing);
  white-space: var(--mixin-S3WiINcMlaJbww_white-space);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h1:where(.plasmic_default__h1),
h1:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h1),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h1,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h1,
h1:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-weight: var(--mixin-YUJischK61kmVo_font-weight);
  font-size: var(--mixin-YUJischK61kmVo_font-size);
  line-height: var(--mixin-YUJischK61kmVo_line-height);
  letter-spacing: var(--mixin-YUJischK61kmVo_letter-spacing);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h2:where(.plasmic_default__h2),
h2:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h2),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h2,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h2,
h2:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-size: var(--mixin-4_lFmndW31IT6F_font-size);
  font-weight: var(--mixin-4_lFmndW31IT6F_font-weight);
  letter-spacing: var(--mixin-4_lFmndW31IT6F_letter-spacing);
  line-height: var(--mixin-4_lFmndW31IT6F_line-height);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) a:where(.plasmic_default__a),
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__a),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) a,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) a,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  color: var(--mixin-g_idphWGM0IcfC_color);
  transition-property: var(--mixin-g_idphWGM0IcfC_transition-property);
  transition-duration: var(--mixin-g_idphWGM0IcfC_transition-duration);
  transition-timing-function: var(
    --mixin-g_idphWGM0IcfC_transition-timing-function
  );
  text-decoration-line: var(--mixin-g_idphWGM0IcfC_text-decoration-line);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h3:where(.plasmic_default__h3),
h3:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h3),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h3,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h3,
h3:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-size: var(--mixin-md9ydSzZJ9zxUs_font-size);
  font-weight: var(--mixin-md9ydSzZJ9zxUs_font-weight);
  letter-spacing: var(--mixin-md9ydSzZJ9zxUs_letter-spacing);
  line-height: var(--mixin-md9ydSzZJ9zxUs_line-height);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h4:where(.plasmic_default__h4),
h4:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h4),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h4,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h4,
h4:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-size: var(--mixin-hdlOtfuGiJidXd_font-size);
  font-weight: var(--mixin-hdlOtfuGiJidXd_font-weight);
  letter-spacing: var(--mixin-hdlOtfuGiJidXd_letter-spacing);
  line-height: var(--mixin-hdlOtfuGiJidXd_line-height);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) code:where(.plasmic_default__code),
code:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__code),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) code,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) code,
code:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  background: var(--token-aacnERCHvPoybg);
  font-family: var(--mixin-mJy4qNbu-Z54cv_font-family);
  font-size: var(--mixin-mJy4qNbu-Z54cv_font-size);
  color: var(--mixin-mJy4qNbu-Z54cv_color);
  border-radius: var(--mixin-mJy4qNbu-Z54cv_border-top-left-radius)
    var(--mixin-mJy4qNbu-Z54cv_border-top-right-radius)
    var(--mixin-mJy4qNbu-Z54cv_border-bottom-right-radius)
    var(--mixin-mJy4qNbu-Z54cv_border-bottom-left-radius);
  padding: var(--mixin-mJy4qNbu-Z54cv_padding-top)
    var(--mixin-mJy4qNbu-Z54cv_padding-right)
    var(--mixin-mJy4qNbu-Z54cv_padding-bottom)
    var(--mixin-mJy4qNbu-Z54cv_padding-left);
  border-top: var(--mixin-mJy4qNbu-Z54cv_border-top-width)
    var(--mixin-mJy4qNbu-Z54cv_border-top-style)
    var(--mixin-mJy4qNbu-Z54cv_border-top-color);
  border-right: var(--mixin-mJy4qNbu-Z54cv_border-right-width)
    var(--mixin-mJy4qNbu-Z54cv_border-right-style)
    var(--mixin-mJy4qNbu-Z54cv_border-right-color);
  border-bottom: var(--mixin-mJy4qNbu-Z54cv_border-bottom-width)
    var(--mixin-mJy4qNbu-Z54cv_border-bottom-style)
    var(--mixin-mJy4qNbu-Z54cv_border-bottom-color);
  border-left: var(--mixin-mJy4qNbu-Z54cv_border-left-width)
    var(--mixin-mJy4qNbu-Z54cv_border-left-style)
    var(--mixin-mJy4qNbu-Z54cv_border-left-color);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y)
  blockquote:where(.plasmic_default__blockquote),
blockquote:where(
    .root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__blockquote
  ),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) blockquote,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) blockquote,
blockquote:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  color: var(--mixin-PZJfDR9PLq8fKJ_color);
  padding-left: var(--mixin-PZJfDR9PLq8fKJ_padding-left);
  font-size: var(--mixin-PZJfDR9PLq8fKJ_font-size);
  border-left: var(--mixin-PZJfDR9PLq8fKJ_border-left-width)
    var(--mixin-PZJfDR9PLq8fKJ_border-left-style)
    var(--mixin-PZJfDR9PLq8fKJ_border-left-color);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) pre:where(.plasmic_default__pre),
pre:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__pre),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) pre,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) pre,
pre:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  background: var(--token-aacnERCHvPoybg);
  font-family: var(--mixin-VSzA6M2qHKklO0_font-family);
  font-size: var(--mixin-VSzA6M2qHKklO0_font-size);
  border-radius: var(--mixin-VSzA6M2qHKklO0_border-top-left-radius)
    var(--mixin-VSzA6M2qHKklO0_border-top-right-radius)
    var(--mixin-VSzA6M2qHKklO0_border-bottom-right-radius)
    var(--mixin-VSzA6M2qHKklO0_border-bottom-left-radius);
  padding: var(--mixin-VSzA6M2qHKklO0_padding-top)
    var(--mixin-VSzA6M2qHKklO0_padding-right)
    var(--mixin-VSzA6M2qHKklO0_padding-bottom)
    var(--mixin-VSzA6M2qHKklO0_padding-left);
  border-top: var(--mixin-VSzA6M2qHKklO0_border-top-width)
    var(--mixin-VSzA6M2qHKklO0_border-top-style)
    var(--mixin-VSzA6M2qHKklO0_border-top-color);
  border-right: var(--mixin-VSzA6M2qHKklO0_border-right-width)
    var(--mixin-VSzA6M2qHKklO0_border-right-style)
    var(--mixin-VSzA6M2qHKklO0_border-right-color);
  border-bottom: var(--mixin-VSzA6M2qHKklO0_border-bottom-width)
    var(--mixin-VSzA6M2qHKklO0_border-bottom-style)
    var(--mixin-VSzA6M2qHKklO0_border-bottom-color);
  border-left: var(--mixin-VSzA6M2qHKklO0_border-left-width)
    var(--mixin-VSzA6M2qHKklO0_border-left-style)
    var(--mixin-VSzA6M2qHKklO0_border-left-color);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) ul:where(.plasmic_default__ul),
ul:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__ul),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) ul,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) ul,
ul:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  display: var(--mixin-OTNK8XO825RqLf_display);
  flex-direction: var(--mixin-OTNK8XO825RqLf_flex-direction);
  align-items: var(--mixin-OTNK8XO825RqLf_align-items);
  justify-content: var(--mixin-OTNK8XO825RqLf_justify-content);
  list-style-position: var(--mixin-OTNK8XO825RqLf_list-style-position);
  position: var(--mixin-OTNK8XO825RqLf_position);
  list-style-type: var(--mixin-OTNK8XO825RqLf_list-style-type);
  padding-left: var(--mixin-OTNK8XO825RqLf_padding-left);
  flex-column-gap: var(--mixin-OTNK8XO825RqLf_flex-column-gap);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) ol:where(.plasmic_default__ol),
ol:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__ol),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) ol,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) ol,
ol:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  display: var(--mixin-kZrimlkU00RPeo_display);
  flex-direction: var(--mixin-kZrimlkU00RPeo_flex-direction);
  align-items: var(--mixin-kZrimlkU00RPeo_align-items);
  justify-content: var(--mixin-kZrimlkU00RPeo_justify-content);
  list-style-position: var(--mixin-kZrimlkU00RPeo_list-style-position);
  padding-left: var(--mixin-kZrimlkU00RPeo_padding-left);
  position: var(--mixin-kZrimlkU00RPeo_position);
  list-style-type: var(--mixin-kZrimlkU00RPeo_list-style-type);
  font-size: var(--mixin-kZrimlkU00RPeo_font-size);
  flex-column-gap: var(--mixin-kZrimlkU00RPeo_flex-column-gap);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h5:where(.plasmic_default__h5),
h5:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h5),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h5,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h5,
h5:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-family: var(--mixin-Dtj4trBpZ1IZlY_font-family);
  font-size: var(--mixin-Dtj4trBpZ1IZlY_font-size);
  font-weight: var(--mixin-Dtj4trBpZ1IZlY_font-weight);
  line-height: var(--mixin-Dtj4trBpZ1IZlY_line-height);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) h6:where(.plasmic_default__h6),
h6:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__h6),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) h6,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) h6,
h6:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
  font-size: var(--mixin-t6BCPHBP_PJHX9_font-size);
  font-weight: var(--mixin-t6BCPHBP_PJHX9_font-weight);
  line-height: var(--mixin-t6BCPHBP_PJHX9_line-height);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) a:where(.plasmic_default__a):hover,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__a):hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) a:hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) a:hover,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):hover {
  color: var(--mixin-QB6G_5ZRBqsFTL_color);
  text-decoration-line: var(--mixin-QB6G_5ZRBqsFTL_text-decoration-line);
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) a:where(.plasmic_default__a):active,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__a):active,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) a:active,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) a:active,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):active {
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) a:where(.plasmic_default__a):focus,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__a):focus,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) a:focus,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) a:focus,
a:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):focus {
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y)
  blockquote:where(.plasmic_default__blockquote):focus,
blockquote:where(
    .root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__blockquote
  ):focus,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text)
  blockquote:focus,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) blockquote:focus,
blockquote:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):focus {
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y)
  blockquote:where(.plasmic_default__blockquote):hover,
blockquote:where(
    .root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__blockquote
  ):hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text)
  blockquote:hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) blockquote:hover,
blockquote:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):hover {
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y)
  code:where(.plasmic_default__code):hover,
code:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__code):hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) code:hover,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) code:hover,
code:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags):hover {
}

:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y) li:where(.plasmic_default__li),
li:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y.plasmic_default__li),
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y .__wab_expr_html_text) li,
:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) li,
li:where(.root_reset_mqAqufVw1Tm6ZHyrufFb4Y_tags) {
}
