.ProcessCardAuthor__root__yGv4J.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__text__mPwSb {
  padding-bottom: 0px;
}
.ProcessCardAuthor__processCardSection__eVheJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardAuthor__freeBox__h91Ws {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCardAuthor__freeBox__h91Ws {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessCardAuthor__freeBox__h91Ws {
    grid-template-columns: 1fr;
  }
}
.ProcessCardAuthor__formGroup__png4I.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
@media (max-width: 480px) {
  .ProcessCardAuthor__formGroup__png4I.__wab_instance {
    grid-column-end: span 2;
  }
}
.ProcessCardAuthor__textInput47___6OaFe.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg___6Xp8M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__svg__wodWf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__text__cxUnH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardAuthor__text___0Xjxu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__text__pNhtt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__symbolCounter__g6Ncz.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__formGroup__tkG0Z.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  .ProcessCardAuthor__formGroup__tkG0Z.__wab_instance {
    grid-column-end: span 2;
  }
}
.ProcessCardAuthor__refNr__ltkv8.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg__fASsk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__svg__iukh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__text__npDb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardAuthor__text__twUCu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__text__ujjrv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__symbolCounter__wt8A.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__formGroup__eoWc9.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.ProcessCardAuthor__text__yEc2T {
  padding-right: 0px;
}
.ProcessCardAuthor__realtyDataReferralDate__egXym.__wab_instance {
  width: 100%;
  min-width: 0;
}
.ProcessCardAuthor__svg__gg6Bx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__svg__hhYkr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__text__js3Op {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardAuthor__text__egsz7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__text__xut4D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__symbolCounter__pa98D.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__formGroup__huWf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.ProcessCardAuthor__objStandLzy__gwo61.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.ProcessCardAuthor__svg___1SIoJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.ProcessCardAuthor__option__lYj1.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg__bX7Tq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessCardAuthor__svg__rZwfW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__option__nTF2.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg__wwCks {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessCardAuthor__svg___8Kbjg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__option__wnGuu.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg___1Dvgi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessCardAuthor__svg__ijz30 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__option__hbdJe.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__svg__xyf6S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessCardAuthor__svg__qb4Nz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__option__evUea.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__text__bxtBx {
  padding-bottom: 0px;
}
.ProcessCardAuthor__svg__s7P1G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessCardAuthor__svg__uagXa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__symbolCounter__s9G1P.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__formGroup__oxxZd.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  .ProcessCardAuthor__formGroup__oxxZd.__wab_instance {
    grid-column-end: span 2;
  }
}
.ProcessCardAuthor__text__qyYiC {
  padding-right: 0px;
}
.ProcessCardAuthor__version__ueGy.__wab_instance {
  width: 100%;
  min-width: 0;
}
.ProcessCardAuthor__svg__bZhCs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__svg__jJQs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__text__vjJ3W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardAuthor__text__dYygl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__text__xsnCr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__symbolCounter__kzuGw.__wab_instance {
  position: relative;
}
.ProcessCardAuthor__formGroup__eXhJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardAuthor__textInput124__kEAe0.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.ProcessCardAuthor__svg__rgLeV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__svg__td4Ms {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardAuthor__text__h1Euo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardAuthor__text__tyoIs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__text__dhl2H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardAuthor__symbolCounter__mZx98.__wab_instance {
  position: relative;
}
