.ProcessSectionHeader__root__rw9O {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-fkHbhUQaFbf-Tk);
  padding-bottom: var(--token-TJik2L8OcSV6kD);
  position: relative;
  padding-right: var(--token-fkHbhUQaFbf-Tk);
  min-width: 0;
}
.ProcessSectionHeader__root__rw9O > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.ProcessSectionHeader__root__rw9O > .__wab_flex-container > *,
.ProcessSectionHeader__root__rw9O > .__wab_flex-container > .__wab_slot > *,
.ProcessSectionHeader__root__rw9O > .__wab_flex-container > picture > img,
.ProcessSectionHeader__root__rw9O
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessSectionHeader__root__rw9O {
    display: flex;
    flex-direction: column;
    padding: 0px 8px 40px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > *,
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > .__wab_slot > *,
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > picture > img,
  .ProcessSectionHeader__root__rw9O
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
  }
}
@media (max-width: 480px) {
  .ProcessSectionHeader__root__rw9O {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > *,
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > .__wab_slot > *,
  .ProcessSectionHeader__root__rw9O > .__wab_flex-container > picture > img,
  .ProcessSectionHeader__root__rw9O
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.ProcessSectionHeader__freeBox__v3PV {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessSectionHeader__freeBox__v3PV > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessSectionHeader__freeBox__v3PV > .__wab_flex-container > *,
.ProcessSectionHeader__freeBox__v3PV > .__wab_flex-container > .__wab_slot > *,
.ProcessSectionHeader__freeBox__v3PV > .__wab_flex-container > picture > img,
.ProcessSectionHeader__freeBox__v3PV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.ProcessSectionHeader__freeBox__fxtRd {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
}
.ProcessSectionHeader__slotTargetTitle__ifsAd {
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 600;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessSectionHeader__slotTargetTitle__ifsAd {
    font-size: var(--token-JJM6LNeA-0eMML);
  }
}
@media (max-width: 480px) {
  .ProcessSectionHeader__slotTargetTitle__ifsAd {
    font-size: var(--token-JJM6LNeA-0eMML);
  }
}
.ProcessSectionHeader__sectionToggleButton__fGOhW.__wab_instance {
  position: relative;
}
@media (max-width: 480px) {
  .ProcessSectionHeader__sectionToggleButton__fGOhW.__wab_instance {
    flex-shrink: 0;
  }
}
.ProcessSectionHeader__svg__qnwbk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessSectionHeader__text___0YJ7D {
  height: auto;
}
.ProcessSectionHeader__textisClosed___0YJ7DnsPxu {
  height: auto;
}
.ProcessSectionHeader__svg__c2EuX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessSectionHeader__freeBox__qAvsf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 540px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.ProcessSectionHeader__slotTargetText__o6Ugp {
  font-size: var(--token-xowVQ_ic8i1X0_);
  color: var(--token-8D7PsQmKPjCWdA);
}
@media (max-width: 480px) {
  .ProcessSectionHeader__slotTargetText__o6Ugp {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
