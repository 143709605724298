.UsageAgreementUserSettings__root___4RrZd {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  position: relative;
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .UsageAgreementUserSettings__root___4RrZd {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .UsageAgreementUserSettings__root___4RrZd {
    grid-template-columns: 1fr;
  }
}
.UsageAgreementUserSettings__freeBox__ujyHu {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.UsageAgreementUserSettings__freeBox__ujyHu > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.UsageAgreementUserSettings__freeBox__ujyHu > .__wab_flex-container > *,
.UsageAgreementUserSettings__freeBox__ujyHu
  > .__wab_flex-container
  > .__wab_slot
  > *,
.UsageAgreementUserSettings__freeBox__ujyHu
  > .__wab_flex-container
  > picture
  > img,
.UsageAgreementUserSettings__freeBox__ujyHu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.UsageAgreementUserSettings__text__iZd1J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .UsageAgreementUserSettings__text__iZd1J {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.UsageAgreementUserSettings__termsOfUseForm__xcLbk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.UsageAgreementUserSettings__freeBox___1XK1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.UsageAgreementUserSettings__freeBox___1XK1 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.UsageAgreementUserSettings__freeBox___1XK1 > .__wab_flex-container > *,
.UsageAgreementUserSettings__freeBox___1XK1
  > .__wab_flex-container
  > .__wab_slot
  > *,
.UsageAgreementUserSettings__freeBox___1XK1
  > .__wab_flex-container
  > picture
  > img,
.UsageAgreementUserSettings__freeBox___1XK1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
.UsageAgreementUserSettings__button__zEv1.__wab_instance {
  max-width: 100%;
  position: relative;
}
.UsageAgreementUserSettings__svg__tcHk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.UsageAgreementUserSettings__svg__xOgvh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.UsageAgreementUserSettings__link__bhGir {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-decoration-line: underline;
  color: var(--token-SkTYvRtmE);
}
