.FutureEnergyMainCategory__root___4Efpb.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__select___82HJz.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.FutureEnergyMainCategory__svg___3GZtC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergyMainCategory__option__fSfNt.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__wT0Gx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__koJqM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__lyMm9.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg___77B4L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg___8WAoK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__xdGs9.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__b5HcH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__qimqT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__x8DDj.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__nqqi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__alLVa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__egs4W.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__bEjpf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__uSoOt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__me7Hi.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__iZ7Ct {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__czKjg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__isCzs.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__gyqLy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__byxX4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__jvDNx.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__uj9Wb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg___0Srhe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__gSud5.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__zhil7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__puGXj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__gek82.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__vq5Vz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__kpiuP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option___19Qif.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__rQVkE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg___3NS6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__hcxx0.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__kqW1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__pa97X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__option__oxMaS.__wab_instance {
  position: relative;
}
.FutureEnergyMainCategory__svg__d9G2A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergyMainCategory__svg__fFhbf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergyMainCategory__symbolCounter__uxe7U.__wab_instance {
  position: relative;
}
