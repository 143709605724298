.Select__root___5Li3U {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 36px;
}
.Select__rootsize_extraSmall___5Li3U4Fdnx {
  height: 22px;
}
.Select__rootsize_small___5Li3UViiWp {
  height: 28px;
}
.Select__rootsize_large___5Li3UhXls2 {
  height: 44px;
}
.Select__roothasCustomTrigger___5Li3U1SgP4 {
  height: auto;
}
.Select__root___5Li3U:focus-within {
  outline: none;
}
.Select__root___focusVisibleWithin___5Li3UxR46Q {
  outline: none;
}
.Select__trigger__t9Kjf {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a,
    0px 0px 0px 1px #2c624c40;
  position: relative;
  display: flex;
  background: #001c1466;
  transition-property: box-shadow, background;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  min-height: 36px;
  height: auto;
  cursor: pointer;
  -webkit-transition-property: box-shadow, background;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 2px;
  padding: 7px 6px 7px 10px;
  border-style: none;
}
.Select__trigger__t9Kjf > .__wab_flex-container {
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.Select__trigger__t9Kjf > .__wab_flex-container > *,
.Select__trigger__t9Kjf > .__wab_flex-container > .__wab_slot > *,
.Select__trigger__t9Kjf > .__wab_flex-container > picture > img,
.Select__trigger__t9Kjf > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 8px;
}
.Select__triggerisOpen__t9KjfYNiFs {
  background: var(--token-NfSc1CXMklYEK2);
}
.Select__triggerisDisabled__t9Kjfjuzvj {
  opacity: 0.5;
}
.Select__triggercolor_softBlue__t9KjfxdTUm {
  background: #edf6ff;
  border-color: #ffffff00;
}
.Select__triggercolor_softYellow__t9Kjfu39Te {
  background: #fffbd1;
  border-color: #ffffff00;
}
.Select__triggercolor_softGray__t9KjfTfaTg {
  background: var(--token-aacnERCHvPoybg);
  border-style: none;
}
.Select__triggercolor_clear__t9KjfxVpi {
  background: none;
}
.Select__triggersize_extraSmall__t9Kjf4Fdnx {
  min-height: 22px;
  padding: 0px 2px 0px 6px;
}
.Select__triggersize_extraSmall__t9Kjf4Fdnx > .__wab_flex-container {
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.Select__triggersize_extraSmall__t9Kjf4Fdnx > .__wab_flex-container > *,
.Select__triggersize_extraSmall__t9Kjf4Fdnx
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Select__triggersize_extraSmall__t9Kjf4Fdnx
  > .__wab_flex-container
  > picture
  > img,
.Select__triggersize_extraSmall__t9Kjf4Fdnx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 2px;
  margin-top: 0px;
}
.Select__triggersize_small__t9KjfViiWp {
  min-height: 28px;
  padding: 3px 4px 3px 8px;
}
.Select__triggersize_small__t9KjfViiWp > .__wab_flex-container {
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.Select__triggersize_small__t9KjfViiWp > .__wab_flex-container > *,
.Select__triggersize_small__t9KjfViiWp
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Select__triggersize_small__t9KjfViiWp > .__wab_flex-container > picture > img,
.Select__triggersize_small__t9KjfViiWp
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 4px;
  margin-top: 0px;
}
.Select__triggersize_large__t9KjfhXls2 {
  padding-left: 18px;
  padding-right: 14px;
  min-height: 44px;
}
.Select__triggerstate_success__t9KjfDubKe {
  background: var(--token--h6FHSrgUAQsDe);
  border-color: var(--token-6_9iE0w_5TGI8j);
}
.Select__triggerstate_error__t9Kjfk8TwU {
  background: var(--token-ATEWP6hIq5CbNw);
  border-color: var(--token-d6Fhz0OrC2AHcn);
}
.Select__triggerhasCustomTrigger__t9Kjf1SgP4 {
  min-height: 0px;
  width: 100%;
  height: auto;
  background: none;
  box-shadow: 0px 0px 0px 0px #0000001a, 0px 0px 0px 0px #0000001a,
    0px 0px 0px 0px #2c624c40;
  min-width: 0;
  border-radius: 0px;
  padding: 0px;
  border-style: none;
}
.Select__triggerhasCustomTrigger__t9Kjf1SgP4 > .__wab_flex-container {
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.Select__triggerhasCustomTrigger__t9Kjf1SgP4 > .__wab_flex-container > *,
.Select__triggerhasCustomTrigger__t9Kjf1SgP4
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Select__triggerhasCustomTrigger__t9Kjf1SgP4
  > .__wab_flex-container
  > picture
  > img,
.Select__triggerhasCustomTrigger__t9Kjf1SgP4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.Select__triggerhasCustomTrigger_isOpen__t9Kjf1SgP4YNiFs {
  background: none;
}
.Select__triggerhasCustomTrigger_size_small__t9Kjf1SgP4ViiWp {
  padding: 0px;
}
.Select__root___5Li3U:focus-within .Select__trigger__t9Kjf {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a,
    0px 0px 0px 4px #95f3b34d;
  outline: none;
}
.Select__root___5Li3U .Select__trigger___focusVisibleWithin__t9KjfxR46Q {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a,
    0px 0px 0px 4px #95f3b34d;
  outline: none;
}
.Select__root___5Li3U:hover .Select__trigger__t9Kjf {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a,
    0px 0px 0px 1px #2e815f80;
}
.Select__hoverWrapper__ordDx {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: auto;
  left: -8px;
  right: auto;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Select__hoverWrappersize_extraSmall__ordDx4Fdnx {
  left: -2px;
}
.Select__hoverWrappersize_small__ordDxviiWp {
  left: -4px;
}
.Select__root___5Li3U:hover .Select__hoverWrapper__ordDx {
  opacity: 0.75;
}
.Select__root___5Li3U:active .Select__hoverWrapper__ordDx {
  opacity: 1;
}
.Select__hoverColor__vgtum {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: var(--token-73C_KCa5JvrUv3);
  transition-property: background, transform, opacity;
  transition-duration: 0.1s, 0.1s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  opacity: 0.06;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: background, transform, opacity;
  -webkit-transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.1s, 0.1s, 0.2s;
  border-radius: 5px;
}
.Select__root___5Li3U:hover .Select__hoverColor__vgtum {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.Select__root___5Li3U:active .Select__hoverColor__vgtum {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.Select__startIconContainer__qQdfF {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.Select__startIconContainershowStartIcon__qQdfFjtxg9 {
  display: flex;
}
.Select__slotTargetStartIcon___0WvZc {
  color: var(--token-L1EeQgMLm);
}
.Select__svg__lxQmv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.Select__contentContainer__bjjk8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
  min-width: 0;
}
.Select__contentContainerhasCustomTrigger__bjjk81SgP4 {
  margin-top: calc(0px + 0px) !important;
}
.Select__root___5Li3U:focus-within .Select__contentContainer__bjjk8 {
  outline: none;
}
.Select__slotTargetSelectedContent__mkWfB {
  white-space: pre;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.1px;
}
.Select__slotTargetSelectedContent__mkWfB > .__wab_text,
.Select__slotTargetSelectedContent__mkWfB > .__wab_expr_html_text,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot-string-wrapper,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot > .__wab_text,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot > .__wab_expr_html_text,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.Select__slotTargetSelectedContent__mkWfB > *,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot > *,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot > .__wab_slot > *,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__slotTargetSelectedContent__mkWfB > picture > img,
.Select__slotTargetSelectedContent__mkWfB > .__wab_slot > picture > img,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
}
.Select__slotTargetSelectedContentcolor_softBlue__mkWfBxdTUm {
  color: #006adc;
}
.Select__slotTargetSelectedContentcolor_softYellow__mkWfBu39Te {
  color: #946800;
}
.Select__slotTargetSelectedContentstate_success__mkWfBDubKe {
  color: var(--token-ObRRGpZ-v1PQfN);
}
.Select__slotTargetSelectedContentstate_error__mkWfBk8TwU {
  color: var(--token-NFXZ__zPMi8_rv);
}
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > *,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > *,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > picture
  > img,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > picture
  > img,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__root___5Li3U:focus-within
  .Select__slotTargetSelectedContent__mkWfB
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}
.Select__slotTargetPlaceholder__uNyR {
  white-space: pre;
  color: var(--token-NfHg4McmY);
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.1px;
}
.Select__slotTargetPlaceholder__uNyR > .__wab_text,
.Select__slotTargetPlaceholder__uNyR > .__wab_expr_html_text,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot-string-wrapper,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > .__wab_text,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > .__wab_expr_html_text,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > .__wab_slot-string-wrapper,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > .__wab_slot > .__wab_text,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.Select__slotTargetPlaceholder__uNyR > *,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > *,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > .__wab_slot > *,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__slotTargetPlaceholder__uNyR > picture > img,
.Select__slotTargetPlaceholder__uNyR > .__wab_slot > picture > img,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__slotTargetPlaceholder__uNyR
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
}
.Select__slotTargetPlaceholdershowPlaceholder__uNyRwpLhR {
  color: var(--token-31C7iZ0eD);
}
.Select__slotTargetPlaceholdershowPlaceholder_color_softBlue__uNyRwpLhRXdTUm {
  color: #006adc;
}
.Select__slotTargetPlaceholdercolor_softYellow_showPlaceholder__uNyRu39TeWpLhR {
  color: #946800;
}
.Select__dropdownIcon__i3Twp {
  position: relative;
  object-fit: cover;
  color: var(--token-ZviHPuuzk);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  -webkit-transition-property: transform;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Select__dropdownIconisOpen__i3TwpYNiFs {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-180deg);
}
.Select__dropdownIconcolor_softBlue__i3TwpxdTUm {
  color: #0091ff;
}
.Select__dropdownIconcolor_softYellow__i3Twpu39Te {
  color: #f5d90a;
}
.Select__dropdownIconstate_success__i3TwpDubKe {
  color: var(--token-vvi_7r45uYs8w7);
}
.Select__dropdownIconstate_error__i3Twpk8TwU {
  color: var(--token-1EW9WFy_Sby7ig);
}
.Select__root___5Li3U .Select__dropdownIcon___focusVisibleWithin__i3TwpxR46Q {
  outline: none;
}
.Select__root___5Li3U:focus-within .Select__dropdownIcon__i3Twp {
  outline: none;
}
.Select__customTriggerWrapper__b7BhO {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
}
.Select__customTriggerWrapperhasCustomTrigger__b7BhO1SgP4 {
  width: 100%;
  min-width: 0;
  display: flex;
}
.Select__overlay__trloK.__wab_instance {
  position: absolute;
  left: -12px;
  top: 100%;
  z-index: 1000;
  margin: 2px 12px;
}
.Select__optionsContainer__w3Saj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  overflow: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 0;
}
.Select__optionsContainerhasCustomTrigger__w3Saj1SgP4 {
  width: 100%;
  min-width: 0;
}
.Select__option__yuihX.__wab_instance {
  position: relative;
}
.Select__svg___15XzP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Select__svg__ciwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Select__option__hpmYg.__wab_instance {
  position: relative;
}
.Select__svg__zcoMv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Select__svg__hmFvj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
