.FormGroup__root__sxYwn {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.FormGroup__root__sxYwn > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.FormGroup__root__sxYwn > .__wab_flex-container > *,
.FormGroup__root__sxYwn > .__wab_flex-container > .__wab_slot > *,
.FormGroup__root__sxYwn > .__wab_flex-container > picture > img,
.FormGroup__root__sxYwn > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 6px;
}
.FormGroup__freeBox__zyl7N {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: var(--token-u4z6Uru_YfAR62);
  align-self: stretch;
  min-width: 0;
}
.FormGroup__freeBox__zyl7N > .__wab_flex-container {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.FormGroup__freeBox__zyl7N > .__wab_flex-container > *,
.FormGroup__freeBox__zyl7N > .__wab_flex-container > .__wab_slot > *,
.FormGroup__freeBox__zyl7N > .__wab_flex-container > picture > img,
.FormGroup__freeBox__zyl7N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 4px;
}
.FormGroup__freeBoxisRequired__zyl7NJ9Wvt {
  margin-left: -10px;
}
.FormGroup__freeBoxhasNoLabel__zyl7NPd2Hd {
  display: none;
}
.FormGroup__text___3SDmY {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-97-B3t__LugLsk);
  color: var(--token-HHAuBKYL3pvmHz);
}
.FormGroup__textisRequired___3SDmYj9Wvt {
  display: block;
}
.FormGroup__slotTargetLabel__rwn8 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: var(--token-97-B3t__LugLsk);
  color: var(--token-zqrEV3Z8z);
}
.FormGroup__slotTargetLabelSecondary___3I1Tn {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  font-weight: 400;
  line-height: var(--token-97-B3t__LugLsk);
}
.FormGroup__text__xqBZm {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-97-B3t__LugLsk);
  color: var(--token-SkTYvRtmE);
}
.FormGroup__textisNiceToHave__xqBZmkH38P {
  display: block;
}
.FormGroup__link__e8XmD {
  position: relative;
  display: flex;
}
.FormGroup__linkhasHelperLink__e8XmD62Pax {
  display: flex;
}
.FormGroup__svg___2Xb5C {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-c8ADL42ppeG1sf);
  height: 1em;
}
.FormGroup__textInput__tcTvv.__wab_instance {
  position: relative;
  flex-shrink: 0;
}
.FormGroup__svg__pKaUh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FormGroup__svg__zo7Sw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FormGroup__text__pqDi4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.FormGroup__text__fzUI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.FormGroup__text___50Mat {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.FormGroup__freeBox__pWLo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 2px;
  justify-content: space-between;
  min-width: 0;
  display: none;
}
.FormGroup__freeBoxhasHelperText__pWLoGeBf {
  display: flex;
}
.FormGroup__freeBoxhasSymbolCounter__pWLo5GsLk {
  display: flex;
}
.FormGroup__slotTargetHelperText__sKsEr {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
  line-height: var(--token-97-B3t__LugLsk);
}
.FormGroup__symbolCounter__oevbg.__wab_instance {
  position: relative;
}
.FormGroup__stateTextWrapper__nBjH9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 2px;
  min-width: 0;
}
.FormGroup__stateTextWrapperstate_success__nBjH9HTvnz {
  display: flex;
}
.FormGroup__stateTextWrapperstate_warning__nBjH9Rsky8 {
  display: flex;
}
.FormGroup__stateTextWrapperstate_error__nBjH9FgD {
  display: flex;
}
.FormGroup__slotTargetStateText__rZifW {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
  line-height: var(--token-97-B3t__LugLsk);
}
.FormGroup__slotTargetStateTextstate_success__rZifWhTvnz {
  color: var(--token-6_9iE0w_5TGI8j);
}
.FormGroup__slotTargetStateTextstate_warning__rZifWrsky8 {
  color: var(--token-6VQ__RqwTsOFsq);
}
.FormGroup__slotTargetStateTextstate_error__rZifWFgD {
  color: var(--token-SNDFNRi9ingNbb);
}
