.ObjectDetailRow__root__raWnz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  padding-bottom: var(--token-P3nFMsZz2JMXx8);
  width: 100%;
  min-height: var(--token-qecgvVQwVo7jFR);
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  border-top: 1px solid #c1cec924;
}
.ObjectDetailRow__freeBox___6YjJ4 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  width: 100%;
  grid-row-gap: var(--token-P3nFMsZz2JMXx8);
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
.ObjectDetailRow__freeBox__bEkwh {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 5;
}
.ObjectDetailRow__freeBox__bEkwh > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ObjectDetailRow__freeBox__bEkwh > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__bEkwh > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__bEkwh > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__bEkwh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.ObjectDetailRow__activeRadio___5Xiz7.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__freeBox__kpLT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ObjectDetailRow__freeBox__kpLT > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HaxgWLsrVFdD87));
  height: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ObjectDetailRow__freeBox__kpLT > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__kpLT > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__kpLT > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__kpLT
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HaxgWLsrVFdD87);
}
.ObjectDetailRow__freeBox__qq4RJ {
  display: flex;
  flex-direction: row;
  height: auto;
}
.ObjectDetailRow__freeBox__qq4RJ > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ObjectDetailRow__freeBox__qq4RJ > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__qq4RJ > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__qq4RJ > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__qq4RJ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.ObjectDetailRow__slotTargetRefNr__rYMc {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
.ObjectDetailRow__text__bxyuj {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-NfHg4McmY);
}
.ObjectDetailRow__texthasVersion_hasReferenceNr__bxyujTq9WEUifCo {
  display: block;
}
.ObjectDetailRow__slotTargetVersion__c1Huk {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
.ObjectDetailRow__freeBox__zbcqE {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ObjectDetailRow__freeBox__zbcqE > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.ObjectDetailRow__freeBox__zbcqE > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__zbcqE > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__zbcqE > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__zbcqE
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.ObjectDetailRow__tag__vMcjt.__wab_instance {
  position: relative;
  display: none;
}
.ObjectDetailRow__tagisActive__vMcjtQZnFg.__wab_instance {
  display: flex;
}
.ObjectDetailRow__text__xy7XQ {
  padding-right: 0px;
}
.ObjectDetailRow__tag__jYtzE.__wab_instance {
  position: relative;
}
.ObjectDetailRow__tagisDraft__jYtzEpNFkM.__wab_instance {
  display: flex;
}
.ObjectDetailRow__text___2JUju {
  padding-right: 0px;
}
.ObjectDetailRow__freeBox__m0Xc {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.ObjectDetailRow__slotTargetDataDate__p8RS3 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.ObjectDetailRow__freeBox___8AYi5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ObjectDetailRow__freeBox___8AYi5 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.ObjectDetailRow__freeBox___8AYi5 > .__wab_flex-container > *,
.ObjectDetailRow__freeBox___8AYi5 > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox___8AYi5 > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox___8AYi5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.ObjectDetailRow__text__uXAgw {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.ObjectDetailRow__slotTargetModelDate__i9Ozw {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.ObjectDetailRow__freeBox__igZ0D {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.ObjectDetailRow__slotTargetVerbrauch__r7C1I {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.ObjectDetailRow__freeBox__sJsKy {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.ObjectDetailRow__slotTargetEmission__cKX {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.ObjectDetailRow__freeBox__ij9Eu {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
.ObjectDetailRow__freeBox__ij9Eu > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.ObjectDetailRow__freeBox__ij9Eu > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__ij9Eu > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__ij9Eu > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__ij9Eu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.ObjectDetailRow__freeBox___9DWmx {
  display: flex;
  flex-direction: column;
}
.ObjectDetailRow__freeBox___9DWmx > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.ObjectDetailRow__freeBox___9DWmx > .__wab_flex-container > *,
.ObjectDetailRow__freeBox___9DWmx > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox___9DWmx > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox___9DWmx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 0px;
}
.ObjectDetailRow__slotTargetCapex__yqDGm {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-Bl-7HCzgz);
}
.ObjectDetailRow__freeBox__wqE8X {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.ObjectDetailRow__freeBox__wqE8X > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.ObjectDetailRow__freeBox__wqE8X > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__wqE8X > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__wqE8X > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__wqE8X
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.ObjectDetailRow__text__qVn0R {
  padding-right: 0px;
}
.ObjectDetailRow__text__zuNx2 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.ObjectDetailRow__text__wdhZe {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.ObjectDetailRow__freeBox__ikQlh {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 3;
}
.ObjectDetailRow__freeBox__ikQlh > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ObjectDetailRow__freeBox__ikQlh > .__wab_flex-container > *,
.ObjectDetailRow__freeBox__ikQlh > .__wab_flex-container > .__wab_slot > *,
.ObjectDetailRow__freeBox__ikQlh > .__wab_flex-container > picture > img,
.ObjectDetailRow__freeBox__ikQlh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.ObjectDetailRow__select___8PSo5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__svg__bunU1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.ObjectDetailRow__button__nAqD.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__svg__ulubq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ObjectDetailRow__svg__vzjm7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ObjectDetailRow__option__aZRiS.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__svg__vDfVb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ObjectDetailRow__text__mBw4D {
  padding-left: 0px;
}
.ObjectDetailRow__svg__yWIb0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ObjectDetailRow__option__jOs6E.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__svg___8Jk8W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ObjectDetailRow__text__ur0N3 {
  padding-left: 0px;
}
.ObjectDetailRow__svg__geXg9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ObjectDetailRow__option__gI2Rm.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ObjectDetailRow__svg__i6FiK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ObjectDetailRow__text__vaR6D {
  padding-left: 0px;
}
.ObjectDetailRow__svg__vsdHi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
