.Modal__root__jixWr {
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  background: var(--token-IaDlhQPgR);
  position: relative;
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-x_jRp3a5FpON8z);
}
.Modal__freeBox__f94Rp {
  display: flex;
  flex-direction: column;
}
.Modal__freeBox__f94Rp > .__wab_flex-container {
  flex-direction: column;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.Modal__freeBox__f94Rp > .__wab_flex-container > *,
.Modal__freeBox__f94Rp > .__wab_flex-container > .__wab_slot > *,
.Modal__freeBox__f94Rp > .__wab_flex-container > picture > img,
.Modal__freeBox__f94Rp > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.Modal__freeBox__c9GHr {
  display: flex;
  position: relative;
  flex-direction: column;
}
.Modal__freeBox__c9GHr > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Modal__freeBox__c9GHr > .__wab_flex-container > *,
.Modal__freeBox__c9GHr > .__wab_flex-container > .__wab_slot > *,
.Modal__freeBox__c9GHr > .__wab_flex-container > picture > img,
.Modal__freeBox__c9GHr > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.Modal__freeBox__kwIcu {
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.Modal__slotTargetTitle__ekpt8 {
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
}
@media (max-width: 480px) {
  .Modal__slotTargetTitle__ekpt8 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.Modal__freeBox__es43H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.Modal__slotTargetSubtitle__eJlx {
  font-size: var(--token-xowVQ_ic8i1X0_);
  color: var(--token-s9gzWmEFR);
}
.Modal__freeBox__kfOdi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Modal__freeBox__kfOdi > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.Modal__freeBox__kfOdi > .__wab_flex-container > *,
.Modal__freeBox__kfOdi > .__wab_flex-container > .__wab_slot > *,
.Modal__freeBox__kfOdi > .__wab_flex-container > picture > img,
.Modal__freeBox__kfOdi > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.Modal__freeBox__wjyCb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Modal__freeBoxhasActions__wjyCBl3ESq {
  display: flex;
}
.Modal__freeBox__in43S {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Modal__button__iIba.__wab_instance {
  max-width: 100%;
}
.Modal__svg__g203R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Modal__text___6An3V {
  height: auto;
  padding-right: 0px;
}
.Modal__svg__lrUbU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Modal__closeButton__mYFjE.__wab_instance {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: -16px;
  z-index: 1;
  right: -16px;
  flex-shrink: 0;
}
.Modal__svg__fm1Hi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Modal__svg__mfIcw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
