._022BerichtSuccessNichtRegisriert__root__iEcm7 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._022BerichtSuccessNichtRegisriert__processHeader__fNuyj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessNichtRegisriert__processBreadCrumb__k7TtU.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: var(--token-6rZumor6mC3fF5) 24px var(--token-ew2vDb1S1Vm4F-);
}
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 88px 24px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 16px;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8 > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessNichtRegisriert__freeBox__wrxw8
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._022BerichtSuccessNichtRegisriert__freeBox__kupWn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 720px;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__kupWn > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
._022BerichtSuccessNichtRegisriert__freeBox__kupWn > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__kupWn
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__kupWn
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__kupWn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
._022BerichtSuccessNichtRegisriert__reportSuccessContent__kRy5Y.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-YOOgp3GSpDYosY));
  height: calc(100% + var(--token-YOOgp3GSpDYosY));
}
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-YOOgp3GSpDYosY);
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__kXq6N > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
    > .__wab_flex-container
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessNichtRegisriert__freeBox__kXq6N
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
._022BerichtSuccessNichtRegisriert__divider__wDCZ.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-HUUwrpE5bq87rt);
  border: 1px solid var(--token-O8yxnKF8d);
}
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__zdtJv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__xUo0T
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__bc4Sl
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox___5KYwG
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._022BerichtSuccessNichtRegisriert__text___52Pzw {
  width: 100%;
  height: auto;
  font-size: var(--token-xowVQ_ic8i1X0_);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text___52Pzw {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text___4TFdH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__formGroup__anxJv.__wab_instance {
  position: relative;
}
._022BerichtSuccessNichtRegisriert__text__bts6 {
  padding-right: 0px;
}
._022BerichtSuccessNichtRegisriert__checkbox3__eRlZz.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessNichtRegisriert__symbolCounter__ehyb7.__wab_instance {
  position: relative;
}
._022BerichtSuccessNichtRegisriert__svg__gcKel {
  position: relative;
  object-fit: cover;
  width: 56px;
  height: 56px;
  color: var(--token-rqm9BSu09);
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__pS2Qm {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-HUUwrpE5bq87rt);
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__pS2Qm {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__freeBox__pS2Qm {
    grid-template-columns: 1fr;
  }
}
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8 > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__oNcB8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
._022BerichtSuccessNichtRegisriert__text__t5BQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text__t5BQ {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__addressFormGroup___8A8UK.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._022BerichtSuccessNichtRegisriert__portfolioQuestionCard__gUcil.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__cAkF1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._022BerichtSuccessNichtRegisriert__checkboxUsageAgreement__ig9Nk.__wab_instance.__wab_instance {
  position: relative;
  align-self: auto;
}
._022BerichtSuccessNichtRegisriert__button__vmC3O.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessNichtRegisriert__svg__o7Iui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__text__rtYaD {
  height: auto;
}
._022BerichtSuccessNichtRegisriert__svg__rHgcV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__overlay__arWhw {
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: -2px;
  z-index: 1;
  right: 0;
  bottom: 0;
  display: none;
}
._022BerichtSuccessNichtRegisriert__backgorund__cz1Iv {
  display: block;
  position: fixed;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--token-hEzPFeV5Y);
}
._022BerichtSuccessNichtRegisriert__freeBox__zzVk0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  z-index: 10;
  background: var(--token-umjgs5uqk);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-x_jRp3a5FpON8z);
}
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5 {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5 > .__wab_flex-container {
  flex-direction: column;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__bhDq5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh {
  display: flex;
  position: relative;
  flex-direction: column;
}
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__gYsUh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._022BerichtSuccessNichtRegisriert__text__a9Cxk {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text__a9Cxk {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text__qnCw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-xowVQ_ic8i1X0_);
  color: var(--token-s9gzWmEFR);
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5 > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__e8Vo5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__zRuL {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__zRuL > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._022BerichtSuccessNichtRegisriert__freeBox__zRuL > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__zRuL
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__zRuL
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__zRuL
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__oSgyz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox___0SfWf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__svg__gcqCy {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__kJtSq {
  display: flex;
  position: relative;
  background: var(--token-NfSc1CXMklYEK2);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._022BerichtSuccessNichtRegisriert__svg__sFrxi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  opacity: 0;
  height: 1em;
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__fgeU7
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox___3KpVe
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._022BerichtSuccessNichtRegisriert__text___8Ozg4 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text___8Ozg4 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text___2B9Ol {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text___2B9Ol {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text__uffQ {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-7xB3ITuN8);
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__ul__emsgJ {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-6_9iE0w_5TGI8j),
      var(--token-6_9iE0w_5TGI8j)
    ),
    var(--token-SqSK5Lk3X);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__sQFjl
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._022BerichtSuccessNichtRegisriert__freeBox__nG0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__nG0 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessNichtRegisriert__freeBox__nG0 > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__nG0
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__nG0
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__nG0
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessNichtRegisriert__freeBox___7Hhnn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__svg___2TYJn {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__sv8Oj {
  display: flex;
  position: relative;
  background: var(--token-ObRRGpZ-v1PQfN);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._022BerichtSuccessNichtRegisriert__svg__zOoz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  color: var(--token-xgado2J7YlFS13);
  height: 1em;
  flex-shrink: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__naGLr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__naGLr > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._022BerichtSuccessNichtRegisriert__freeBox__naGLr > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__naGLr
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__naGLr
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__naGLr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae > .__wab_flex-container > *,
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessNichtRegisriert__freeBox__rq7Ae
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._022BerichtSuccessNichtRegisriert__text__qghwq {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text__qghwq {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text__kDNf {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessNichtRegisriert__text__kDNf {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessNichtRegisriert__text___82DTf {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__ul__xw5Ot {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessNichtRegisriert__freeBox__f4Jh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessNichtRegisriert__button__wfZxn.__wab_instance {
  max-width: 100%;
}
._022BerichtSuccessNichtRegisriert__svg__tcmck {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__text__cy4N {
  height: auto;
  padding-right: 0px;
}
._022BerichtSuccessNichtRegisriert__svg__algz9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__button__o9WtZ.__wab_instance {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: -14px;
  z-index: 1;
  right: -14px;
}
._022BerichtSuccessNichtRegisriert__svg__trfis {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__svg__oWNq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessNichtRegisriert__footer__pkiZs.__wab_instance {
  max-width: 100%;
  position: relative;
}
