.Banner__root__sDh4H {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 12;
  position: relative;
  border-radius: 2px;
  padding: 20px;
  border: 1px solid var(--token-O8yxnKF8d);
}
.Banner__root__sDh4H > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.Banner__root__sDh4H > .__wab_flex-container > *,
.Banner__root__sDh4H > .__wab_flex-container > .__wab_slot > *,
.Banner__root__sDh4H > .__wab_flex-container > picture > img,
.Banner__root__sDh4H > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 16px;
}
@media (max-width: 480px) {
  .Banner__root__sDh4H {
    padding: 12px;
  }
}
.Banner__roottype_info__sDh4HFpfwa {
  background: var(--token-73C_KCa5JvrUv3);
  border: 1px solid var(--token-73C_KCa5JvrUv3);
}
.Banner__roottype_error__sDh4HXoWuX {
  background: var(--token-NFXZ__zPMi8_rv);
  border-color: var(--token-9Zm0PFeo1rK0Eh);
}
.Banner__roottype_warning__sDh4HLhgh9 {
  background: var(--token-YGkzw-IqGKzMqr);
  border-color: var(--token-P5Q55lfBPHP7gh);
}
.Banner__roottype_success__sDh4HWqwq {
  background: var(--token-QI60SWB06gBJ5K);
  border-color: var(--token-6_9iE0w_5TGI8j);
}
.Banner__rootisElevated__sDh4H9JLo5 {
  box-shadow: 0px 20px 25px -5px #0000001a, 0px 8px 10px -6px #0000001a;
}
.Banner__roothasDismissButton__sDh4H0SQhc {
  padding-right: 56px;
}
.Banner__svg__u2XrC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-zw8MjMyAW);
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.Banner__svgtype_info__u2XrCfpfwa {
  color: var(--token-WEnykX89Xj8V-V);
}
.Banner__svgtype_error__u2XrCxoWuX {
  color: var(--token-SNDFNRi9ingNbb);
}
.Banner__svgtype_warning__u2XrCLhgh9 {
  color: var(--token-UQb389MFjCYuwK);
}
.Banner__svgtype_success__u2XrCwqwq {
  color: var(--token-plIc-sUNu2-k5-);
}
.Banner__freeBox__fzgZw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Banner__freeBox__fzgZw > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.Banner__freeBox__fzgZw > .__wab_flex-container > *,
.Banner__freeBox__fzgZw > .__wab_flex-container > .__wab_slot > *,
.Banner__freeBox__fzgZw > .__wab_flex-container > picture > img,
.Banner__freeBox__fzgZw > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 12px;
}
.Banner__freeBox__o5YFl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.Banner__freeBox__mkmvW {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.Banner__freeBoxhasNoTitle__mkmvW6DbKa {
  display: none;
}
.Banner__slotTargetTitle__fiw2F {
  font-weight: 500;
  line-height: var(--token-97-B3t__LugLsk);
  color: var(--token-Bl-7HCzgz);
}
.Banner__slotTargetTitletype_info__fiw2Ffpfwa {
  color: var(--token-4_CeG-8iBJgDtW);
}
.Banner__slotTargetTitletype_error__fiw2FxoWuX {
  color: var(--token-ATEWP6hIq5CbNw);
}
.Banner__slotTargetTitletype_warning__fiw2FLhgh9 {
  color: var(--token-rT8-IAfiNlr-EF);
}
.Banner__slotTargetTitletype_success__fiw2Fwqwq {
  color: var(--token-ObRRGpZ-v1PQfN);
}
.Banner__freeBox__d16Ou {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.Banner__freeBoxhasDescription__d16OUvzBwI {
  display: flex;
}
.Banner__freeBoxhasNoTitle__d16Ou6DbKa {
  display: flex;
}
.Banner__slotTargetText__w59UN {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-97-B3t__LugLsk);
  color: var(--token-s9gzWmEFR);
}
.Banner__slotTargetTexttype_info__w59UNfpfwa {
  color: var(--token-stSp0Rv36gxavp);
}
.Banner__slotTargetTexttype_error__w59UNxoWuX {
  color: var(--token-SNDFNRi9ingNbb);
}
.Banner__slotTargetTexttype_warning__w59UNLhgh9 {
  color: var(--token-dF6--250OOKgt2);
}
.Banner__slotTargetTexttype_success__w59UNwqwq {
  color: var(--token-NfSc1CXMklYEK2);
}
.Banner__freeBox__tDpEk {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Banner__freeBox__tDpEk > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.Banner__freeBox__tDpEk > .__wab_flex-container > *,
.Banner__freeBox__tDpEk > .__wab_flex-container > .__wab_slot > *,
.Banner__freeBox__tDpEk > .__wab_flex-container > picture > img,
.Banner__freeBox__tDpEk > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 12px;
  margin-top: 8px;
}
.Banner__freeBoxhasActions__tDpEkTm67D {
  display: flex;
}
.Banner__button__qoroF.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Banner__svg__iX90X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Banner__svg__lfxfq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Banner__dismissButton__cbNTp.__wab_instance {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: 16px;
  right: 16px;
  flex-shrink: 0;
  display: none;
}
.Banner__dismissButtontype_info__cbNTpFpfwa.__wab_instance {
  flex-shrink: 0;
}
.Banner__dismissButtontype_error__cbNTpXoWuX.__wab_instance {
  flex-shrink: 0;
}
.Banner__dismissButtontype_warning__cbNTpLhgh9.__wab_instance {
  flex-shrink: 0;
}
.Banner__dismissButtontype_success__cbNTpWqwq.__wab_instance {
  flex-shrink: 0;
}
.Banner__dismissButtonhasDismissButton__cbNTp0SQhc.__wab_instance {
  flex-shrink: 0;
  display: flex;
}
.Banner__svg__deZmn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  color: var(--token-8D7PsQmKPjCWdA);
}
.Banner__svgtype_info__deZmnfpfwa {
  color: var(--token-uafO86Amhx2CAZ);
}
.Banner__svgtype_error__deZmnxoWuX {
  color: var(--token-nBkEYAcG_o3k6g);
}
.Banner__svgtype_warning__deZmnLhgh9 {
  color: var(--token-Va8fP8SDJXmUCM);
}
.Banner__svgtype_success__deZmnwqwq {
  color: var(--token-QI60SWB06gBJ5K);
}
.Banner__svg__ol1KF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
