.Navigation__root__kc65L {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-x_jRp3a5FpON8z);
  padding-right: var(--token-x_jRp3a5FpON8z);
  position: relative;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
}
.Navigation__root__kc65L > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-x_jRp3a5FpON8z));
  width: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Navigation__root__kc65L > .__wab_flex-container > *,
.Navigation__root__kc65L > .__wab_flex-container > .__wab_slot > *,
.Navigation__root__kc65L > .__wab_flex-container > picture > img,
.Navigation__root__kc65L > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-x_jRp3a5FpON8z);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Navigation__root__kc65L > .__wab_flex-container {
    justify-content: space-evenly;
  }
}
@media (max-width: 480px) {
  .Navigation__root__kc65L {
    padding: 16px;
  }
}
.Navigation__freeBox___01Hw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 1440px;
  padding-top: var(--token-TJik2L8OcSV6kD);
  padding-bottom: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  border-bottom: 2px solid #c1cec924;
}
.Navigation__link___1Go6L {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
.Navigation__link___1Go6L > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.Navigation__link___1Go6L > .__wab_flex-container > *,
.Navigation__link___1Go6L > .__wab_flex-container > .__wab_slot > *,
.Navigation__link___1Go6L > .__wab_flex-container > picture > img,
.Navigation__link___1Go6L
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
.Navigation__img__qq2Lj {
  object-fit: scale-down;
  max-width: var(--token-HUUwrpE5bq87rt);
}
.Navigation__img__qq2Lj > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  .Navigation__img__qq2Lj {
    display: none !important;
  }
}
.Navigation__img__awzvI {
  object-fit: scale-down;
  max-width: var(--token-qecgvVQwVo7jFR);
}
.Navigation__img__awzvI > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  .Navigation__img__awzvI {
    display: none !important;
  }
}
