.Radio2__root__u8PSn {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  justify-self: flex-start;
}
.Radio2__root__u8PSn > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.Radio2__root__u8PSn > .__wab_flex-container > *,
.Radio2__root__u8PSn > .__wab_flex-container > .__wab_slot > *,
.Radio2__root__u8PSn > .__wab_flex-container > picture > img,
.Radio2__root__u8PSn > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.Radio2__rootisDisabled__u8PSnOZrv8 {
  opacity: 0.5;
}
.Radio2__freeBox___6SDuv {
  display: flex;
  position: relative;
  transition-property: background, border-color, box-shadow;
  transition-duration: 0.2s, 0.2s, 0.2s;
  align-items: center;
  justify-content: center;
  height: var(--token-TJik2L8OcSV6kD);
  width: var(--token-TJik2L8OcSV6kD);
  box-shadow: 0px 0px 0px 0px #7df1ab00;
  flex-shrink: 0;
  -webkit-transition-property: background, border-color, box-shadow;
  -webkit-transition-duration: 0.2s, 0.2s, 0.2s;
  border-radius: 100%;
}
.Radio2__root__u8PSn:focus-within .Radio2__freeBox___6SDuv {
  box-shadow: 0px 0px 0px 2px #7df1ab99;
  outline: none;
}
.Radio2__root__u8PSn .Radio2__freeBox___focusVisibleWithin___6SDuvdBg3D {
  box-shadow: 0px 0px 0px 2px #7df1ab99;
  outline: none;
}
.Radio2__freeBox___9ZeBs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: var(--token-KVbFqNIPJ);
  transition-property: background, border-color;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  flex-shrink: 0;
  -webkit-transition-property: background, border-color;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 100%;
  border: 1.5px solid var(--token-KVbFqNIPJ);
}
.Radio2__freeBoxisChecked___9ZeBskNhmm {
  background: var(--token-6_9iE0w_5TGI8j);
  border-color: var(--token-31C7iZ0eD);
}
.Radio2__freeBox__u9FQj {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 6px;
  height: 6px;
  background: var(--token-ObRRGpZ-v1PQfN);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0) scaleY(0) scaleZ(1);
  flex-shrink: 0;
  -webkit-transition-property: transform;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.Radio2__freeBoxisChecked__u9FQJkNhmm {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.Radio2__freeBox__wdFiv {
  display: flex;
  position: relative;
  transition-property: all;
  transition-duration: 0.2s;
  align-items: center;
  justify-content: center;
  padding-top: var(--token-u4z6Uru_YfAR62);
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.Radio2__slotTargetText__ppAHo {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-FvQuxC04RZW1hi);
  color: var(--token-zqrEV3Z8z);
}
