.AutocompleteResult__root__dhXjw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 2px 8px;
}
.AutocompleteResult__freeBox__viu10 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
  padding: 6px 10px;
}
.AutocompleteResult__freeBoxisSelected__viu10JeXk {
  background: var(--token-vvi_7r45uYs8w7);
}
.AutocompleteResult__labelContainer__yrRk9 {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 21px;
  min-width: 0;
}
.AutocompleteResult__labelContainer__yrRk9 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.AutocompleteResult__labelContainer__yrRk9 > .__wab_flex-container > *,
.AutocompleteResult__labelContainer__yrRk9
  > .__wab_flex-container
  > .__wab_slot
  > *,
.AutocompleteResult__labelContainer__yrRk9
  > .__wab_flex-container
  > picture
  > img,
.AutocompleteResult__labelContainer__yrRk9
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.AutocompleteResult__slotTargetChildren__s4VSx {
  font-size: 14px;
}
.AutocompleteResult__slotTargetChildrenisSelected__s4VSxJeXk {
  color: var(--token--h6FHSrgUAQsDe);
}
