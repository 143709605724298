.Switch__root__vma2D {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  cursor: pointer;
}
.Switch__root__vma2D > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
}
.Switch__rootisDisabled__vma2DuouBq {
  cursor: not-allowed;
}
.Switch__roottextAlignement_left__vma2Dfeby9 {
  flex-direction: row-reverse;
}
.Switch__roottextAlignement_left__vma2Dfeby9 > .__wab_flex-container {
  flex-direction: row-reverse;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.Switch__roottextAlignement_left__vma2Dfeby9 > .__wab_flex-container > *,
.Switch__roottextAlignement_left__vma2Dfeby9
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Switch__roottextAlignement_left__vma2Dfeby9
  > .__wab_flex-container
  > picture
  > img,
.Switch__roottextAlignement_left__vma2Dfeby9
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.Switch__root___focusVisibleWithin__vma2DdJCa3 {
  outline: none;
}
.Switch__root__vma2D:focus-within {
  outline: none;
}
.Switch__toggle__d6TTm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 24px;
  flex-shrink: 0;
}
.Switch__root__vma2D .Switch__toggle___focusVisibleWithin__d6TTMdJCa3 {
  outline: none;
}
.Switch__root__vma2D:focus-within .Switch__toggle__d6TTm {
  outline: none;
}
.Switch__track__ss5Rm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 20px;
  transition-property: background;
  transition-duration: 0.2s;
  background: var(--token-D5PfQGq_p2o95u);
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.2s;
  border-radius: 12px;
  border: 1px solid #ffffff00;
}
.Switch__trackisDisabled__ss5RmUouBq {
  background: var(--token-RkiaL8IPSKlGAr);
}
.Switch__trackisChecked__ss5RmTfRlv {
  background: var(--token-uafO86Amhx2CAZ);
}
.Switch__root__vma2D:hover .Switch__track__ss5Rm {
  background: var(--token-c8ADL42ppeG1sf);
}
.Switch__root__vma2D .Switch__track___focusVisibleWithin__ss5RmdJCa3 {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.Switch__root__vma2D:focus-within .Switch__track__ss5Rm {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.Switch__root__vma2D:active .Switch__track__ss5Rm {
  background: var(--token-8D7PsQmKPjCWdA);
}
.Switch__rootisChecked__vma2DTfRlv:hover .Switch__trackisChecked__ss5RmTfRlv {
  background: #0081f1;
}
.Switch__thumb___7A3BZ {
  display: block;
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: 3px;
  top: 5px;
  width: 14px;
  height: 14px;
  transition-property: all;
  transition-duration: 0.2s;
  background: var(--token-CkHZor_7deI9A3);
  box-shadow: 0px 1px 1px 0px #1e2a3b1a;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.Switch__thumbisDisabled___7A3BZuouBq {
  background: var(--token-aacnERCHvPoybg);
}
.Switch__thumbisChecked___7A3BZTfRlv {
  left: 17px;
  right: auto;
  border-style: none;
}
.Switch__root__vma2D .Switch__thumb___focusVisibleWithin___7A3BZdJCa3 {
  outline: none;
}
.Switch__root__vma2D:focus-within .Switch__thumb___7A3BZ {
  outline: none;
}
.Switch__labelContainer__bZa9K {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 8px;
}
.Switch__root__vma2D .Switch__labelContainer___focusVisibleWithin__bZa9KdJCa3 {
  outline: none;
}
.Switch__slotTargetChildren__xn68Z {
  white-space: pre;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.Switch__slotTargetChildren__xn68Z > .__wab_text,
.Switch__slotTargetChildren__xn68Z > .__wab_expr_html_text,
.Switch__slotTargetChildren__xn68Z > .__wab_slot-string-wrapper,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_text,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_expr_html_text,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_slot-string-wrapper,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_slot > .__wab_text,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.Switch__slotTargetChildren__xn68Z > *,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > *,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_slot > *,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Switch__slotTargetChildren__xn68Z > picture > img,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > picture > img,
.Switch__slotTargetChildren__xn68Z > .__wab_slot > .__wab_slot > picture > img,
.Switch__slotTargetChildren__xn68Z
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
}
.Switch__slotTargetChildrenisDisabled__xn68ZuouBq {
  color: var(--token-c8ADL42ppeG1sf);
}
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > *,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > *,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > .__wab_slot
  > *,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > picture
  > img,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > picture
  > img,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Switch__root__vma2D
  .Switch__slotTargetChildren___focusVisibleWithin__xn68ZdJCa3
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}
