.FilterButton__root__nHn9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  background: var(--token-SqSK5Lk3X);
  position: relative;
  box-shadow: inset 0px 0px 0px 0px var(--token-6_9iE0w_5TGI8j);
  transition-property: box-shadow, background;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in-out, ease-in-out;
  cursor: pointer;
  -webkit-transition-property: box-shadow, background;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.3s, 0.3s;
  border-radius: 1px;
  padding: var(--token-A-OXrd0RfsEv85) var(--token-P3nFMsZz2JMXx8);
}
.FilterButton__rootisActive__nHn9LYUxh {
  box-shadow: inset 0px 0px 0px 2px var(--token-6_9iE0w_5TGI8j);
  background: var(--token-NfSc1CXMklYEK2);
}
.FilterButton__slotTargetText__mok10 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-7xB3ITuN8);
  line-height: var(--token-lWY7kGeXV7C_yD);
  white-space: pre;
}
.FilterButton__slotTargetText__mok10 > *,
.FilterButton__slotTargetText__mok10 > .__wab_slot > *,
.FilterButton__slotTargetText__mok10 > .__wab_slot > .__wab_slot > *,
.FilterButton__slotTargetText__mok10
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.FilterButton__slotTargetText__mok10 > picture > img,
.FilterButton__slotTargetText__mok10 > .__wab_slot > picture > img,
.FilterButton__slotTargetText__mok10
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.FilterButton__slotTargetText__mok10
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.FilterButton__slotTargetTextisActive__mok10LYUxh {
  color: var(--token-plIc-sUNu2-k5-);
}
