.NavButton__root__pMsR5 {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}
.NavButton__button__clPbj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.NavButton__buttonisIconOnly__clPbjmJe4P.__wab_instance {
  flex-shrink: 0;
}
.NavButton__svg__hz6Cf {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.NavButton__text__lCleh {
  height: auto;
}
.NavButton__svg__jTAv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
