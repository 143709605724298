.RatingCardBlock__root__caXMz {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-CkHZor_7deI9A3);
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: 32px;
  border: 1px solid var(--token-RkiaL8IPSKlGAr);
}
.RatingCardBlock__root__caXMz > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.RatingCardBlock__root__caXMz > .__wab_flex-container > *,
.RatingCardBlock__root__caXMz > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__root__caXMz > .__wab_flex-container > picture > img,
.RatingCardBlock__root__caXMz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
@media (max-width: 480px) {
  .RatingCardBlock__root__caXMz {
    padding: 16px;
  }
}
.RatingCardBlock__freeBox__pCgX {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.RatingCardBlock__freeBox__pCgX > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.RatingCardBlock__freeBox__pCgX > .__wab_flex-container > *,
.RatingCardBlock__freeBox__pCgX > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__pCgX > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__pCgX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.RatingCardBlock__svg__cQjPy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
.RatingCardBlock__freeBox__uS9Vk {
  display: flex;
  position: relative;
  flex-direction: row;
}
.RatingCardBlock__freeBox__uS9Vk > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.RatingCardBlock__freeBox__uS9Vk > .__wab_flex-container > *,
.RatingCardBlock__freeBox__uS9Vk > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__uS9Vk > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__uS9Vk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 4px;
}
.RatingCardBlock__slotTargetTitle__plr9 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
.RatingCardBlock__text__s2Gz7 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-D5PfQGq_p2o95u);
}
.RatingCardBlock__slotTargetRatingText__u71T {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
}
.RatingCardBlock__slider__vA42Z.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.RatingCardBlock__freeBox__p733N {
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-top: 32px;
  min-width: 0;
  display: none;
}
.RatingCardBlock__freeBox__p733N > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.RatingCardBlock__freeBox__p733N > .__wab_flex-container > *,
.RatingCardBlock__freeBox__p733N > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__p733N > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__p733N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
.RatingCardBlock__freeBoxisOpen__p733NOBimy {
  display: flex;
}
.RatingCardBlock__freeBox___1UdXv {
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: var(--token-RkiaL8IPSKlGAr);
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .RatingCardBlock__freeBox___1UdXv {
    display: none;
  }
}
.RatingCardBlock__freeBox__faEFi {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.RatingCardBlock__freeBox__faEFi > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.RatingCardBlock__freeBox__faEFi > .__wab_flex-container > *,
.RatingCardBlock__freeBox__faEFi > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__faEFi > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__faEFi
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 8px;
}
.RatingCardBlock__slotTargetDataInfoTitle__hATxv {
  font-weight: 500;
}
.RatingCardBlock__freeBox___2WMk {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.RatingCardBlock__freeBox___2WMk > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.RatingCardBlock__freeBox___2WMk > .__wab_flex-container > *,
.RatingCardBlock__freeBox___2WMk > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox___2WMk > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox___2WMk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 24px;
}
@media (max-width: 480px) {
  .RatingCardBlock__freeBox___2WMk > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .RatingCardBlock__freeBox___2WMk > .__wab_flex-container > *,
  .RatingCardBlock__freeBox___2WMk > .__wab_flex-container > .__wab_slot > *,
  .RatingCardBlock__freeBox___2WMk > .__wab_flex-container > picture > img,
  .RatingCardBlock__freeBox___2WMk
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: 12px;
  }
}
.RatingCardBlock__freeBox___1KPge {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.RatingCardBlock__freeBox___1KPge > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.RatingCardBlock__freeBox___1KPge > .__wab_flex-container > *,
.RatingCardBlock__freeBox___1KPge > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox___1KPge > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox___1KPge
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.RatingCardBlock__freeBox__cs2Te {
  display: flex;
  position: relative;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  width: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.RatingCardBlock__freeBox__zaqhh {
  display: block;
  position: relative;
  width: 2px;
  height: 16px;
  max-width: 100%;
  background: var(--token-HHAuBKYL3pvmHz);
  flex-shrink: 0;
  border-radius: 100px;
}
.RatingCardBlock__freeBox__wFs4V {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  max-width: 100%;
  background: var(--token-CkHZor_7deI9A3);
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--token-HHAuBKYL3pvmHz);
}
.RatingCardBlock__text__uwPsg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-c8ADL42ppeG1sf);
  letter-spacing: 0.2px;
}
.RatingCardBlock__freeBox__toM0H {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  max-width: 100%;
}
.RatingCardBlock__freeBox__toM0H > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.RatingCardBlock__freeBox__toM0H > .__wab_flex-container > *,
.RatingCardBlock__freeBox__toM0H > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__toM0H > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__toM0H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.RatingCardBlock__freeBox__qxYXz {
  display: block;
  position: relative;
  width: 1px;
  height: 16px;
  max-width: 100%;
  background: var(--token-c8ADL42ppeG1sf);
  flex-shrink: 0;
}
.RatingCardBlock__text__sIxG {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-c8ADL42ppeG1sf);
  letter-spacing: 0.2px;
}
.RatingCardBlock__freeBox__lZg8H {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  min-width: 0;
}
.RatingCardBlock__freeBox__lZg8H > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.RatingCardBlock__freeBox__lZg8H > .__wab_flex-container > *,
.RatingCardBlock__freeBox__lZg8H > .__wab_flex-container > .__wab_slot > *,
.RatingCardBlock__freeBox__lZg8H > .__wab_flex-container > picture > img,
.RatingCardBlock__freeBox__lZg8H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
.RatingCardBlock__dataBarSegment__majV0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.RatingCardBlock__dataBarSegment___3ZCt2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.RatingCardBlock__dataBarSegment__tgf85.__wab_instance {
  max-width: 100%;
  position: relative;
}
.RatingCardBlock__dataBarSegment__aIcCw.__wab_instance {
  max-width: 100%;
  position: relative;
}
