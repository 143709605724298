.Benutzereinstellungen__root__ehkfJ {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
.Benutzereinstellungen__navigation___9ApaX.__wab_instance {
  position: relative;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__navigation___9ApaX.__wab_instance {
    position: relative;
  }
}
.Benutzereinstellungen__freeBox__sRqG1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 88px 24px;
}
.Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__sRqG1
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__sRqG1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox__sRqG1 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 88px 24px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__sRqG1 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 16px;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__sRqG1 > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox__sRqG1
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.Benutzereinstellungen__freeBox__wc7Ob {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 720px;
  min-width: 0;
}
.Benutzereinstellungen__freeBox__wc7Ob > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.Benutzereinstellungen__freeBox__wc7Ob > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__wc7Ob
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__wc7Ob > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__wc7Ob
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
.Benutzereinstellungen__freeBox___7DuIa {
  display: flex;
  position: relative;
  flex-direction: column;
}
.Benutzereinstellungen__freeBox___7DuIa > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.Benutzereinstellungen__freeBox___7DuIa > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox___7DuIa
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox___7DuIa > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox___7DuIa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 48px;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox___7DuIa > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox___7DuIa > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox___7DuIa
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox___7DuIa
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox___7DuIa
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
.Benutzereinstellungen__freeBox__xqRsj {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Benutzereinstellungen__freeBox__bx0Ir {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Benutzereinstellungen__freeBox__bx0Ir > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.Benutzereinstellungen__freeBox__bx0Ir > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__bx0Ir
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__bx0Ir > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__bx0Ir
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
.Benutzereinstellungen__freeBox__nSvao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: var(--token-YOOgp3GSpDYosY);
  height: var(--token-YOOgp3GSpDYosY);
  max-width: 100%;
  background: var(--token-NfHg4McmY);
  flex-shrink: 0;
  border-radius: 200px;
  padding: var(--token-A-OXrd0RfsEv85);
}
.Benutzereinstellungen__svg__ye8FJ {
  position: relative;
  object-fit: cover;
  width: var(--token-TJik2L8OcSV6kD);
  height: var(--token-TJik2L8OcSV6kD);
  color: var(--token-vvi_7r45uYs8w7);
  flex-shrink: 0;
}
.Benutzereinstellungen__freeBox__mhek8 {
  display: flex;
  flex-direction: column;
}
.Benutzereinstellungen__userFullName__opuXp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__userFullName__opuXp {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.Benutzereinstellungen__text__vAlYe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-s9gzWmEFR);
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
.Benutzereinstellungen__freeBox__kjwgg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Benutzereinstellungen__freeBox__kjwgg > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.Benutzereinstellungen__freeBox__kjwgg > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__kjwgg
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__kjwgg > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__kjwgg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__kjwgg {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__kjwgg > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__kjwgg > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox__kjwgg
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox__kjwgg
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox__kjwgg
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.Benutzereinstellungen__freeBox__yydzH {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  position: relative;
  min-height: 0;
}
.Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__yydzH
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__yydzH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox__yydzH {
    min-width: auto;
    max-width: 280px;
    display: flex;
    width: auto;
    height: 100%;
    position: relative;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container {
    min-height: 0;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__yydzH {
    height: auto;
    display: flex;
    flex-direction: row;
    max-width: none;
    width: 100%;
    overflow: auto;
    border-right-color: var(--token-RkiaL8IPSKlGAr);
    border-right-style: none;
    left: 0px;
    top: 0px;
    z-index: 1;
    position: sticky;
    min-width: auto;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--token-RkiaL8IPSKlGAr);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__yydzH > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox__yydzH
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox__yydzH
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox__yydzH
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.Benutzereinstellungen__freeBox__f3Ixd {
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__f3Ixd {
    position: relative;
    left: auto;
    top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    height: auto;
    max-width: 100%;
    z-index: 1;
  }
}
.Benutzereinstellungen__freeBox__clBlg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  overflow: auto;
  max-height: 100vh;
  padding-right: 40px;
  padding-top: 32px;
}
.Benutzereinstellungen__freeBox__clBlg > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.Benutzereinstellungen__freeBox__clBlg > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox__clBlg
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox__clBlg > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox__clBlg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__clBlg {
    display: flex;
    flex-direction: row;
    max-height: none;
    overflow: visible;
    position: relative;
    width: auto;
    height: auto;
    max-width: 100%;
    left: auto;
    top: auto;
    z-index: 1;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__clBlg > .__wab_flex-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__clBlg > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox__clBlg
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox__clBlg
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox__clBlg
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 32px;
  }
}
.Benutzereinstellungen__freeBox___1Bs26 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
}
.Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container > *,
.Benutzereinstellungen__freeBox___1Bs26
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container > picture > img,
.Benutzereinstellungen__freeBox___1Bs26
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox___1Bs26 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: auto;
    height: auto;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 12px;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox___1Bs26 {
    display: flex;
    flex-direction: row;
    width: auto;
    max-width: none;
    position: relative;
    top: auto;
    height: auto;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox___1Bs26 > .__wab_flex-container > *,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > picture
    > img,
  .Benutzereinstellungen__freeBox___1Bs26
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 12px;
    margin-top: 12px;
  }
}
.Benutzereinstellungen__navButton__nbouS.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__yugse {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__npb94 {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__tMule.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__nsChm {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__gWt {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__jvopD.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__qnXK {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__qtkXe {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__tNHwi.__wab_instance {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Benutzereinstellungen__navButton__tNHwi.__wab_instance {
    display: none;
  }
}
.Benutzereinstellungen__svg__r871U {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__ga5E {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__c37S.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__x7GeL {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__tAclx {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__kJmbT.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__sSlJt {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__ko8Ad {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__navButton__r3X0V.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Benutzereinstellungen__svg__uogi5 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.Benutzereinstellungen__text__kjYkh {
  height: auto;
  padding-right: 0px;
}
.Benutzereinstellungen__freeBox__wC7Mv {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .Benutzereinstellungen__freeBox__wC7Mv {
    width: 1px;
    height: 20px;
    display: block;
    position: relative;
    max-width: 100%;
    flex-shrink: 0;
  }
}
.Benutzereinstellungen__freeBox__x8HL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 32px;
  min-width: 0;
}
.Benutzereinstellungen__generalUserSettings__fyN95.__wab_instance {
  position: relative;
  align-self: flex-start;
}
.Benutzereinstellungen__passwordUserSettings__yZjI.__wab_instance {
  position: relative;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.Benutzereinstellungen__addressUserSettings__vs3Tf.__wab_instance {
  position: relative;
  align-self: flex-start;
  width: 100%;
  max-width: 652px;
  margin-bottom: var(--token-fkHbhUQaFbf-Tk);
  min-width: 0;
}
.Benutzereinstellungen__usageAgreementUserSettings___2URcW.__wab_instance {
  position: relative;
  align-self: flex-start;
}
.Benutzereinstellungen__benchmarkUserSettings__gvCpC.__wab_instance {
  position: relative;
  align-self: flex-start;
}
.Benutzereinstellungen__accountDeletionUserSettings__xtMeV.__wab_instance {
  position: relative;
  align-self: flex-start;
}
.Benutzereinstellungen__footer___4JsU7.__wab_instance {
  max-width: 100%;
  position: relative;
}
