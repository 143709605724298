.ProcessCardSection__root___3Hd0W {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: var(--token-P3nFMsZz2JMXx8) var(--token-fkHbhUQaFbf-Tk)
    var(--token-HUUwrpE5bq87rt);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCardSection__root___3Hd0W {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 480px) {
  .ProcessCardSection__root___3Hd0W {
    padding: 12px 24px;
  }
}
.ProcessCardSection__rootcolor_error___3Hd0WpFzt4 {
  background: var(--token-ATEWP6hIq5CbNw);
  padding-top: 32px;
  padding-bottom: 32px;
}
.ProcessCardSection__rootcolor_algo___3Hd0WrUsgv {
  background: var(--token-iOXFRSAwZrWdi2);
  padding-top: 32px;
  padding-bottom: 32px;
}
.ProcessCardSection__rootcolor_blue___3Hd0WfGqb9 {
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--token-4_CeG-8iBJgDtW);
}
.ProcessCardSection__rootcolor_gray___3Hd0WPv7Lv {
  background: var(--token-O8yxnKF8d);
  padding-top: 32px;
  padding-bottom: 32px;
}
.ProcessCardSection__rootcolor_success___3Hd0WkKp7D {
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--token--h6FHSrgUAQsDe);
}
.ProcessCardSection__rootisLast___3Hd0WgAUuG {
  border-radius: 0px 0px 12px 12px;
}
@media (max-width: 480px) {
  .ProcessCardSection__rootisLast___3Hd0WgAUuG {
    border-radius: 0px;
  }
}
.ProcessCardSection__roothasDivider___3Hd0WDwQym {
  border-top: 1px solid #c1cec924;
}
.ProcessCardSection__freeBox__olOqv {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 24px 0px 40px;
}
.ProcessCardSection__freeBox__olOqv > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessCardSection__freeBox__olOqv > .__wab_flex-container > *,
.ProcessCardSection__freeBox__olOqv > .__wab_flex-container > .__wab_slot > *,
.ProcessCardSection__freeBox__olOqv > .__wab_flex-container > picture > img,
.ProcessCardSection__freeBox__olOqv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.ProcessCardSection__freeBoxhasTitle__olOqv1Ymup {
  display: flex;
}
.ProcessCardSection__freeBoxisExpandable__olOqvm2FPr {
  display: flex;
}
.ProcessCardSection__freeBox__kbvSt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessCardSection__freeBox__t7Huy {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.ProcessCardSection__freeBoxhasTitle__t7Huy1Ymup {
  display: flex;
}
.ProcessCardSection__slotTargetTitle__gnAhj {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 600;
}
.ProcessCardSection__expandButton__vCyY.__wab_instance {
  max-width: 100%;
  flex-shrink: 0;
}
.ProcessCardSection__expandButtonisExpandable__vCyYm2FPr.__wab_instance {
  flex-shrink: 0;
  display: flex;
}
.ProcessCardSection__svg__okg8J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__svg__jvDu3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__freeBox__dWQy {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.ProcessCardSection__formGroup__y78GQ.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCardSection__textInput__uNerc.__wab_instance {
  position: relative;
}
.ProcessCardSection__svg__qmRnF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__svg__l5GIr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__text__rPls0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardSection__text__yIkQo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__text__sDt7N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__symbolCounter__vBNg.__wab_instance {
  position: relative;
}
.ProcessCardSection__formGroup__l7EmP.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCardSection__text__zHv7 {
  height: auto;
}
.ProcessCardSection__textInput__xq3U0.__wab_instance {
  position: relative;
}
.ProcessCardSection__svg__eOz3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__svg___5Nr0O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__text__o8Zu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardSection__text__ry5E7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__text__jplJf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__symbolCounter__qadG.__wab_instance {
  position: relative;
}
.ProcessCardSection__formGroup__fDaY.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.ProcessCardSection__textInput___2IqGg.__wab_instance {
  position: relative;
}
.ProcessCardSection__svg__fgHh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__svg__voG2O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSection__text__uOcV2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardSection__text__nixdl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__text__u22Db {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSection__symbolCounter__qQ8Kw.__wab_instance {
  position: relative;
}
