.Button__root__e5RXn {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-L1EeQgMLm);
  cursor: pointer;
  transition-property: background, box-shadow;
  transition-duration: 0.2s, 0.1s;
  transition-timing-function: ease-in-out, ease-in-out;
  box-shadow: 0px 0px 0px 0px #2571eb03;
  width: auto;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 36px;
  -webkit-transition-property: background, box-shadow;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.1s;
  border-radius: 1px;
  padding: 0px 14px;
  border: 1px none var(--token-vvi_7r45uYs8w7);
}
.Button__rootshowStartIcon__e5RXnKEeJ {
  padding-left: 10px;
}
.Button__rootshowEndIcon__e5RXnAu5Zv {
  padding-right: 10px;
}
.Button__rootisIconOnly__e5RXn32Dlr {
  width: 36px;
  padding: 0px;
}
.Button__rootisDisabled__e5RXnBoAe8 {
  cursor: not-allowed;
  opacity: 0.4;
}
.Button__rootshape_rounded__e5RXnziEHh {
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 999px;
}
.Button__rootshape_sharp__e5RXn1BdSy {
  border-radius: 2px;
}
.Button__rootsize_extraSmall__e5RXnsjrny {
  min-height: 22px;
  padding: 0px 6px;
}
.Button__rootsize_small__e5RXnrwEc3 {
  min-height: 28px;
  padding: 0px 8px;
}
.Button__rootsize_medium__e5RXn5Nosy {
  padding-top: 7px;
  padding-bottom: 7px;
}
.Button__rootsize_large__e5RXn1DdK3 {
  min-height: 44px;
  padding: 11px 18px;
}
.Button__rootcolor_blue__e5RXnrIND {
  background: var(--token-uafO86Amhx2CAZ);
  border-width: 0px;
  border-color: var(--token-i2qCvUVknsIZp2);
}
.Button__rootcolor_gold__e5RXnYb6Zt {
  background: var(--token-HHAuBKYL3pvmHz);
  border-width: 0px;
  border-color: var(--token-fMOFOdWlIwmyMR);
}
.Button__rootcolor_green__e5RXnImJsr {
  background: var(--token-6_9iE0w_5TGI8j);
  border-width: 0px;
  border-color: var(--token-plIc-sUNu2-k5-);
}
.Button__rootcolor_red__e5RXnLyR9S {
  background: var(--token-d6Fhz0OrC2AHcn);
  border-width: 0px;
  border-color: var(--token-4U0t8o9I_jm3I_);
}
.Button__rootcolor_grey__e5RXn2F9C {
  background: var(--token--EHWRjPIh);
  border-width: 0px;
}
.Button__rootcolor_white__e5RXnfdbIo {
  background: #ffffff;
  border-width: 0px;
  border-color: var(--token-CkHZor_7deI9A3);
}
.Button__rootcolor_clear__e5RXnIFdPx {
  background: none;
  border-width: 0px;
}
.Button__rootcolor_link__e5RXnwUi7L {
  background: #ffffff00;
  height: auto;
  min-height: auto;
  padding: 0px 2px;
  border-width: 0px;
}
.Button__rootcolor_orange__e5RXn0ZRcI {
  background: var(--token-6VQ__RqwTsOFsq);
  border-width: 0px;
  border-color: var(--token-GiX5g5XVDVSbZB);
}
.Button__rootbuttonType_outline__e5RXnnDeyj {
  background: none;
  border-width: 1px;
  border-style: solid;
}
.Button__rootbuttonType_dashed__e5RXnLxw37 {
  background: none;
  border-width: 1px;
  border-style: dashed;
}
.Button__rootbuttonType_solid__e5RXnUTvUv {
  border-width: 0px;
}
.Button__rootbuttonType_soft__e5RXnFzssk {
  background: none;
  border-width: 0px;
}
.Button__rootshowStartIcon_shape_rounded__e5RXnKEeJZiEHh {
  padding-left: 12px;
}
.Button__rootshowEndIcon_shape_rounded__e5RXnAu5ZvZiEHh {
  padding-right: 12px;
}
.Button__rootisIconOnly_size_extraSmall__e5RXn32DlrSjrny {
  width: 22px;
  height: 22px;
  padding: 0px;
}
.Button__rootisIconOnly_size_small__e5RXn32DlrRwEc3 {
  width: 28px;
  height: 28px;
  padding: 0px;
}
.Button__rootsize_large_showStartIcon__e5RXn1DdK3KEeJ {
  padding-left: 14px;
}
.Button__rootsize_large_showEndIcon__e5RXn1DdK3Au5Zv {
  padding-right: 14px;
}
.Button__rootsize_large_isIconOnly__e5RXn1DdK332Dlr {
  width: 44px;
  height: 44px;
  padding: 11px;
}
.Button__rootsize_small_shape_rounded__e5RXnrwEc3ZiEHh {
  padding-left: var(--token-7uzqW3y-2Tgb_s);
  padding-right: var(--token-7uzqW3y-2Tgb_s);
}
.Button__rootcolor_orange_buttonType_outline__e5RXn0ZRcINDeyj {
  border-width: 1px;
}
.Button__root___focusVisibleWithin__e5RXnekHe {
  box-shadow: 0px 0px 0px 3px #2571eb4d;
  outline: none;
}
.Button__root__e5RXn:focus-within {
  outline: none;
}
.Button__rootcolor_link__e5RXnwUi7L:hover {
  background: #ffffff00;
}
.Button__rootcolor_link__e5RXnwUi7L:active {
  background: #ffffff00;
}
.Button__softColor__wxNXh {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  background: #ffffff1a;
  border-radius: 1px;
}
.Button__softColorcolor_blue__wxNXhrIND {
  background: var(--token-1wSt5dJHZL9Eyo);
}
.Button__softColorcolor_gold__wxNXhYb6Zt {
  background: var(--token-im9ygHZ7u_gX0C);
}
.Button__softColorcolor_green__wxNXhImJsr {
  background: var(--token-NfSc1CXMklYEK2);
}
.Button__softColorcolor_red__wxNXhLyR9S {
  background: var(--token-fKmQxzFxQYEI3S);
}
.Button__softColorcolor_grey__wxNXh2F9C {
  background: var(--token-IaDlhQPgR);
}
.Button__softColorcolor_white__wxNXhfdbIo {
  opacity: 0.3;
}
.Button__softColorcolor_orange__wxNXh0ZRcI {
  background: var(--token-P5Q55lfBPHP7gh);
}
.Button__softColorbuttonType_soft__wxNXhFzssk {
  display: block;
}
.Button__hoverWrapper__la2RK {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: opacity;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Button__root__e5RXn:hover .Button__hoverWrapper__la2RK {
  opacity: 0.75;
}
.Button__root__e5RXn:active .Button__hoverWrapper__la2RK {
  opacity: 1;
}
.Button__rootcolor_link__e5RXnwUi7L:hover
  .Button__hoverWrappercolor_link__la2RKwUi7L {
  display: none;
}
.Button__rootcolor_link__e5RXnwUi7L:active
  .Button__hoverWrappercolor_link__la2RKwUi7L {
  display: none;
}
.Button__hoverColor__wdz35 {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: var(--token-FbjX2dQyRXMDtR);
  transition-property: background, opacity;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  opacity: 0.06;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: background, opacity;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 1px;
}
.Button__hoverColorcolor_blue__wdz35RIND {
  background: var(--token-73C_KCa5JvrUv3);
}
.Button__hoverColorcolor_gold__wdz35Yb6Zt {
  background: var(--token-vmRjBGgeQdkb50);
}
.Button__hoverColorcolor_green__wdz35ImJsr {
  background: var(--token-ObRRGpZ-v1PQfN);
}
.Button__hoverColorcolor_red__wdz35LyR9S {
  background: var(--token-NFXZ__zPMi8_rv);
}
.Button__hoverColorcolor_orange__wdz350ZRcI {
  background: var(--token-rT8-IAfiNlr-EF);
}
.Button__hoverColorbuttonType_solid__wdz35UTvUv {
  background: #21489680;
  opacity: 0.5;
}
.Button__loaderIconContainer__knzIu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.Button__loaderIconContainerisLoading__knzIucAe {
  position: absolute;
  left: auto;
  top: auto;
  display: flex;
}
.Button__svg__cGqH8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-8D7PsQmKPjCWdA);
  height: 1em;
}
.Button__svgcolor_blue__cGqH8RIND {
  color: var(--token-4_CeG-8iBJgDtW);
}
.Button__svgcolor_gold__cGqH8Yb6Zt {
  color: var(--token-iOXFRSAwZrWdi2);
}
.Button__svgcolor_green__cGqH8ImJsr {
  color: var(--token-zqrEV3Z8z);
}
.Button__buttonContent__nnf5Q.__wab_instance {
  position: relative;
}
.Button__svg__e5MT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Button__slotTargetTextcolor_link__ydyIuwUi7L {
  color: var(--token-QI60SWB06gBJ5K);
}
.Button__svg__omtoU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
