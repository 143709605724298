.SingleSelectButtonGroup__root__pXz8 {
  display: flex;
  flex-direction: row;
  position: relative;
}
.SingleSelectButtonGroup__root__pXz8 > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.SingleSelectButtonGroup__root__pXz8 > .__wab_flex-container > *,
.SingleSelectButtonGroup__root__pXz8 > .__wab_flex-container > .__wab_slot > *,
.SingleSelectButtonGroup__root__pXz8 > .__wab_flex-container > picture > img,
.SingleSelectButtonGroup__root__pXz8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
  margin-top: 12px;
}
.SingleSelectButtonGroup__button___1IZ8R.__wab_instance {
  max-width: 100%;
}
.SingleSelectButtonGroup__svg__yqOS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.SingleSelectButtonGroup__svg__aFMyc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.SingleSelectButtonGroup__button__kpgV.__wab_instance {
  max-width: 100%;
}
.SingleSelectButtonGroup__svg__gq0IJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.SingleSelectButtonGroup__text__irrGk {
  padding-left: 0px;
}
.SingleSelectButtonGroup__svg__rxHmh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
