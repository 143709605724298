.ReportDownloadButton__root__kpaG4 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.ReportDownloadButton__root__kpaG4 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-HaxgWLsrVFdD87));
  height: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.ReportDownloadButton__root__kpaG4 > .__wab_flex-container > *,
.ReportDownloadButton__root__kpaG4 > .__wab_flex-container > .__wab_slot > *,
.ReportDownloadButton__root__kpaG4 > .__wab_flex-container > picture > img,
.ReportDownloadButton__root__kpaG4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HaxgWLsrVFdD87);
}
.ReportDownloadButton__button__ymgKv.__wab_instance {
  max-width: 100%;
}
.ReportDownloadButton__svg__fISj8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportDownloadButton__svg__uLWkf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ReportDownloadButton__text__eYi2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token--h6FHSrgUAQsDe);
  min-width: 0;
}
.ReportDownloadButton__textisLoading__eYi2XVy {
  display: block;
}
.ReportDownloadButton__textisError__eYi2IjDpY {
  color: var(--token-ZPfWHFliL43P_r);
  display: block;
}
