.ProcessEsgStickyNav__root__nFqMk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessEsgStickyNav__freeBox__wVTtu {
  box-shadow: 0px 10px 15px -4px #0000001a, 0px 4px 6px -4px #0000001a;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: var(--token-hEzPFeV5Y);
  transform: translateX(0px) translateY(0px) translateZ(0px);
  min-width: 0;
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
  border-top: 1px solid #c1cec924;
}
.ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container > *,
.ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container > .__wab_slot > *,
.ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container > picture > img,
.ProcessEsgStickyNav__freeBox__wVTtu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessEsgStickyNav__freeBox__wVTtu {
    padding: 20px 40px;
  }
}
@media (max-width: 480px) {
  .ProcessEsgStickyNav__freeBox__wVTtu {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container > *,
  .ProcessEsgStickyNav__freeBox__wVTtu
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .ProcessEsgStickyNav__freeBox__wVTtu > .__wab_flex-container > picture > img,
  .ProcessEsgStickyNav__freeBox__wVTtu
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.ProcessEsgStickyNav__freeBox__dTrJ {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.ProcessEsgStickyNav__freeBox__dTrJ > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.ProcessEsgStickyNav__freeBox__dTrJ > .__wab_flex-container > *,
.ProcessEsgStickyNav__freeBox__dTrJ > .__wab_flex-container > .__wab_slot > *,
.ProcessEsgStickyNav__freeBox__dTrJ > .__wab_flex-container > picture > img,
.ProcessEsgStickyNav__freeBox__dTrJ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
.ProcessEsgStickyNav__submitButton___2YaCe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessEsgStickyNav__svg__eAceN {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ProcessEsgStickyNav__text__quczj {
  height: auto;
  margin-bottom: 0px;
}
.ProcessEsgStickyNav__svg__zpqv8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
