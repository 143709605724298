.PasswordUserSettings__root__zHmR {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  position: relative;
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .PasswordUserSettings__root__zHmR {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .PasswordUserSettings__root__zHmR {
    grid-template-columns: 1fr;
  }
}
.PasswordUserSettings__freeBox__qWn7R {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.PasswordUserSettings__freeBox__qWn7R > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PasswordUserSettings__freeBox__qWn7R > .__wab_flex-container > *,
.PasswordUserSettings__freeBox__qWn7R > .__wab_flex-container > .__wab_slot > *,
.PasswordUserSettings__freeBox__qWn7R > .__wab_flex-container > picture > img,
.PasswordUserSettings__freeBox__qWn7R
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.PasswordUserSettings__text__ccLfH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .PasswordUserSettings__text__ccLfH {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.PasswordUserSettings__formGroup__wzebf.__wab_instance {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.PasswordUserSettings__textInput24___1UA8.__wab_instance {
  position: relative;
}
.PasswordUserSettings__svg__sh32 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__svg__zivOs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__text__df7LF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PasswordUserSettings__text__sC7Zx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__text__sjHf6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__symbolCounter__pvFt1.__wab_instance {
  position: relative;
}
.PasswordUserSettings__formGroup__tjSyC.__wab_instance {
  position: relative;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.PasswordUserSettings__textInput25__ytl6O.__wab_instance {
  position: relative;
}
.PasswordUserSettings__svg__kilkf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__svg__ryWWu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__text__pf7A6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PasswordUserSettings__text__bMlYb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__text__yW685 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__text__j1Mg5 {
  color: var(--token-s9gzWmEFR);
}
.PasswordUserSettings__symbolCounter__smHrb.__wab_instance {
  position: relative;
}
.PasswordUserSettings__formGroup___5R55P.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  width: 100%;
  min-width: 0;
}
.PasswordUserSettings__textInput58__yfYz3.__wab_instance {
  width: 100%;
  min-width: 0;
}
.PasswordUserSettings__svg__xazmw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__svg__xKdb8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__text__lu3Mq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PasswordUserSettings__text___54CSm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__text___0Ox4F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PasswordUserSettings__symbolCounter___3Psu.__wab_instance {
  position: relative;
}
.PasswordUserSettings__freeBox__spRzf {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.PasswordUserSettings__freeBox__spRzf > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PasswordUserSettings__freeBox__spRzf > .__wab_flex-container > *,
.PasswordUserSettings__freeBox__spRzf > .__wab_flex-container > .__wab_slot > *,
.PasswordUserSettings__freeBox__spRzf > .__wab_flex-container > picture > img,
.PasswordUserSettings__freeBox__spRzf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.PasswordUserSettings__button__kf9P6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PasswordUserSettings__svg__wukiS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PasswordUserSettings__svg__hmqfR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
