._001PasswordReset01__root__wDvhO {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: flex-start;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._001PasswordReset01__freeBox__chrHx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._001PasswordReset01__freeBox__zrgCg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 72px 24px;
}
@media (max-width: 480px) {
  ._001PasswordReset01__freeBox__zrgCg {
    max-width: none;
  }
}
._001PasswordReset01__freeBox__odR9N {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 320px;
  min-width: 0;
}
._001PasswordReset01__freeBox__odR9N > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 56px);
  height: calc(100% + 56px);
}
._001PasswordReset01__freeBox__odR9N > .__wab_flex-container > *,
._001PasswordReset01__freeBox__odR9N > .__wab_flex-container > .__wab_slot > *,
._001PasswordReset01__freeBox__odR9N > .__wab_flex-container > picture > img,
._001PasswordReset01__freeBox__odR9N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 56px;
}
@media (max-width: 480px) {
  ._001PasswordReset01__freeBox__odR9N {
    max-width: none;
  }
}
._001PasswordReset01__freeBox__gowKg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
._001PasswordReset01__freeBox__gowKg > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
._001PasswordReset01__freeBox__gowKg > .__wab_flex-container > *,
._001PasswordReset01__freeBox__gowKg > .__wab_flex-container > .__wab_slot > *,
._001PasswordReset01__freeBox__gowKg > .__wab_flex-container > picture > img,
._001PasswordReset01__freeBox__gowKg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
._001PasswordReset01__freeBox__tKw90 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._001PasswordReset01__freeBox__tKw90 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._001PasswordReset01__freeBox__tKw90 > .__wab_flex-container > *,
._001PasswordReset01__freeBox__tKw90 > .__wab_flex-container > .__wab_slot > *,
._001PasswordReset01__freeBox__tKw90 > .__wab_flex-container > picture > img,
._001PasswordReset01__freeBox__tKw90
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
._001PasswordReset01__img__coOlj {
  object-fit: scale-down;
  max-width: 40px;
}
._001PasswordReset01__img__coOlj > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  ._001PasswordReset01__img__coOlj {
    display: none !important;
  }
}
._001PasswordReset01__img__aIaHh {
  object-fit: scale-down;
  max-width: 88px;
}
._001PasswordReset01__img__aIaHh > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  ._001PasswordReset01__img__aIaHh {
    display: none !important;
  }
}
._001PasswordReset01__text__rqM9Z {
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
._001PasswordReset01__formWrapperComponent__p1Yks {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._001PasswordReset01__formWrapperComponent__p1Yks > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
._001PasswordReset01__formWrapperComponent__p1Yks > .__wab_flex-container > *,
._001PasswordReset01__formWrapperComponent__p1Yks
  > .__wab_flex-container
  > .__wab_slot
  > *,
._001PasswordReset01__formWrapperComponent__p1Yks
  > .__wab_flex-container
  > picture
  > img,
._001PasswordReset01__formWrapperComponent__p1Yks
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
._001PasswordReset01__formGroup__rPepv.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: stretch;
}
._001PasswordReset01__textInput__b3Brs.__wab_instance {
  position: relative;
}
._001PasswordReset01__svg___1Lhai {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._001PasswordReset01__svg__apylt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._001PasswordReset01__text__lTwbm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._001PasswordReset01__text__fFfk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._001PasswordReset01__text__cKydb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._001PasswordReset01__symbolCounter___95P.__wab_instance {
  position: relative;
}
._001PasswordReset01__button__u6Nve.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: stretch;
  margin-top: calc(12px + var(--token-Wf1GGJSbrxsgS-)) !important;
}
._001PasswordReset01__svg__uuZ8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._001PasswordReset01__svg__vrIyH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._001PasswordReset01__freeBox__miD21 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._001PasswordReset01__freeBox__miD21 > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._001PasswordReset01__freeBox__miD21 > .__wab_flex-container > *,
._001PasswordReset01__freeBox__miD21 > .__wab_flex-container > .__wab_slot > *,
._001PasswordReset01__freeBox__miD21 > .__wab_flex-container > picture > img,
._001PasswordReset01__freeBox__miD21
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
._001PasswordReset01__freeBox___9T2Sc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._001PasswordReset01__text__laCi2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-c8ADL42ppeG1sf);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._001PasswordReset01__link__l4Rkr {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
