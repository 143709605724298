.CardMakroMikrolage__root__u3RhW.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__processCardSection__oDzNc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__freeBox__tlFpZ {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox__tlFpZ {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox__tlFpZ {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.CardMakroMikrolage__formGroup__p0Eqs.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup__p0Eqs.__wab_instance {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__button__pc4L.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__qbioS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__rKhjO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__bEbJj.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__e7Idv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__zbjSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__ow7Wc.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg___8Re96 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__imGUt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__elZ1D.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__b6ZqQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__cveig {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__zqnVv.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__banner__hgAtg.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__banner__hgAtg.__wab_instance {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__button__icjP6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__svg__jrMyc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__wPhLc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__formGroup__jctfb.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.CardMakroMikrolage__textInput216__bIdQx.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___92M5K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__rBVs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__vv8Kl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__elZhp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__o5M9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__vWjpx.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__w8RKk.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput217__uvpqU.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___60C8J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__yCfRa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text___5P17 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__ozvpG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__wyDcR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__vqGi5.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__bWiRf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput218__ywxo0.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___1Fmkr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__hQq6N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__iNvm2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__xPaDd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__k1KvN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___4BNmA.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__vTnAl.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput219__nxdZr.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__zqvzp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___9Cw00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__rBoex {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__okUgn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__gkDc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__qVuXa.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__a33Lo.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput220__a9O0.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___8BrWv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__oujRv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__z9QY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__xjjKe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___0FAeo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__rmTBt.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__ziHcO.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__l1Fr {
  padding-left: 0px;
}
.CardMakroMikrolage__textInput221__unfx2.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__ilVia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__vqeJr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__b361H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__eqHf4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__oqlQa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__efsMo.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__zr5Yc.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput222__r1Up1.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__lrEZd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___4KuXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__sjLna {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__widEc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__wKnRs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__yfD2S.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__vnGIk.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput223__wvZw0.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__fMbv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__nkby8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__gZ6X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__mziQm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__oWvSw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__gr8GJ.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__pQhMo.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput224__j855M.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__ox4A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__u5N6X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__hPbpy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__d98Lv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__ksgMj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__fb4KM.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__kTyMf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput226__jhshb.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__oZdg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__mcaxl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__v37Qp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__ally1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___008K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__q4Duk.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup___54OR.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput227___8SfGa.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__xjtt0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__vrzL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__vpTX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__k8XgV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__vbySg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__vCnS1.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__wJjd.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput228___7I9Ee.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___8NIuN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__kkspy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__rVoah {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__i8Sgf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__y9PmE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___3UoRr.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__geQlZ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput229__qpPea.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__bu8I2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__o0B8O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__s54Q9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__gVc6C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__wl1N1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__nRcs0.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__gXo9S.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput230__nkSyl.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__kkOI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___9Lh9V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__kvJro {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__tZeMo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__ed1X7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__yBr73.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__bgrKa.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput231__erNju.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___8YTbJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__zYeEg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__nY78M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__tcJp2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__e2OUb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__pms.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__text___8GNo7 {
  padding-right: 0px;
}
.CardMakroMikrolage__processCardSection__oVe7V.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__freeBox___1JrLl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox___1JrLl {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox___1JrLl {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.CardMakroMikrolage__formGroup__puSm.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
.CardMakroMikrolage__textInput248___6ACpX.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__mqr6M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__s7Qr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text___4Em5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__vkfiw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___1XnYb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__j7TFi.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__uKng3.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.CardMakroMikrolage__text___9VUMg {
  padding-right: 0px;
}
.CardMakroMikrolage__select__yU9Dv.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__g1LAz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.CardMakroMikrolage__option__jXbWb.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__tmBu0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__gzxx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__xxk.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___1HODt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg___2OkTh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__a4CEd.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__eO6Pf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg___0Fn12 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__eBsk.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__yudpz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__wSYq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__vfFwq.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__sMj4L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__b0RGq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__r9UoC.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup___28XT0.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__saBsl {
  padding-right: 0px;
}
.CardMakroMikrolage__select2__smNo1.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__lylvt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.CardMakroMikrolage__option___1Dd7.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__zFlXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__pTl3C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__qaO7E.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__wsKSi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__vsHu2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__uHJp.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__cGqp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__ziO5V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__j71AF.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__m8DsC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__fBg5C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__t6S4O.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___0Qqq8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg___1Tw2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__mprra.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__whala.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__ql6U7 {
  padding-right: 0px;
}
.CardMakroMikrolage__select3___99OtN.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__k0NPy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.CardMakroMikrolage__option__f0R8.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__gRvi7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__inuyS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__fkfNm.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__p3Eeo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__p6Hu2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__mTvh.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___5UoRq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__nfuef {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__uhMa.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__w0Yy8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__yqf8N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__nWck3.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__sjGmq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__gm8C3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__cBwyy.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__e4MUg.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__xiMXt {
  padding-right: 0px;
}
.CardMakroMikrolage__select4__aW5E2.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__vcDdp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.CardMakroMikrolage__option___374F4.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__tn9Ad {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__pPflS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__hckfj.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__sriLk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__nk1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option___5Nm2O.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___4JTho {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__t94Gb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option___2OBMp.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___9Te4T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__s9HxP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__nUj0K.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__gAf6I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__lZ0QZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__xu7GH.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__yzFa7.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text___4W3Tf {
  padding-right: 0px;
}
.CardMakroMikrolage__select5__ycI9.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__d4R7Y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.CardMakroMikrolage__option__o62On.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___1Cnl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__p87O8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__b5SZu.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___57U7R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__yc8By {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__abp1D.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__oEoyf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg___3KcGo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__rf2Pg.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg__sAqZz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__xipQr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__option__l1Str.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__svg___18TqY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CardMakroMikrolage__svg__pf5Dc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__hQwp.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__text__qpUgR {
  padding-right: 0px;
}
.CardMakroMikrolage__processCardSection__kipN0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__freeBox___4LIti {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox___4LIti {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox___4LIti {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.CardMakroMikrolage__formGroup__fEnrm.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup__fEnrm.__wab_instance {
    grid-column-end: span 2;
  }
}
.CardMakroMikrolage__button__t0SAu.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__koUw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__yEvO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__jl9Oc.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__e6Zvh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__dxNZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__uvsL.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__tHxq1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___9S38Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button___0RWds.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg___5Ykiv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__wYh45 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__button__r9Nq8.__wab_instance {
  max-width: 100%;
}
.CardMakroMikrolage__svg__uvIGe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text___2Ol04 {
  padding-right: 0px;
}
.CardMakroMikrolage__svg__byjAn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__symbolCounter__vm1Q3.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__banner__ehMfM.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__banner__ehMfM.__wab_instance {
    grid-column-end: span 2;
  }
}
.CardMakroMikrolage__button___5UaMn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__svg___84PBy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__bxbvh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__formGroup___8UUm8.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup___8UUm8.__wab_instance {
    grid-column-end: span 1;
  }
}
.CardMakroMikrolage__textInput232__yunN.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__qroJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__uKSiG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__ohNyD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__aIcxc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__hbVqn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__oZjGw.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__xl6Ne.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup__xl6Ne.__wab_instance {
    grid-column-end: span 1;
  }
}
.CardMakroMikrolage__textInput233__lVMts.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__h7YM7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__kEZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__iUrDq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text___98VaK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___7Fy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___00UDi.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__urqXw.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup__urqXw.__wab_instance {
    grid-column-end: span 1;
  }
}
.CardMakroMikrolage__textInput235__e0Lmd.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__taMzx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__d2Ufy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__qehM2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__hL5Ik {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___2GxkA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___48Luc.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__jtNh4.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__formGroup__jtNh4.__wab_instance {
    grid-column-end: span 1;
  }
}
.CardMakroMikrolage__textInput234__aNVa.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___5Itdg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__vUG1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__uVOvs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__zTgzo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__pgEu1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__hSd70.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__freeBox__dDbvd {
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 32px;
  width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox__dDbvd {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox__dDbvd {
    grid-column-end: span 2;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.CardMakroMikrolage__text__xpZzp {
  grid-column-end: span 6;
  color: var(--token-zqrEV3Z8z);
}
@media (max-width: 480px) {
  .CardMakroMikrolage__text__xpZzp {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__formGroup__hndUx.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.CardMakroMikrolage__text__nFojs {
  padding-right: 0px;
}
.CardMakroMikrolage__textInput236__dwqbb.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__wVjFo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__lncHu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__niGtG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__s3Mos {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__fCjK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__b6Gk4.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__hkE1Q.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput237__e59Cj.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__irFeS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___5JtHj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__mcc6W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__uTk5A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__krnsz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__nWu7.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__loYyp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput238__whty3.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___65Bf7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__wZfDo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__i1DJs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__zMuiP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__sbH8T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__tNdvB.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__rdm1L.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__b3UhG {
  padding-left: 0px;
}
.CardMakroMikrolage__textInput239__gAyqu.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg___1Dc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__gv2V8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__xy1JZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__keyMm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__za11X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__oheR0.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__freeBox__swhIp {
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 32px;
  width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox__swhIp {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox__swhIp {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-end: span 2;
  }
}
.CardMakroMikrolage__text__fIefR {
  grid-column-end: span 6;
  color: var(--token-zqrEV3Z8z);
}
@media (max-width: 480px) {
  .CardMakroMikrolage__text__fIefR {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__formGroup___7GWwW.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.CardMakroMikrolage__text__wMrQc {
  padding-right: 0px;
}
.CardMakroMikrolage__textInput240__ntxOm.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__mStxV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___7EeSn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__dfoMn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__stwT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___8LSIg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__j716W.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__lgCx0.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput241__swsRd.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__iq8Rg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__mjl5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__ra8Ze {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__qKlwe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__a3XLg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___3TlYw.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__qnDlq.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput242__ejezw.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__cgfHa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___5X7L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__koy84 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__m45B7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__yIWe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__fvVjh.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup___7DwlD.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text___6DSdt {
  padding-left: 0px;
}
.CardMakroMikrolage__textInput243__jhw1G.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__dNaLd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__lN2CT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__gopnX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__uegGi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__utcCi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__w2Hh.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__freeBox__tu3On {
  display: grid;
  position: relative;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 32px;
  width: 100%;
  grid-column-end: span 6;
  padding-top: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .CardMakroMikrolage__freeBox__tu3On {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .CardMakroMikrolage__freeBox__tu3On {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-end: span 2;
  }
}
.CardMakroMikrolage__text__ld540 {
  grid-column-end: span 6;
  color: var(--token-zqrEV3Z8z);
}
@media (max-width: 480px) {
  .CardMakroMikrolage__text__ld540 {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__banner__egQMy.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 8;
}
@media (max-width: 480px) {
  .CardMakroMikrolage__banner__egQMy.__wab_instance {
    grid-column-end: span 4;
  }
}
.CardMakroMikrolage__button___6Fqar.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__svg__yKa9C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__zWuWf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__formGroup___1Dfvj.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.CardMakroMikrolage__text__vC1N {
  padding-right: 0px;
}
.CardMakroMikrolage__textInput244__qlzSq.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__c9It {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__wpPa8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__czPwa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__nsUnh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text___3Hu1S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__dB9V.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup___9Buws.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput245__vxbP6.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__zaVze {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__wtfTr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__yoMqc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__bcxxn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__tPdcM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__ngicQ.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__o4DeH.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__textInput246__ygysp.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__yfOun {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__cB3Fs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__bVCyM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__uNsCi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__a2JCy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter__iwhcp.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__formGroup__lntq3.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.CardMakroMikrolage__text__l6Jif {
  padding-left: 0px;
}
.CardMakroMikrolage__textInput247__d5JxA.__wab_instance {
  width: 100%;
  min-width: 0;
}
.CardMakroMikrolage__svg__gx8H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg___61D2B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__aqjFv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__noEt9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__uZoUq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___3MZ91.__wab_instance {
  position: relative;
}
.CardMakroMikrolage__text__wfWb7 {
  padding-right: 0px;
}
.CardMakroMikrolage__formGroup__uf3Sk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CardMakroMikrolage__textInput225__ijnpt.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.CardMakroMikrolage__svg___5Jrw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__svg__ni01A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CardMakroMikrolage__text__tz3E3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.CardMakroMikrolage__text__uqq8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__text__u5LdD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.CardMakroMikrolage__symbolCounter___1LXi4.__wab_instance {
  position: relative;
}
