.ProcessSection__root__hz4Um {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-width: 0;
}
.ProcessSection__sectionHeader___65TWj.__wab_instance {
  position: relative;
}
.ProcessSection__slotTargetSectionTitle__eG3Z {
  color: var(--token-CkHZor_7deI9A3);
  font-weight: 500;
  font-size: var(--token-iRie9MHRquxJOV);
}
.ProcessSection__slotTargetSectionText__cCG {
  color: var(--token-7xB3ITuN8);
}
.ProcessSection__freeBox__l41Nu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessSection__freeBox__l41Nu > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.ProcessSection__freeBox__l41Nu > .__wab_flex-container > *,
.ProcessSection__freeBox__l41Nu > .__wab_flex-container > .__wab_slot > *,
.ProcessSection__freeBox__l41Nu > .__wab_flex-container > picture > img,
.ProcessSection__freeBox__l41Nu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
