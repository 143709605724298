.LangSelectItem__navLink__xVidX {
  display: flex;
  flex-direction: column;
  padding-left: var(--token-a0wt3E9LJjO_Nh);
  padding-right: var(--token-a0wt3E9LJjO_Nh);
  position: relative;
  cursor: pointer;
}
.LangSelectItem__navLink__xVidX > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.LangSelectItem__navLink__xVidX > .__wab_flex-container > *,
.LangSelectItem__navLink__xVidX > .__wab_flex-container > .__wab_slot > *,
.LangSelectItem__navLink__xVidX > .__wab_flex-container > picture > img,
.LangSelectItem__navLink__xVidX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
.LangSelectItem__freeBox__o3U2H {
  display: flex;
  position: relative;
  flex-direction: row;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  box-shadow: 0px 0px 0px 0px var(--token-6_9iE0w_5TGI8j);
  -webkit-transition-property: box-shadow;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.LangSelectItem__freeBox__o3U2H > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.LangSelectItem__freeBox__o3U2H > .__wab_flex-container > *,
.LangSelectItem__freeBox__o3U2H > .__wab_flex-container > .__wab_slot > *,
.LangSelectItem__freeBox__o3U2H > .__wab_flex-container > picture > img,
.LangSelectItem__freeBox__o3U2H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.LangSelectItem__freeBoxisActive__o3U2HlmZbJ {
  box-shadow: 0px 2px 0px 0px var(--token-6_9iE0w_5TGI8j);
}
.LangSelectItem__navLink__xVidX:hover .LangSelectItem__freeBox__o3U2H {
  box-shadow: 0px 2px 0px 0px var(--token-6_9iE0w_5TGI8j);
}
.LangSelectItem__freeBox___0RNht {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: row;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.LangSelectItem__slotTargetText__v69th {
  font-weight: 500;
  color: var(--token-QI60SWB06gBJ5K);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.LangSelectItem__slotTargetText__v69th > *,
.LangSelectItem__slotTargetText__v69th > .__wab_slot > *,
.LangSelectItem__slotTargetText__v69th > .__wab_slot > .__wab_slot > *,
.LangSelectItem__slotTargetText__v69th
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.LangSelectItem__slotTargetText__v69th > picture > img,
.LangSelectItem__slotTargetText__v69th > .__wab_slot > picture > img,
.LangSelectItem__slotTargetText__v69th
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.LangSelectItem__slotTargetText__v69th
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.LangSelectItem__slotTargetTextisActive__v69thLmZbJ {
  color: var(--token-plIc-sUNu2-k5-);
}
.LangSelectItem__navLink__xVidX:hover .LangSelectItem__slotTargetText__v69th {
  color: var(--token-plIc-sUNu2-k5-);
}
