.BenchmarkUserSettings__root__cEsyT {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  position: relative;
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
.BenchmarkUserSettings__h1___1PaT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  font-weight: 600;
  font-size: var(--token-JJM6LNeA-0eMML);
  min-width: 0;
}
.BenchmarkUserSettings__formGroup__iVSbp.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.BenchmarkUserSettings__select1___2O7D.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.BenchmarkUserSettings__svg__mlgml {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.BenchmarkUserSettings__option__mF9La.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___4Y2XB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___4Oqee {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__gnJas.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___21CuJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__jk1Mc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__xartr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__lnYa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__hgohq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__orKM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___6QXsG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__djpiW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___14AJu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__r3Cj0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__xwIb8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__yjtha.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__jNmlU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___3DEco {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__y5UYz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__izM5P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___3PAnb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__ugwxo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__rfipf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__hngX4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__m0Qs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__z3XTq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___9SgTh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___4IZbg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__bvQg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__v1CGy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__symbolCounter__kseCg.__wab_instance {
  position: relative;
}
.BenchmarkUserSettings__formGroup__qp1Vn.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.BenchmarkUserSettings__bmAuswEigTyp__a7Nmb.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.BenchmarkUserSettings__svg__vIyh0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.BenchmarkUserSettings__option__bmM9Y.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__jaoYt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__fdQEf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__shlIs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__fo7Wm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__btTzw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___1Qx3L.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__em7Gv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__ecPp6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__kzL8X.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__kg0BF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___38Cd6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___1Oagx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__ekRbE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__irLv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__h1Lq3.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__ln3C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__wBogL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__holrH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___1Ttha {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__iQYr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___2DrhG.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__nrZdm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__koL61 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___0M7Vw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__sMtu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__ayw1C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__bn7Ts.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__iHaT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__iVyBx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__qK53.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__eqfwj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__kGlk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__zDxWf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___0RhJa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__iZ0NT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__gSnLd.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__eaEfv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__kl4X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__eSesu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__xLuEg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__yvwd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__w5Xrp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__uHdDk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__deo2P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___46RCn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__sjUdZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__vBpLx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__ndSRw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___8DYi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__usG4Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__uphru.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__vLvzj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___1P2Cn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option___6D63M.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__voShj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___4Y8Ik {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__symbolCounter__hPp9G.__wab_instance {
  position: relative;
}
.BenchmarkUserSettings__formGroup___3KcK8.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.BenchmarkUserSettings__bmAuswReg__iJnsh.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.BenchmarkUserSettings__svg__uzIw8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.BenchmarkUserSettings__option__jUfDn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__wJ7F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__flLXm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__iBwj1.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___8Ocy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__mw07 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__vFyA.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__il4GZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__kFq3M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__bnaDw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg___7YJrm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__rNyCb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__nzrBd.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__nykXv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__mKhV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__w1P2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__eqv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__sobRr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__h6B4F.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__tfxer {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__o4D2F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__fGEgs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__yoqJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg___7SMA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__option__zjQzp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__h8ZOs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.BenchmarkUserSettings__svg__f0Of {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__symbolCounter__uS9YW.__wab_instance {
  position: relative;
}
.BenchmarkUserSettings__freeBox__fvuf3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.BenchmarkUserSettings__freeBox__fvuf3 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.BenchmarkUserSettings__freeBox__fvuf3 > .__wab_flex-container > *,
.BenchmarkUserSettings__freeBox__fvuf3
  > .__wab_flex-container
  > .__wab_slot
  > *,
.BenchmarkUserSettings__freeBox__fvuf3 > .__wab_flex-container > picture > img,
.BenchmarkUserSettings__freeBox__fvuf3
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
.BenchmarkUserSettings__button___1CXhq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BenchmarkUserSettings__svg__tMmlb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BenchmarkUserSettings__svg__uqK2V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
