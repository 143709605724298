.SliderThumb__root__pkwpF {
  display: block;
  flex-direction: column;
  position: relative;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  cursor: grab;
}
.SliderThumb__rootisDragging__pkwpFkOg7V {
  cursor: grabbing;
}
.SliderThumb__rootisAlgo__pkwpF6YnJs {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.SliderThumb__thumb__vm7Z2 {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: var(--token-BqwcOUIRst2GEg);
  box-shadow: 0px 0px 0px 0px var(--token-BqwcOUIRst2GEg),
    0px 0px 0px 0px #3b85ed33, 0px 2px 4px -1px #0f182a33;
  transition-property: background, box-shadow;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  left: 0px;
  top: 0px;
  -webkit-transition-property: background, box-shadow;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 100%;
}
.SliderThumb__thumbisDragging__vm7Z2KOg7V {
  box-shadow: 0px 0px 0px 1px var(--token-BqwcOUIRst2GEg),
    0px 0px 0px 12px #3b85ed24, 0px 2px 4px -1px #0f182a33;
}
.SliderThumb__thumbisAlgo__vm7Z26YnJs {
  width: 24px;
  height: 24px;
  background: var(--token-iOXFRSAwZrWdi2);
  box-shadow: 0px 0px 0px 0px var(--token-BqwcOUIRst2GEg),
    0px 0px 0px 0px #3b85ed33, 0px 0px 0px 0px #ffffff;
  border: 2px solid var(--token-3XfQdrAjqc1oI9);
}
.SliderThumb__thumbcolor_gold__vm7Z2CaP35 {
  background: var(--token-HHAuBKYL3pvmHz);
  box-shadow: 0px 0px 0px 0px var(--token-HHAuBKYL3pvmHz),
    0px 0px 0px 0px #edbe3b33, 0px 2px 4px -1px #0f182a33;
}
.SliderThumb__thumbcolor_gray__vm7Z2H6Sqr {
  background: var(--token-c8ADL42ppeG1sf);
}
.SliderThumb__root__pkwpF:hover .SliderThumb__thumb__vm7Z2 {
  background: var(--token-uafO86Amhx2CAZ);
  box-shadow: 0px 0px 0px 1px var(--token-uafO86Amhx2CAZ),
    0px 0px 0px 12px #3b85ed24, 0px 2px 4px -1px #0f182a33;
}
.SliderThumb__rootisAlgo__pkwpF6YnJs:hover
  .SliderThumb__thumbisAlgo__vm7Z26YnJs {
  background: var(--token-im9ygHZ7u_gX0C);
  box-shadow: none;
}
.SliderThumb__rootcolor_gold__pkwpFCaP35:hover
  .SliderThumb__thumbcolor_gold__vm7Z2CaP35 {
  background: var(--token-gjAKHzQ_0d9Weq);
  box-shadow: 0px 0px 0px 1px var(--token-gjAKHzQ_0d9Weq),
    0px 0px 0px 12px #edbe3b24, 0px 2px 4px -1px #0f182a33;
}
.SliderThumb__svg__lcV8V {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-fMOFOdWlIwmyMR);
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.SliderThumb__svgisAlgo__lcV8V6YnJs {
  color: var(--token-3XfQdrAjqc1oI9);
  display: block;
}
.SliderThumb__tooltipContainer__pXvQh {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--token-CkHZor_7deI9A3);
  margin-top: -32px;
  margin-bottom: 4px;
  box-shadow: 0px 4px 16px -1px #1e2a3b2b;
  left: 0px;
  top: 0px;
  display: none;
  border-radius: 4px;
  padding: 2px 6px;
}
.SliderThumb__tooltipContainerisDragging__pXvQhkOg7V {
  display: flex;
}
.SliderThumb__slotTargetTooltip__lqaTk {
  font-weight: 500;
  text-align: center;
}
