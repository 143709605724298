.AccountDeletionUserSettings__root__wTvAz {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  position: relative;
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .AccountDeletionUserSettings__root__wTvAz {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .AccountDeletionUserSettings__root__wTvAz {
    grid-template-columns: 1fr;
  }
}
.AccountDeletionUserSettings__freeBox___570T {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.AccountDeletionUserSettings__freeBox___570T > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.AccountDeletionUserSettings__freeBox___570T > .__wab_flex-container > *,
.AccountDeletionUserSettings__freeBox___570T
  > .__wab_flex-container
  > .__wab_slot
  > *,
.AccountDeletionUserSettings__freeBox___570T
  > .__wab_flex-container
  > picture
  > img,
.AccountDeletionUserSettings__freeBox___570T
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.AccountDeletionUserSettings__text__o1Te0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .AccountDeletionUserSettings__text__o1Te0 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.AccountDeletionUserSettings__freeBox__gp6AF {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.AccountDeletionUserSettings__freeBox__gp6AF > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.AccountDeletionUserSettings__freeBox__gp6AF > .__wab_flex-container > *,
.AccountDeletionUserSettings__freeBox__gp6AF
  > .__wab_flex-container
  > .__wab_slot
  > *,
.AccountDeletionUserSettings__freeBox__gp6AF
  > .__wab_flex-container
  > picture
  > img,
.AccountDeletionUserSettings__freeBox__gp6AF
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.AccountDeletionUserSettings__button__b2M7K.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AccountDeletionUserSettings__svg__k3Twj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AccountDeletionUserSettings__svg__zyFBz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
