.AddressSearchBar__root__lQa3U {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.AddressSearchBar__root__lQa3U > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.AddressSearchBar__root__lQa3U > .__wab_flex-container > *,
.AddressSearchBar__root__lQa3U > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__root__lQa3U > .__wab_flex-container > picture > img,
.AddressSearchBar__root__lQa3U
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.AddressSearchBar__rootisActive__lQa3UOe3UT {
  background: var(--token-QI60SWB06gBJ5K);
  border-bottom-style: none;
  padding: 46px 24px;
}
.AddressSearchBar__freeBox__fwyMn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.AddressSearchBar__freeBox__fwyMn > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.AddressSearchBar__freeBox__fwyMn > .__wab_flex-container > *,
.AddressSearchBar__freeBox__fwyMn > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__fwyMn > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__fwyMn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.AddressSearchBar__freeBoxisActive__fwyMnoe3UT {
  display: none;
}
.AddressSearchBar__freeBox__ijzpj {
  display: flex;
  flex-direction: row;
}
.AddressSearchBar__freeBox__ijzpj > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.AddressSearchBar__freeBox__ijzpj > .__wab_flex-container > *,
.AddressSearchBar__freeBox__ijzpj > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__ijzpj > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__ijzpj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 40px;
}
.AddressSearchBar__freeBox__lHd5W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.AddressSearchBar__freeBox__lHd5W > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.AddressSearchBar__freeBox__lHd5W > .__wab_flex-container > *,
.AddressSearchBar__freeBox__lHd5W > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__lHd5W > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__lHd5W
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 4px;
}
.AddressSearchBar__freeBox__taklE {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.AddressSearchBar__freeBox__taklE > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.AddressSearchBar__freeBox__taklE > .__wab_flex-container > *,
.AddressSearchBar__freeBox__taklE > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__taklE > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__taklE
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
}
.AddressSearchBar__mapButton__b2M80.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.AddressSearchBar__svg__xuXj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__a8Zls {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__freeBox__sAKjD {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  max-width: 400px;
  width: 100%;
  min-width: 0;
}
.AddressSearchBar__searchInput___0M1Sh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__autocompleteResult__vv9S5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__autocompleteResult__f206U.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__svg__fqreW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__submitButton___3XbEg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__svg__aq3Pv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__uaHfF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__freeBox__ohH5X {
  display: flex;
  flex-direction: row;
  height: auto;
  align-self: flex-start;
}
.AddressSearchBar__freeBox__ohH5X > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.AddressSearchBar__freeBox__ohH5X > .__wab_flex-container > *,
.AddressSearchBar__freeBox__ohH5X > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__ohH5X > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__ohH5X
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.AddressSearchBar__countrySwitcher__aM3Af.__wab_instance.__wab_instance {
  position: relative;
}
.AddressSearchBar__freeBox___43XyE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.AddressSearchBar__freeBoxhasRecentAdresses___43XyEedTm3 {
  display: flex;
}
.AddressSearchBar__freeBox__bshqT {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  min-width: 0;
}
.AddressSearchBar__freeBox__bshqT > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.AddressSearchBar__freeBox__bshqT > .__wab_flex-container > *,
.AddressSearchBar__freeBox__bshqT > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__bshqT > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__bshqT
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
.AddressSearchBar__freeBox__qsR4 {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
}
.AddressSearchBar__freeBox__qsR4 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.AddressSearchBar__freeBox__qsR4 > .__wab_flex-container > *,
.AddressSearchBar__freeBox__qsR4 > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__qsR4 > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__qsR4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.AddressSearchBar__svg__d3098 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
.AddressSearchBar__text__evO {
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-c8ADL42ppeG1sf);
}
.AddressSearchBar__freeBox__rvPlp {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
.AddressSearchBar__freeBox__rvPlp > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.AddressSearchBar__freeBox__rvPlp > .__wab_flex-container > *,
.AddressSearchBar__freeBox__rvPlp > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__rvPlp > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__rvPlp
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.AddressSearchBar__button___2RJu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__svg__a4Lzi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__gOty6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__button__gwIng.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__svg__hcBgz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__b1Zuu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__button__ykb6K.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressSearchBar__svg__ogGn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__dmHgM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__freeBox__sdhl1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.AddressSearchBar__freeBox__sdhl1 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.AddressSearchBar__freeBox__sdhl1 > .__wab_flex-container > *,
.AddressSearchBar__freeBox__sdhl1 > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__sdhl1 > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__sdhl1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.AddressSearchBar__freeBox___2BsIv {
  display: flex;
  flex-direction: row;
}
.AddressSearchBar__freeBox___2BsIv > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.AddressSearchBar__freeBox___2BsIv > .__wab_flex-container > *,
.AddressSearchBar__freeBox___2BsIv > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox___2BsIv > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox___2BsIv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 40px;
}
.AddressSearchBar__freeBox__fCrAn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.AddressSearchBar__freeBox__fCrAn > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.AddressSearchBar__freeBox__fCrAn > .__wab_flex-container > *,
.AddressSearchBar__freeBox__fCrAn > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__fCrAn > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__fCrAn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.AddressSearchBar__freeBox__adXpV {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.AddressSearchBar__freeBox__adXpV > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.AddressSearchBar__freeBox__adXpV > .__wab_flex-container > *,
.AddressSearchBar__freeBox__adXpV > .__wab_flex-container > .__wab_slot > *,
.AddressSearchBar__freeBox__adXpV > .__wab_flex-container > picture > img,
.AddressSearchBar__freeBox__adXpV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.AddressSearchBar__button__on0GU.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .AddressSearchBar__button__on0GU.__wab_instance {
    height: 100%;
    flex-shrink: 0;
    min-height: 0;
  }
}
.AddressSearchBar__svg__qa1Ps {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__gmzr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__freeBox__mnuzW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.AddressSearchBar__text___2Rn3 {
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-CkHZor_7deI9A3);
  font-weight: 500;
  opacity: 0.5;
}
.AddressSearchBar__slotTargetAddress__whf1G {
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  color: var(--token-CkHZor_7deI9A3);
  line-height: var(--token-97-B3t__LugLsk);
}
.AddressSearchBar__resetButton__pcdMz.__wab_instance {
  max-width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .AddressSearchBar__resetButton__pcdMz.__wab_instance {
    flex-shrink: 0;
  }
}
.AddressSearchBar__svg__i1Thb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressSearchBar__svg__kPKt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
