.ScrollNav__root___358C {
  display: inline-flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding-bottom: 40px;
  padding-left: 40px;
  position: relative;
  padding-right: 40px;
  min-width: 320px;
  min-height: 0;
}
.ScrollNav__root___358C > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.ScrollNav__root___358C > .__wab_flex-container > *,
.ScrollNav__root___358C > .__wab_flex-container > .__wab_slot > *,
.ScrollNav__root___358C > .__wab_flex-container > picture > img,
.ScrollNav__root___358C > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__root___358C {
    min-width: auto;
    max-width: none;
    width: 100%;
    height: auto;
    flex-direction: row;
    overflow: auto;
    padding: 16px 0px 16px 40px;
    border-right: 1px none var(--token-KVbFqNIPJ);
    border-bottom: 1px solid var(--token-KVbFqNIPJ);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__root___358C > .__wab_flex-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__root___358C > .__wab_flex-container > *,
  .ScrollNav__root___358C > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__root___358C > .__wab_flex-container > picture > img,
  .ScrollNav__root___358C
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__root___358C {
    height: auto;
    display: flex;
    flex-direction: row;
    max-width: none;
    width: 100%;
    overflow: auto;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-style: none;
    min-width: 0;
    padding: 16px 0px 16px 24px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__root___358C > .__wab_flex-container {
    flex-direction: row;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ScrollNav__root___358C > .__wab_flex-container > *,
  .ScrollNav__root___358C > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__root___358C > .__wab_flex-container > picture > img,
  .ScrollNav__root___358C
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: 0px;
  }
}
.ScrollNav__freeBox__bwtdN {
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__bwtdN {
    position: relative;
    left: auto;
    top: auto;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__bwtdN {
    position: relative;
    left: auto;
    top: auto;
  }
}
.ScrollNav__freeBox__kEsIo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  overflow: auto;
  max-height: 100vh;
  padding-top: 40px;
  min-width: 0;
}
.ScrollNav__freeBox__kEsIo > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.ScrollNav__freeBox__kEsIo > .__wab_flex-container > *,
.ScrollNav__freeBox__kEsIo > .__wab_flex-container > .__wab_slot > *,
.ScrollNav__freeBox__kEsIo > .__wab_flex-container > picture > img,
.ScrollNav__freeBox__kEsIo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__kEsIo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: none;
    flex-direction: row;
    overflow: visible;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > *,
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > picture > img,
  .ScrollNav__freeBox__kEsIo
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__kEsIo {
    display: flex;
    flex-direction: row;
    max-height: none;
    overflow: visible;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > *,
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__freeBox__kEsIo > .__wab_flex-container > picture > img,
  .ScrollNav__freeBox__kEsIo
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.ScrollNav__freeBox__yb3Bo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.ScrollNav__freeBox__yb3Bo > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 48px);
  height: calc(100% + 48px);
}
.ScrollNav__freeBox__yb3Bo > .__wab_flex-container > *,
.ScrollNav__freeBox__yb3Bo > .__wab_flex-container > .__wab_slot > *,
.ScrollNav__freeBox__yb3Bo > .__wab_flex-container > picture > img,
.ScrollNav__freeBox__yb3Bo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 48px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__yb3Bo {
    width: auto;
    height: auto;
    max-width: none;
    flex-direction: row;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 24px);
    width: calc(100% + 24px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > *,
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > picture > img,
  .ScrollNav__freeBox__yb3Bo
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 24px;
    margin-top: 0px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__yb3Bo {
    display: flex;
    flex-direction: row;
    position: relative;
    top: auto;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > *,
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > .__wab_slot > *,
  .ScrollNav__freeBox__yb3Bo > .__wab_flex-container > picture > img,
  .ScrollNav__freeBox__yb3Bo
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.ScrollNav__scrollNavGroup__up0Ca.__wab_instance {
  position: relative;
}
.ScrollNav__button__rw1Bh.__wab_instance {
  max-width: 100%;
}
.ScrollNav__svg___81OyP {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ScrollNav__text__dTps7 {
  height: auto;
}
.ScrollNav__svg__ecqey {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ScrollNav__button__bQwdb.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ScrollNav__svg__k2SD {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ScrollNav__text__jnmV {
  height: auto;
}
.ScrollNav__svg__qV1Xj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ScrollNav__freeBox__fhC3J {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__fhC3J {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__fhC3J {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}
.ScrollNav__freeBox__qxwFt {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ScrollNav__freeBox__qxwFt {
    width: 1px;
    height: 20px;
    max-width: 100%;
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .ScrollNav__freeBox__qxwFt {
    width: 1px;
    height: 20px;
    flex-shrink: 0;
  }
}
