.Select__Option__root__fHrOa {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 2px 8px;
}
.Select__Option__freeBox__sMShE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
  padding: 6px 10px;
}
.Select__Option__freeBoxisSelected__sMShEXqj3 {
  background: var(--token-vvi_7r45uYs8w7);
}
.Select__Option__freeBoxisHighlighted__sMShEt5F06 {
  background: var(--token-s9gzWmEFR);
}
.Select__Option__root__fHrOa:hover .Select__Option__freeBox__sMShE {
  background: var(--token-umjgs5uqk);
}
.Select__Option__labelContainer__iZx7V {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.Select__Option__labelContainer__iZx7V > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
}
.Select__Option__labelContainer__iZx7V > .__wab_flex-container > *,
.Select__Option__labelContainer__iZx7V
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Select__Option__labelContainer__iZx7V > .__wab_flex-container > picture > img,
.Select__Option__labelContainer__iZx7V
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
}
.Select__Option__slotTargetStartIcon__e40HH {
  color: var(--token-7xB3ITuN8);
}
.Select__Option__slotTargetStartIcon__e40HH > *,
.Select__Option__slotTargetStartIcon__e40HH > .__wab_slot > *,
.Select__Option__slotTargetStartIcon__e40HH > .__wab_slot > .__wab_slot > *,
.Select__Option__slotTargetStartIcon__e40HH
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__Option__slotTargetStartIcon__e40HH > picture > img,
.Select__Option__slotTargetStartIcon__e40HH > .__wab_slot > picture > img,
.Select__Option__slotTargetStartIcon__e40HH
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__Option__slotTargetStartIcon__e40HH
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Select__Option__slotTargetStartIconisSelected__e40HHXqj3 {
  color: var(--token-plIc-sUNu2-k5-);
}
.Select__Option__slotTargetStartIconisHighlighted__e40HHt5F06 {
  color: var(--token-8D7PsQmKPjCWdA);
}
.Select__Option__slotTargetStartIconisDisabled__e40HHicRep {
  color: var(--token-yDpImGgAX);
}
.Select__Option__root__fHrOa:hover .Select__Option__slotTargetStartIcon__e40HH {
  color: var(--token-L1EeQgMLm);
}
.Select__Option__svg__pu41K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Select__Option__slotTargetChildren__xzQod {
  white-space: pre;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--token-gBTCEyDxU);
}
.Select__Option__slotTargetChildren__xzQod > .__wab_text,
.Select__Option__slotTargetChildren__xzQod > .__wab_expr_html_text,
.Select__Option__slotTargetChildren__xzQod > .__wab_slot-string-wrapper,
.Select__Option__slotTargetChildren__xzQod > .__wab_slot > .__wab_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.Select__Option__slotTargetChildren__xzQod > *,
.Select__Option__slotTargetChildren__xzQod > .__wab_slot > *,
.Select__Option__slotTargetChildren__xzQod > .__wab_slot > .__wab_slot > *,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__Option__slotTargetChildren__xzQod > picture > img,
.Select__Option__slotTargetChildren__xzQod > .__wab_slot > picture > img,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__Option__slotTargetChildren__xzQod
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Select__Option__slotTargetChildrenisSelected__xzQodXqj3 {
  color: var(--token-Ga5PN1FRCoAB6A);
}
.Select__Option__slotTargetChildrenisDisabled__xzQodicRep {
  color: var(--token-s9gzWmEFR);
}
.Select__Option__root__fHrOa:hover .Select__Option__slotTargetChildren__xzQod {
  color: var(--token-WqiMp9lH7V64qa);
}
.Select__Option__slotTargetEndIcon__viunI {
  color: var(--token-7xB3ITuN8);
}
.Select__Option__slotTargetEndIcon__viunI > *,
.Select__Option__slotTargetEndIcon__viunI > .__wab_slot > *,
.Select__Option__slotTargetEndIcon__viunI > .__wab_slot > .__wab_slot > *,
.Select__Option__slotTargetEndIcon__viunI
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__Option__slotTargetEndIcon__viunI > picture > img,
.Select__Option__slotTargetEndIcon__viunI > .__wab_slot > picture > img,
.Select__Option__slotTargetEndIcon__viunI
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__Option__slotTargetEndIcon__viunI
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
}
.Select__Option__slotTargetEndIconisSelected__viunIXqj3 {
  color: var(--token-plIc-sUNu2-k5-);
}
.Select__Option__slotTargetEndIconisHighlighted__viunIt5F06 {
  color: var(--token-8D7PsQmKPjCWdA);
}
.Select__Option__slotTargetEndIconisDisabled__viunIicRep {
  color: var(--token-yDpImGgAX);
}
.Select__Option__root__fHrOa:hover .Select__Option__slotTargetEndIcon__viunI {
  color: var(--token-L1EeQgMLm);
}
.Select__Option__svg__hyh1Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
