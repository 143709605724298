._002PasswordReset02__root__lXcZn {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: flex-start;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._002PasswordReset02__freeBox__w4TOt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._002PasswordReset02__freeBox__subDv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 72px 24px;
}
@media (max-width: 480px) {
  ._002PasswordReset02__freeBox__subDv {
    max-width: none;
  }
}
._002PasswordReset02__freeBox__lmHlo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 320px;
  min-width: 0;
}
._002PasswordReset02__freeBox__lmHlo > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 56px);
  height: calc(100% + 56px);
}
._002PasswordReset02__freeBox__lmHlo > .__wab_flex-container > *,
._002PasswordReset02__freeBox__lmHlo > .__wab_flex-container > .__wab_slot > *,
._002PasswordReset02__freeBox__lmHlo > .__wab_flex-container > picture > img,
._002PasswordReset02__freeBox__lmHlo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 56px;
}
@media (max-width: 480px) {
  ._002PasswordReset02__freeBox__lmHlo {
    max-width: none;
  }
}
._002PasswordReset02__freeBox___6QmGt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
._002PasswordReset02__freeBox___6QmGt > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
._002PasswordReset02__freeBox___6QmGt > .__wab_flex-container > *,
._002PasswordReset02__freeBox___6QmGt > .__wab_flex-container > .__wab_slot > *,
._002PasswordReset02__freeBox___6QmGt > .__wab_flex-container > picture > img,
._002PasswordReset02__freeBox___6QmGt
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
._002PasswordReset02__freeBox__v02K3 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._002PasswordReset02__freeBox__v02K3 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._002PasswordReset02__freeBox__v02K3 > .__wab_flex-container > *,
._002PasswordReset02__freeBox__v02K3 > .__wab_flex-container > .__wab_slot > *,
._002PasswordReset02__freeBox__v02K3 > .__wab_flex-container > picture > img,
._002PasswordReset02__freeBox__v02K3
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
._002PasswordReset02__img__jHzbs {
  object-fit: scale-down;
  max-width: 40px;
}
._002PasswordReset02__img__jHzbs > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  ._002PasswordReset02__img__jHzbs {
    display: none !important;
  }
}
._002PasswordReset02__img__lldIr {
  object-fit: scale-down;
  max-width: 88px;
}
._002PasswordReset02__img__lldIr > picture > img {
  object-fit: scale-down;
}
@media (max-width: 480px) {
  ._002PasswordReset02__img__lldIr {
    display: none !important;
  }
}
._002PasswordReset02__text__nVZz {
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
._002PasswordReset02__formWrapperComponent__vnKv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._002PasswordReset02__formWrapperComponent__vnKv > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
._002PasswordReset02__formWrapperComponent__vnKv > .__wab_flex-container > *,
._002PasswordReset02__formWrapperComponent__vnKv
  > .__wab_flex-container
  > .__wab_slot
  > *,
._002PasswordReset02__formWrapperComponent__vnKv
  > .__wab_flex-container
  > picture
  > img,
._002PasswordReset02__formWrapperComponent__vnKv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
._002PasswordReset02__formGroup__pLiLx.__wab_instance {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
._002PasswordReset02__text__cCzTf {
  height: auto;
}
._002PasswordReset02__textInput__xx46B.__wab_instance {
  position: relative;
}
._002PasswordReset02__svg__adbBp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__svg__t5Nh6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__text__jsJSn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._002PasswordReset02__text___6Ywnj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._002PasswordReset02__text__kA5Lo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._002PasswordReset02__symbolCounter__rs4Wd.__wab_instance {
  position: relative;
}
._002PasswordReset02__formGroup___2HMq.__wab_instance {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
._002PasswordReset02__text__whc4 {
  height: auto;
}
._002PasswordReset02__textInput2__lLbHs.__wab_instance {
  position: relative;
}
._002PasswordReset02__svg__smSnr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__svg___82USh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__text__gvhLj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._002PasswordReset02__text__dnCh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._002PasswordReset02__text__rs8My {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._002PasswordReset02__symbolCounter__ckkKr.__wab_instance {
  position: relative;
}
._002PasswordReset02__button__nhudy.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: stretch;
  margin-top: calc(12px + var(--token-Wf1GGJSbrxsgS-)) !important;
}
._002PasswordReset02__svg__wq7Uw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__svg__bT4Ol {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._002PasswordReset02__freeBox__p8GLk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._002PasswordReset02__freeBox__p8GLk > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._002PasswordReset02__freeBox__p8GLk > .__wab_flex-container > *,
._002PasswordReset02__freeBox__p8GLk > .__wab_flex-container > .__wab_slot > *,
._002PasswordReset02__freeBox__p8GLk > .__wab_flex-container > picture > img,
._002PasswordReset02__freeBox__p8GLk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
._002PasswordReset02__freeBox__qaBp6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._002PasswordReset02__text__cxROo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-c8ADL42ppeG1sf);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  min-width: 0;
}
._002PasswordReset02__link__wqVyc {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
}
