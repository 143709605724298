.DynamicEnergyListItem__root__wGzdt {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-P3nFMsZz2JMXx8) var(--token-TJik2L8OcSV6kD)
    var(--token-TJik2L8OcSV6kD);
  border: 1px solid var(--token-O8yxnKF8d);
}
.DynamicEnergyListItem__root__wGzdt > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.DynamicEnergyListItem__root__wGzdt > .__wab_flex-container > *,
.DynamicEnergyListItem__root__wGzdt > .__wab_flex-container > .__wab_slot > *,
.DynamicEnergyListItem__root__wGzdt > .__wab_flex-container > picture > img,
.DynamicEnergyListItem__root__wGzdt
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
.DynamicEnergyListItem__rootenergyType_keine__wGzdtVwPe0 {
  padding-bottom: var(--token-P3nFMsZz2JMXx8);
}
.DynamicEnergyListItem__freeBox__n85Gh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__freeBox__n85Gh > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.DynamicEnergyListItem__freeBox__n85Gh > .__wab_flex-container > *,
.DynamicEnergyListItem__freeBox__n85Gh
  > .__wab_flex-container
  > .__wab_slot
  > *,
.DynamicEnergyListItem__freeBox__n85Gh > .__wab_flex-container > picture > img,
.DynamicEnergyListItem__freeBox__n85Gh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.DynamicEnergyListItem__freeBox___8Z3W5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  min-width: 0;
}
.DynamicEnergyListItem__freeBox___8Z3W5 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.DynamicEnergyListItem__freeBox___8Z3W5 > .__wab_flex-container > *,
.DynamicEnergyListItem__freeBox___8Z3W5
  > .__wab_flex-container
  > .__wab_slot
  > *,
.DynamicEnergyListItem__freeBox___8Z3W5 > .__wab_flex-container > picture > img,
.DynamicEnergyListItem__freeBox___8Z3W5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
.DynamicEnergyListItem__text__jbMy5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-Bl-7HCzgz);
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 600;
  align-self: auto;
  min-width: 0;
}
.DynamicEnergyListItem__itemDeleteButton__cb7KO.__wab_instance {
  max-width: 100%;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__itemDeleteButton__cb7KO.__wab_instance {
    flex-shrink: 0;
  }
}
.DynamicEnergyListItem__itemDeleteButtonhasNoDeleteButton__cb7KOfwGvi.__wab_instance {
  flex-shrink: 0;
}
.DynamicEnergyListItem__svg__obYZz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__dkhBc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__gas__rdBn6 {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__gasenergyType_gas__rdBn6Hjn02 {
  display: grid;
}
.DynamicEnergyListItem__formGroup__oR3UR.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__oR3UR.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__oR3UR.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__jmg {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrGasSubk__jpeYo.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__p1Dle {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__bs912.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mdizD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__t0Esu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__fNzEu.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__m7Dgj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__pVsCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__zDqAz.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__bjiIp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__dl2Gn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__epea.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__eMzn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__ofbf0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__z90Xs.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__uh0Lx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__dvOut {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__raQSe.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__yvbaQ.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__yvbaQ.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__yvbaQ.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__ro8Q2 {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrGasVerb__beo2.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___92UZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__pcriJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__pTgBp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__cvuRd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__ucg5L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter___6DAwh.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__es9Yw.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__es9Yw.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__es9Yw.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__cUpz {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrGasEinh__mgSm1.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__xscFq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__dexy.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__lmRuJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__no5Cj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__mmKdG.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__qDwhI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__dvSj2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__bXcUl.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___1Gt2I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__xbGy4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hL0Nd.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ihb65 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___78ZEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__worL4.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__ol__mXtiH {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__olenergyType_oel__mXtiHt3WMv {
  display: grid;
}
.DynamicEnergyListItem__formGroup___7OiIj.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___7OiIj.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___7OiIj.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__hrp6W {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrOelSubk__uKeIa.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__tXUxT {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__s5JZq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__t56V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__kzJah {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__fa7Jy.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup___1SpjG.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___1SpjG.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___1SpjG.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__ncTni {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrOelVerb__eOaVf.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__pmGnc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__jaWee {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__uoN55 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__cHzB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__dGn1B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__xzXuF.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__lKrda.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__lKrda.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__lKrda.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__geVkB {
  padding-right: 0px;
}
.DynamicEnergyListItem__select__nnuLl.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__kw8Bu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter___61Zcf.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__fernwarme__mbMWc {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__fernwarmeenergyType_fernwaerme__mbMWc7DNzy {
  display: grid;
}
.DynamicEnergyListItem__formGroup___0Gp4K.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___0Gp4K.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___0Gp4K.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text___098C3 {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrFwaeSubk___7Klns.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__ah9Fv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__i7Vj.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ytfM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__bkzA1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__o1NgS.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___9Ooe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__npeux {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__z6Y6S.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__lSuv0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__hupUq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__xarCh.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___29MTr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___7B9I0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__gNxkR.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__tpWvN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__shJuE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__heQnR.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6UX0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__rEoBp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__lSotE.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xwXzx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__oBFk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__wip1I.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__n10TA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__hhm7U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___7G9WY.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xKFdu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__bXtEv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__gauB3.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__dhN3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__gj1WS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__nvOmz.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__u7PfB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___6H6N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__oDk3W.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__nky8Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__nVLia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__frhA.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__j9Sxc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__zP2X6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__grPcf.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ekX59 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__lqJzZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__jMuc.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__sqZm2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__d3SzK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___8ZT4X.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___4Hxvz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__kBr0O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__o1Cgu.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__sjk9J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__xDolp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__vbOu.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__hvqNm.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__hvqNm.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__hvqNm.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__oaXdt {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrFwaeVerb__sahSq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__zfksr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__yjEl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text___55XcF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__vt3I7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__leNlO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__tMqvV.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup___1PKY.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___1PKY.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___1PKY.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__nz2Ja {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrFwaeEinh__tRffS.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__buWlW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option___62EBl.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___4Uysp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___8Cz3X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__vCrR8.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mZwn7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___3MZf4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__czf1B.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__warmepumpe__ksseG {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__warmepumpeenergyType_waermepumpe__ksseGJqGsd {
  display: grid;
}
.DynamicEnergyListItem__formGroup__tYeHv.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__tYeHv.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__tYeHv.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__lOcud {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWpSubk__gzSKe.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__yPkcr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option___7R2Ea.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__a0Yjz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___1Tpj7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hAvau.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__vpsOh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__qeggM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__tGf4M.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__aRa8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__fxioj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__ebeaW.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___8KBnh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__wWzTh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__yYfId.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mrwe6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__u1HzR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__mbuC.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__nIl5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__q0O8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__rnad1.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__flMtQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__tfjtE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___4M4Pv.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__fwDgZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___6KlUr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___8FO5G.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ebE96 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___6Ubym {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___7WI2L.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6N6U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__zM8Xx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__jGkp.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ePTss {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__ryOIx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__od0Cm.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__vbuoE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__wpNAf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__oqim.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__stPcR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__mnBst {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__gsUeG.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xfGlO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__msuug {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__mtPE.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__olP5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__uuUe0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__xT4Lk.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__p7Cjw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___5Gxrh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__fnFlp.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___9BZrg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__ykkup {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__fy10.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__uRj9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__lrtvk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___68R3M.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__oyVLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__yEkkP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__sGxfx.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___1Eq9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__pgzTs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__tLxXv.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup___5FkPg.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___5FkPg.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___5FkPg.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__z7Ze {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWpVerb__e9BXq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xEf19 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__vbAX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__xfZsp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__ho1Bn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__kXiOj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter___2SQc8.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__dG36P.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__dG36P.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__dG36P.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__wfzEo {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWpEinh__aVpPk.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__kf0TB {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__iO4Oq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__y4BVr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__cZDiL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__sgz6H.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___26DZg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___99F3J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__lhEuW.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__holz__amWbr {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__holzenergyType_holz__amWbRy7HUj {
  display: grid;
}
.DynamicEnergyListItem__formGroup__mi7Hr.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__mi7Hr.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__mi7Hr.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__m64Et {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrHlzSubk__rcotR.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__qGNz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__vuzGk.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xDgzv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___4Ddve {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__e5XtB.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ihG3I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__pcaIj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__twZhn.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__wCcKa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__ki5Yn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hyu8H.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__xjgGa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__xWd1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__yvLw.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__yL3U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__jsXsh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__yCSpy.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__gUcty.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__gUcty.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__gUcty.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__qx3Pr {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrHlzVerb__aPs.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__nRb9H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__pQrVt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__cz99X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__kyjS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__yiMgq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__pNq3K.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__dCkrA.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__dCkrA.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__dCkrA.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__z9Nm {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrHlzEinh__tLfnA.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__zyFmS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__dolwj.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__c2Xm6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__moSej {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__fd68S.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mZfbJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__jcV38 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__cQFhK.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6NI5S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__fWxUj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___24PBd.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___8Z2Ik {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__rB0Te {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__yNsly.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__gzjZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__cUFv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__sYvbm.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__k9Awy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___2AoFx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__saMSz.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6Cigk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__abAsi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__lKef0.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__vN4Jx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___8Z83O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__k0Nw1.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__solarthermie__lWKl {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__solarthermieenergyType_solarthermie__lWKLafFlh {
  display: grid;
}
.DynamicEnergyListItem__formGroup__y0086.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__y0086.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__y0086.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__dc9Lh {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrSoltSubk__ztiUy.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__v5XAl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__vo9I6.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__u3AE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__gi3Qs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___90I2.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ylz4X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__c4Y2N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__r58Ue.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__is3If.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__is3If.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__is3If.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text___3WlUt {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrSoltVerb__aP8Qq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__kqPem {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg___1PKwP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__qxpcg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__htqbC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__otjL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__pHaU.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__g5XbY.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__g5XbY.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__g5XbY.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__eqpK {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrSoltEinh___7UGs6.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__ar9Wt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option___7JNe.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ile2E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__huvI4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__era15.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__umYNi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__rJnNs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__fQruA.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__elektroheizung__gtVbi {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__elektroheizungenergyType_elektroheizung__gtVbiRuy3K {
  display: grid;
}
.DynamicEnergyListItem__formGroup___9Yz7A.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___9Yz7A.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___9Yz7A.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__nz9Gq {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrElekhzSubk__s0Glo.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__y77Q {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__mHqda.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mFTb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__bv3E7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___6Prdd.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___5N127 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___0Wyf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__dlir2.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__yNliT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__um9Ay {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__sVoHq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__r3Gzl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__uordu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__jxkeh.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___3Saz6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__wv2Ky {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__xkEfo.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__uD5X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__yHm7Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__vi6V.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__uT5Xb.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__uT5Xb.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__uT5Xb.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__dXirI {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrElekhzVerb__fOaF9.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__nAgcl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__nquIq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text___5Uuy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text___5RRwp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__o2M7M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__rmhdc.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__d0Dx.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__d0Dx.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__d0Dx.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__hXxpg {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrElekhzEinh__uRCtl.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__yjsbA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__irNvO.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___48WCj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__s0Hne {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__z6Uh4.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___4JgC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___3DU3C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__f3XHi.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__stromEigentumerKomplex__zxsLj {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
}
.DynamicEnergyListItem__stromEigentumerKomplexenergyType_stromEigentuemerKomplex__zxsLJtEzN {
  display: grid;
}
.DynamicEnergyListItem__formGroup__qgHgM.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__qgHgM.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__qgHgM.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__yStgy {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrStrmegtKat2__r3Y0F.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__zLoEj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__cNOjO.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__i3Iby {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__h8Jqy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__o2Hyw.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__dkvf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___91WD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__kSnXz.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__puWca {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__wvtpp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hvDsD.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___9YjFi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__vhtKu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__kuFqt.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__mx0Qa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__n3JSx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__l6Rv6.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__sq2JA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___2CIbz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__zUdRg.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__gsq6W.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__gsq6W.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__gsq6W.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__u5 {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrStrmegtVerb2__dkus0.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__z2X5M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__nsH89 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__eOlml {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__dLv2V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__fxR7L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter___9HnSy.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__mBb03.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__mBb03.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__mBb03.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text___8FmHe {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrStrmegtEinh2__gLTnp.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg___5Ku2Y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__hwpzU.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__e1Mzh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__yYeJa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__w2OS.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__eqdbW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__mnLcx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter___5W63F.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__weitereAndere__ieqv8 {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__weitereAndereenergyType_weitereAndere__ieqv8UcD3G {
  display: grid;
}
.DynamicEnergyListItem__formGroup___1Svw1.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup___1Svw1.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup___1Svw1.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__j6TEj {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWeitrSubk__ffJi9.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg___734GV {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__aqNOd.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__vMPd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__awqUo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__giVzD.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__gUcXh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__gXRb0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___4SA0L.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___5YoB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__fLeK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__dvOri.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6M8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__i2AmY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hviY.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__zxSjc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___6C9E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option___9LXx3.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___9JDf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__w1Grk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__agcyi.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__vyHdv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__pla8K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__bww6B.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ny0Ui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__p3Pb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__hkq3.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__ezxX7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__hzzl5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__vjStf.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__stVwu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg___5L93N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__jntv0.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__mUc2.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__mUc2.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__mUc2.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__oaSzh {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWeitrVerb__wimns.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg___6IwMe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg___0GfFp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__bw1Uj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text___5Db {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__jn7K1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__vueY.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__cog1A.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__cog1A.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__cog1A.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__vdsSd {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrWeitrEinh__p9T2B.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__cQPhW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option___54PjP.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__hWpey {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__bCbG1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__v787Q.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__yNSg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__dwMrv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__v3UOw.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__unbekannt__kwAFk {
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  display: none;
}
.DynamicEnergyListItem__unbekanntenergyType_unbekannt__kwAFkrsVgk {
  display: grid;
}
.DynamicEnergyListItem__formGroup__nC1Lv.__wab_instance {
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__nC1Lv.__wab_instance {
    grid-column-end: span 12;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__nC1Lv.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__wfBi {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrUnbeknSubk2___0T6Zs.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__urkl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__uqWLx.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__zancP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__hwzc3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter__lCq1K.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__f8Gwl.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__f8Gwl.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__f8Gwl.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__vIByC {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrUnbeknVerb__aGG3.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__sdQdY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__svg__b4Pt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__text__a5OsU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.DynamicEnergyListItem__text__pdkxK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__text__zOv6X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.DynamicEnergyListItem__symbolCounter__xINY.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__formGroup__w2IrL.__wab_instance {
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .DynamicEnergyListItem__formGroup__w2IrL.__wab_instance {
    grid-column-end: span 6;
  }
}
@media (max-width: 480px) {
  .DynamicEnergyListItem__formGroup__w2IrL.__wab_instance {
    width: 100%;
  }
}
.DynamicEnergyListItem__text__pgosU {
  padding-right: 0px;
}
.DynamicEnergyListItem__enrEntrUnbeknEinh__whR6W.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.DynamicEnergyListItem__svg__iXKh8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicEnergyListItem__option__rtoRq.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__i4Omd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__yc6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__option__xSl5V.__wab_instance {
  position: relative;
}
.DynamicEnergyListItem__svg__oxMa5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicEnergyListItem__svg__wPMh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicEnergyListItem__symbolCounter___0RrpW.__wab_instance {
  position: relative;
}
