.DashboardRatingNumber__root__a22Oz {
  display: flex;
  flex-direction: row;
  position: relative;
}
.DashboardRatingNumber__root__a22Oz > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.DashboardRatingNumber__root__a22Oz > .__wab_flex-container > *,
.DashboardRatingNumber__root__a22Oz > .__wab_flex-container > .__wab_slot > *,
.DashboardRatingNumber__root__a22Oz > .__wab_flex-container > picture > img,
.DashboardRatingNumber__root__a22Oz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.DashboardRatingNumber__freeBox___2GkB7 {
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
}
.DashboardRatingNumber__slotTargetRatingNumber__hCr2Q {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.DashboardRatingNumber__slotTargetRatingNumbersize_small__hCr2QJNUn {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.DashboardRatingNumber__svg___2Qp51 {
  position: relative;
  object-fit: cover;
  color: var(--token-UQb389MFjCYuwK);
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  display: none;
}
.DashboardRatingNumber__svghasWarning___2Qp51TfTgn {
  display: block;
}
.DashboardRatingNumber__svgsize_small___2Qp51JNUn {
  width: 13px;
  height: 13px;
  flex-shrink: 0;
}
