.DataBarSegment__root__hQfZb {
  display: flex;
  position: relative;
  flex-direction: column;
}
.DataBarSegment__root__hQfZb > .__wab_flex-container {
  flex-direction: column;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.DataBarSegment__root__hQfZb > .__wab_flex-container > *,
.DataBarSegment__root__hQfZb > .__wab_flex-container > .__wab_slot > *,
.DataBarSegment__root__hQfZb > .__wab_flex-container > picture > img,
.DataBarSegment__root__hQfZb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
.DataBarSegment__freeBox__oVAbq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DataBarSegment__slotTargetTitle__xQn1X {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
}
.DataBarSegment__barContainer___6HObL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: auto;
  min-width: 0;
}
.DataBarSegment__segmentsContainer__tnYm5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  max-width: 100%;
  min-width: 200px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 4px;
}
.DataBarSegment__segment1__em72N {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-ZPfWHFliL43P_r);
  min-height: 0;
}
.DataBarSegment__segment2__mOiM {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-fKmQxzFxQYEI3S);
  min-height: 0;
}
.DataBarSegment__median__wVaP {
  display: block;
  position: relative;
  height: 100%;
  width: 1px;
  background: var(--token-c8ADL42ppeG1sf);
  flex-shrink: 0;
  min-height: 0;
}
.DataBarSegment__segment3__dcjzh {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-Ga5PN1FRCoAB6A);
  min-height: 0;
}
.DataBarSegment__segment4__woJZd {
  display: block;
  position: relative;
  height: 100%;
  width: 40%;
  background: var(--token-WqiMp9lH7V64qa);
  min-height: 0;
}
.DataBarSegment__shadow__renMi {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0px -2px 2px -1px #0000000a;
}
.DataBarSegment__freeBox__qbU5K {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: -4px;
  top: auto;
  width: 100%;
  height: 0px;
  right: 4px;
  bottom: auto;
  min-width: 0;
  flex-shrink: 0;
}
.DataBarSegment__indicator__xac92 {
  display: flex;
  position: absolute;
  height: auto;
  max-width: 100%;
  left: 40%;
  top: auto;
  width: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.DataBarSegment__freeBox__bHxxs {
  display: block;
  position: relative;
  width: 2px;
  height: 24px;
  max-width: 100%;
  background: var(--token-HHAuBKYL3pvmHz);
  flex-shrink: 0;
  border-radius: 100px;
}
.DataBarSegment__freeBox__nikqc {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  max-width: 100%;
  background: var(--token-CkHZor_7deI9A3);
  left: auto;
  top: auto;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--token-HHAuBKYL3pvmHz);
}
