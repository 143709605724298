.Radio__root___7HrMj {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
}
.Radio__root___7HrMj > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.Radio__root___7HrMj > .__wab_flex-container > *,
.Radio__root___7HrMj > .__wab_flex-container > .__wab_slot > *,
.Radio__root___7HrMj > .__wab_flex-container > picture > img,
.Radio__root___7HrMj > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 4px;
}
.Radio__rootisDisabled___7HrMJpXz2 {
  opacity: 0.5;
}
.Radio__root___focusVisibleWithin___7HrMJv2XJb {
  outline: none;
}
.Radio__root___7HrMj:focus-within {
  outline: none;
}
.Radio__freeBox__uba62 {
  display: flex;
  position: relative;
  transition-property: all;
  transition-duration: 0.2s;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.Radio__root___7HrMj .Radio__freeBox___focusVisibleWithin__uba62V2XJb {
  box-shadow: 0px 0px 0px 3px #a0e9b740;
  justify-content: center;
  align-items: center;
  outline: none;
}
.Radio__root___7HrMj:focus-within .Radio__freeBox__uba62 {
  box-shadow: 0px 0px 0px 3px #a0e9b740;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  outline: none;
}
.Radio__freeBox__dIgJo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: var(--token-KVbFqNIPJ);
  transition-property: background, border-color;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out;
  flex-shrink: 0;
  -webkit-transition-property: background, border-color;
  -webkit-transition-timing-function: ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s;
  border-radius: 100%;
  border: 1.5px solid var(--token-KVbFqNIPJ);
}
.Radio__freeBoxisChecked__dIgJoa8A6S {
  background: var(--token-6_9iE0w_5TGI8j);
  border-color: var(--token-31C7iZ0eD);
}
.Radio__root___7HrMj .Radio__freeBox___focusVisibleWithin__dIgJOv2XJb {
  outline: none;
}
.Radio__freeBox__wNkUi {
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px -1px #0000001a;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 6px;
  height: 6px;
  background: var(--token-ObRRGpZ-v1PQfN);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0) scaleY(0) scaleZ(1);
  flex-shrink: 0;
  -webkit-transition-property: transform;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  border-radius: 100%;
}
.Radio__freeBoxisChecked__wNkUia8A6S {
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
.Radio__freeBox__ruzmi {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.Radio__labelContainer___0HuSi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 1px;
}
.Radio__root___7HrMj .Radio__labelContainer___focusVisibleWithin___0HuSIv2XJb {
  outline: none;
}
.Radio__root___7HrMj:focus-within .Radio__labelContainer___0HuSi {
  outline: none;
}
.Radio__slotTargetChildren__gJgxM {
  white-space: pre-wrap;
  color: var(--token-zqrEV3Z8z);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > *,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > *,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > .__wab_slot
  > *,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > picture
  > img,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > picture
  > img,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Radio__root___7HrMj
  .Radio__slotTargetChildren___focusVisibleWithin__gJgxMv2XJb
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}
.Radio__root___7HrMj:focus-within .Radio__slotTargetChildren__gJgxM > *,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > *,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > .__wab_slot
  > *,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > picture
  > img,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > picture
  > img,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Radio__root___7HrMj:focus-within
  .Radio__slotTargetChildren__gJgxM
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}
