.TextInput__root__ygJuZ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  align-items: center;
  justify-content: flex-start;
  background: #001c1466;
  position: sticky;
  box-shadow: 0px 0px 0px 0px #2571eb03, 0px 0px 0px 1px #2c624c40;
  transition-property: box-shadow, color, background;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  min-width: 0;
  -webkit-transition-property: box-shadow, color, background;
  -webkit-transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  -webkit-transition-duration: 0.2s, 0.2s, 0.2s;
  border-radius: 2px;
  padding: 0px;
}
.TextInput__rootshowStartIcon__ygJuZsaOhl {
  padding-left: 8px;
}
.TextInput__rootshowEndIcon__ygJuZl45Qc {
  padding-right: 8px;
}
.TextInput__rootisDisabled__ygJuZc88Ma {
  background: var(--token-O8yxnKF8d);
}
.TextInput__rootinputType_textArea__ygJuZqPyr8 {
  height: auto;
  min-height: 36px;
}
.TextInput__rootinputSize_sm__ygJuZ76XRt {
  height: 28px;
}
.TextInput__rootinputSize_lg__ygJuZzRnLh {
  height: 44px;
  padding-left: var(--token-7uzqW3y-2Tgb_s);
}
.TextInput__rootinputSize_xl__ygJuZl1O5T {
  height: 56px;
}
.TextInput__rootstate_success__ygJuZLhzHf {
  box-shadow: inset 0px 0px 0px 1px var(--token-6_9iE0w_5TGI8j),
    0px 0px 0px 0px #2571eb03;
  background: var(--token--h6FHSrgUAQsDe);
}
.TextInput__rootstate_warning__ygJuZmO0Jp {
  background: linear-gradient(
      var(--token-YGkzw-IqGKzMqr),
      var(--token-YGkzw-IqGKzMqr)
    ),
    var(--token-CkHZor_7deI9A3);
  box-shadow: inset 0px 0px 0px 1px var(--token-6VQ__RqwTsOFsq),
    0px 0px 0px 0px #2571eb03;
}
.TextInput__rootstate_error__ygJuZlRUsr {
  box-shadow: inset 0px 0px 0px 1px var(--token-1EW9WFy_Sby7ig),
    0px 0px 0px 0px #2571eb03;
  background: var(--token-NFXZ__zPMi8_rv);
}
.TextInput__root__ygJuZ:hover {
  box-shadow: 0px 0px 0px 0px #2571eb03, 0px 0px 0px 1px #2e815f80;
  background: #001f15a6;
}
.TextInput__root__ygJuZ:focus-within {
  box-shadow: 0px 0px 0px 4px #95f3b34d;
  outline: none;
}
.TextInput__root___focusVisibleWithin__ygJuZqk3Af {
  box-shadow: 0px 0px 0px 4px #95f3b34d;
  outline: none;
}
.TextInput__rootisDisabled__ygJuZc88Ma:hover {
  background: var(--token-O8yxnKF8d);
}
.TextInput__startIconContainer__hx10X {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  display: none;
}
.TextInput__startIconContainershowStartIcon__hx10XsaOhl {
  display: flex;
}
.TextInput__root__ygJuZ
  .TextInput__startIconContainer___focusVisibleWithin__hx10Xqk3Af {
  outline: none;
}
.TextInput__slotTargetStartIcon__gJ6Cs {
  font-size: 16px;
  color: var(--token-c8ADL42ppeG1sf);
}
.TextInput__slotTargetStartIconstate_error__gJ6CslRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__svg__pbgGm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.TextInput__startAddon__b1DMo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  border-right: 1px solid #c1cec924;
}
.TextInput__startAddonhasAddon_start__b1DMoP33SU {
  display: flex;
}
.TextInput__slotTargetStartAddonText__rjT6B {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-s9gzWmEFR);
}
.TextInput__slotTargetStartAddonTextinputSize_sm__rjT6B76XRt {
  font-size: var(--token-1lkMHpovBhH3r-);
}
.TextInput__slotTargetStartAddonTextstate_error__rjT6BlRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__startCurrencty__y8V4G {
  position: relative;
  color: var(--token-s9gzWmEFR);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.TextInput__startCurrenctystate_error__y8V4GlRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__startCurrenctyaddonType_currency__y8V4G8Y8V0 {
  display: block;
}
.TextInput__startPercent__wVro6 {
  position: relative;
  color: var(--token-s9gzWmEFR);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  display: none;
}
.TextInput__startPercentstate_error__wVro6LRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__startPercentaddonType_currency__wVro68Y8V0 {
  display: none;
}
.TextInput__startPercentaddonType_percent__wVro6V3Sua {
  display: block;
}
.TextInput__startSquareMeter__kiQa3 {
  position: relative;
  color: var(--token-s9gzWmEFR);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.TextInput__startSquareMeterstate_error__kiQa3LRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__startSquareMeteraddonType_squareMeter__kiQa3JhAw {
  display: block;
}
.TextInput__span__hxSBy {
  font-weight: 500;
}
.TextInput__freeBox__kabYw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  align-self: stretch;
  min-width: 0;
}
.TextInput__freeBox__kabYw > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.TextInput__freeBox__kabYw > .__wab_flex-container > *,
.TextInput__freeBox__kabYw > .__wab_flex-container > .__wab_slot > *,
.TextInput__freeBox__kabYw > .__wab_flex-container > picture > img,
.TextInput__freeBox__kabYw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.TextInput__root__ygJuZ:focus-within .TextInput__freeBox__kabYw {
  outline: none;
}
.TextInput__root__ygJuZ .TextInput__freeBox___focusVisibleWithin__kabYwqk3Af {
  outline: none;
}
.TextInput__input__e4Fwc {
  width: 100%;
  background: #ffffff00;
  font-size: 14px;
  line-height: 1.4;
  color: var(--token-gBTCEyDxU);
  min-width: 0;
  padding: 8px 10px;
  border-style: none;
}
.TextInput__inputshowStartIcon__e4FwCsaOhl {
  padding-left: 0px;
}
.TextInput__inputshowEndIcon__e4Fwcl45Qc {
  padding-right: 0px;
}
.TextInput__inputisDisabled__e4FwCc88Ma {
  cursor: not-allowed;
  color: var(--token-_RKWiZNMAdxvNg);
}
.TextInput__inputinputType_textArea__e4FwCqPyr8 {
  display: none;
}
.TextInput__inputalignment_center__e4FwcIu5E1 {
  text-align: center;
}
.TextInput__inputalignment_right__e4FwcpRmAo {
  text-align: right;
}
.TextInput__inputalignment_left__e4FwCb7BO0 {
  text-align: left;
}
.TextInput__inputinputSize_sm__e4Fwc76XRt {
  padding: 4px 8px;
}
.TextInput__inputinputSize_lg__e4FwCzRnLh {
  padding-right: var(--token-7uzqW3y-2Tgb_s);
}
.TextInput__inputstate_error__e4FwClRUsr {
  color: var(--token-ATEWP6hIq5CbNw);
}
.TextInput__root__ygJuZ .TextInput__input___focusVisibleWithin__e4FwCqk3Af {
  outline: none;
}
.TextInput__root__ygJuZ:focus-within .TextInput__input__e4Fwc {
  outline: none;
}
.TextInput__root__ygJuZ .TextInput__input__e4Fwc:focus {
  outline: none;
}
.TextInput__root__ygJuZ .TextInput__input__e4Fwc::placeholder {
  color: var(--token-c8ADL42ppeG1sf);
}
.TextInput__rootinputType_password__ygJuZvZqU0
  .TextInput__inputinputType_password__e4FwCvZqU0:focus {
  outline: none;
}
.TextInput__rootinputType_date__ygJuZUkqRg
  .TextInput__inputinputType_date__e4FwcUkqRg:focus {
  outline: none;
}
.TextInput__rootalignment_right__ygJuZpRmAo
  .TextInput__inputalignment_right__e4FwcpRmAo::placeholder {
  text-align: right;
}
.TextInput__rootstate_error__ygJuZlRUsr
  .TextInput__inputstate_error__e4FwClRUsr::placeholder {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__root__ygJuZ:focus-within .TextInput__input__e4Fwc:focus {
  outline: none;
}
.TextInput__root__ygJuZ:focus-within .TextInput__input__e4Fwc::placeholder {
  outline: none;
}
.TextInput__root__ygJuZ
  .TextInput__input___focusVisibleWithin__e4FwCqk3Af:focus {
  outline: none;
}
.TextInput__root__ygJuZ
  .TextInput__input___focusVisibleWithin__e4FwCqk3Af::placeholder {
  outline: none;
}
.TextInput__freeBox__dWyoF {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-CkHZor_7deI9A3);
  min-width: 0;
  display: none;
  border-radius: 6px;
  padding: 8px 10px;
}
.TextInput__freeBox__dWyoF > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 2px);
  height: calc(100% + 2px);
}
.TextInput__freeBox__dWyoF > .__wab_flex-container > *,
.TextInput__freeBox__dWyoF > .__wab_flex-container > .__wab_slot > *,
.TextInput__freeBox__dWyoF > .__wab_flex-container > picture > img,
.TextInput__freeBox__dWyoF
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 2px;
}
.TextInput__freeBox__ciT6M {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.TextInput__text__cGiTj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.TextInput__text__aDNqy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.TextInput__textarea__xfuUy {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.4;
  margin-right: 2px;
  margin-bottom: 2px;
  min-width: 0;
  min-height: 0;
  display: none;
  padding: 8px 10px;
  border-width: 1px;
  border-style: none;
}
.TextInput__textareainputType_textArea__xfuUYqPyr8 {
  align-self: stretch;
  display: block;
}
.TextInput__textareaalignment_center__xfuUyIu5E1 {
  text-align: center;
}
.TextInput__textareaalignment_right__xfuUypRmAo {
  text-align: right;
}
.TextInput__textareaalignment_left__xfuUYb7BO0 {
  text-align: left;
}
.TextInput__textareainputSize_xl_inputType_textArea__xfuUYl1O5TQPyr8 {
  font-size: var(--token-lwXLVsVbV9nDvt);
  padding: 20px 24px;
}
.TextInput__rootinputType_textArea__ygJuZqPyr8:focus-within
  .TextInput__textareainputType_textArea__xfuUYqPyr8 {
  outline: none;
}
.TextInput__rootinputType_textArea__ygJuZqPyr8
  .TextInput__textareainputType_textArea____focusVisibleWithin__xfuUYqPyr8Qk3Af {
  outline: none;
}
.TextInput__root__ygJuZ .TextInput__textarea__xfuUy::placeholder {
  color: var(--token-c8ADL42ppeG1sf);
}
.TextInput__endAddon___4I2Pr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  padding-right: 8px;
  padding-left: 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 1px;
  border-left: 1px solid #c1cec924;
}
.TextInput__endAddonhasAddon_end___4I2Pr7W8R6 {
  display: flex;
}
.TextInput__slotTargetEndAddonText__kQd7T {
  white-space: pre;
  color: var(--token-c8ADL42ppeG1sf);
}
.TextInput__slotTargetEndAddonTextstate_error__kQd7TlRUsr {
  color: var(--token-9Zm0PFeo1rK0Eh);
}
.TextInput__text__zLlOm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.TextInput__endCurrency__mDcd {
  position: relative;
  color: var(--token-s9gzWmEFR);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.TextInput__endCurrencystate_error__mDcDlRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__endCurrencyaddonType_currency__mDcd8Y8V0 {
  display: block;
}
.TextInput__endPercent__pm7Cv {
  position: relative;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  display: none;
}
.TextInput__endPercentstate_error__pm7CvlRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__endPercentaddonType_currency__pm7Cv8Y8V0 {
  display: none;
}
.TextInput__endPercentaddonType_percent__pm7CvV3Sua {
  display: block;
}
.TextInput__endSquareMeter__sH6Uu {
  position: relative;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.TextInput__endSquareMeterstate_error__sH6UUlRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__endSquareMeteraddonType_squareMeter__sH6UuJhAw {
  display: block;
}
.TextInput__span___84FqD {
  font-weight: 500;
}
.TextInput__endIconContainer___1Jka1 {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-left: 8px;
  display: none;
}
.TextInput__endIconContainershowEndIcon___1Jka1L45Qc {
  display: flex;
}
.TextInput__slotTargetEndIcon___70D1 {
  font-size: 16px;
  color: var(--token-c8ADL42ppeG1sf);
}
.TextInput__slotTargetEndIconstate_error___70D1LRUsr {
  color: var(--token-SNDFNRi9ingNbb);
}
.TextInput__svg__ipEpx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
