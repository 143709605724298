.ProcessCardSocialQuestionnaire__root__l9KA.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__processCardSection__k3D3P.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__freeBox__dmDpn {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCardSocialQuestionnaire__freeBox__dmDpn {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessCardSocialQuestionnaire__freeBox__dmDpn {
    grid-template-columns: 1fr;
  }
}
.ProcessCardSocialQuestionnaire__formGroup___1FZgM.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  .ProcessCardSocialQuestionnaire__formGroup___1FZgM.__wab_instance {
    grid-column-end: span 2;
  }
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__kunFd.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__l7Hai.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__diO7Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__xOaMi {
  width: auto;
}
.ProcessCardSocialQuestionnaire__svg__uqFIb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__hoy1I.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__rygpg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__iRdCo {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__wfpCc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__hrqmJ.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___2TXat {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ltOcR {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__v5XYs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__y0Vtu.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__formGroup__ziJZu.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.ProcessCardSocialQuestionnaire__text__wFgbJ {
  padding-right: 0px;
}
.ProcessCardSocialQuestionnaire__textInput108___9JBc.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__svg__y9A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg___13Mox {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___9E8Ku {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text__mgV4T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text__vzQa7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__symbolCounter__kZiqU.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__formGroup__l3Kwz.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.ProcessCardSocialQuestionnaire__text___8Zxx0 {
  padding-right: 0px;
}
.ProcessCardSocialQuestionnaire__textInput109__tnJSl.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__svg__uypDt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__w27Me {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__wEh4M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text__imMiv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text___01TC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__symbolCounter__do0G.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__processCardSection___2ANmZ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__freeBox__bU8Vy {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessCardSocialQuestionnaire__freeBox__bU8Vy {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessCardSocialQuestionnaire__freeBox__bU8Vy {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
.ProcessCardSocialQuestionnaire__questionGroup__d9KQh.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__text__krUeo {
  padding-right: 0px;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__aa6Qm.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__saUaJ.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__dOmgW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__n8WnW {
  width: auto;
}
.ProcessCardSocialQuestionnaire__svg___4PlXl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__o2I17.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___3TOpK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__vu94W {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___4Cjew {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___0N8E.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__kWx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__bXxe {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__xRcJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__mgFyq.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__dhFjI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__eSObf {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__jPj6I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__p4SIk.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__z6UtX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___2UZn0 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__c85Lu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__hueyf.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__fvAp.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__dxR.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__omZww.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button___5V2A7.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__ocokr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__vxSsN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__dfwr2.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__bMeZd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__meYqu {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___1N9IT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__mpLx4.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__eqJd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___1C6Km {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__dOpe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__bZ7N.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___0VfSv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__rf1Wf {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__hrJaM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__yf6E9.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__qHg05 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___6FzUk {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___3TfJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__zrJtg.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__b8VuN.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__t8Ie5.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__fc5K6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button___6Mbn.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__ynRaW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg___9UKp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__khaU6.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___0YmYf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__svAn {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__fqJzx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__h8Lf.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__uuXym {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__s3BvO {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__eehBf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__vWkus.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__nXh2D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__vXlzI {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__p6Rcz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__lQouj.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__mBtDp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ydbJs {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___1S50 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__sHFF.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup___0RHw.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup___18W3N.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__zJvPy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__pun5R.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__kxG7W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ojcgy {
  width: auto;
}
.ProcessCardSocialQuestionnaire__svg__qGqMn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___8HdHx.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__ccbmG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__kw8L3 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__tGviU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__uQxo3.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__nAl4Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ql1Xd {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__qSvx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__bz6Xb.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__dk9Gy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__hEyx {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__tfwfy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__sdDif.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__w2MW6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__b404H {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__kwaY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__lfLub.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__buwdv.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__t0Uut.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__tzNjr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__xbGaH.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__qhFEi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__qXI2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__t2KX.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__jytm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___30F6R {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__oead {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__fqJBt.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__uVhGg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ramBx {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___8EJhp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___83YcB.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___2Xmgz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__dmUj {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__mz4Go {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___1JMzp.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__r4HiD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__cKJi7 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__oudmE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__lDnWc.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__empl6.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup___4Y4Cm.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__text__mT6IK {
  padding-right: 0px;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__rZisW.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button___9CsQe.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__pGmUc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg___7Uho2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__i94YL.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__xmb77 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__l7Vh7 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__balSp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___1NUha.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__kxJod {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__n5Gvu {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___5MsDn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__qxAbr.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__zbNyq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__pxJYu {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__fIb8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___3V5Ee.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__hsBsG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__tb8Qn {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__lf41Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__t6APs.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup___3QAUb.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__qonbq.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__nm9Cl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__c8J2.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__pdgNb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__sEw64 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__tSfmV.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__oXuz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__ta3B9 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__ihJzx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__iHr5S.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__jF5RL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__fsnf0 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__ubmbo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__hpMa.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__g57EY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__qTnxO {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__ei9Mj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__d1ZWx.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__hkDly {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___4QHx3 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__fwRcT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__t7XAe.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__ehIzs.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__jL8Ts.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__xh9Xc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button___8QWeo.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__aAt1H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__eMNjp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__bqmN7.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__r0Jmb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__izg3F {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__t0RYt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___0AAV.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___6KFv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__pWNn {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__vnW3Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__ePfb.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___1G2Y1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__erjT1 {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__aBeSs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__cjls0.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__dZcNl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__fnQgl {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___5OvxF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter___4D2I0.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__rIkCg.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__gCfT0.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__gijrC.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__iVrBu.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__dHZsU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__o6Rjm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__q2C08.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__asgE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__xPfLz {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg___9HfAx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__jqimo.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___0CtBx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__xNi6J {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__nqYth {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button___5TsDv.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__uS6RW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__wC4Eb {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__gv18X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__b7Nin.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__fnfYd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__jb7Zg {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__xs5Bg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__qIh1S.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__orDb3.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__lijZv.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__tDmGp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button__aWUy.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__qmIFl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__xoo9Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__gy4Nz.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__qUlk9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__pGayy {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__nvm1F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__lSZj1.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__ojEfX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__y3Rqu {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__jGuyG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__dwa6F.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__cbEgi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__u3UgT {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__gou0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__d11Lf.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__j3Inq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__fAdRh {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__wNlR2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__jgo1O.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__questionGroup__sx6Tx.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__formGroup__aqsxp.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.ProcessCardSocialQuestionnaire__singleSelectButtonGroup__tghS.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__button___4Rn17.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__cbQmk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg__abSUd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__gbxsw.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__wCFdd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text___6AmUg {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__k7Ozx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__seVzR.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__wNjra {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__gunLw {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__wrgOs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__oUuqZ.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg___5Qqe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__eZxvd {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__iybJv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__button__dUiea.__wab_instance {
  max-width: 100%;
}
.ProcessCardSocialQuestionnaire__svg__qqpoU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__thoDl {
  padding-left: 0px;
}
.ProcessCardSocialQuestionnaire__svg__bc7SR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__symbolCounter__a7Ruf.__wab_instance {
  position: relative;
}
.ProcessCardSocialQuestionnaire__formGroup__gPqoo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessCardSocialQuestionnaire__textInput72__rnI6V.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.ProcessCardSocialQuestionnaire__svg__ocXqn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__svg___9Ox5A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessCardSocialQuestionnaire__text__lnjmD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text__ckxvz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__text__bptWg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.ProcessCardSocialQuestionnaire__symbolCounter__nFdfD.__wab_instance {
  position: relative;
}
