._022BerichtSuccessRegistriert__root__ciu5E {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._022BerichtSuccessRegistriert__processHeader__qgpvG.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessRegistriert__processBreadCrumb__tJrG0.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
._022BerichtSuccessRegistriert__freeBox___7Tkoe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: var(--token-6rZumor6mC3fF5) 24px var(--token-ew2vDb1S1Vm4F-);
}
._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox___7Tkoe
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox___7Tkoe
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox___7Tkoe
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 88px 24px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container > *,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 16px;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe > .__wab_flex-container > *,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessRegistriert__freeBox___7Tkoe
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._022BerichtSuccessRegistriert__freeBox___0AtS5 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 720px;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox___0AtS5 > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
._022BerichtSuccessRegistriert__freeBox___0AtS5 > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox___0AtS5
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox___0AtS5
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox___0AtS5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
._022BerichtSuccessRegistriert__freeBox__gZg9T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__gZg9T > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessRegistriert__freeBox__gZg9T > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__gZg9T
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__gZg9T
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__gZg9T
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox__gZg9T > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox__gZg9T > .__wab_flex-container > *,
  ._022BerichtSuccessRegistriert__freeBox__gZg9T
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessRegistriert__freeBox__gZg9T
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessRegistriert__freeBox__gZg9T
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
._022BerichtSuccessRegistriert__freeBox__jx7I {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__jx7I > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
._022BerichtSuccessRegistriert__freeBox__jx7I > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__jx7I
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__jx7I
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__jx7I
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox__jx7I {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox__jx7I > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__freeBox__jx7I > .__wab_flex-container > *,
  ._022BerichtSuccessRegistriert__freeBox__jx7I
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._022BerichtSuccessRegistriert__freeBox__jx7I
    > .__wab_flex-container
    > picture
    > img,
  ._022BerichtSuccessRegistriert__freeBox__jx7I
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._022BerichtSuccessRegistriert__freeBox__flIg {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__flIg > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-qecgvVQwVo7jFR));
  width: calc(100% + var(--token-qecgvVQwVo7jFR));
}
._022BerichtSuccessRegistriert__freeBox__flIg > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__flIg
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__flIg
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__flIg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-qecgvVQwVo7jFR);
}
._022BerichtSuccessRegistriert__reportDownloadButton___1ZUwW.__wab_instance {
  position: relative;
}
._022BerichtSuccessRegistriert__button__rqwGd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._022BerichtSuccessRegistriert__svg__pmCp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__svg__doHw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__overlay__xCK5 {
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: -2px;
  z-index: 1;
  right: 0;
  bottom: 0;
  display: none;
}
._022BerichtSuccessRegistriert__backgorund__eZSpk {
  display: block;
  position: fixed;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--token-hEzPFeV5Y);
}
._022BerichtSuccessRegistriert__freeBox__gv1UT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  z-index: 10;
  background: var(--token-umjgs5uqk);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-x_jRp3a5FpON8z);
}
._022BerichtSuccessRegistriert__freeBox__c7NCa {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessRegistriert__freeBox__c7NCa > .__wab_flex-container {
  flex-direction: column;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessRegistriert__freeBox__c7NCa > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__c7NCa
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__c7NCa
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__c7NCa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__uHi8C {
  display: flex;
  position: relative;
  flex-direction: column;
}
._022BerichtSuccessRegistriert__freeBox__uHi8C > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._022BerichtSuccessRegistriert__freeBox__uHi8C > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__uHi8C
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__uHi8C
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__uHi8C
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._022BerichtSuccessRegistriert__text__tWoY {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__text__tWoY {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessRegistriert__text___4LlqE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-xowVQ_ic8i1X0_);
  color: var(--token-s9gzWmEFR);
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__yscOy {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__yscOy > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessRegistriert__freeBox__yscOy > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__yscOy
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__yscOy
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__yscOy
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__mugpX {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__mugpX > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._022BerichtSuccessRegistriert__freeBox__mugpX > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__mugpX
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__mugpX
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__mugpX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._022BerichtSuccessRegistriert__freeBox__kGlh2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__kGlh2 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessRegistriert__freeBox__kGlh2 > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__kGlh2
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__kGlh2
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__kGlh2
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__oaBvL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__svg__xdIjo {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._022BerichtSuccessRegistriert__freeBox__cm59Y {
  display: flex;
  position: relative;
  background: var(--token-NfSc1CXMklYEK2);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._022BerichtSuccessRegistriert__svg___8DViF {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  opacity: 0;
  height: 1em;
  flex-shrink: 0;
}
._022BerichtSuccessRegistriert__freeBox__pdHy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__pdHy > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._022BerichtSuccessRegistriert__freeBox__pdHy > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__pdHy
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__pdHy
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__pdHy
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._022BerichtSuccessRegistriert__freeBox__ccDx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__ccDx > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._022BerichtSuccessRegistriert__freeBox__ccDx > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__ccDx
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__ccDx
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__ccDx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._022BerichtSuccessRegistriert__text___1HTr7 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__text___1HTr7 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessRegistriert__text__dB8Zx {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__text__dB8Zx {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessRegistriert__text__gs3Xp {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-7xB3ITuN8);
  min-width: 0;
}
._022BerichtSuccessRegistriert__ul__fHiQa {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessRegistriert__freeBox__bvNU {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-6_9iE0w_5TGI8j),
      var(--token-6_9iE0w_5TGI8j)
    ),
    var(--token-SqSK5Lk3X);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__bvNU > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._022BerichtSuccessRegistriert__freeBox__bvNU > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__bvNU
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__bvNU
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__bvNU
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._022BerichtSuccessRegistriert__freeBox__v61I {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__v61I > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._022BerichtSuccessRegistriert__freeBox__v61I > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__v61I
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__v61I
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__v61I
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._022BerichtSuccessRegistriert__freeBox__m4CBf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__svg__phfrG {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._022BerichtSuccessRegistriert__freeBox__tm6Na {
  display: flex;
  position: relative;
  background: var(--token-ObRRGpZ-v1PQfN);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._022BerichtSuccessRegistriert__svg__pAwVg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  color: var(--token-xgado2J7YlFS13);
  height: 1em;
  flex-shrink: 0;
}
._022BerichtSuccessRegistriert__freeBox__y5Pae {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__y5Pae > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._022BerichtSuccessRegistriert__freeBox__y5Pae > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__y5Pae
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__y5Pae
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__y5Pae
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._022BerichtSuccessRegistriert__freeBox__uEj3D {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__freeBox__uEj3D > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._022BerichtSuccessRegistriert__freeBox__uEj3D > .__wab_flex-container > *,
._022BerichtSuccessRegistriert__freeBox__uEj3D
  > .__wab_flex-container
  > .__wab_slot
  > *,
._022BerichtSuccessRegistriert__freeBox__uEj3D
  > .__wab_flex-container
  > picture
  > img,
._022BerichtSuccessRegistriert__freeBox__uEj3D
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._022BerichtSuccessRegistriert__text___4G01X {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__text___4G01X {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessRegistriert__text__laTq3 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._022BerichtSuccessRegistriert__text__laTq3 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._022BerichtSuccessRegistriert__text__gHcOf {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
}
._022BerichtSuccessRegistriert__ul__nLsU {
  display: flex;
  flex-direction: column;
}
._022BerichtSuccessRegistriert__freeBox__xeyga {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._022BerichtSuccessRegistriert__button__mnyco.__wab_instance {
  max-width: 100%;
}
._022BerichtSuccessRegistriert__svg__hJma4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__text__jcgvn {
  height: auto;
  padding-right: 0px;
}
._022BerichtSuccessRegistriert__svg__pr5Jc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__button__ehAYr.__wab_instance {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: -14px;
  z-index: 1;
  right: -14px;
}
._022BerichtSuccessRegistriert__svg__vbXic {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__svg__aPu3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._022BerichtSuccessRegistriert__footer__o9Tdt.__wab_instance {
  max-width: 100%;
  position: relative;
}
