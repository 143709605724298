.AutocompleteTextInput__root___28Sdd {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.AutocompleteTextInput__root___28Sdd > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
.AutocompleteTextInput__root___28Sdd > .__wab_flex-container > *,
.AutocompleteTextInput__root___28Sdd > .__wab_flex-container > .__wab_slot > *,
.AutocompleteTextInput__root___28Sdd > .__wab_flex-container > picture > img,
.AutocompleteTextInput__root___28Sdd
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
.AutocompleteTextInput__rootisOpen___28SddGkgun > .__wab_flex-container {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.AutocompleteTextInput__rootisOpen___28SddGkgun > .__wab_flex-container > *,
.AutocompleteTextInput__rootisOpen___28SddGkgun
  > .__wab_flex-container
  > .__wab_slot
  > *,
.AutocompleteTextInput__rootisOpen___28SddGkgun
  > .__wab_flex-container
  > picture
  > img,
.AutocompleteTextInput__rootisOpen___28SddGkgun
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
.AutocompleteTextInput__textInput__nLuR4.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.AutocompleteTextInput__textInputshowStartIcon__nLuR45Hm5.__wab_instance {
  flex-shrink: 0;
}
.AutocompleteTextInput__textInputisOpen__nLuR4Gkgun.__wab_instance {
  flex-shrink: 0;
}
.AutocompleteTextInput__textInputstate_error__nLuR4CAHo.__wab_instance {
  flex-shrink: 0;
}
.AutocompleteTextInput__svg__j6Cx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AutocompleteTextInput__text__jOxS1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.AutocompleteTextInput__text__ayo0D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.AutocompleteTextInput__text__tvug6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.AutocompleteTextInput__svg__wm2NX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AutocompleteTextInput__dropdown___8Vzlj {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background: var(--token-ObRRGpZ-v1PQfN);
  left: 0px;
  top: 38px;
  align-self: auto;
  box-shadow: 0px 8px 32px -8px #00000033, 0px 8px 20px -16px #00000033;
  z-index: 1000;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  min-width: 0;
  display: none;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1s;
  border-radius: 6px;
  padding: 6px 0px;
}
.AutocompleteTextInput__dropdownisOpen___8VzljGkgun {
  display: flex;
}
.AutocompleteTextInput__freeBox___8GcSi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.AutocompleteTextInput__autocompleteResult___58Yt3.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AutocompleteTextInput__autocompleteResult__k0EPz.__wab_instance {
  max-width: 100%;
  position: relative;
}
