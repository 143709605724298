.MultiSelectButtonGroup__root___3HzLf {
  display: flex;
  flex-direction: row;
  position: relative;
}
.MultiSelectButtonGroup__root___3HzLf > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.MultiSelectButtonGroup__root___3HzLf > .__wab_flex-container > *,
.MultiSelectButtonGroup__root___3HzLf > .__wab_flex-container > .__wab_slot > *,
.MultiSelectButtonGroup__root___3HzLf > .__wab_flex-container > picture > img,
.MultiSelectButtonGroup__root___3HzLf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
  margin-top: 10px;
}
.MultiSelectButtonGroup__button__z4N35.__wab_instance {
  max-width: 100%;
}
.MultiSelectButtonGroup__svg__b90D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.MultiSelectButtonGroup__svg__qtjZa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.MultiSelectButtonGroup__button__bgmXw.__wab_instance {
  max-width: 100%;
}
.MultiSelectButtonGroup__svg__r3Du4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.MultiSelectButtonGroup__text___1C9Gw {
  padding-left: 0px;
}
.MultiSelectButtonGroup__svg__bNbeE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
