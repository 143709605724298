.TabItem__root__oBtzF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: inset 0px 0px 0px 0px var(--token-6_9iE0w_5TGI8j);
  padding: var(--token-P3nFMsZz2JMXx8) var(--token-P3nFMsZz2JMXx8)
    var(--token-TJik2L8OcSV6kD);
}
.TabItem__rootisActive__oBtzFsHPc7 {
  box-shadow: inset 0px -2px 0px 0px var(--token-QI60SWB06gBJ5K);
}
.TabItem__slotTargetText__aDx89 {
  color: var(--token-SkTYvRtmE);
}
.TabItem__slotTargetTextisActive__aDx89SHPc7 {
  color: var(--token-Ga5PN1FRCoAB6A);
}
