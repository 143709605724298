.Select__OptionGroup__root__wVxYm {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  background: var(--token-zw8MjMyAW);
  min-width: 0;
}
.Select__OptionGroup__separator__f1SZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 0px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  min-width: 0;
  flex-shrink: 0;
  border-top: 1px solid var(--token-s9gzWmEFR);
}
.Select__OptionGroup__titleContainer___8Q6N {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding: 8px 16px 5px;
}
.Select__OptionGroup__slotTargetTitle__gwM8G {
  white-space: pre;
  color: var(--token-7xB3ITuN8);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_text,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_expr_html_text,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_slot-string-wrapper,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_slot > .__wab_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_expr_html_text,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.Select__OptionGroup__slotTargetTitle__gwM8G > *,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_slot > *,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_slot > .__wab_slot > *,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.Select__OptionGroup__slotTargetTitle__gwM8G > picture > img,
.Select__OptionGroup__slotTargetTitle__gwM8G > .__wab_slot > picture > img,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.Select__OptionGroup__slotTargetTitle__gwM8G
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
}
.Select__OptionGroup__optionsContainer__l4Eyw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 0;
}
.Select__OptionGroup__option__mG7J5.__wab_instance {
  position: relative;
}
.Select__OptionGroup__svg__e3L0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Select__OptionGroup__svg__mo1Zf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Select__OptionGroup__option__tR268.__wab_instance {
  position: relative;
}
.Select__OptionGroup__svg__liUNs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Select__OptionGroup__svg__n1ADg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
