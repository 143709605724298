.SliderTrack__root__utTZy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2px;
  max-width: 100%;
  background: var(--token-D5PfQGq_p2o95u);
  position: relative;
  min-width: 0;
  border-radius: 6px;
}
.SliderTrack__rootfilled__utTZyByy2J {
  background: var(--token-i2qCvUVknsIZp2);
  height: 4px;
}
.SliderTrack__rootcolor_gold__utTZyZgFk5 {
  background: var(--token-fMOFOdWlIwmyMR);
}
.SliderTrack__rootfilled_color_gray__utTZyByy2JYeLkx {
  background: var(--token-D5PfQGq_p2o95u);
}
