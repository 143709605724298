.DashboardEntry__root__gzZr {
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-left: var(--token-P3nFMsZz2JMXx8);
  padding-right: var(--token-P3nFMsZz2JMXx8);
  overflow: visible;
  min-width: 0;
  border-radius: 2px;
  border: 1px solid var(--token-O8yxnKF8d);
}
.DashboardEntry__freeBox___4IotN {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-P3nFMsZz2JMXx8);
  width: 100%;
  padding-top: var(--token-TJik2L8OcSV6kD);
  padding-bottom: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
}
.DashboardEntry__freeBoxisExpanded___4IotNTiEOo {
  padding-bottom: 0px;
}
.DashboardEntry__freeBox__h3Jmd {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 5;
  padding-top: 0px;
}
.DashboardEntry__freeBox__h3Jmd > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.DashboardEntry__freeBox__h3Jmd > .__wab_flex-container > *,
.DashboardEntry__freeBox__h3Jmd > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__h3Jmd > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__h3Jmd
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.DashboardEntry__freeBox__mUcd {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: var(--token-Wf1GGJSbrxsgS-);
  height: var(--token-Wf1GGJSbrxsgS-);
  flex-shrink: 0;
  display: none;
}
.DashboardEntry__freeBoxhasCheckbox__mUcDaZYh {
  display: flex;
}
.DashboardEntry__checkbox___2OOuw.__wab_instance {
  width: var(--token-TJik2L8OcSV6kD);
  height: var(--token-TJik2L8OcSV6kD);
  flex-shrink: 0;
}
.DashboardEntry__text__rf285 {
  display: none;
}
.DashboardEntry__collapseButton__omCi.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
.DashboardEntry__collapseButtonisExpanded__omCiTiEOo.__wab_instance {
  flex-shrink: 0;
}
.DashboardEntry__collapseButtonhasCheckbox__omCiAZYh.__wab_instance {
  flex-shrink: 0;
}
.DashboardEntry__collapseButtonhasVersion__omCiLJao3.__wab_instance {
  flex-shrink: 0;
}
.DashboardEntry__svg__blqrc {
  position: relative;
  object-fit: cover;
  width: var(--token-C4KuLs5uqsxF8L);
  height: var(--token-C4KuLs5uqsxF8L);
}
.DashboardEntry__svgisExpanded__blqrcTiEOo {
  transform: rotateX(180deg) rotateY(0deg) rotateZ(0deg);
  transform-style: preserve-3d;
}
.DashboardEntry__svgisExpanded__blqrcTiEOo * {
  transform-style: preserve-3d;
}
.DashboardEntry__svg___0YwQ2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__freeBox__eVgtA {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.DashboardEntry__freeBox__eVgtA > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.DashboardEntry__freeBox__eVgtA > .__wab_flex-container > *,
.DashboardEntry__freeBox__eVgtA > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__eVgtA > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__eVgtA
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.DashboardEntry__freeBox___9RPgU {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DashboardEntry__freeBox___9RPgU > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.DashboardEntry__freeBox___9RPgU > .__wab_flex-container > *,
.DashboardEntry__freeBox___9RPgU > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox___9RPgU > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox___9RPgU
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
.DashboardEntry__slotTargetAddress___7Ba05 {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
  line-height: var(--token-97-B3t__LugLsk);
}
.DashboardEntry__freeBox__fWogt {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.DashboardEntry__freeBox__fWogt > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.DashboardEntry__freeBox__fWogt > .__wab_flex-container > *,
.DashboardEntry__freeBox__fWogt > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__fWogt > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__fWogt
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.DashboardEntry__slotTargetRefNr__tNBsJ {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
.DashboardEntry__text__xrmQ {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-NfHg4McmY);
}
.DashboardEntry__texthasVersion_hasReferenceNr__xrmQlJao3YBpdt {
  display: block;
}
.DashboardEntry__slotTargetVersion__mm6H {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
  font-weight: 500;
}
.DashboardEntry__freeBox__lejhi {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.DashboardEntry__slotTargetDataDate__a6RAq {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.DashboardEntry__freeBox___9KLii {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DashboardEntry__freeBox___9KLii > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.DashboardEntry__freeBox___9KLii > .__wab_flex-container > *,
.DashboardEntry__freeBox___9KLii > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox___9KLii > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox___9KLii
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.DashboardEntry__text__e87DI {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.DashboardEntry__slotTargetModelDate__rCxHb {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.DashboardEntry__freeBox__mrZOn {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.DashboardEntry__slotTargetVerbrauch__rfsgo {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.DashboardEntry__freeBox___9KyAl {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.DashboardEntry__slotTargetEmission__tNDf {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.DashboardEntry__freeBox___7Gt7Y {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
.DashboardEntry__freeBox___7Gt7Y > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.DashboardEntry__freeBox___7Gt7Y > .__wab_flex-container > *,
.DashboardEntry__freeBox___7Gt7Y > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox___7Gt7Y > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox___7Gt7Y
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.DashboardEntry__freeBox__zeniG {
  display: flex;
  flex-direction: column;
}
.DashboardEntry__freeBox__zeniG > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.DashboardEntry__freeBox__zeniG > .__wab_flex-container > *,
.DashboardEntry__freeBox__zeniG > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__zeniG > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__zeniG
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 0px;
}
.DashboardEntry__slotTargetCapex__vTmUe {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
  color: var(--token-gBTCEyDxU);
}
.DashboardEntry__freeBox___9WIjU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.DashboardEntry__freeBox__yWhde {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DashboardEntry__freeBox__yWhde > .__wab_flex-container {
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.DashboardEntry__freeBox__yWhde > .__wab_flex-container > *,
.DashboardEntry__freeBox__yWhde > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__yWhde > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__yWhde
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.DashboardEntry__text__lxluH {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.DashboardEntry__text__fKtu2 {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  color: var(--token-NfHg4McmY);
}
.DashboardEntry__freeBox__dj0Zj {
  display: flex;
  position: relative;
  flex-direction: row;
  grid-column-end: span 3;
}
.DashboardEntry__freeBox__dj0Zj > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-HaxgWLsrVFdD87));
  width: calc(100% + var(--token-HaxgWLsrVFdD87));
}
.DashboardEntry__freeBox__dj0Zj > .__wab_flex-container > *,
.DashboardEntry__freeBox__dj0Zj > .__wab_flex-container > .__wab_slot > *,
.DashboardEntry__freeBox__dj0Zj > .__wab_flex-container > picture > img,
.DashboardEntry__freeBox__dj0Zj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HaxgWLsrVFdD87);
}
.DashboardEntry__button__zAnl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__mhcQr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__svg__djHvq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__select__hyt4M.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__fsC03 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DashboardEntry__button__kqqTi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__jnG5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__svg__l2G38 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__option___2BO9I.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__h8MZ2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DashboardEntry__svg__lqZsy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__option__zjt0V.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__o0Yfc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DashboardEntry__svg__d9WzQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__option__nkeCq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DashboardEntry__svg__wSzt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DashboardEntry__svg__gHOpe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DashboardEntry__snapshotList___7PVj {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 100%;
  grid-column-end: span 20;
  display: none;
}
.DashboardEntry__snapshotListisExpanded___7PVjTiEOo {
  display: flex;
}
.DashboardEntry__objectDetailRow__bFch.__wab_instance {
  position: relative;
}
.DashboardEntry__text__vekKi {
  width: auto;
}
.DashboardEntry__text__kkftV {
  padding-top: 0px;
}
.DashboardEntry__text__ulaEr {
  padding-bottom: 0px;
}
.DashboardEntry__objectDetailRow__f52Ru.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.DashboardEntry__objectDetailRow__gtIvY.__wab_instance {
  position: relative;
}
