.QuestionGroup__root__jk7Nu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-x_jRp3a5FpON8z);
  padding-bottom: var(--token-x_jRp3a5FpON8z);
  min-width: 0;
  border-bottom: 1px solid #c1cec924;
}
.QuestionGroup__root__jk7Nu > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.QuestionGroup__root__jk7Nu > .__wab_flex-container > *,
.QuestionGroup__root__jk7Nu > .__wab_flex-container > .__wab_slot > *,
.QuestionGroup__root__jk7Nu > .__wab_flex-container > picture > img,
.QuestionGroup__root__jk7Nu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
.QuestionGroup__rootisLast__jk7NuFrpBm {
  border-bottom-style: none;
}
.QuestionGroup__freeBox___1Vc3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.QuestionGroup__freeBox___1Vc3 > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
.QuestionGroup__freeBox___1Vc3 > .__wab_flex-container > *,
.QuestionGroup__freeBox___1Vc3 > .__wab_flex-container > .__wab_slot > *,
.QuestionGroup__freeBox___1Vc3 > .__wab_flex-container > picture > img,
.QuestionGroup__freeBox___1Vc3
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
.QuestionGroup__freeBox__sakvN {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.QuestionGroup__freeBox__sakvN > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.QuestionGroup__freeBox__sakvN > .__wab_flex-container > *,
.QuestionGroup__freeBox__sakvN > .__wab_flex-container > .__wab_slot > *,
.QuestionGroup__freeBox__sakvN > .__wab_flex-container > picture > img,
.QuestionGroup__freeBox__sakvN
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.QuestionGroup__slotTargetTitle__mJAbV {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
}
.QuestionGroup__text__kwLim {
  position: relative;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
}
.QuestionGroup__textisNiceToHave__kwLimVGeXc {
  display: block;
}
.QuestionGroup__slotTargetText__kbBb3 {
  color: var(--token-SkTYvRtmE);
}
.QuestionGroup__formGroup__lfO0Y.__wab_instance {
  position: relative;
}
.QuestionGroup__singleSelectButtonGroup__zTq8L.__wab_instance {
  max-width: 100%;
  position: relative;
}
.QuestionGroup__button___1Tvex.__wab_instance {
  max-width: 100%;
}
.QuestionGroup__svg__goltq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__svg__yfXxm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__button__m2V7P.__wab_instance {
  max-width: 100%;
}
.QuestionGroup__svg__zFn5R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__text__ve6Ky {
  padding-left: 0px;
}
.QuestionGroup__svg__yLo3I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__button__epn2N.__wab_instance {
  max-width: 100%;
}
.QuestionGroup__svg__ox8M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__text__cdtuv {
  padding-left: 0px;
}
.QuestionGroup__svg__tuDg7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__button__wggx9.__wab_instance {
  max-width: 100%;
}
.QuestionGroup__svg___7ZFtP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__text___3Qlxj {
  padding-left: 0px;
}
.QuestionGroup__svg__wtjf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__button__uccyD.__wab_instance {
  max-width: 100%;
}
.QuestionGroup__svg__eiJx1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__text__xxmlB {
  padding-left: 0px;
}
.QuestionGroup__svg__d6Jqt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.QuestionGroup__symbolCounter__p71Fp.__wab_instance {
  position: relative;
}
