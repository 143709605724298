._020ErfassungVerification01__root__vAlXd {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._020ErfassungVerification01__processHeader__mcUc.__wab_instance {
  max-width: 100%;
  position: relative;
}
._020ErfassungVerification01__processBreadCrumb___3K9ZL.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
._020ErfassungVerification01__overlayUsertype__tcrm4 {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: -2px;
  z-index: 1;
  right: 0;
  bottom: 0;
}
._020ErfassungVerification01__backgorund__slEyV {
  display: block;
  position: fixed;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--token-hEzPFeV5Y);
}
._020ErfassungVerification01__freeBox__uqWhI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  z-index: 10;
  background: var(--token-umjgs5uqk);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-x_jRp3a5FpON8z);
}
._020ErfassungVerification01__freeBox__bhfVk {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__freeBox__bhfVk > .__wab_flex-container {
  flex-direction: column;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox__bhfVk > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__bhfVk
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__bhfVk
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__bhfVk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__o1Stv {
  display: flex;
  position: relative;
  flex-direction: column;
}
._020ErfassungVerification01__freeBox__o1Stv > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox__o1Stv > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__o1Stv
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__o1Stv
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__o1Stv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__text__gdNPq {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__gdNPq {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__qzuVo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-xowVQ_ic8i1X0_);
  color: var(--token-s9gzWmEFR);
  min-width: 0;
}
._020ErfassungVerification01__freeBox___4FlX1 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___4FlX1 > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox___4FlX1 > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___4FlX1
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___4FlX1
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___4FlX1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__uprFi {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__uprFi > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._020ErfassungVerification01__freeBox__uprFi > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__uprFi
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__uprFi
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__uprFi
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._020ErfassungVerification01__freeBox__fysE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__fysE > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox__fysE > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__fysE
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__fysE
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__fysE
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox___5HExv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__svg__kzPsm {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__kmUzz {
  display: flex;
  position: relative;
  background: var(--token-NfSc1CXMklYEK2);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._020ErfassungVerification01__svg__gVclW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  opacity: 0;
  height: 1em;
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__llWa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__llWa > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._020ErfassungVerification01__freeBox__llWa > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__llWa
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__llWa
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__llWa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._020ErfassungVerification01__freeBox___7W0FR {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___7W0FR > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._020ErfassungVerification01__freeBox___7W0FR > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___7W0FR
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___7W0FR
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___7W0FR
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._020ErfassungVerification01__text__bsFe {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__bsFe {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__yvTIi {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-L1EeQgMLm);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__yvTIi {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__c3H4L {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-7xB3ITuN8);
  min-width: 0;
}
._020ErfassungVerification01__ul__tWySu {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__freeBox___8J06 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      var(--token-6_9iE0w_5TGI8j),
      var(--token-6_9iE0w_5TGI8j)
    ),
    var(--token-SqSK5Lk3X);
  min-width: 0;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox___8J06 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._020ErfassungVerification01__freeBox___8J06 > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___8J06
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___8J06
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___8J06
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._020ErfassungVerification01__freeBox__amIhH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__amIhH > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox__amIhH > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__amIhH
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__amIhH
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__amIhH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__mGaoa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__svg__d11Pw {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__g3Ade {
  display: flex;
  position: relative;
  background: var(--token-ObRRGpZ-v1PQfN);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--token-HaxgWLsrVFdD87);
}
._020ErfassungVerification01__svg___4N5Wi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  left: auto;
  top: auto;
  color: var(--token-xgado2J7YlFS13);
  height: 1em;
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__qWqR5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__qWqR5 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox__qWqR5 > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__qWqR5
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__qWqR5
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__qWqR5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__freeBox___0F1G1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___0F1G1 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-uC-_WkHfVJTm0T));
  height: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._020ErfassungVerification01__freeBox___0F1G1 > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___0F1G1
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___0F1G1
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___0F1G1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-uC-_WkHfVJTm0T);
}
._020ErfassungVerification01__text__kr3Lc {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 400;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__kr3Lc {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__y3B3 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  color: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__y3B3 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__wuzqn {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-NfSc1CXMklYEK2);
  min-width: 0;
}
._020ErfassungVerification01__ul__zWaAb {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__freeBox__mlMwg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__button__jDeYm.__wab_instance {
  max-width: 100%;
}
._020ErfassungVerification01__svg__vG5GR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text___4WeE0 {
  height: auto;
  padding-right: 0px;
}
._020ErfassungVerification01__svg__nT8Ki {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__button__h9QoM.__wab_instance {
  max-width: 100%;
  position: absolute;
  left: auto;
  top: -14px;
  z-index: 1;
  right: -14px;
}
._020ErfassungVerification01__svg__zhhYc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__svg__ob3B2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__freeBox__uApJx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: var(--token-6rZumor6mC3fF5) 24px var(--token-ew2vDb1S1Vm4F-);
}
._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__uApJx
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__uApJx
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__uApJx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__freeBox__uApJx {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 88px 24px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container > *,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > picture
    > img,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__uApJx {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 16px;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__uApJx > .__wab_flex-container > *,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > picture
    > img,
  ._020ErfassungVerification01__freeBox__uApJx
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._020ErfassungVerification01__freeBox__k15A {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 600px;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__k15A > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
._020ErfassungVerification01__freeBox__k15A > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__k15A
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__k15A
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__k15A
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
._020ErfassungVerification01__freeBox__nV4O {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__nV4O > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-YOOgp3GSpDYosY));
  height: calc(100% + var(--token-YOOgp3GSpDYosY));
}
._020ErfassungVerification01__freeBox__nV4O > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__nV4O
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__nV4O
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__nV4O
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-YOOgp3GSpDYosY);
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__nV4O > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__nV4O > .__wab_flex-container > *,
  ._020ErfassungVerification01__freeBox__nV4O
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._020ErfassungVerification01__freeBox__nV4O
    > .__wab_flex-container
    > picture
    > img,
  ._020ErfassungVerification01__freeBox__nV4O
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
._020ErfassungVerification01__freeBox___3FLpk {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___3FLpk > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._020ErfassungVerification01__freeBox___3FLpk > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___3FLpk
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___3FLpk
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___3FLpk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._020ErfassungVerification01__text__cbzuN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__cbzuN {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__xIq8O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._020ErfassungVerification01__freeBox__cJrGf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__cJrGf > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
._020ErfassungVerification01__freeBox__cJrGf > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__cJrGf
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__cJrGf
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__cJrGf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__cJrGf {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__cJrGf > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__cJrGf > .__wab_flex-container > *,
  ._020ErfassungVerification01__freeBox__cJrGf
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._020ErfassungVerification01__freeBox__cJrGf
    > .__wab_flex-container
    > picture
    > img,
  ._020ErfassungVerification01__freeBox__cJrGf
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._020ErfassungVerification01__freeBox__kk64T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__kk64T > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-qecgvVQwVo7jFR));
  height: calc(100% + var(--token-qecgvVQwVo7jFR));
}
._020ErfassungVerification01__freeBox__kk64T > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__kk64T
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__kk64T
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__kk64T
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-qecgvVQwVo7jFR);
}
._020ErfassungVerification01__freeBox__ygce5 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__freeBox__ygce5 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__ygce5 {
    grid-template-columns: 1fr;
  }
}
._020ErfassungVerification01__freeBox__yNb3H {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 4;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__yNb3H > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._020ErfassungVerification01__freeBox__yNb3H > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__yNb3H
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__yNb3H
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__yNb3H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._020ErfassungVerification01__freeBox__aLavf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__aLavf > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox__aLavf > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__aLavf
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__aLavf
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__aLavf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__formGroup__stxyu.__wab_instance {
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__text__td2Mw {
  padding-right: 0px;
}
._020ErfassungVerification01__textInput54__zWp9M.__wab_instance {
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__svg___4IHzz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__svg__l3Jh8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__bMm1V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._020ErfassungVerification01__text__uSuk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__text__re6Xm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__symbolCounter__fp0Es.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__freeBox__cpvCm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 25px;
}
._020ErfassungVerification01__phoneVerificationButton__tr0Bs.__wab_instance {
  max-width: 100%;
}
._020ErfassungVerification01__svg___1KXt0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__rpXu8 {
  height: auto;
  padding-right: 0px;
}
._020ErfassungVerification01__svg__prkzf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__hAsxB {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
._020ErfassungVerification01__freeBox__jq0Qf {
  box-shadow: 0px 10px 15px -4px #0000001a, 0px 4px 6px -4px #0000001a;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  grid-column-start: 1;
  grid-column-end: span 6;
  background: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
  display: none;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__jq0Qf > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._020ErfassungVerification01__freeBox__jq0Qf > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__jq0Qf
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__jq0Qf
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__jq0Qf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._020ErfassungVerification01__freeBox__qubos {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__qubos > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox__qubos > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__qubos
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__qubos
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__qubos
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__dmlXf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__dmlXf > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._020ErfassungVerification01__freeBox__dmlXf > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__dmlXf
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__dmlXf
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__dmlXf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._020ErfassungVerification01__svg__vfKr9 {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__pOCes {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__pOCes > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox__pOCes > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__pOCes
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__pOCes
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__pOCes
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__text__s3SP {
  width: 100%;
  height: auto;
  font-size: var(--token-xowVQ_ic8i1X0_);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__s3SP {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__wYvda {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._020ErfassungVerification01__ul__gbYac {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__ul__e1PdL {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__ul__r7PP {
  display: flex;
  flex-direction: column;
}
._020ErfassungVerification01__formGroup__sYwsa.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__text__lWg0S {
  padding-right: 0px;
}
._020ErfassungVerification01__checkbox__kSchy.__wab_instance {
  max-width: 100%;
  position: relative;
}
._020ErfassungVerification01__symbolCounter___7FIsd.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__freeBox__jVcfU {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__jVcfU > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._020ErfassungVerification01__freeBox__jVcfU > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__jVcfU
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__jVcfU
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__jVcfU
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
._020ErfassungVerification01__freeBox___0LLrw {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___0LLrw > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox___0LLrw > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___0LLrw
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___0LLrw
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___0LLrw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__formGroup__sf6G0.__wab_instance {
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__text__o0B5Y {
  padding-right: 0px;
}
._020ErfassungVerification01__textInput53__sp74W.__wab_instance {
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__svg__srTAq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__svg__z0FAt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__snHz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._020ErfassungVerification01__text__kQusX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__text__cdIzY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__symbolCounter__lRWm.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__formGroup__x95V.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
._020ErfassungVerification01__text__q8UBd {
  padding-right: 0px;
}
._020ErfassungVerification01__textInput51__p5YPe.__wab_instance {
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__svg___8JFpa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__svg__jmPpS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__hRMqg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._020ErfassungVerification01__text__akDoy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__text__qzEh2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._020ErfassungVerification01__symbolCounter__iyIa.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__freeBox__qtdF5 {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-HUUwrpE5bq87rt);
  border: 1px solid var(--token-O8yxnKF8d);
}
._020ErfassungVerification01__freeBox__qtdF5 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox__qtdF5 > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__qtdF5
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__qtdF5
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__qtdF5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox___3IDol {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___3IDol > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._020ErfassungVerification01__freeBox___3IDol > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___3IDol
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___3IDol
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___3IDol
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._020ErfassungVerification01__freeBox__qaVKa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__qaVKa > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._020ErfassungVerification01__freeBox__qaVKa > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__qaVKa
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__qaVKa
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__qaVKa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._020ErfassungVerification01__freeBox__nBb7L {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__nBb7L > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._020ErfassungVerification01__freeBox__nBb7L > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__nBb7L
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__nBb7L
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__nBb7L
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._020ErfassungVerification01__text__otzjZ {
  width: 100%;
  height: auto;
  font-size: var(--token-xowVQ_ic8i1X0_);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text__otzjZ {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__text__wSzMs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._020ErfassungVerification01__formGroup__eopf3.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__text__d3T3J {
  padding-right: 0px;
}
._020ErfassungVerification01__checkbox3__m3NcX.__wab_instance {
  max-width: 100%;
  position: relative;
}
._020ErfassungVerification01__symbolCounter__rqz3O.__wab_instance {
  position: relative;
}
._020ErfassungVerification01__svg__mrB {
  position: relative;
  object-fit: cover;
  width: 56px;
  height: 56px;
  color: var(--token-rqm9BSu09);
  flex-shrink: 0;
}
._020ErfassungVerification01__freeBox__ooffk {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-HUUwrpE5bq87rt);
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__freeBox__ooffk {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__freeBox__ooffk {
    grid-template-columns: 1fr;
  }
}
._020ErfassungVerification01__freeBox___1G1Un {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
._020ErfassungVerification01__freeBox___1G1Un > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._020ErfassungVerification01__freeBox___1G1Un > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox___1G1Un
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox___1G1Un
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox___1G1Un
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
._020ErfassungVerification01__text___0XgfZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__text___0XgfZ {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._020ErfassungVerification01__addressFormGroup__nKlPg.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._020ErfassungVerification01__portfolioQuestionCard__ftWgc.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._020ErfassungVerification01__divider__hqjZ.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._020ErfassungVerification01__divider__hqjZ.__wab_instance {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  ._020ErfassungVerification01__divider__hqjZ.__wab_instance {
    flex-shrink: 0;
  }
}
._020ErfassungVerification01__freeBox__qifJo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__qifJo > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._020ErfassungVerification01__freeBox__qifJo > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__qifJo
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__qifJo
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__qifJo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._020ErfassungVerification01__freeBox__r3Cdz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: auto;
  width: 100%;
  min-width: 0;
}
._020ErfassungVerification01__freeBox__r3Cdz > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
._020ErfassungVerification01__freeBox__r3Cdz > .__wab_flex-container > *,
._020ErfassungVerification01__freeBox__r3Cdz
  > .__wab_flex-container
  > .__wab_slot
  > *,
._020ErfassungVerification01__freeBox__r3Cdz
  > .__wab_flex-container
  > picture
  > img,
._020ErfassungVerification01__freeBox__r3Cdz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
._020ErfassungVerification01__button__mHqcM.__wab_instance {
  position: relative;
  align-self: flex-start;
}
._020ErfassungVerification01__svg__f0PMa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__aFljm {
  color: var(--token-SkTYvRtmE);
  text-decoration-line: underline !important;
}
._020ErfassungVerification01__svg__lvMeM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__submitButton__jtXm5.__wab_instance {
  max-width: 100%;
}
._020ErfassungVerification01__svg__zlWuR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__text__nEuLm {
  height: auto;
  padding-right: 0px;
}
._020ErfassungVerification01__svg__yPpKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._020ErfassungVerification01__footer___25Zru.__wab_instance {
  max-width: 100%;
  position: relative;
}
