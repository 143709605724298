.GeneralUserSettings__root__yyEys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  position: relative;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .GeneralUserSettings__root__yyEys {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .GeneralUserSettings__root__yyEys {
    grid-template-columns: 1fr;
  }
}
.GeneralUserSettings__freeBox__hz08N {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.GeneralUserSettings__freeBox__hz08N > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.GeneralUserSettings__freeBox__hz08N > .__wab_flex-container > *,
.GeneralUserSettings__freeBox__hz08N > .__wab_flex-container > .__wab_slot > *,
.GeneralUserSettings__freeBox__hz08N > .__wab_flex-container > picture > img,
.GeneralUserSettings__freeBox__hz08N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.GeneralUserSettings__text__hhtD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .GeneralUserSettings__text__hhtD {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.GeneralUserSettings__formGroup__ylYb.__wab_instance {
  position: relative;
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.GeneralUserSettings__textInput2__fvbdb.__wab_instance {
  position: relative;
}
.GeneralUserSettings__svg__nCpqW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.GeneralUserSettings__svg__k0Fdc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.GeneralUserSettings__text__yriYh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.GeneralUserSettings__text__d1RoA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.GeneralUserSettings__text__gofop {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.GeneralUserSettings__symbolCounter__eQbY4.__wab_instance {
  position: relative;
}
.GeneralUserSettings__formGroup___0Hzqz.__wab_instance {
  position: relative;
  width: 100%;
  grid-column-end: span 3;
  min-width: 0;
}
.GeneralUserSettings__textInput3__ugsRs.__wab_instance {
  position: relative;
}
.GeneralUserSettings__svg__oO9Ph {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.GeneralUserSettings__svg__hfFRd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.GeneralUserSettings__text__qVQwI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.GeneralUserSettings__text___6UxbP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.GeneralUserSettings__text__cwGYj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.GeneralUserSettings__symbolCounter__b9CYv.__wab_instance {
  position: relative;
}
.GeneralUserSettings__portfolioQuestionCard__b47L.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.GeneralUserSettings__freeBox__g1MHu {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.GeneralUserSettings__freeBox__g1MHu > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.GeneralUserSettings__freeBox__g1MHu > .__wab_flex-container > *,
.GeneralUserSettings__freeBox__g1MHu > .__wab_flex-container > .__wab_slot > *,
.GeneralUserSettings__freeBox__g1MHu > .__wab_flex-container > picture > img,
.GeneralUserSettings__freeBox__g1MHu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.GeneralUserSettings__button__vKv8L.__wab_instance {
  max-width: 100%;
  position: relative;
}
.GeneralUserSettings__svg__gxXqq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.GeneralUserSettings__svg__yn4LM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
