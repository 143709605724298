.TermsOfUseModal__root__isN8D.__wab_instance {
  position: relative;
}
.TermsOfUseModal__link___8GFp0 {
  color: var(--token-s9gzWmEFR);
}
.TermsOfUseModal__divider___2MBr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseModal__freeBox__nwgs0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.TermsOfUseModal__freeBox__nwgs0 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.TermsOfUseModal__freeBox__nwgs0 > .__wab_flex-container > *,
.TermsOfUseModal__freeBox__nwgs0 > .__wab_flex-container > .__wab_slot > *,
.TermsOfUseModal__freeBox__nwgs0 > .__wab_flex-container > picture > img,
.TermsOfUseModal__freeBox__nwgs0
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.TermsOfUseModal__text__ixLyq {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
}
.TermsOfUseModal__text___6UOdJ {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.TermsOfUseModal__divider___8LgP.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseModal__termsOfUseForm___42Jqg.__wab_instance {
  position: relative;
}
.TermsOfUseModal__divider__oqUKg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.TermsOfUseModal__freeBox__x8BWd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: auto;
  max-width: 100%;
}
.TermsOfUseModal__confirmButton__lOqfm.__wab_instance {
  max-width: 100%;
}
.TermsOfUseModal__svg__opCrG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.TermsOfUseModal__text__bgKHq {
  height: auto;
  padding-right: 0px;
}
.TermsOfUseModal__svg__pk4Wt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
