._013Registrierung02__root__bPVb {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._013Registrierung02__navigation__ewuYb.__wab_instance {
  max-width: 100%;
  position: relative;
}
._013Registrierung02__freeBox__ngOat {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: var(--token-6rZumor6mC3fF5) var(--token-x_jRp3a5FpON8z)
    var(--token-ew2vDb1S1Vm4F-);
}
._013Registrierung02__freeBox__ngOat > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
._013Registrierung02__freeBox__ngOat > .__wab_flex-container > *,
._013Registrierung02__freeBox__ngOat > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__ngOat > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__ngOat
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__freeBox__ngOat {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 88px 24px;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container > *,
  ._013Registrierung02__freeBox__ngOat
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container > picture > img,
  ._013Registrierung02__freeBox__ngOat
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__ngOat {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 16px;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container > *,
  ._013Registrierung02__freeBox__ngOat
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._013Registrierung02__freeBox__ngOat > .__wab_flex-container > picture > img,
  ._013Registrierung02__freeBox__ngOat
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._013Registrierung02__freeBox__zxMcj {
  max-width: 1440px;
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
}
._013Registrierung02__freeBox__zxMcj > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-qecgvVQwVo7jFR));
  width: calc(100% + var(--token-qecgvVQwVo7jFR));
}
._013Registrierung02__freeBox__zxMcj > .__wab_flex-container > *,
._013Registrierung02__freeBox__zxMcj > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__zxMcj > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__zxMcj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-qecgvVQwVo7jFR);
}
._013Registrierung02__freeBox__wdk24 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-width: 0;
}
._013Registrierung02__freeBox__wdk24 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-YOOgp3GSpDYosY));
  height: calc(100% + var(--token-YOOgp3GSpDYosY));
}
._013Registrierung02__freeBox__wdk24 > .__wab_flex-container > *,
._013Registrierung02__freeBox__wdk24 > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__wdk24 > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__wdk24
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-YOOgp3GSpDYosY);
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__wdk24 > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__wdk24 > .__wab_flex-container > *,
  ._013Registrierung02__freeBox__wdk24
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._013Registrierung02__freeBox__wdk24 > .__wab_flex-container > picture > img,
  ._013Registrierung02__freeBox__wdk24
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 40px;
  }
}
._013Registrierung02__freeBox__gKn9H {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__gKn9H > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._013Registrierung02__freeBox__gKn9H > .__wab_flex-container > *,
._013Registrierung02__freeBox__gKn9H > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__gKn9H > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__gKn9H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._013Registrierung02__text__bbMqs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-rX_DHtXlxFDVF-);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._013Registrierung02__text__bbMqs {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._013Registrierung02__text__pvMn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._013Registrierung02__freeBox__esRn7 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__esRn7 > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
._013Registrierung02__freeBox__esRn7 > .__wab_flex-container > *,
._013Registrierung02__freeBox__esRn7 > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__esRn7 > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__esRn7
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__esRn7 {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__esRn7 > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__esRn7 > .__wab_flex-container > *,
  ._013Registrierung02__freeBox__esRn7
    > .__wab_flex-container
    > .__wab_slot
    > *,
  ._013Registrierung02__freeBox__esRn7 > .__wab_flex-container > picture > img,
  ._013Registrierung02__freeBox__esRn7
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._013Registrierung02__freeBox__dopc7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__dopc7 > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-fkHbhUQaFbf-Tk));
  height: calc(100% + var(--token-fkHbhUQaFbf-Tk));
}
._013Registrierung02__freeBox__dopc7 > .__wab_flex-container > *,
._013Registrierung02__freeBox__dopc7 > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__dopc7 > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__dopc7
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-fkHbhUQaFbf-Tk);
}
._013Registrierung02__divider__cB9Uo.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__divider__cB9Uo.__wab_instance {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__divider__cB9Uo.__wab_instance {
    flex-shrink: 0;
  }
}
._013Registrierung02__freeBox__k5ZHp {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__freeBox__k5ZHp {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__k5ZHp {
    grid-template-columns: 1fr;
  }
}
._013Registrierung02__freeBox__ybyDn {
  box-shadow: 0px 10px 15px -4px #0000001a, 0px 4px 6px -4px #0000001a;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  grid-column-start: 1;
  grid-column-end: span 6;
  background: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
  display: none;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._013Registrierung02__freeBox__ybyDn > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._013Registrierung02__freeBox__ybyDn > .__wab_flex-container > *,
._013Registrierung02__freeBox__ybyDn > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__ybyDn > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__ybyDn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._013Registrierung02__freeBox__bVbfV {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__bVbfV > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._013Registrierung02__freeBox__bVbfV > .__wab_flex-container > *,
._013Registrierung02__freeBox__bVbfV > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__bVbfV > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__bVbfV
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._013Registrierung02__freeBox___3C58H {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox___3C58H > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._013Registrierung02__freeBox___3C58H > .__wab_flex-container > *,
._013Registrierung02__freeBox___3C58H > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox___3C58H > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox___3C58H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._013Registrierung02__svg__fUSct {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._013Registrierung02__freeBox__i5Jk9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__i5Jk9 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._013Registrierung02__freeBox__i5Jk9 > .__wab_flex-container > *,
._013Registrierung02__freeBox__i5Jk9 > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__i5Jk9 > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__i5Jk9
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._013Registrierung02__text__ycTq {
  width: 100%;
  height: auto;
  font-size: var(--token-xowVQ_ic8i1X0_);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._013Registrierung02__text__ycTq {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._013Registrierung02__text__yPLzi {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._013Registrierung02__ul__t6S3J {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__ul__o8HiW {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__ul__hZnG {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__formGroup__jg7Kp.__wab_instance {
  position: relative;
}
._013Registrierung02__text__qoipC {
  padding-right: 0px;
}
._013Registrierung02__checkbox2__q1Zmw.__wab_instance {
  max-width: 100%;
  position: relative;
}
._013Registrierung02__symbolCounter__qnKfg.__wab_instance {
  position: relative;
}
._013Registrierung02__text__yekn6 {
  position: relative;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  ._013Registrierung02__text__yekn6 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._013Registrierung02__formGroup__kOkjz.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._013Registrierung02__text__eiHgP {
  padding-right: 0px;
}
._013Registrierung02__textInput53__tyWyn.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__svg__peTQn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__svg__gw6Go {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__oTcub {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._013Registrierung02__text__tFzPc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__text__eggv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__symbolCounter__fE4En.__wab_instance {
  position: relative;
}
._013Registrierung02__formGroup__bbJrN.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._013Registrierung02__text__bxmGk {
  padding-right: 0px;
  height: auto;
}
._013Registrierung02__textInput59__fcQ21.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__svg__rLgrr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__svg__eOrKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__cvDwf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._013Registrierung02__text__awzqy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__text__hGakH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__symbolCounter__gevAj.__wab_instance {
  position: relative;
}
._013Registrierung02__formGroup__lhDfj.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._013Registrierung02__textInput58___3NO7J.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__svg___7JnpE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__svg___4Fg0V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__a0V3F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._013Registrierung02__text__yLhOi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__text__mrJX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__symbolCounter__fTgVr.__wab_instance {
  position: relative;
}
._013Registrierung02__divider__iHiSt.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__divider__iHiSt.__wab_instance {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__divider__iHiSt.__wab_instance {
    flex-shrink: 0;
  }
}
._013Registrierung02__freeBox__vMLbX {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__freeBox__vMLbX {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__freeBox__vMLbX {
    grid-template-columns: 1fr;
  }
}
._013Registrierung02__freeBox__etGlW {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
._013Registrierung02__freeBox__etGlW > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._013Registrierung02__freeBox__etGlW > .__wab_flex-container > *,
._013Registrierung02__freeBox__etGlW > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__etGlW > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__etGlW
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._013Registrierung02__text__gzyBe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-WA_NCXKiXv5f4n);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._013Registrierung02__text__gzyBe {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._013Registrierung02__text__q0Fc7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._013Registrierung02__freeBox__kXc8E {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 4;
  min-width: 0;
}
._013Registrierung02__freeBox__kXc8E > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._013Registrierung02__freeBox__kXc8E > .__wab_flex-container > *,
._013Registrierung02__freeBox__kXc8E > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__kXc8E > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__kXc8E
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._013Registrierung02__freeBox__uSjzc {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__uSjzc > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._013Registrierung02__freeBox__uSjzc > .__wab_flex-container > *,
._013Registrierung02__freeBox__uSjzc > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__uSjzc > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__uSjzc
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
._013Registrierung02__formGroup__vTPvw.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__text__zGhg8 {
  padding-right: 0px;
}
._013Registrierung02__textInput54__xhGq.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__svg__c5Ff0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__svg___68JbI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__am2VM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._013Registrierung02__text__ujHI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__text__maprY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__symbolCounter___4Objz.__wab_instance {
  position: relative;
}
._013Registrierung02__phoneVerificationButton___8JpVb.__wab_instance {
  max-width: 100%;
  margin-top: 25px;
}
._013Registrierung02__svg___8HrS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__qhFo5 {
  height: auto;
  padding-right: 0px;
}
._013Registrierung02__svg__nKerP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__l5ATo {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
._013Registrierung02__freeBox___4OixT {
  box-shadow: 0px 10px 15px -4px #0000001a, 0px 4px 6px -4px #0000001a;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  grid-column-start: 1;
  grid-column-end: span 6;
  background: var(--token-ObRRGpZ-v1PQfN);
  min-width: 0;
  display: none;
  border-radius: 2px;
  padding: var(--token-HUUwrpE5bq87rt);
}
._013Registrierung02__freeBox___4OixT > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._013Registrierung02__freeBox___4OixT > .__wab_flex-container > *,
._013Registrierung02__freeBox___4OixT > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox___4OixT > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox___4OixT
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._013Registrierung02__freeBox__tK6Ge {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__tK6Ge > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-HUUwrpE5bq87rt));
  width: calc(100% + var(--token-HUUwrpE5bq87rt));
}
._013Registrierung02__freeBox__tK6Ge > .__wab_flex-container > *,
._013Registrierung02__freeBox__tK6Ge > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__tK6Ge > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__tK6Ge
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-HUUwrpE5bq87rt);
}
._013Registrierung02__freeBox__fH2D9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__fH2D9 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
._013Registrierung02__freeBox__fH2D9 > .__wab_flex-container > *,
._013Registrierung02__freeBox__fH2D9 > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__fH2D9 > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__fH2D9
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
._013Registrierung02__svg__grBfj {
  object-fit: cover;
  color: var(--token-vvi_7r45uYs8w7);
  width: var(--token-ti5ui7Y6hnPLfa);
  height: var(--token-ti5ui7Y6hnPLfa);
  flex-shrink: 0;
}
._013Registrierung02__freeBox___299Gi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox___299Gi > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._013Registrierung02__freeBox___299Gi > .__wab_flex-container > *,
._013Registrierung02__freeBox___299Gi > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox___299Gi > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox___299Gi
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
._013Registrierung02__text__frxn7 {
  width: 100%;
  height: auto;
  font-size: var(--token-xowVQ_ic8i1X0_);
  font-weight: 500;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  ._013Registrierung02__text__frxn7 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
._013Registrierung02__text__wVxT6 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-SkTYvRtmE);
  min-width: 0;
}
._013Registrierung02__ul__dx9Gf {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__ul__w7LGn {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__ul__otjOr {
  display: flex;
  flex-direction: column;
}
._013Registrierung02__formGroup__wDc1X.__wab_instance {
  position: relative;
}
._013Registrierung02__text___44Vjk {
  padding-right: 0px;
}
._013Registrierung02__checkbox__rrXbD.__wab_instance {
  max-width: 100%;
  position: relative;
}
._013Registrierung02__symbolCounter__sdHkd.__wab_instance {
  position: relative;
}
._013Registrierung02__freeBox___7JfvJ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
._013Registrierung02__freeBox___7JfvJ > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._013Registrierung02__freeBox___7JfvJ > .__wab_flex-container > *,
._013Registrierung02__freeBox___7JfvJ > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox___7JfvJ > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox___7JfvJ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
._013Registrierung02__freeBox__c550L {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__c550L > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
._013Registrierung02__freeBox__c550L > .__wab_flex-container > *,
._013Registrierung02__freeBox__c550L > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__c550L > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__c550L
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
._013Registrierung02__formGroup__zNvMk.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__text___7RuGq {
  padding-right: 0px;
}
._013Registrierung02__textInput55__p3KNz.__wab_instance {
  width: 100%;
  min-width: 0;
}
._013Registrierung02__svg__cecjt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__svg__wrujq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__xzBu2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._013Registrierung02__text__jKcXr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__text___17HTh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._013Registrierung02__symbolCounter__vpRdf.__wab_instance {
  position: relative;
}
._013Registrierung02__divider__hF2Bv.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._013Registrierung02__divider__hF2Bv.__wab_instance {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  ._013Registrierung02__divider__hF2Bv.__wab_instance {
    flex-shrink: 0;
  }
}
._013Registrierung02__freeBox__jo6G {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._013Registrierung02__freeBox__jo6G > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
._013Registrierung02__freeBox__jo6G > .__wab_flex-container > *,
._013Registrierung02__freeBox__jo6G > .__wab_flex-container > .__wab_slot > *,
._013Registrierung02__freeBox__jo6G > .__wab_flex-container > picture > img,
._013Registrierung02__freeBox__jo6G
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
._013Registrierung02__button__t2CWt.__wab_instance {
  max-width: 100%;
}
._013Registrierung02__svg__eVjZb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__text__nuW63 {
  height: auto;
  padding-right: 0px;
}
._013Registrierung02__svg__zECm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._013Registrierung02__footer___0VzEa.__wab_instance {
  max-width: 100%;
  position: relative;
}
