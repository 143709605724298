.BooleanSelectButtonGroup__root__yqMlI {
  display: flex;
  flex-direction: row;
  position: relative;
}
.BooleanSelectButtonGroup__root__yqMlI > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: calc(0px - 6px);
  width: calc(100% + 6px);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.BooleanSelectButtonGroup__root__yqMlI > .__wab_flex-container > *,
.BooleanSelectButtonGroup__root__yqMlI
  > .__wab_flex-container
  > .__wab_slot
  > *,
.BooleanSelectButtonGroup__root__yqMlI > .__wab_flex-container > picture > img,
.BooleanSelectButtonGroup__root__yqMlI
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 6px;
  margin-top: 12px;
}
.BooleanSelectButtonGroup__buttonGroup__gpndT.__wab_instance {
  max-width: 100%;
  position: relative;
}
.BooleanSelectButtonGroup__button__zkh52.__wab_instance {
  max-width: 100%;
}
.BooleanSelectButtonGroup__svg__igcdQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BooleanSelectButtonGroup__svg__jmr6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BooleanSelectButtonGroup__button___5Iarl.__wab_instance {
  max-width: 100%;
}
.BooleanSelectButtonGroup__svg__ktGvv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.BooleanSelectButtonGroup__freeBox___3GBhL {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
}
.BooleanSelectButtonGroup__svg__hqSe5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
