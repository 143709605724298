.CheckboxUsageAgreement__root__gHvwn.__wab_instance {
  position: relative;
}
.CheckboxUsageAgreement__text__wzXu {
  padding-right: 0px;
}
.CheckboxUsageAgreement__checkbox___47Wkc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CheckboxUsageAgreement__symbolCounter___5Knr8.__wab_instance {
  position: relative;
}
