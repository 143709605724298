.Dashboard__root___1MImY {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
.Dashboard__navigation__iYcdp.__wab_instance {
  position: relative;
}
@media (max-width: 480px) {
  .Dashboard__navigation__iYcdp.__wab_instance {
    position: relative;
  }
}
.Dashboard__freeBox__zSkFz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-76-ojDPNB2wcuq) var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBox__zSkFz > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__zSkFz > .__wab_flex-container > *,
.Dashboard__freeBox__zSkFz > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__zSkFz > .__wab_flex-container > picture > img,
.Dashboard__freeBox__zSkFz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__zSkFz {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__zSkFz {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px var(--token-7uzqW3y-2Tgb_s);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__zSkFz > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__zSkFz > .__wab_flex-container > *,
  .Dashboard__freeBox__zSkFz > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__zSkFz > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__zSkFz
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.Dashboard__bannerList__acuMh {
  display: flex;
  position: relative;
  flex-direction: column;
  height: auto;
  max-width: 1400px;
  width: 100%;
  min-width: 0;
}
.Dashboard__bannerList__acuMh > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.Dashboard__bannerList__acuMh > .__wab_flex-container > *,
.Dashboard__bannerList__acuMh > .__wab_flex-container > .__wab_slot > *,
.Dashboard__bannerList__acuMh > .__wab_flex-container > picture > img,
.Dashboard__bannerList__acuMh
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-7uzqW3y-2Tgb_s);
}
.Dashboard__freeBox__wy8Ml {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1400px;
  min-width: 0;
  display: none;
}
.Dashboard__freeBox__wy8Ml > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__wy8Ml > .__wab_flex-container > *,
.Dashboard__freeBox__wy8Ml > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__wy8Ml > .__wab_flex-container > picture > img,
.Dashboard__freeBox__wy8Ml
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBoxportfolioEnabled__wy8Mlb6BIv {
  display: flex;
}
.Dashboard__freeBox__weNOl {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.Dashboard__freeBox__tsKj6 {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-x_jRp3a5FpON8z);
  padding-right: var(--token-x_jRp3a5FpON8z);
  min-width: 0;
  display: none;
}
.Dashboard__freeBox__tsKj6 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.Dashboard__freeBox__tsKj6 > .__wab_flex-container > *,
.Dashboard__freeBox__tsKj6 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__tsKj6 > .__wab_flex-container > picture > img,
.Dashboard__freeBox__tsKj6
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
.Dashboard__tabItem__rwMpz.__wab_instance {
  position: relative;
}
.Dashboard__tabItem__wlcEh.__wab_instance {
  position: relative;
}
.Dashboard__text___42CXz {
  padding-top: 0px;
}
.Dashboard__freeBox__udNZ {
  background: var(--token-O8yxnKF8d);
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-x_jRp3a5FpON8z);
  border: 1px solid var(--token-O8yxnKF8d);
}
.Dashboard__freeBox__udNZ > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.Dashboard__freeBox__udNZ > .__wab_flex-container > *,
.Dashboard__freeBox__udNZ > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__udNZ > .__wab_flex-container > picture > img,
.Dashboard__freeBox__udNZ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__udNZ {
    padding: var(--token-TJik2L8OcSV6kD);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__udNZ {
    padding: var(--token-P3nFMsZz2JMXx8);
  }
}
.Dashboard__freeBox__uBbvn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__uBbvn > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.Dashboard__freeBox__uBbvn > .__wab_flex-container > *,
.Dashboard__freeBox__uBbvn > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__uBbvn > .__wab_flex-container > picture > img,
.Dashboard__freeBox__uBbvn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.Dashboard__freeBox__pguAz {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__pguAz > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__pguAz > .__wab_flex-container > *,
.Dashboard__freeBox__pguAz > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__pguAz > .__wab_flex-container > picture > img,
.Dashboard__freeBox__pguAz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__pguAz > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
    height: calc(100% + var(--token-P3nFMsZz2JMXx8));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__pguAz > .__wab_flex-container > *,
  .Dashboard__freeBox__pguAz > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__pguAz > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__pguAz
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: var(--token-P3nFMsZz2JMXx8);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__pguAz {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__pguAz > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
    height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__pguAz > .__wab_flex-container > *,
  .Dashboard__freeBox__pguAz > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__pguAz > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__pguAz
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-7uzqW3y-2Tgb_s);
  }
}
.Dashboard__freeBox__g7MrH {
  display: flex;
  position: relative;
  height: auto;
  width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__eUtFp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.Dashboard__slotTargetEsgPortfolioName__llDfP {
  font-size: var(--token-iRie9MHRquxJOV);
  color: var(--token--h6FHSrgUAQsDe);
  line-height: var(--token-lWY7kGeXV7C_yD);
}
@media (max-width: 480px) {
  .Dashboard__slotTargetEsgPortfolioName__llDfP {
    font-size: var(--token-JJM6LNeA-0eMML);
  }
}
.Dashboard__portfolioNameSubtitle__oeBf3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-lwXLVsVbV9nDvt);
  min-width: 0;
}
.Dashboard__freeBox___3Wx9B {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.Dashboard__freeBox___3Wx9B > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox___3Wx9B > .__wab_flex-container > *,
.Dashboard__freeBox___3Wx9B > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox___3Wx9B > .__wab_flex-container > picture > img,
.Dashboard__freeBox___3Wx9B
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox___3Wx9B > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    align-content: flex-start;
    margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
    height: calc(100% + var(--token-P3nFMsZz2JMXx8));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox___3Wx9B > .__wab_flex-container > *,
  .Dashboard__freeBox___3Wx9B > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox___3Wx9B > .__wab_flex-container > picture > img,
  .Dashboard__freeBox___3Wx9B
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: var(--token-P3nFMsZz2JMXx8);
  }
}
.Dashboard__button__mHsgv.__wab_instance {
  max-width: 100%;
}
.Dashboard__svg___5CUo0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__sANck {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__newPortfolioButton___38Pd.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__qQwfa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg___31GZn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__freeBox__euO13 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__euO13 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox__euO13 > .__wab_flex-container > *,
.Dashboard__freeBox__euO13 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__euO13 > .__wab_flex-container > picture > img,
.Dashboard__freeBox__euO13
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__euO13 {
    padding: 24px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__euO13 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 16px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__euO13 > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__euO13 > .__wab_flex-container > *,
  .Dashboard__freeBox__euO13 > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__euO13 > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__euO13
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 12px;
  }
}
.Dashboard__filter__ykL4F {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  z-index: 1;
  margin-bottom: var(--token-P3nFMsZz2JMXx8);
  min-width: 0;
  display: none;
}
.Dashboard__filter__ykL4F > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.Dashboard__filter__ykL4F > .__wab_flex-container > *,
.Dashboard__filter__ykL4F > .__wab_flex-container > .__wab_slot > *,
.Dashboard__filter__ykL4F > .__wab_flex-container > picture > img,
.Dashboard__filter__ykL4F
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__filter__ykL4F {
    padding: 0px 24px 24px;
  }
}
@media (max-width: 480px) {
  .Dashboard__filter__ykL4F {
    padding: 0px 16px 16px;
  }
}
@media (max-width: 480px) {
  .Dashboard__filter__ykL4F > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .Dashboard__filter__ykL4F > .__wab_flex-container > *,
  .Dashboard__filter__ykL4F > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__filter__ykL4F > .__wab_flex-container > picture > img,
  .Dashboard__filter__ykL4F
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: 12px;
  }
}
.Dashboard__freeBox__o531 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__o531 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox__o531 > .__wab_flex-container > *,
.Dashboard__freeBox__o531 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__o531 > .__wab_flex-container > picture > img,
.Dashboard__freeBox__o531
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
.Dashboard__text__aRiQ8 {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  height: auto;
  color: var(--token-L1EeQgMLm);
}
.Dashboard__freeBox__aSdHx {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.Dashboard__freeBox__aSdHx > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-u4z6Uru_YfAR62));
  width: calc(100% + var(--token-u4z6Uru_YfAR62));
}
.Dashboard__freeBox__aSdHx > .__wab_flex-container > *,
.Dashboard__freeBox__aSdHx > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__aSdHx > .__wab_flex-container > picture > img,
.Dashboard__freeBox__aSdHx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-u4z6Uru_YfAR62);
}
.Dashboard__filterButton__ww5MQ.__wab_instance {
  position: relative;
}
.Dashboard__filterButton__xUxP6.__wab_instance {
  position: relative;
}
.Dashboard__filterButton__loSh2.__wab_instance {
  position: relative;
}
.Dashboard__filterButton__wuVcw.__wab_instance {
  position: relative;
}
.Dashboard__freeBox__ierBo {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__ierBo {
    overflow: auto;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__ierBo {
    padding: 0px;
  }
}
.Dashboard__portfolioEntryList___4QDCy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioEntry__koh6Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioEntry__gDvy4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__freeBox__n7Iyq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1400px;
  min-width: 0;
}
.Dashboard__freeBox__n7Iyq > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__n7Iyq > .__wab_flex-container > *,
.Dashboard__freeBox__n7Iyq > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__n7Iyq > .__wab_flex-container > picture > img,
.Dashboard__freeBox__n7Iyq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBox__rfu0M {
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: var(--token-x_jRp3a5FpON8z);
  border: 1px solid var(--token-O8yxnKF8d);
}
.Dashboard__freeBox__rfu0M > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.Dashboard__freeBox__rfu0M > .__wab_flex-container > *,
.Dashboard__freeBox__rfu0M > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__rfu0M > .__wab_flex-container > picture > img,
.Dashboard__freeBox__rfu0M
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__rfu0M {
    padding: var(--token-TJik2L8OcSV6kD);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__rfu0M {
    padding: var(--token-P3nFMsZz2JMXx8);
  }
}
.Dashboard__freeBox__wQxWr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__wQxWr > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.Dashboard__freeBox__wQxWr > .__wab_flex-container > *,
.Dashboard__freeBox__wQxWr > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__wQxWr > .__wab_flex-container > picture > img,
.Dashboard__freeBox__wQxWr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.Dashboard__freeBox__wnX8Z {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__wnX8Z > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__wnX8Z > .__wab_flex-container > *,
.Dashboard__freeBox__wnX8Z > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__wnX8Z > .__wab_flex-container > picture > img,
.Dashboard__freeBox__wnX8Z
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
    height: calc(100% + var(--token-P3nFMsZz2JMXx8));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > *,
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__wnX8Z
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: var(--token-P3nFMsZz2JMXx8);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__wnX8Z {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
    height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > *,
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__wnX8Z > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__wnX8Z
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-7uzqW3y-2Tgb_s);
  }
}
.Dashboard__text__uiKKf {
  position: relative;
  font-size: var(--token-iRie9MHRquxJOV);
  height: auto;
  color: var(--token--h6FHSrgUAQsDe);
  padding-bottom: 0px;
  width: 100%;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .Dashboard__text__uiKKf {
    font-size: var(--token-JJM6LNeA-0eMML);
  }
}
.Dashboard__freeBox__vdp71 {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.Dashboard__freeBox__vdp71 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox__vdp71 > .__wab_flex-container > *,
.Dashboard__freeBox__vdp71 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__vdp71 > .__wab_flex-container > picture > img,
.Dashboard__freeBox__vdp71
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__vdp71 > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
    height: calc(100% + var(--token-P3nFMsZz2JMXx8));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__vdp71 > .__wab_flex-container > *,
  .Dashboard__freeBox__vdp71 > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__vdp71 > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__vdp71
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: var(--token-P3nFMsZz2JMXx8);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__vdp71 {
    width: 100%;
    overflow-x: visible;
    overflow-y: auto;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__vdp71 > .__wab_flex-container {
    justify-content: flex-start;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
.Dashboard__moreSelect__bFjhv.__wab_instance {
  max-width: 100%;
}
.Dashboard__svg__ucLp3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.Dashboard__button__cjWxB.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__r0S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__lkhav {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__option__f8GRc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__sbNYc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.Dashboard__svg__qYszR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__importButton__sviSj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__lb7Sl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__d2AcD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__esgQuestionnaireButton__rzGk6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__j8AGb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__vLrC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__button__rcTu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__i461N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__sWne {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__freeBox__n8Yn5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__n8Yn5 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox__n8Yn5 > .__wab_flex-container > *,
.Dashboard__freeBox__n8Yn5 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__n8Yn5 > .__wab_flex-container > picture > img,
.Dashboard__freeBox__n8Yn5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__n8Yn5 {
    padding: 24px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__n8Yn5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 16px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__n8Yn5 > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__n8Yn5 > .__wab_flex-container > *,
  .Dashboard__freeBox__n8Yn5 > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__n8Yn5 > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__n8Yn5
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 12px;
  }
}
.Dashboard__freeBox__gVynN {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__gVynN {
    overflow: auto;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__gVynN {
    padding: 0px;
  }
}
.Dashboard__freeBox__jnSpj {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__jnSpj {
    min-width: 1040px;
  }
}
.Dashboard__freeBox__mwsCc {
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-A-OXrd0RfsEv85);
  background: #183630;
  width: auto;
  left: 0px;
  top: 0px;
  z-index: 1;
  right: 0px;
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .Dashboard__freeBox__mwsCc {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.Dashboard__freeBox___7ZeWb {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 5;
}
.Dashboard__freeBox___7ZeWb > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.Dashboard__freeBox___7ZeWb > .__wab_flex-container > *,
.Dashboard__freeBox___7ZeWb > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox___7ZeWb > .__wab_flex-container > picture > img,
.Dashboard__freeBox___7ZeWb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
}
.Dashboard__text__uG4Jy {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__d5RyW {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.Dashboard__text__nceRr {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox___6Tk9Y {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__jxn2H {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text__ehf5 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__hF4Er {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__lOx1Y {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text__pp9D2 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__p8TFb {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__pn8RN {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text__c6HYg {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__snOh {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.Dashboard__text___5H0U2 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__vdE7G {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.Dashboard__freeBox__vdE7G > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__vdE7G > .__wab_flex-container > *,
.Dashboard__freeBox__vdE7G > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__vdE7G > .__wab_flex-container > picture > img,
.Dashboard__freeBox__vdE7G
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__ky5Lb {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.Dashboard__freeBox__t9PZ {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 3;
}
.Dashboard__freeBox__t9PZ > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__t9PZ > .__wab_flex-container > *,
.Dashboard__freeBox__t9PZ > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__t9PZ > .__wab_flex-container > picture > img,
.Dashboard__freeBox__t9PZ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__cvAbS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: right;
  min-width: 0;
}
.Dashboard__realtyList__wfXde {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__realtyList__wfXde > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__realtyList__wfXde > .__wab_flex-container > *,
.Dashboard__realtyList__wfXde > .__wab_flex-container > .__wab_slot > *,
.Dashboard__realtyList__wfXde > .__wab_flex-container > picture > img,
.Dashboard__realtyList__wfXde
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.Dashboard__dashboardEntry___8Ehzk.__wab_instance {
  position: relative;
}
.Dashboard__dashboardEntry___5KfrM.__wab_instance {
  position: relative;
}
.Dashboard__dashboardEntry___1QuAl.__wab_instance {
  position: relative;
}
.Dashboard__freeBox__jvtwn {
  display: block;
  position: relative;
  width: 60px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__jvtwn {
    display: block;
  }
}
.Dashboard__freeBox__tlEwz {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 80px;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #2c624c00 0%, #193630 71%) 0% 0% / 100%
    100% repeat;
  pointer-events: none;
  margin-right: -2px;
  z-index: 1;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__tlEwz {
    display: block;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__tlEwz {
    background: linear-gradient(90deg, #2c624c00 0%, #193630 83%) 0% 0% / 100%
      100% repeat;
  }
}
.Dashboard__archivedRealtiesCard__o9WB3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1400px;
  min-width: 0;
}
.Dashboard__archivedRealtiesCard__o9WB3 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__archivedRealtiesCard__o9WB3 > .__wab_flex-container > *,
.Dashboard__archivedRealtiesCard__o9WB3
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Dashboard__archivedRealtiesCard__o9WB3 > .__wab_flex-container > picture > img,
.Dashboard__archivedRealtiesCard__o9WB3
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.Dashboard__archivedRealtiesCardnoArchivedRealties__o9WB3YQKqn {
  display: none;
}
.Dashboard__freeBox__naOt {
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: var(--token-x_jRp3a5FpON8z);
  border: 1px solid var(--token-O8yxnKF8d);
}
.Dashboard__freeBox__naOt > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.Dashboard__freeBox__naOt > .__wab_flex-container > *,
.Dashboard__freeBox__naOt > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__naOt > .__wab_flex-container > picture > img,
.Dashboard__freeBox__naOt
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__naOt {
    padding: var(--token-TJik2L8OcSV6kD);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__naOt {
    padding: var(--token-P3nFMsZz2JMXx8);
  }
}
.Dashboard__freeBox___4BHea {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox___4BHea > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.Dashboard__freeBox___4BHea > .__wab_flex-container > *,
.Dashboard__freeBox___4BHea > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox___4BHea > .__wab_flex-container > picture > img,
.Dashboard__freeBox___4BHea
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 16px;
}
.Dashboard__freeBox__bDQeK {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__bDQeK > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__bDQeK > .__wab_flex-container > *,
.Dashboard__freeBox__bDQeK > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__bDQeK > .__wab_flex-container > picture > img,
.Dashboard__freeBox__bDQeK
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
@media (max-width: 480px) {
  .Dashboard__freeBox__bDQeK {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__bDQeK > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-7uzqW3y-2Tgb_s));
    height: calc(100% + var(--token-7uzqW3y-2Tgb_s));
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__bDQeK > .__wab_flex-container > *,
  .Dashboard__freeBox__bDQeK > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__bDQeK > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__bDQeK
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-7uzqW3y-2Tgb_s);
  }
}
.Dashboard__text___6HbkO {
  position: relative;
  font-size: var(--token-iRie9MHRquxJOV);
  height: auto;
  color: var(--token--h6FHSrgUAQsDe);
  padding-bottom: 0px;
  width: 100%;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .Dashboard__text___6HbkO {
    font-size: var(--token-JJM6LNeA-0eMML);
  }
}
.Dashboard__freeBox__w97Ah {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__freeBox__w97Ah > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__freeBox__w97Ah > .__wab_flex-container > *,
.Dashboard__freeBox__w97Ah > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__w97Ah > .__wab_flex-container > picture > img,
.Dashboard__freeBox__w97Ah
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__w97Ah {
    padding: 24px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__w97Ah {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 16px 0px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__w97Ah > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__w97Ah > .__wab_flex-container > *,
  .Dashboard__freeBox__w97Ah > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__w97Ah > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__w97Ah
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 12px;
  }
}
.Dashboard__freeBox__l12Ww {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__l12Ww {
    overflow: auto;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__l12Ww {
    padding: 0px;
  }
}
.Dashboard__freeBox__asz5X {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__asz5X {
    min-width: 1040px;
  }
}
.Dashboard__freeBox__wj70U {
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-A-OXrd0RfsEv85);
  background: #183630;
  width: auto;
  left: 0px;
  top: 0px;
  z-index: 1;
  right: 0px;
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .Dashboard__freeBox__wj70U {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.Dashboard__freeBox__q3MbW {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 5;
}
.Dashboard__freeBox__q3MbW > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.Dashboard__freeBox__q3MbW > .__wab_flex-container > *,
.Dashboard__freeBox__q3MbW > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__q3MbW > .__wab_flex-container > picture > img,
.Dashboard__freeBox__q3MbW
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
}
.Dashboard__text__g6FLz {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__n8HoK {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.Dashboard__text__k0GYc {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__g3Ng {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__he9Rz {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text___4Rgzp {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__kT94P {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__wedo0 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text__jKj6Q {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox___7MdSp {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text___6Iefz {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text___0X7Nz {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__esBw {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.Dashboard__text__tcwzf {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__gQlZv {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.Dashboard__freeBox__gQlZv > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__gQlZv > .__wab_flex-container > *,
.Dashboard__freeBox__gQlZv > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__gQlZv > .__wab_flex-container > picture > img,
.Dashboard__freeBox__gQlZv
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__fT26W {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.Dashboard__freeBox__oOyVr {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 3;
}
.Dashboard__freeBox__oOyVr > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__oOyVr > .__wab_flex-container > *,
.Dashboard__freeBox__oOyVr > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__oOyVr > .__wab_flex-container > picture > img,
.Dashboard__freeBox__oOyVr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__zRai4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: right;
  min-width: 0;
}
.Dashboard__archivedRealtyList__l3E13 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Dashboard__archivedRealtyList__l3E13 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.Dashboard__archivedRealtyList__l3E13 > .__wab_flex-container > *,
.Dashboard__archivedRealtyList__l3E13 > .__wab_flex-container > .__wab_slot > *,
.Dashboard__archivedRealtyList__l3E13 > .__wab_flex-container > picture > img,
.Dashboard__archivedRealtyList__l3E13
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.Dashboard__dashboardEntry__irsGu.__wab_instance {
  position: relative;
}
.Dashboard__dashboardEntry__woz0X.__wab_instance {
  position: relative;
}
.Dashboard__dashboardEntry__pQXy.__wab_instance {
  position: relative;
}
.Dashboard__freeBox__z3Ec9 {
  display: block;
  position: relative;
  width: 60px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__z3Ec9 {
    display: block;
  }
}
.Dashboard__freeBox__t9R9L {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 80px;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #2c624c00 0%, #193630 63%) 0% 0% / 100%
    100% repeat;
  pointer-events: none;
  margin-right: -2px;
  z-index: 1;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__t9R9L {
    display: block;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__t9R9L {
    max-width: 60px;
  }
}
.Dashboard__freeBox__jaALx {
  position: relative;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  display: none;
}
.Dashboard__freeBox__jaALx > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__jaALx > .__wab_flex-container > *,
.Dashboard__freeBox__jaALx > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__jaALx > .__wab_flex-container > picture > img,
.Dashboard__freeBox__jaALx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5 {
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: 100%;
  width: 100%;
  min-width: 0;
  display: flex;
  padding: var(--token-qecgvVQwVo7jFR);
}
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5 > .__wab_flex-container {
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5 > .__wab_flex-container > *,
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5
  > .__wab_flex-container
  > .__wab_slot
  > *,
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5
  > .__wab_flex-container
  > picture
  > img,
.Dashboard__freeBoxshowPortfolioModal__jaALxFOgp5
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.Dashboard__portfolioModal__xVw6A.__wab_instance.__wab_instance {
  max-width: auto;
  position: relative;
  z-index: 2;
}
.Dashboard__background2__qMvZ {
  display: block;
  position: fixed;
  width: auto;
  height: 100%;
  max-width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--token-hEzPFeV5Y);
  min-height: 0;
}
.Dashboard__freeBox__stxBa {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: var(--token-76-ojDPNB2wcuq) var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBox__stxBa > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__stxBa > .__wab_flex-container > *,
.Dashboard__freeBox__stxBa > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__stxBa > .__wab_flex-container > picture > img,
.Dashboard__freeBox__stxBa
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Dashboard__freeBox__stxBa {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__stxBa {
    position: relative;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
    padding: 40px 24px;
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__stxBa > .__wab_flex-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  .Dashboard__freeBox__stxBa > .__wab_flex-container > *,
  .Dashboard__freeBox__stxBa > .__wab_flex-container > .__wab_slot > *,
  .Dashboard__freeBox__stxBa > .__wab_flex-container > picture > img,
  .Dashboard__freeBox__stxBa
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.Dashboard__freeBoxshowPortfolioModal__stxBafOgp5 {
  display: none;
}
.Dashboard__freeBox__pau5W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1400px;
  min-width: 0;
}
.Dashboard__freeBox__pau5W > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
  height: calc(100% + var(--token-x_jRp3a5FpON8z));
}
.Dashboard__freeBox__pau5W > .__wab_flex-container > *,
.Dashboard__freeBox__pau5W > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__pau5W > .__wab_flex-container > picture > img,
.Dashboard__freeBox__pau5W
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-x_jRp3a5FpON8z);
}
.Dashboard__freeBox__j9177 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.Dashboard__modal__f2FOg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__formGroup__fU1Ev.__wab_instance {
  max-width: 400px;
  position: relative;
}
.Dashboard__textInput2__o9Ifj.__wab_instance {
  position: relative;
}
.Dashboard__svg__fOdQl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__svg__eB6Zi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__text__c8OzN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.Dashboard__text___6YmFg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.Dashboard__text__fP4Rw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.Dashboard__symbolCounter__wBdSg.__wab_instance {
  position: relative;
}
.Dashboard__freeBox___6B0Vy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-width: 0;
}
.Dashboard__freeBox___3FzE {
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-A-OXrd0RfsEv85);
  width: auto;
  left: -2px;
  top: 0px;
  z-index: 1;
  right: -2px;
  grid-column-gap: var(--token-P3nFMsZz2JMXx8);
  background: var(--token-IaDlhQPgR);
  margin-left: -2px;
  margin-right: -2px;
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .Dashboard__freeBox___3FzE {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.Dashboard__freeBox__rPx0G {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 6;
}
.Dashboard__freeBox__rPx0G > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__rPx0G > .__wab_flex-container > *,
.Dashboard__freeBox__rPx0G > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__rPx0G > .__wab_flex-container > picture > img,
.Dashboard__freeBox__rPx0G
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__yPeha {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox___5ZFGq {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__aSnsE {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text__fuqF3 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__w0B8Q {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 2;
}
.Dashboard__text__mPzh {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__text___9E7Bj {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__zdrc {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 4;
}
.Dashboard__text__oWvOd {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__bt0OK {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
}
.Dashboard__freeBox__bt0OK > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__bt0OK > .__wab_flex-container > *,
.Dashboard__freeBox__bt0OK > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__bt0OK > .__wab_flex-container > picture > img,
.Dashboard__freeBox__bt0OK
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__t7Wk {
  position: relative;
  height: auto;
  color: var(--token-31C7iZ0eD);
  font-size: var(--token-1lkMHpovBhH3r-);
}
.Dashboard__freeBox__spYpm {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-end: span 3;
}
.Dashboard__text__u5HL8 {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  min-width: 0;
}
.Dashboard__freeBox__xPqlN {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  grid-column-end: span 3;
}
.Dashboard__freeBox__xPqlN > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Dashboard__freeBox__xPqlN > .__wab_flex-container > *,
.Dashboard__freeBox__xPqlN > .__wab_flex-container > .__wab_slot > *,
.Dashboard__freeBox__xPqlN > .__wab_flex-container > picture > img,
.Dashboard__freeBox__xPqlN
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.Dashboard__text__bnJxi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-7xB3ITuN8);
  font-size: var(--token-1lkMHpovBhH3r-);
  text-align: right;
  min-width: 0;
}
.Dashboard__portfolioDetailRow__oZdrb.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__qrnXy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow___23Hwa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__roJpj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__rBhJs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__qi2K.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow___0H7.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__y6Z9Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__d7LH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__portfolioDetailRow__uPjOs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__freeBox__vmWrr {
  display: flex;
  position: sticky;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  height: auto;
  bottom: var(--token-TJik2L8OcSV6kD);
}
.Dashboard__button__cJfbP.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg__ytXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__text__tNj3X {
  height: auto;
  padding-right: 0px;
}
.Dashboard__svg__hPfhF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__freeBox__h9Gd6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: 44px;
  max-width: 100%;
}
.Dashboard__button__k8YWo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__svg___8VhNt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__text__eD03Y {
  height: auto;
  padding-right: 0px;
}
.Dashboard__svg__a7Wk6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Dashboard__footer__pzwBp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Dashboard__freeBox__boMjY {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding-bottom: var(--token-qecgvVQwVo7jFR);
  padding-top: var(--token-qecgvVQwVo7jFR);
  min-height: 100vh;
  overflow: auto;
  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
  bottom: 0px;
  right: 0px;
}
.Dashboard__freeBoxshowImportModal__boMjYFtNgF {
  display: flex;
}
.Dashboard__freeBoxshowPortfolioModal__boMjYfOgp5 {
  display: none;
}
.Dashboard__background___7W7L3 {
  display: block;
  position: fixed;
  width: auto;
  height: 100%;
  max-width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  background: var(--token-hEzPFeV5Y);
  min-height: 0;
}
.Dashboard__importModal__sH3Cb.__wab_instance.__wab_instance {
  position: fixed;
  z-index: 2;
  left: auto;
  top: 10%;
  right: auto;
}
.Dashboard__svg__zQXp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
