.NavLink__navLink__o899C {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  padding: var(--token-A-OXrd0RfsEv85);
}
.NavLink__navLink__o899C > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  height: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.NavLink__navLink__o899C > .__wab_flex-container > *,
.NavLink__navLink__o899C > .__wab_flex-container > .__wab_slot > *,
.NavLink__navLink__o899C > .__wab_flex-container > picture > img,
.NavLink__navLink__o899C > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-a0wt3E9LJjO_Nh);
}
.NavLink__freeBox__ccnYa {
  display: flex;
  position: relative;
  flex-direction: row;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  box-shadow: 0px 0px 0px 0px var(--token-6_9iE0w_5TGI8j);
  -webkit-transition-property: box-shadow;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.NavLink__freeBox__ccnYa > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.NavLink__freeBox__ccnYa > .__wab_flex-container > *,
.NavLink__freeBox__ccnYa > .__wab_flex-container > .__wab_slot > *,
.NavLink__freeBox__ccnYa > .__wab_flex-container > picture > img,
.NavLink__freeBox__ccnYa > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.NavLink__freeBoxisActive__ccnYa6QYsf {
  box-shadow: 0px 2px 0px 0px var(--token-6_9iE0w_5TGI8j);
}
.NavLink__navLink__o899C:hover .NavLink__freeBox__ccnYa {
  box-shadow: 0px 2px 0px 0px var(--token-6_9iE0w_5TGI8j);
}
.NavLink__slotTargetIcon__fuuRb {
  color: var(--token-NfSc1CXMklYEK2);
}
.NavLink__slotTargetIcon__fuuRb > *,
.NavLink__slotTargetIcon__fuuRb > .__wab_slot > *,
.NavLink__slotTargetIcon__fuuRb > .__wab_slot > .__wab_slot > *,
.NavLink__slotTargetIcon__fuuRb > .__wab_slot > .__wab_slot > .__wab_slot > *,
.NavLink__slotTargetIcon__fuuRb > picture > img,
.NavLink__slotTargetIcon__fuuRb > .__wab_slot > picture > img,
.NavLink__slotTargetIcon__fuuRb > .__wab_slot > .__wab_slot > picture > img,
.NavLink__slotTargetIcon__fuuRb
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.NavLink__slotTargetIconisActive__fuuRb6QYsf {
  color: var(--token-6_9iE0w_5TGI8j);
}
.NavLink__navLink__o899C:hover .NavLink__slotTargetIcon__fuuRb {
  color: var(--token-6_9iE0w_5TGI8j);
}
.NavLink__svg__d6DmH {
  object-fit: cover;
  width: auto;
  height: 1em;
}
.NavLink__freeBox__zpMf8 {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: row;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.NavLink__slotTargetText__yuBhs {
  font-weight: 500;
  color: var(--token-QI60SWB06gBJ5K);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
}
.NavLink__slotTargetText__yuBhs > *,
.NavLink__slotTargetText__yuBhs > .__wab_slot > *,
.NavLink__slotTargetText__yuBhs > .__wab_slot > .__wab_slot > *,
.NavLink__slotTargetText__yuBhs > .__wab_slot > .__wab_slot > .__wab_slot > *,
.NavLink__slotTargetText__yuBhs > picture > img,
.NavLink__slotTargetText__yuBhs > .__wab_slot > picture > img,
.NavLink__slotTargetText__yuBhs > .__wab_slot > .__wab_slot > picture > img,
.NavLink__slotTargetText__yuBhs
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: color;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
}
.NavLink__slotTargetTextisActive__yuBhs6QYsf {
  color: var(--token-plIc-sUNu2-k5-);
}
.NavLink__navLink__o899C:hover .NavLink__slotTargetText__yuBhs {
  color: var(--token-plIc-sUNu2-k5-);
}
