.FutureEnergySubCategory__root___7KUzd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__formGroup__zm9St.__wab_instance {
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__formGroupisDisabled__zm9STjhBdo.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__f6Fw7 {
  height: auto;
}
.FutureEnergySubCategory__select__hFUxi.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__wZlup {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__pOa40.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup___3GjUc.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup___3GjUc.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_gas___3GjUcKr1F1.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__fAr1 {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrGasSubk__askeK.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__bvjtn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__jZu7.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__lqXWm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__hKqJy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__sRMx.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__bu9Zs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__jJ6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__qDqf1.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__dFnCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__b49A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__we94K.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__k9Ub {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__eCjfi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___6YVhr.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__ebr2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__rIn1X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__scvh7.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__cSe2H.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__cSe2H.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_oel__cSe2HlAj8H.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__x77Hw {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrOelSubk__koe3J.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__fEq9X {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option___1NjEp.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__oqzgi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__n4S1W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter___2Vzp.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__xjhew.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__xjhew.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_fernwaerme__xjhewL4RoH.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__dlldR {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrFwaeSubk__htyDn.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__lzlDw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__blBsy.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__rrxIq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___6CVrj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__dmGk4.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__kdCTl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__gFyjm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___5RUbv.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__yU8Yd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__jbMiy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__hTzYv.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__bo2Rs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__szgEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__oKrXe.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__fTFiT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__mEyxY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__eOrAx.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__sYdy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__rPkqf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__efJz6.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__aDd44 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__s7Yh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__qPnGe.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__teiKo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___8LSzG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__e1EMs.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__tlc1N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__uTbTs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__eeIMj.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__jTnTk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__t7Gqc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__hqh7Z.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__w5Bpn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__beAtZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__xf9K.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___81XuW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__ePdTw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__cmL7.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___2Wpcr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__jBeRi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___0EQdr.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__aGdQ9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__oyghG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__y9Z5I.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__leCmp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__eoFoE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__uK0Wz.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__jMfh4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__r4YWv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__iiGue.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__pxl5W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__x9JnM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__nUu.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__pUyu5.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__pUyu5.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_waermepumpe__pUyu5NpBx.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__hUqWh {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrWpSubk__d66.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__p54Dh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option___8Z7J.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___1KLY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__hjcnq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__aEqS9.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___7XZoH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__uc30U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__dj2Dq.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__cd9Zf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__sM3Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__mrv77.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__t7Yo9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___7NsLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__lRrFw.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___2Dthl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__rtPeo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__doTq5.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__m9Tl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__utscg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__xk31F.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__xhA3E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__vuPyu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__iQ4H6.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__fjdUl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__pBidC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__olo1N.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__spPrx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___5Tv2S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter___0Fd3T.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup___728Ke.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup___728Ke.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_holz___728KetOKe.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__xnf {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrHlzSubk__ojyMv.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__nrtRz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__vFQqX.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___42XkC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__sjcvf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__kSdMg.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__rn38J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__fhd5G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__cqRiV.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__qqgaf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__c4Hts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__lPu07.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__uOh5Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__ndcir {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__aL4I.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__kqF6R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___50O3B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__qr0N2.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__nNkjz.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__nNkjz.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_solarthermie__nNkjzfTyV.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__sO6Ly {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrSoltSubk__twv4V.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__gSfB {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__fHgaY.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__cvPr3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___2C96G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__iNvfa.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__yzFqe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__mDrcY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__vdeSo.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__glAl7.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__glAl7.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_elektroheizung__glAl7J7Ztp.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__gY8X {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrElekhzSubk__fJNg.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__aXz9R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option___99ZqO.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__qqCdc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__prTrh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__swR.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___9AJ8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__nu1CQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__iRbyf.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___14Iq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__ygZ8S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__bke5X.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__s8147 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__xGjU6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__dpDlT.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___7PU2O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__hCp3S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__p0PjR.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__tdnZn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__igHZp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__puzO.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__vJdMv.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__vJdMv.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_weitere__vJdMvm1SVp.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__njr4V {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrWeitrSubk__qlhoq.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__vvQnY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__uzs2E.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__zRGiw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__wibYo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__sp9ET.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___1Lsgu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__fX893 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__i4Bry.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__ohF6Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__jppIb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__klBYj.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__jqrOv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__oBjfm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__yh988.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__ssKqw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__qEzJf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__xtKd9.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__fiYrS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__tKbhh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__ierB.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___47UyT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__a9TB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__spysa.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___3OtRo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__dxil9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___0XOU.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__gHeDl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__mKOjY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__sfUS.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__rD0I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__oUrej {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__bmN2R.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__u3F5K.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__u3F5K.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_unbekannt__u3F5K7Frc2.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__b8Ib {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrUnbeknSubk__poCux.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__aiSwQ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option___8Nyoi.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__vwSt5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__gHUk1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__kYbh.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__l5Qs5.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__l5Qs5.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_kein__l5Qs5JiSXt.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__atTdd {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrUnbeknSubk2__fQidP.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__jK3Sl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option___65Six.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__mAs00 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__raj1T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__hlWnJ.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__iTwU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__oev20 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__k4V2X.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__fNbFw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__msSqr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__q49Qh.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__hbcNb.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__hbcNb.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_stromEigentuemer__hbcNb9CLl1.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text___9GoWn {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrStrmegtKat___6Vi3V.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__lwFxi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__n0WQ.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__kBcwS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__sQv1D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__uVwlm.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__qFT7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___7WU1I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__i9Web.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___0Lp1Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___0Fdi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__nkhA7.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__mUFsg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__ih6T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__ly2B.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___3JzFv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___5CuP3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___6CP1R.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__gf9K7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__sylM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__qs2Mi.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__tavXn.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__tavXn.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_stromMieter__tavXnegzrT.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__tWWe7 {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrStrmietKat__osQ1Q.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__osIcZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__i0QhP.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__jbnuC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___6VKrM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__rFu8B.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg___8PWqJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__eywJq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__n7ZvT.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__lr08R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__wids3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__oopz3.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__cwqla {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__q1ExF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__mpEeL.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__hP6K6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___3ME50 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option___8Y3Ce.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__gwe9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__fOjfy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__xMqnq.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__formGroup__dIuJa.__wab_instance {
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  .FutureEnergySubCategory__formGroup__dIuJa.__wab_instance {
    width: 100%;
  }
}
.FutureEnergySubCategory__formGroupenergyType_photovoltaik__dIuJa1A5M.__wab_instance {
  display: flex;
}
.FutureEnergySubCategory__text__aj4Rz {
  padding-right: 0px;
}
.FutureEnergySubCategory__enrEntrPvstromKat__d9Gly.__wab_instance {
  position: relative;
  flex-shrink: 0;
  max-width: none;
  width: 100%;
  min-width: 0;
}
.FutureEnergySubCategory__svg__jEaWy {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.FutureEnergySubCategory__option__dw0L0.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__jXedz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg__lQwQ5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__option__kr2O5.__wab_instance {
  position: relative;
}
.FutureEnergySubCategory__svg__xjNvk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.FutureEnergySubCategory__svg___8FfmA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.FutureEnergySubCategory__symbolCounter__sXeQl.__wab_instance {
  position: relative;
}
