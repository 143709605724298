.PortfolioQuestionCard__root__hsovw {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-TJik2L8OcSV6kD);
  border: 1px solid var(--token-O8yxnKF8d);
}
.PortfolioQuestionCard__root__hsovw > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.PortfolioQuestionCard__root__hsovw > .__wab_flex-container > *,
.PortfolioQuestionCard__root__hsovw > .__wab_flex-container > .__wab_slot > *,
.PortfolioQuestionCard__root__hsovw > .__wab_flex-container > picture > img,
.PortfolioQuestionCard__root__hsovw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.PortfolioQuestionCard__freeBox__eDoG {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PortfolioQuestionCard__freeBox__eDoG > .__wab_flex-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.PortfolioQuestionCard__freeBox__eDoG > .__wab_flex-container > *,
.PortfolioQuestionCard__freeBox__eDoG > .__wab_flex-container > .__wab_slot > *,
.PortfolioQuestionCard__freeBox__eDoG > .__wab_flex-container > picture > img,
.PortfolioQuestionCard__freeBox__eDoG
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
.PortfolioQuestionCard__freeBox__aFp97 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.PortfolioQuestionCard__freeBox__aFp97 > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.PortfolioQuestionCard__freeBox__aFp97 > .__wab_flex-container > *,
.PortfolioQuestionCard__freeBox__aFp97
  > .__wab_flex-container
  > .__wab_slot
  > *,
.PortfolioQuestionCard__freeBox__aFp97 > .__wab_flex-container > picture > img,
.PortfolioQuestionCard__freeBox__aFp97
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
.PortfolioQuestionCard__text__rpz0 {
  padding-right: 0px;
  height: auto;
}
.PortfolioQuestionCard__checkbox4__qxmO.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
}
.PortfolioQuestionCard__text__pJocl {
  padding-top: 0px;
}
.PortfolioQuestionCard__symbolCounter__w19Np.__wab_instance {
  position: relative;
}
.PortfolioQuestionCard__formGroup__dZVdk.__wab_instance {
  position: relative;
  width: 100%;
  min-width: 0;
  display: none;
}
.PortfolioQuestionCard__formGroupisChecked__dZVdkxnApw.__wab_instance {
  display: flex;
}
.PortfolioQuestionCard__text__n7DCl {
  padding-right: 0px;
  height: auto;
}
.PortfolioQuestionCard__textInput67__y1Hnx.__wab_instance {
  width: 100%;
  min-width: 0;
}
.PortfolioQuestionCard__svg__k5Oqt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioQuestionCard__svg__bIa48 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioQuestionCard__text__u1D0M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PortfolioQuestionCard__text__ibfnQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioQuestionCard__text__tqDX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioQuestionCard__symbolCounter___0JXsW.__wab_instance {
  position: relative;
}
.PortfolioQuestionCard__svg__vn698 {
  object-fit: cover;
  color: var(--token-QI60SWB06gBJ5K);
  width: var(--token-YOOgp3GSpDYosY);
  height: var(--token-YOOgp3GSpDYosY);
  flex-shrink: 0;
}
