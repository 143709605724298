.EsgQuestionnaire__root___9QnUo {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
.EsgQuestionnaire__processHeader___3Hdv2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__processBreadCrumb__ta9Gm.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
.EsgQuestionnaire__freeBox__fXrRv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__fXrRv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__fXrRv {
    display: flex;
    flex-direction: column;
  }
}
.EsgQuestionnaire__scrollNav__pljls.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__scrollNavGroup__xvjS.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__navButton__i9CjH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__text__pG6JH {
  height: auto;
}
.EsgQuestionnaire__svg__ueX6Y {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.EsgQuestionnaire__navButton___1FPa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__text__ukTI {
  height: auto;
}
.EsgQuestionnaire__svg__sxL1F {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.EsgQuestionnaire__navButton___4VcaM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__text__hrqFb {
  height: auto;
}
.EsgQuestionnaire__svg___8Xym {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.EsgQuestionnaire__navButton__unPas.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__text___4GZbA {
  height: auto;
}
.EsgQuestionnaire__svg__fxxg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.EsgQuestionnaire__processPageContent__lKhpz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox__ngZy8 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
}
.EsgQuestionnaire__freeBox__ngZy8 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.EsgQuestionnaire__freeBox__ngZy8 > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__ngZy8 > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__ngZy8 > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__ngZy8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__ngZy8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__ngZy8 {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.EsgQuestionnaire__freeBox__fyK40 {
  background: var(--token-O8yxnKF8d);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: var(--token-7uzqW3y-2Tgb_s) var(--token-7uzqW3y-2Tgb_s)
    var(--token-7uzqW3y-2Tgb_s) var(--token-P3nFMsZz2JMXx8);
  border: 1px solid var(--token-O8yxnKF8d);
}
.EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__fyK40
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__fyK40 {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > *,
  .EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > .__wab_slot > *,
  .EsgQuestionnaire__freeBox__fyK40 > .__wab_flex-container > picture > img,
  .EsgQuestionnaire__freeBox__fyK40
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.EsgQuestionnaire__text__fbmlg {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__freeBox__saKMb {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.EsgQuestionnaire__freeBox__saKMb > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.EsgQuestionnaire__freeBox__saKMb > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__saKMb > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__saKMb > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__saKMb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.EsgQuestionnaire__svg__jAVc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__noIJs {
  height: auto;
}
.EsgQuestionnaire__svg__uHe4T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__freeBox__sgAaQ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__sgAaQ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__sgAaQ {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > *,
  .EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > .__wab_slot > *,
  .EsgQuestionnaire__freeBox__sgAaQ > .__wab_flex-container > picture > img,
  .EsgQuestionnaire__freeBox__sgAaQ
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.EsgQuestionnaire__text__x1A1U {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__freeBox__m2ISq {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.EsgQuestionnaire__freeBox__m2ISq > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
.EsgQuestionnaire__freeBox__m2ISq > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__m2ISq > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__m2ISq > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__m2ISq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
.EsgQuestionnaire__text__bKhob {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__text__hziY0 {
  position: relative;
  height: auto;
  color: var(--token-HHAuBKYL3pvmHz);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__freeBox__e9OVg {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.EsgQuestionnaire__freeBox__e9OVg > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
.EsgQuestionnaire__freeBox__e9OVg > .__wab_flex-container > *,
.EsgQuestionnaire__freeBox__e9OVg > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionnaire__freeBox__e9OVg > .__wab_flex-container > picture > img,
.EsgQuestionnaire__freeBox__e9OVg
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
.EsgQuestionnaire__text___8Igi {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__text__c8D4R {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.EsgQuestionnaire__processSection__f33Cz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__processCard__cKonn.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCardSection__lK7C5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox___7Hrr7 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__qsvqH.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  width: 387px;
}
.EsgQuestionnaire__textInput___3Epp.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___201A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___89X4Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__w723O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__bpMuz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__nvuVe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__pkivr.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__hRyql.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.EsgQuestionnaire__textInput3___0BEfg.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__mrSvk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__bsKIb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__qcMp8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__wckUm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__tXbuP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__sWMdD.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__obkWl.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.EsgQuestionnaire__text__bd1JS {
  height: auto;
}
.EsgQuestionnaire__textInput2__ad2WE.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__dlduT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__gsgZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__qrfKe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__cdztd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__oPWp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__aK50U.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___4YRhb.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.EsgQuestionnaire__text__y1H2T {
  height: auto;
}
.EsgQuestionnaire__textInput4__qm7Ti.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__pkj02 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__btpfe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__ttNc4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__upFhe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__mwoP1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__rObp.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__chuBf.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.EsgQuestionnaire__text__vt5Gq {
  height: auto;
}
.EsgQuestionnaire__textInput7__l76J3.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__sz8Fq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___4G3Lb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__uWne {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__uZzQq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__v9Pf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__gfaL.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__ezl6V.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
.EsgQuestionnaire__text__o3Obv {
  height: auto;
}
.EsgQuestionnaire__textInput6___6Cp0J.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__yERx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__irhfy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___7YbXc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__qqlhU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__fOc0B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__qGgvw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCardSection__ewYaV.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox__xk2Sq {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__xk2Sq {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__xk2Sq {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
.EsgQuestionnaire__esgQuestionGroup__xEmae.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__nkQby {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup___8Iu2J.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
  width: 526px;
}
.EsgQuestionnaire__esgQuestFr11___7MDrd.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__dj9Ft {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__aUHiJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__hjWhF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__nuGiy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__o2Y1F.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qbzKh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3Udt3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vZwjJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__aJa8X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dRjys {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__c9Bml.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__dnWnI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__bQfOf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__rYeLf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__zyY8F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__sKd3C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter___1ZCyb.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__mo7Bm.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__njTaU {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput113__tl3Ye.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___9ZA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__gL8H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___084Gn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__akWis {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__nPtDx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__qa4Gn.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__text__ux2TO {
  padding-bottom: 0px;
}
.EsgQuestionnaire__esgQuestionGroup__kuxVl.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__flBlm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__u3UjW.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__singleSelectButtonGroup__uu3Xu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__sPQms.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__lo6Rl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__tv2Oo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__yLIpa.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__yYs4U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__rNDq1 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__uYxWk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__sBbvJ.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__rnbYe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__bIlBi {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__yz48A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__tWcGb.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__yoqz7.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__pIu7 {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput114__pPzxr.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__x8QZ5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__mBmx6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__mq7Hg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__irEdf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__uPtAo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__iv86E.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__text__yUcXb {
  padding-bottom: 0px;
}
.EsgQuestionnaire__esgQuestionGroup__vdYrt.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox___2PmZs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__c64N.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__yExgf {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr31__fJg74.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__y70O {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__vqPyr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gs74P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__exYb9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ealJ8.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__lNbz3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__w8D8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lJpHi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kwky5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___64Nnc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__utfy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__u4RQs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__hjvZo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__i4PM1.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qtQnW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___06J1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__kcDr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__asehu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__yc06T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___4Qxik.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__oyGVr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__c9JrK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__jfUqy.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__mgl71.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__yYmYc {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput115__dG1CK.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__vlLx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___4Zs5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__ywFg2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__oAPbx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__gaGiS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter___7YJqK.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup___0RQsx.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__qhZbT {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup___8WmRt.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr41__xJio0.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__ihpuK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__gjAEw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qjRmc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___5Kc3S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bZt4D.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___9QegC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__nV5U1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__oDhxk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ac3Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zykR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qE6Nu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__dAjGd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__i3DVm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vcIxJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__lovci {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qGj7Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__nnY7T.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__pIebA.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr42__v5Gq0.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__qG4Ee {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__byffe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pzrji {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uiyBy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ffJIo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__dyI0A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jzV5G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__jM0Jz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__q8YVm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gHi6T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__hRfut.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gzCix {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uYaA7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mpsf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tUXy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___6Yp8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__zp6Ne.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___1X3Ig.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text___5NkD {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput116__rtWbn.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__vkWpG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__rjKi9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__odVzA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__rbhF9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__czx4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__ofgJg.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__kJy2Z.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__textInput44___2Ab0O.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__uIsi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__textInput72__rshOb.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.EsgQuestionnaire__svg__jpiVh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__j7HOz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__rmT1J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___8VnsI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__nPwys {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__c2Bbz.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCard__qaiCu.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCardSection___9AW5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox__ckin5 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__ckin5 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__ckin5 {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
.EsgQuestionnaire__esgQuestionGroup___7YLi0.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__kESgg {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__tRoEb.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__jJcuP {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput121__oz246.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___3QhF2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__oc1Sf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__tmrwb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__mYQt1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__f8Tm0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__il2Tq.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__gmKr4.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text___1UkmS {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr52__kioww.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__aNhnv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__gdADc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__s7Hpa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zwcie {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lgb2F.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__q2Af {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__tMejy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vmjz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bTl2G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zwFpa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__klpig.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qJDoL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3DDox {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__yR5BG.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__hKki.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr53__b7Yiw.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__oyOn3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__st6Wi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__eqx8N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__v06XU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lkwqM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___1CN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__j4Ctr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__gRsev.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___2GLfN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___7WHpj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vLdex.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ze4A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jSTnp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__s325I.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___19W5R.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__uyQrm {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__textInput117__lTIeR.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__hHrvL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__qAtw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__z56Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__w90N4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__m2FNi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter___2SnRm.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__jZwC.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.EsgQuestionnaire__esgQuestFr55__lxSzA.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__j1Tg0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__yaCf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ghuwm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__b3Rwp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__d3Lwg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ixmw0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___7JbKd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mGHav.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mEp0W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___7WvHu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1XOqV.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__h7Xxv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zTheD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter___2FK3J.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__n7Mqp.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr56___4MqAk.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__drjWq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__dElIi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__aM21L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__nrJol {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zs5Ox.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__dwqvt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___82IBt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__o8GZe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__u5Rf5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___0FlyX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bOseW.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__sf8Ur {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__sN5B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zPDc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__wQMqY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__rVnF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__gA6W.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__hqliX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___7CpOz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1JwS.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__uFJa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___5I1Zp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__cbMj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tTwaF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__xfkvj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__pqHes.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___9Vq3Y.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__tTeIg {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput122__lYtC.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__zhdKj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__wbiWx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__zk4Os {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__swRta {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text___9E56 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__adPpp.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__s6Pr8.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox___8GrBt {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__mtYEv.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__ji76Y {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__textInput123__qObZd.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__hmrUb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___2BojT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__ksQip {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__eTxDp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__cRbZj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__dhGe3.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__h8MLb.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text___926M {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr62__waKf.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__sc0UO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__c5QJz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__nc3A9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zFjwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__yvCif.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___7VuzV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3VPmf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__v7IEi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__o9Xtb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__e312D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__sgtr7.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__xkBg6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3240G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__fG0C.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__viHxW.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr63___5Bpuo.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__xE1Px {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__jmfY6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__wgHgh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3DnAo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__s8EeV.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__apB7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__yOiU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__rO5KZ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jimdj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jdYBh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nDpx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___2EKh5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ya5Ar {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__jVtvg.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___86Pxh.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__x4JlL {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__textInput124___7ZoGo.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__so2FJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__tkrHq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__k56H9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__mdYrh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__zCrHv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__exull.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__cxulC.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr65__cvoMx.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___1Fis8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___4Hh6X.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bexHm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__n7TXl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__jfLs3.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___8UhXj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__nSaI0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ojKtQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__s6Njy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fbNp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__cpl2H.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__cNhr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aDskl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__iUocN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__wOjx5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___4Pjl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qjdRx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__oCbzO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__rxtu5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___13Gsn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__x4Ok {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___9I3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__oYc8S.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___9ZaBr.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
.EsgQuestionnaire__text__w1V9 {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput125__lykiB.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__fhYrP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__sCE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___8IaW6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__z3LhG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text___0MZ4F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__kpIwP.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__xsggQ.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr67__vkDke.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__cdOyT {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___5381.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___49Ueu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__pTx1F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fjCfQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__nCez0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__v66Vf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__tfcW.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gZBg6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fjpSv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ydsDu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bdbqo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ksr31 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vb7Hx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___1EgfM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aH8Ck {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___6O8QE.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___4BjIe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qPtVv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xFmvp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___4Au2T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__yzUTv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__j2Hx1.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__ivo0W.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__esgQuestFr68__kafbR.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__edS6N {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___2HyEy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___2BWr8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fGsa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___2DKtt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__byLa9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__g6XSf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pyrt9.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__w5MdJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___5YegA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__iw7P.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tynCc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3Or6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__rjWow.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__wssC4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ibYiG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zrTbx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__hzp2B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___1Xll {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__aYhlt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__rfX9R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__iuFjj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mSVdN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__txlEp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ed4CD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__jXlU6.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__rgPzt.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__dwvK6 {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput126__n3KtR.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__n3Ze {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__uCt0T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__xVrf8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___9QtV0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__zzOuu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__kjLox.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup___6Q1Fg.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__zAOuX {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__xhpG8.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__text__ouD6 {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr71__hxReS.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__dtN9P {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__cPm3Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gNf0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___9Liar {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__sEtJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gzrxv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__kegQm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nAs4O.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__vqWjy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qNni6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nDtsS.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__yaPk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__xCur {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1XuOd.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__y0Lzf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___4SFlr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__qsba7.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__jH3Ja.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr72__vvX65.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__uScFt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__n1Gp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jgPo8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__eCxRd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__oNxaT.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__hwDEt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__e0Dbe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__x9T9M.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pqwwh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__iBenc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qFmgy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jEIxn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qGsbl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__kddgh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___9H4S5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___6Ez7T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__j4QDy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__zeE4C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__tAtki {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__j3Exo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mwPsD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vJ937 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__z0Ars.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__soq9X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gsLwe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter___84Sor.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__kdIcR.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__wNjOn {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput118__sLjVw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__bFKg4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__bf2Ns {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__l92I6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___2NtT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__ntaQq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__bP0OM.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__z7Bv9.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__xdiO {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__j7Lho.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr81__dwLho.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__iVta {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__yhvf4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__vxyYe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__tJaVd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zOhlh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__i6UOe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vt01D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___4K3Oh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__wFz2L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aXFvA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vFoF.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__klevx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___0B40D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__juFwy.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___0ENet {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___5JJjl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__t2CcU.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___8Tsf.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
.EsgQuestionnaire__text___5Wvs {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__textInput127__yalxt.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___9Sdcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__ikA53 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__mZuRo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__qZ9Oh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__iQvDq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__jiGtP.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__cz7Ti.__wab_instance {
  grid-column-end: span 3;
}
.EsgQuestionnaire__text___4Td9W {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__checkbox__f3Dlm.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__symbolCounter__aUuUs.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__j2IuT.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
.EsgQuestionnaire__esgQuestFr84__w8Iu8.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__q8QSr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__gfopv.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__xgUbt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dyRdF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__cKrgn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kVzia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__blq1Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__tPqgD.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__xb9Dk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uPMxg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__dSllc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___8K0Ac {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___4Uqof {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__urf0W.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__z6BT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__nBsiF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ozNsi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mdi3Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___31OF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__aSUee.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__o0Uw3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fDDbU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xFU6.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__yl9I7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___0AJdQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__fYu.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__gZBaT.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__fYUqJ {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput119__bnO5J.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__mkriI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__deynq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__tXjjS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___2WPjf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__voE85 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__v3Qaw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__bvfQb.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__rL1J0 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup___8PnQr.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__kenj {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr91__tXfJ.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__s3P4G {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__rwsGl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__b0GiV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vx6Sh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bigRu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__oMyx4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__x0Vqe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lqTTj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pwetS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___0ORoC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fC0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tv76P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__kNQp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ldXSu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__o2VuS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__mSO6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__kZi8L.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__uezRg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qyBo4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__h2VqI.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___3Vhws.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr92___99Yr7.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___2JPnd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___6UcbG.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ok8Ia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jX7Dk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___4561J.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__cDsZ4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__l59O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__x4VmV.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___2QgQj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__juAtJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pmuAa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__j9Te {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dZ3Sv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__t8Qni.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__fee4H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dceux {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fy59A.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kV3Eq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__cKz1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qDzca.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__rxWau {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___8XkNn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qJHay.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__hLpHf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ynfK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__c8Ou.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__hIGgB.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__qHjnv {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput120__odLfX.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__g73D7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__fdZha {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__iatEl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__g97IY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__g0AXr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__bluwy.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__cExz5.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__zGCiO {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__eeuR1.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__byiiv {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr101___1RvZm.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__oFWw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__cy8Xh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tsh8Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__voAx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mqRh4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__scg3T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jve69 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__h1Xhx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___6CuJ6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uU3W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___5Jw9I.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__lA9P9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___362P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__hWx4A.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___65X6Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__pBxnx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__k5Qqu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bLu0W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__x9RwU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__apN6Y.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__dtav3.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr102__qMdwd.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__b9WR6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__ryuoI.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__uQgQo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ljhpm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ajEj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gzVDf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__f0Dzu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mf3Ei.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pK0Yg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__eiVqN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mlz9T.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__zl89S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jec2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___3AK6G.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gxv8G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ii4Ld {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pn85B.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pwFo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__bF6Ka {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qj1Jf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___196Vs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___8CHq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qtfj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__w6Ob {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zjZAh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__uw8G8.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___4XdL.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__fdsYb {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput128__fukdr.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__n6SEb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__xoOl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__hxB7W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__yptHc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__jv5N5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter___3M5Pw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__uMq5Z.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__mhivu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__uwz6W.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__xwab6 {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr111__te87T.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___1Pi5G {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__ze6Qt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gvE56 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dHtts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__l33L3.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__o9Lx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__d9JoO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fyzds.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__p0E8K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gg3Vj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__gIqK.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__f9VZd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___6VuFc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__iyCiy.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__dGjsf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text___7JmHr {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput130__frn0J.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__d4Tsc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__p3Jcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__mHiy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___1ZNs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__b6C0M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__hse1I.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__kHbVu.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr113__ei3Ur.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__p88L1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__asea9.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__sYVx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ywdfL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__taY7E.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qeiIa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__yZr5J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vPcIt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__nGdz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__kzYdg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fcVrn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__a45C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__g5EZl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__xYz6E.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__zBaJ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__ww6Pw {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput129___4KbvE.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___2LRhL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___2F0Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__bxtDa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__wbLhC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__iKlm2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__inwnT.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup___9KtUz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__textInput73___3JuaS.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.EsgQuestionnaire__svg__qoKLe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__apyJl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__iTWq5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__p0M2K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text___9QrqS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__oo0Ei.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCard__b1Off.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCardSection__qjGko.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox__jgwcj {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__jgwcj {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__jgwcj {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
.EsgQuestionnaire__esgQuestionGroup__hjL2A.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__bxSlq {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__fAfZc.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__hVe4J {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr121__pj6Fr.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__g9V4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__ema7K.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__shAh9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qJDf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lw4KN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__nzgSq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vf6Vc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___23CM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__k5N2F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vIh01 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__l3JVz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ld5Eu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___9QBMi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__wCpcI.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__tdolB.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr122__qKn7B.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___5Bs4N {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___4Rzje.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qN6G9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uFmRt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___9Yxo0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___3WhOg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__mr8SR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__fKfDp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__topsa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jXlXr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___0AtgW.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mfd13 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__h6S6W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__l5XA.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__zn7Ma {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__bzP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xn5Mp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__thhqo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__c8EEi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__wJ6S2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__aUnRv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ndsJ5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__yUsmZ.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__muRnm.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__jzQvk {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput145__nlOIn.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__pJrab {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__hOjuW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___7GlC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__p74Pv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__lllBf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__ny40D.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__vTB.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__mwn40 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__dhU9N.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__hbVE {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr131__oqjbh.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__rpv0R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__yndfz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tfS43 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ik4S5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__z4PSq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__uFniq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gpXse {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nIoKl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gsk6W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ld7P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bvE6O.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qtaAk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___1ZZ4O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__aZj0R.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__tmsuk.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr132___0Qv7I.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__cBnv6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__eOM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ikJS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__f8Gha {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__yyAbt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__y135V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__cXt3K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__aK6St.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kaJqY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__v0SfG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pkOzj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__erX63 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__j7UC1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__cjJVa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__r4QQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___753W0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xXd5L.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jjNio {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__akVyy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___104Wa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kAcR4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uQjTd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__stUyw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__rcnL4.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__t0Kqh {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput146__sR1B.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___2Frs9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg___0VEyW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___4WL4X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__xAu6J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__yFb7U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__ktmfV.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__ehy9M.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__a4Kc2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__qKEdc.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__q1Myh {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr141__h1Fl.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__hsYmK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__wumzx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___1HG8S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__qy36E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1KiAh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qI4PV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__kZhvI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vbvBf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__giTdb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__x4Ptw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___3EmV4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pBtvs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uv28P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__aq13D.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__x4Icj.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr142__hpfIz.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__gLZn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___2CvhB.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__fZIzh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__wypvj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pxJjq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__yaf8Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__n6Ksp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__sCelA.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__v5Iwl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__l9Tqq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bsd1R.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___9Zcep {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__lIoeV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vu1.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__cz4Cq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3Ptcn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__c6Ooa.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tm76G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__o7T99 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__hwfmD.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tSb37 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___3Vgl8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__bySru.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__eMj5A.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__zD3Kw {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput147___1LYjk.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___7PwpX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__fi0Zj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__eNtif {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__iYrqD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__j2AuN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__ckcZg.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__jArD.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__fw23 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__zrVN.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__kKjkP {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr151__dMWJ.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__rVvFt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__tQxgr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__etpNj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__prWgX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___5DtU.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__boWr5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dVthz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vu1E.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___8MCTf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__yvG2O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__kblcl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___86J67 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zD7Nd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nLftn.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__rvdDt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___8EsMs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__jbBtk.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pr4Yj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___6XuAl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ocFp.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__koQrM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__tuHhR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__aYKw.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__xt0Uy.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__pSyh {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput148__cOyY8.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__oK3A1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__mp12X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__aygpx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__kMdA6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__zsZ3L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__aDvrA.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__b0XZ7.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__vKgEu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__lPoXg.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__yQpP4 {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__esgQuestFr161__zDwbn.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__fC3B1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__vT3LN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mtBs4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aLKev {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__jUXtO.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__qOLgI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__l4WLd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1Nl3Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__rnJSq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aycOs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter___8ETjt.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__ugDan.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__tEpZl {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__esgQuestFr162__ctbrT.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__y4Wme {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__nckSr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__xh4Sh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__lrNmx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__eOfct.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__w7JoB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gzRsf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__mIkvC.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___6XsXm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__k2IVu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__oOdoa.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__bmAjk.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__envNo {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__esgQuestFr163__fUffG.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__p7HmZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__v2SlT.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___0R9Yg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__rsU3T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__yNecX.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ms5GK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__mbMr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__qtSuH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___7ORFn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jnIti {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__wUbXr.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__mnRfD.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__viL9R {
  padding-right: 0px;
  height: auto;
}
.EsgQuestionnaire__esgQuestFr164__oyTjx.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__cOpcz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__uLzyu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pr4Oz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__mo8E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__iuve4.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__tvWal {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__maLqn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__v44Jl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__cSAyd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fuCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__lyWpS.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__whQvD.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__zkfrJ {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput150__fanB3.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg___9KRCl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__hor7M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__zbDvz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__v8Box {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__gByR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__ctZnm.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__brdW7.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr166__xJxBv.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__hZEh6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option___3QYfb.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bBscy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__q4Rtf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__rh5KM.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bLe1U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__kzHzl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ncSj3.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pvZl4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__bu3V5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__i52Rr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__phhgF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__dFcfD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__b0Mz2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bzldM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___7Scr0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__eoQiZ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__sxadq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__hNPfk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__pzxj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__nh2Ls {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__f3QSt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__rHuf0.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__j5S1W.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__h8F4O {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput149__nlJ36.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__ca28O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__p2Czo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__yHxnw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___27KxL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__xkQ4G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__gDxpI.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__foaWk.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__uXqIw {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__aLlrP.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__dw7Zh {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr171__vJttz.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__xMw2C {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__nu1Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__w9Lo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__arL0C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__l4V0C.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ozfho {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__oEbvZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xxuV0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__unO2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__xO7Iz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__k7FJe.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__pyOrf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__zPcxU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__bNfC5.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ccIh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__s65Nb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__lSe75.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__uu1Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ifpye {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__vJVwJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__lbT5H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__oeH4X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__csJwS.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__jviyf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__gEfwv {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput151___3D62H.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__rplXc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__yxzax {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__imLbr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___9EjrL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__qnPd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__hOpZ1.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__hJluy.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__bwWjv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__cg72E.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__kdHT {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr181__tIy7R.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__qiC2H {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__f1L7J.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ffGe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__rJlXh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__xThJi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__rqvJj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___99MAb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zubbo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___9Bbgj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__olF4U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___8ZZw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__y0I46 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ku08H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__yx8IF.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__jy2CE.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__rTWd {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput152__vSgKp.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__lvAD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__vb2Rz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__tlxJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__me5Ea {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__iRaWk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter___7HsCr.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__fr1Kl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__textInput74__vZhir.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.EsgQuestionnaire__svg__r2Rsu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__jhRvo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__rRm0K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text___2M4N0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__ijnH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__vc87R.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCard__e3WtD.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__processCardSection__bIBj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__freeBox__np4D {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .EsgQuestionnaire__freeBox__np4D {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .EsgQuestionnaire__freeBox__np4D {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
.EsgQuestionnaire__esgQuestionGroup___3Wwve.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__uvO7K {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__zxn0F.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__hf74Y.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__dpEbp.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__oEo3V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__nhNTl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__f5FSb.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__ub6Td {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__gWdsV {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__zomwC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__rckC9.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg___0XiCt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__gDrQb {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___0Wvnv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__yMuwv.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__sucvq.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup___4IYD.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__dYsr.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__ePcSd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__iEnhf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__fhe4K.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__nJ19 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___2Z1Iu {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___2V0Lt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__oXx9J.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__iVkyZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__fCtTc {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__toeJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter___3Bcc8.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__n2Xe6.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__qnyKs.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button___4Uoqp.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__n8Fem {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__hrgur {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__e5Ee2.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__wrw9Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__izSq0 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__c9AiO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button___9Y0Ny.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__seQmg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__zyItQ {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__xlCwo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__ywYn6.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__zmI6.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__rHvEq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__rhUru.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__bdaBs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__yelVj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__oxer.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__ip36N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___7UqH {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__gKTj4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__bb9SY.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__sfBa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__h0Am {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__we79U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__lmjxk.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__p2XaT.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__sCi8Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button___0KWwD.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__s9Z1Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__zmroq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__qlPl.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__zbmk4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__exQ8 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__ifHzj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button___36Dk4.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__xnNm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__zhgKp {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___85Eem {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__t7Nd1.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__thZwU.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__pih71.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__lZaYr.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__lw8W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__g9VKc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__kxD.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__hfhmy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__wZkOj {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__k4DKk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__bZ1RE.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__m6QdN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__mjYj4 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___3OmQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__vo9Xl.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__o9HCv.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__t3NzC.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__oPSx7.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__qkDeO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__hW4HH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__izomu.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__vrRkn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___8Be23 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__jpy8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__sa0Nk.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__wVgH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__phA54 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___1CWh3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__fsRe.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__orCbi.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__eb0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__znLk1.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg___6Z9Be {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__wcKHu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__jMXkI.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__jvt6Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__qj7VC {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__seRQw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__rNtln.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__edqZc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__dExMd {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__j5Run {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__blcm3.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__dnn9A.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup__dkMx0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button___8F9IY.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg___8Z0L0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__vFog5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__rmsap.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__mQsbK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__vuqJt {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___0PzHz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__emUQt.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__msVuz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__m8Sm6 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg__uNgbL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__ucJzH.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__dja6S.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox___97Vv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup___2Rbh.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__singleSelectButtonGroup___9XXc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__button__rQsel.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__zL8F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__zCia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__vOFi.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__jBjOw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text___0Cfk {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___3CjiM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__button__jqQaw.__wab_instance {
  max-width: 100%;
}
.EsgQuestionnaire__svg__kzbZq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__lWgf2 {
  padding-left: 0px;
}
.EsgQuestionnaire__svg___9T97J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__xTRx.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__lT5Pg.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__gL5Hj {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr201__kCotg.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___2NjMj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__uNu2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__vt65P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ivVf1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__nmFg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__t9ScF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__aP381 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___32Izt.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__sdt4B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__gtblv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__p57Zc.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__u1Vlo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fAFnr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__dPcVr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mZnRq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__fm78C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__aTiNb.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jOtO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__cmvR4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__zAfev.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__ozFuh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ejd3R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__op4YV.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__oeoL1.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__zlVrI {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput153__y0Re4.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__z8A3S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__c52Kw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__gGwan {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__hcUuW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__ic8Ns {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__gCd4P.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__esgQuestionGroup__x2UsR.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
.EsgQuestionnaire__freeBox__dh6VR {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__formGroup__uo8Fn.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__text__mflIx {
  padding-right: 0px;
}
.EsgQuestionnaire__esgQuestFr211__naRfd.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg___62Kcv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__hsbHo.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__bvzb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jEyrn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__l98Sf.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__gpOwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__y6OGp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__izTXx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__oiitw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__uaHlq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__prwNq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__jAbZx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__ne3Wu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__q7Cr9.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__a9Ae4.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionnaire__esgQuestFr212__kyFc.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionnaire__svg__m1M6K {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionnaire__option__lTZiK.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___8USut {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__stzoi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__hc1Fi.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__asfI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__rKtN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__kAVf9.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg___6YiWo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__jaUDn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___7Dt2I.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__mTEdW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__xJkRp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__z5Z4U.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__kcVm7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__vvaTm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option__ldyAg.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__yKmL3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg__up8Ma {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__option___1XrXl.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__svg__n8Mc3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.EsgQuestionnaire__svg___4GHmb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__symbolCounter__voIUz.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__r0IKu.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionnaire__text__lSndc {
  padding-right: 0px;
}
.EsgQuestionnaire__textInput131___1YxNs.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__svg__iF2Np {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__fx0Ec {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__udYa7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__ixNfz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__gdzTz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__jffX3.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__formGroup__mKy10.__wab_instance {
  max-width: 100%;
  position: relative;
}
.EsgQuestionnaire__textInput75__rUvhL.__wab_instance {
  position: relative;
  margin-right: 0px;
}
.EsgQuestionnaire__svg__afqtg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__svg__qIhim {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionnaire__text__xW9Yt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.EsgQuestionnaire__text__zlQv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__text__jofkj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionnaire__symbolCounter__nYwp9.__wab_instance {
  position: relative;
}
.EsgQuestionnaire__text__upqSr {
  padding-bottom: 0px;
}
.EsgQuestionnaire__processEsgStickyNav__lX7Um.__wab_instance {
  max-width: 100%;
  position: fixed;
  left: 0;
  top: auto;
  z-index: 1;
  right: 0;
  bottom: 0;
}
.EsgQuestionnaire__footer__htp7U.__wab_instance {
  max-width: 100%;
  position: relative;
}
