.DynamicPowerList__root__s4Q7T {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  grid-row-gap: var(--token-TJik2L8OcSV6kD);
  grid-column-gap: var(--token-TJik2L8OcSV6kD);
  position: relative;
  min-width: 0;
}
.DynamicPowerList__freeBox__l9Uad {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  grid-column-end: span 6;
  grid-row-end: span 1;
  min-width: 0;
}
.DynamicPowerList__freeBox__l9Uad > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TJik2L8OcSV6kD));
  height: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.DynamicPowerList__freeBox__l9Uad > .__wab_flex-container > *,
.DynamicPowerList__freeBox__l9Uad > .__wab_flex-container > .__wab_slot > *,
.DynamicPowerList__freeBox__l9Uad > .__wab_flex-container > picture > img,
.DynamicPowerList__freeBox__l9Uad
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-TJik2L8OcSV6kD);
}
.DynamicPowerList__freeBoxisEmpty__l9Uadl7GC9 {
  display: none;
}
.DynamicPowerList__dynamicPowerListItem___2E8Vq.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicPowerList__freeBox__hJlTt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 6;
  min-width: 0;
}
.DynamicPowerList__freeBoxhiddenAddButton__hJlTtf9WUk {
  display: none;
}
.DynamicPowerList__freeBox__oiZkz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.DynamicPowerList__freeBox__oiZkz > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.DynamicPowerList__freeBox__oiZkz > .__wab_flex-container > *,
.DynamicPowerList__freeBox__oiZkz > .__wab_flex-container > .__wab_slot > *,
.DynamicPowerList__freeBox__oiZkz > .__wab_flex-container > picture > img,
.DynamicPowerList__freeBox__oiZkz
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.DynamicPowerList__select__jnzAw.__wab_instance {
  max-width: none;
  position: relative;
  width: auto;
  align-self: auto;
}
.DynamicPowerList__svg__w3Fh0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.DynamicPowerList__addButton___6WkH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicPowerList__svg__lY85R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerList__text__oe8WB {
  height: auto;
}
.DynamicPowerList__svg__f6YtR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerList__option__rfE5B.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicPowerList__svg__rzp3E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerList__svg__npwGi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerList__option__vzoq7.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicPowerList__svg__ufMWu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerList__svg___3Nwuu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.DynamicPowerList__option__sw9M2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.DynamicPowerList__svg__tlpz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.DynamicPowerList__svg__zL2Gc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
