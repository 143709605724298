.ProcessBreadCrumb__root__msHTo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  position: relative;
  background: var(--token-W3D32h7jWJpwP2);
  min-width: 0;
  padding: 16px 40px;
  border-bottom: 1px solid var(--token-RkiaL8IPSKlGAr);
}
.ProcessBreadCrumb__root__msHTo > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.ProcessBreadCrumb__root__msHTo > .__wab_flex-container > *,
.ProcessBreadCrumb__root__msHTo > .__wab_flex-container > .__wab_slot > *,
.ProcessBreadCrumb__root__msHTo > .__wab_flex-container > picture > img,
.ProcessBreadCrumb__root__msHTo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .ProcessBreadCrumb__root__msHTo {
    display: flex;
  }
}
@media (max-width: 480px) {
  .ProcessBreadCrumb__root__msHTo {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow: auto;
    min-width: 0;
    padding: 12px 24px;
    border-bottom: 1px solid var(--token-RkiaL8IPSKlGAr);
  }
}
@media (max-width: 480px) {
  .ProcessBreadCrumb__root__msHTo > .__wab_flex-container {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ProcessBreadCrumb__root__msHTo > .__wab_flex-container > *,
  .ProcessBreadCrumb__root__msHTo > .__wab_flex-container > .__wab_slot > *,
  .ProcessBreadCrumb__root__msHTo > .__wab_flex-container > picture > img,
  .ProcessBreadCrumb__root__msHTo
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.ProcessBreadCrumb__freeBox__ojjin {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessBreadCrumb__freeBox__ojjin > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.ProcessBreadCrumb__freeBox__ojjin > .__wab_flex-container > *,
.ProcessBreadCrumb__freeBox__ojjin > .__wab_flex-container > .__wab_slot > *,
.ProcessBreadCrumb__freeBox__ojjin > .__wab_flex-container > picture > img,
.ProcessBreadCrumb__freeBox__ojjin
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 4px;
}
.ProcessBreadCrumb__navButtonErfassung__fLiIt.__wab_instance {
  max-width: 100%;
}
.ProcessBreadCrumb__text__fgDoy {
  height: auto;
}
.ProcessBreadCrumb__svg__hHx4E {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ProcessBreadCrumb__svg__dh8QY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-c8ADL42ppeG1sf);
  height: 1em;
}
.ProcessBreadCrumb__navButtonMarktwert___6QbRh.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessBreadCrumb__text___8Ahq1 {
  height: auto;
}
.ProcessBreadCrumb__svg__b9EtK {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ProcessBreadCrumb__svg__s3Ww {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-c8ADL42ppeG1sf);
  height: 1em;
}
.ProcessBreadCrumb__select___5QcF0.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessBreadCrumb__svg__zVh7S {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.ProcessBreadCrumb__freeBox__x7YKo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  background: #f8fafc00;
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: background;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
  padding: 6px 8px;
}
.ProcessBreadCrumb__root__msHTo .ProcessBreadCrumb__freeBox__x7YKo:hover {
  background: #1e2a3b0d;
}
.ProcessBreadCrumb__svg__iDhGd {
  object-fit: cover;
  height: 1em;
  color: var(--token-c8ADL42ppeG1sf);
}
.ProcessBreadCrumb__option__sqIcm.__wab_instance {
  position: relative;
}
.ProcessBreadCrumb__svg___43Das {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessBreadCrumb__svg__qDxQj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessBreadCrumb__option__gFOid.__wab_instance {
  position: relative;
}
.ProcessBreadCrumb__svg__mk35L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessBreadCrumb__svg___6Joda {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessBreadCrumb__option__duCMr.__wab_instance {
  position: relative;
}
.ProcessBreadCrumb__svg___8XlK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.ProcessBreadCrumb__svg__msqga {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessBreadCrumb__svg__qeBfh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-c8ADL42ppeG1sf);
  height: 1em;
}
.ProcessBreadCrumb__navButtonBericht___1LJgY.__wab_instance {
  max-width: 100%;
  position: relative;
}
.ProcessBreadCrumb__text___9E0Qy {
  height: auto;
}
.ProcessBreadCrumb__svg__y4T2Y {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
