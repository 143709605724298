.CountrySwitcher__select__hAAiu.__wab_instance {
  max-width: 100%;
  position: relative;
}
.CountrySwitcher__option__sNxuY.__wab_instance {
  position: relative;
}
.CountrySwitcher__svg__p1BfW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
.CountrySwitcher__svg__eh4Ck {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.CountrySwitcher__svg__adaS0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
