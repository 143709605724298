.Tag__root__oqjlf {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: 20px;
  justify-content: center;
  align-items: center;
  background: var(--token-s9gzWmEFR);
  border-radius: 200px;
  padding: 2px 7px;
}
.Tag__rootcolor_emerald__oqjlf2QqNn {
  background: #c6ecd480;
}
.Tag__rootcolor_teal__oqjlfbLfb {
  background: #c2e3f080;
}
.Tag__rootcolor_purple__oqjlf7QmNv {
  background: #cfd3ed80;
}
.Tag__rootcolor_eggshell__oqjlfRmzRv {
  background: #efdfb280;
}
.Tag__rootcolor_clay__oqjlFnyo0D {
  background: #efc8b280;
}
.Tag__freeBox__wtdq7 {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  min-width: 0;
}
.Tag__slotTargetChildren__w3Gun {
  font-weight: 500;
  color: var(--token-IaDlhQPgR);
  line-height: var(--token-lWY7kGeXV7C_yD);
  font-size: 11px;
  letter-spacing: 0.2px;
}
.Tag__slotTargetChildrencolor_emerald__w3Gun2QqNn {
  color: #20713e;
}
.Tag__slotTargetChildrencolor_teal__w3GunbLfb {
  color: #2f687e;
}
.Tag__slotTargetChildrencolor_purple__w3Gun7QmNv {
  color: #545d92;
}
.Tag__slotTargetChildrencolor_eggshell__w3GunRmzRv {
  color: #594e2e;
}
.Tag__slotTargetChildrencolor_clay__w3GuNnyo0D {
  color: #71442a;
}
