.PortfolioModal__root__ob6S.__wab_instance {
  position: relative;
}
.PortfolioModal__freeBox__q81Yh {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-row-gap: var(--token-YOOgp3GSpDYosY);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  min-width: 0;
}
.PortfolioModal__name__zb6R.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
.PortfolioModal__textInput__x4WuX.__wab_instance {
  position: relative;
}
.PortfolioModal__svg__rUsAz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__svg__ehylx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__wm4Cy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PortfolioModal__text__iz3TG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__text__kixFk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__symbolCounter__nXzR.__wab_instance {
  position: relative;
}
.PortfolioModal__coverage__sk17K.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
.PortfolioModal__text__z9SaZ {
  height: auto;
}
.PortfolioModal__textInput6__hNkLg.__wab_instance {
  position: relative;
}
.PortfolioModal__svg__jbRs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__svg___7HdG9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__gjZvN {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PortfolioModal__text__dWdM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__text___69Rh7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__symbolCounter__iwNt5.__wab_instance {
  position: relative;
}
.PortfolioModal__energyDataReferralDate__fWxh9.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
.PortfolioModal__text__y9Enj {
  height: auto;
}
.PortfolioModal__textInput7__ez8Pw.__wab_instance {
  position: relative;
}
.PortfolioModal__svg___4QwZx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__svg__mXaEx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__ibtBz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PortfolioModal__text__p1ALs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__text__jPxZn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__symbolCounter__jwrZy.__wab_instance {
  position: relative;
}
.PortfolioModal__realtyDataReferralDate__pOv9H.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
.PortfolioModal__text__cWf8 {
  height: auto;
}
.PortfolioModal__textInput8__h3Dby.__wab_instance {
  position: relative;
}
.PortfolioModal__svg__p3VUr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__svg__z3Ubi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__kRhdx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.PortfolioModal__text___5GvYk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__text__d0Pum {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.PortfolioModal__symbolCounter__dkbYc.__wab_instance {
  position: relative;
}
.PortfolioModal__freeBox___7QKiP {
  display: flex;
  position: sticky;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  height: auto;
  bottom: var(--token-TJik2L8OcSV6kD);
}
.PortfolioModal__button___37St2.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioModal__svg__uTYs9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__fPBp {
  height: auto;
  padding-right: 0px;
}
.PortfolioModal__svg__plh9J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__freeBox___8O4QN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: 44px;
  max-width: 100%;
}
.PortfolioModal__button___9OPtN.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PortfolioModal__svg__qmARq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.PortfolioModal__text__xDl2J {
  height: auto;
  padding-right: 0px;
}
.PortfolioModal__svg__yL58S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
