._010Erfassung__root__ictTr {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
  min-height: 0;
}
._010Erfassung__processHeader__gvXjF.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processBreadCrumb__vHvx7.__wab_instance {
  max-width: 100%;
  position: relative;
  display: none;
}
._010Erfassung__freeBox__rpHEz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: visible;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__rpHEz {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__rpHEz {
    display: flex;
    flex-direction: column;
  }
}
._010Erfassung__scrollNav__dfCVe.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__scrollNavGroup__wYuMq.__wab_instance {
  position: relative;
}
._010Erfassung__navButton__wzn26.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__qTjzk {
  height: auto;
}
._010Erfassung__svg__tcpqv {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__qh7Gy.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__xPg9 {
  height: auto;
}
._010Erfassung__svg__d0Hy {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__scrollNavGroup__ohb8F.__wab_instance {
  position: relative;
}
._010Erfassung__navButton__hTF2.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__rEubU {
  height: auto;
}
._010Erfassung__svg__suabx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__tCiIl.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__a6D89 {
  height: auto;
}
._010Erfassung__svg__j1Ek {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__zX0Q.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text___5P4G {
  height: auto;
}
._010Erfassung__svg__i7S9 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__scrollNavGroup___88LlM.__wab_instance {
  position: relative;
}
._010Erfassung__navButton___7ZmlE.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text___3NlVo {
  height: auto;
}
._010Erfassung__svg__pnYzn {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__qXpOs.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__u3Dnf {
  height: auto;
  padding-right: 0px;
}
._010Erfassung__svg___1Dtzy {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__cMaC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__i6Zjb {
  height: auto;
}
._010Erfassung__svg__yqmJk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__w12R7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__qvYIm {
  height: auto;
}
._010Erfassung__svg___2NvVe {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__aROf.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__jgNrp {
  height: auto;
}
._010Erfassung__svg__lkK1W {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__mRyMr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__sPtZc {
  height: auto;
}
._010Erfassung__svg__uQpLx {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__v31Gm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__yygrv {
  height: auto;
}
._010Erfassung__svg__hee9E {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__fUmWd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__ivDte {
  height: auto;
}
._010Erfassung__svg___5GKiB {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__kgk96.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text___2XOcx {
  height: auto;
}
._010Erfassung__svg__aCl2M {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__sUny6.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__oqVjg {
  height: auto;
}
._010Erfassung__svg__uZcWg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__ducYn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__sdnbt {
  height: auto;
}
._010Erfassung__svg__vJLmg {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__tzaaj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__ufHri {
  height: auto;
}
._010Erfassung__svg__vAc3X {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__zMGm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text___4C1P {
  height: auto;
}
._010Erfassung__svg__wta3Q {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__bX7La.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__oQjHw {
  height: auto;
}
._010Erfassung__svg__bcdUc {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__scrollNavGroup__s5Di.__wab_instance {
  position: relative;
}
._010Erfassung__navButton__en4To.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__oZHrS {
  height: auto;
  padding-right: 0px;
}
._010Erfassung__svg__umzbZ {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__sg0Ed.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__bkere {
  height: auto;
}
._010Erfassung__svg__xz4Ws {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__v1Bb1.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__sx0Ac {
  height: auto;
}
._010Erfassung__svg__vEdAk {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__aUlf.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__zDmzv {
  height: auto;
}
._010Erfassung__svg__fTmFy {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton__h9K5R.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__kS2ZP {
  height: auto;
}
._010Erfassung__svg__bXvGf {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__navButton___3QS69.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__w4EDc {
  height: auto;
}
._010Erfassung__svg__ktnp8 {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
._010Erfassung__processPageContent__cSq50.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__g8Mtk {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
}
._010Erfassung__freeBox__g8Mtk > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010Erfassung__freeBox__g8Mtk > .__wab_flex-container > *,
._010Erfassung__freeBox__g8Mtk > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__g8Mtk > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__g8Mtk
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__g8Mtk {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__g8Mtk {
    padding-left: 24px;
    padding-right: 24px;
  }
}
._010Erfassung__freeBox__jh6Qu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010Erfassung__freeBox__jh6Qu > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-P3nFMsZz2JMXx8));
  width: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
._010Erfassung__freeBox__jh6Qu > .__wab_flex-container > *,
._010Erfassung__freeBox__jh6Qu > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__jh6Qu > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__jh6Qu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-P3nFMsZz2JMXx8);
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__jh6Qu {
    display: flex;
  }
}
._010Erfassung__text__nq9SN {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010Erfassung__freeBox___1Kg2L {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
._010Erfassung__freeBox___1Kg2L > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._010Erfassung__freeBox___1Kg2L > .__wab_flex-container > *,
._010Erfassung__freeBox___1Kg2L > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox___1Kg2L > .__wab_flex-container > picture > img,
._010Erfassung__freeBox___1Kg2L
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
._010Erfassung__text__bgP6Q {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010Erfassung__text__hRnX {
  position: relative;
  height: auto;
  color: var(--token-HHAuBKYL3pvmHz);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010Erfassung__freeBox__dXbO {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
._010Erfassung__freeBox__dXbO > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - var(--token-uC-_WkHfVJTm0T));
  width: calc(100% + var(--token-uC-_WkHfVJTm0T));
}
._010Erfassung__freeBox__dXbO > .__wab_flex-container > *,
._010Erfassung__freeBox__dXbO > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__dXbO > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__dXbO
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-uC-_WkHfVJTm0T);
}
._010Erfassung__text__nLJzJ {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010Erfassung__text___496ST {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
._010Erfassung__processSection__c0YOj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processCardAuthor__oZbYs.__wab_instance.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__bjcnN.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__eU2Rx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__ibUbG {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__ibUbG {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__ibUbG {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__bD0Y7.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
._010Erfassung__eigentuemertyp__s01Z8.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___9EmAt {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__ae6Ke.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__nYhd4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___8Tig6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___280B2.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__h8WkR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__sfcK3 {
  height: auto;
}
._010Erfassung__svg__tqKnS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__vWkoB.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__oyeq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__cFVn {
  height: auto;
}
._010Erfassung__svg__eaZvV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xTLi.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__wsodY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__pZlCe {
  height: auto;
}
._010Erfassung__svg__np8Sa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__wLh5W.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__huxti {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__fAiLi {
  height: auto;
}
._010Erfassung__svg__lwQct {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xVdq8.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__yXydv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__jXkVq {
  height: auto;
}
._010Erfassung__svg__zEopx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__uEdy3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__pZu6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text___6WZ1D {
  height: auto;
}
._010Erfassung__svg___68Ifv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___7WF.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__tf3Do {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__c91Rt {
  height: auto;
}
._010Erfassung__svg__qw05S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__y686E.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___1Aqx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text___6PaLs {
  height: auto;
}
._010Erfassung__svg__iyvIi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__hJwd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___1UgRz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text___8SVft {
  height: auto;
}
._010Erfassung__svg__qi7R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__intjc.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__oaC8S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text___4BlB {
  height: auto;
}
._010Erfassung__svg__tZOkY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__fRqfk.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___0Qr4B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__vloZm {
  height: auto;
}
._010Erfassung__svg__rAg2H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___0OxA.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__h00Wy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__sytPb {
  height: auto;
}
._010Erfassung__svg___1YYdv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__hcOuR.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__y2LLn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text___9A0Yk {
  height: auto;
}
._010Erfassung__svg__h8ZDa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___1CSgU.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__wY4Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__cdQ6X {
  height: auto;
}
._010Erfassung__svg__utuvV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__px3MU.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__g9Gb4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__kDhy1 {
  height: auto;
}
._010Erfassung__svg___5Q75D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__bjMNj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__emXeJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__oMnYn {
  height: auto;
}
._010Erfassung__svg__c4YN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__mTxWs.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__qCccf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__tDkp1 {
  height: auto;
}
._010Erfassung__svg__mCwDf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__r3Or5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__rS6Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__text__c3Eeu {
  height: auto;
}
._010Erfassung__svg__oZm8I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__j1OI.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__o8Sv.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__textInput100___3N0Tg.__wab_instance {
  position: relative;
}
._010Erfassung__svg__uxWe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__oxSHi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ta7Mt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__sqU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__da4Eb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__m0Jne.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mgUs.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput9___3DP9.__wab_instance {
  position: relative;
}
._010Erfassung__svg___60Gqi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dk7Yu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__hUhUz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mro4Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__yDzyZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cAsi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ftF1O.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__text__hT3A {
  height: auto;
}
._010Erfassung__textInput11__puOc.__wab_instance {
  position: relative;
}
._010Erfassung__svg___7ShR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___9Y5V2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bim2I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__sO8V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__unay {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__iZ76F.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__vBq2M.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__vBq2M.__wab_instance {
    grid-column-end: span 1;
  }
}
._010Erfassung__booleanSelectButtonGroup__pr3I2.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__zeIds.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__jEuSl.__wab_instance {
  position: relative;
  grid-column-end: span 4;
}
._010Erfassung__textInput10__gvZea.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xc1RU {
  position: relative;
  object-fit: cover;
  height: 1em;
}
._010Erfassung__svg__cdJf0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__crrVz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__c04Yt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__r08Wx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__tTy44.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__g0CFv.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput12___3CIb.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__x9JPc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__oGord {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6PDb8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xCTnJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__bZqjm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__bf72O.__wab_instance {
  position: relative;
}
._010Erfassung__processSection___8G8Zh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processCard__agkek.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__aLQjn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__xcGjf {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__xcGjf {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__xcGjf {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__eRAyB.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput16__sqeB.__wab_instance {
  position: relative;
}
._010Erfassung__svg__uDPpq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___2VG01 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__id8Kt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___8IAc9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__p1JqM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__notJl.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6A40V.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__textInput103__xmTu2.__wab_instance {
  position: relative;
}
._010Erfassung__svg__m2Bzc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ycEpZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xvgCk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__zcqW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xdoFo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__rf41F.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xdDbf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput105__x7SOv.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xmpUv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__v4Xk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__h9WOx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__na40F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__nndty {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__jaZl1.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mkeGz.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput17__sCdru.__wab_instance {
  position: relative;
}
._010Erfassung__svg__wHeAs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__jrxlQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__gjaqJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___5Sm5M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___8CNLu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__rn2F.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__c390M.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput19__xKLld.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg___0Qz52 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ntiSc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mvvhI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__zEuos {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__dMsN7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___2CSp.__wab_instance {
  position: relative;
}
._010Erfassung__processCard___6JC2.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__vrqf4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__qrDoa {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__qrDoa {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__qrDoa {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__v1JKx.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__v1JKx.__wab_instance {
    grid-column-end: span 3;
  }
}
._010Erfassung__singleSelectButtonGroup___9T0Ot.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__aBXr.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__woKi7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__uJtXg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__hoMsT.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__oRNgu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__hkV4V {
  padding-left: 0px;
}
._010Erfassung__svg___7R6Fa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__tvc3D.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__j3Ozu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oaIxi {
  padding-left: 0px;
}
._010Erfassung__svg__zbD5G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__wiudT.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__rdag0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uusNr {
  padding-left: 0px;
}
._010Erfassung__svg___4ZrqA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__fnX1O.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dbpLj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput26__s18RS.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__nEVe5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__frZe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xhIs4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___8IPbV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___9Ub3T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__t2Ft.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__u5C5Q.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__qzv6H.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__vWr6J {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__vWr6J {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__vWr6J {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__fDzG.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
._010Erfassung__singleSelectButtonGroup__kut3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button___2Z25Y.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__hKJbz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__qjVxh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___92Dfl.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__dNR3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bcCj4 {
  padding-left: 0px;
}
._010Erfassung__svg__k3Q4D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rx0B1.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__u6Me3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__gu22Q {
  padding-left: 0px;
}
._010Erfassung__svg__bm9Ut {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___363R8.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__giimw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__n4LTe {
  padding-left: 0px;
}
._010Erfassung__svg___8KI55 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__p3WSe.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__bRbrS.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._010Erfassung__singleSelectButtonGroup__tFi0W.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__tlD6Y.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__n6Bkb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xc9Nu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__iwWza.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__gZd6O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__fn1D2 {
  padding-left: 0px;
}
._010Erfassung__svg__cdOga {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__cHgVo.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__jst59 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__d4YXy {
  padding-left: 0px;
}
._010Erfassung__svg__x9Whi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__dUglZ.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__jd2La {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oMEdp {
  padding-left: 0px;
}
._010Erfassung__svg__g0Tue {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__zZDjo.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___8W0Wf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oUzur {
  padding-left: 0px;
}
._010Erfassung__svg__qonre {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__ooIv.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__qV5Nz.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
._010Erfassung__singleSelectButtonGroup__rmbem.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__edAgB.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__tKBvs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rOcby {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__mgZ8O.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___2IuFu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__rNle {
  padding-left: 0px;
}
._010Erfassung__svg__pLdrF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___4SVw8.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___42X7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__nLy0Y {
  padding-left: 0px;
}
._010Erfassung__svg__zHrW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rdngG.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__agHs9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xNLin {
  padding-left: 0px;
}
._010Erfassung__svg__wRjh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___6Ffy4.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__di4Hj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xuCs {
  padding-left: 0px;
}
._010Erfassung__svg__oMgB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__ktcOc.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__uoesA.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput29__fQnbl.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg___9S4B3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gxSTj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zw7Qb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__f4ML {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__loo9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__fhb6O.__wab_instance {
  position: relative;
}
._010Erfassung__processSection__noLwP.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processCard___8UWxT.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__tmoZg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__zXdD3 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__zXdD3 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__zXdD3 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__tVe0A.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__rzasP {
  padding-right: 0px;
}
._010Erfassung__textInput46__mOh1R.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__bcAy9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dWwIp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__fI4D7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dTzku {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kxFlm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___0RGzH.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ok8EU.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__u9X {
  padding-right: 0px;
}
._010Erfassung__textInput47__fmDbI.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___6XTX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__zYqib {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__chLy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__cbqhy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ldq5U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__la2Q1.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__xXgE4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__tuw0O {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__tuw0O {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__tuw0O {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__nQumb.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__bMpo5 {
  padding-right: 0px;
}
._010Erfassung__textInput50__rcnoV.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__luDm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xd9QW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__f8Y1D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__hmXX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__b0UEj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wa24Z.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dYsEv.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__qi08F {
  padding-right: 0px;
}
._010Erfassung__textInput108__cmO1U.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__oNDt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__oMkRy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tXHrE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ygR5S {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__zSfSn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___1RwoM.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mTlPf.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__mw90R {
  padding-right: 0px;
}
._010Erfassung__textInput113__b27Sv.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__yOtPd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__sePvx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__sBsis {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__uHbyy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__zA1E7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___5XCYy.__wab_instance {
  position: relative;
}
._010Erfassung__text__wVoH3 {
  padding-right: 0px;
}
._010Erfassung__formGroup__e68Cv.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput85__wkEcW.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg___8GnaB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___6JpTh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__fYdd2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__gOPyR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__pah0G {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__stBrl.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__gT5G4.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__fWkj5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__cc97 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__cc97 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__cc97 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__y9It0.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput102__mGsZs.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__fcNmH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___619Bv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___1TqV7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ggdez {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___2Squ5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aQeL.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__pEAkF.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput109__wKa37.__wab_instance {
  position: relative;
}
._010Erfassung__svg___5Kiqu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ddh7Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___0LVty {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___4XUw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__flHtk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pj1Y4.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___0OIiL.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput110___2Rt4A.__wab_instance {
  position: relative;
}
._010Erfassung__svg__cxafE {
  position: relative;
  object-fit: cover;
  height: 1em;
}
._010Erfassung__svg__tDxPb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___9O9Ic {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__tryJs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___8Wbbp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__xDIfw.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__pSeuy.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__select9__o9Gm.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__k9TSf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__kIn5Z.__wab_instance {
  position: relative;
}
._010Erfassung__svg__k709E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__tsAiK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__z62Kp.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dH8Jk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__yBx78 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ir9Vo.__wab_instance {
  position: relative;
}
._010Erfassung__svg___5Jup7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__mrrEg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__mMqF.__wab_instance {
  position: relative;
}
._010Erfassung__svg__jiCuR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__sirE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__kMleS.__wab_instance {
  position: relative;
}
._010Erfassung__svg__lusDp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__fCl4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__kbIb.__wab_instance {
  position: relative;
}
._010Erfassung__svg__gChZy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__qpuV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__bm5C.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dihUg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ru4G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xq2CD.__wab_instance {
  position: relative;
}
._010Erfassung__svg__btv3L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__saJ8X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___0TsGe.__wab_instance {
  position: relative;
}
._010Erfassung__svg___7GNc7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__oo0Ae {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__kFe3A.__wab_instance {
  position: relative;
}
._010Erfassung__svg__cDrSr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__h2Fk7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___75Kvg.__wab_instance {
  position: relative;
}
._010Erfassung__svg__bW9Yh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__kHhiZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__tgayo.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dX4EB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__tYAkk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__easf8.__wab_instance {
  position: relative;
}
._010Erfassung__svg__w3B7C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__gbYqj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__x7ACz.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ozKlB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__kNxWr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__tZYax.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__y3Ax {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__i4Ifu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ixPo.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__b8DEm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__qeYza {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__gbJ2D.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__ahnwx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__bvfcE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__p4GZh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__g5HhK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__qwU0L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__hvvnn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__ri0Ca {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__sOkUw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__yUy4P.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__xY1M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__qcMf1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___0KTc1.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__qWta {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xRdjJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__rzPC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___3B7My {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__mxo7J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__qjsvl.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__g17X8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__l3Osn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__jurea.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__u8KB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__fMhw5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___75Z7S.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__tnAk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__zhFtF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__lkV5A.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__gCfn8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__kfnk7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__iF5Rv.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__xdUw4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__sYJr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__kxUe.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___8FWHj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__n9Pc5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__tcgrq.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__ehYsM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__vbfZs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__lPpF.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__wmTz2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__n1F24 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___2Zhgo.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___50Xvp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xOeMh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__dRaO.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__eEaW5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xmgOc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__osy3Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__jps66 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ynf4T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__eAvNm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ttgYx.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput111__oygiq.__wab_instance {
  position: relative;
}
._010Erfassung__svg__qQaiF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fm6Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___2B3I2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__c3Bko {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ysWrh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wggbI.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__fi4Am.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__svg__kqQ3R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gEJe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zjsDu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qbPdF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__t0GKh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wEDrU.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__qaq.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__button__iu87K.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__mVvE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__sY368 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__so1TE.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__umEY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___0Qsx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__pJc9T.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__xHjqQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nv7Zi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__nYr7R.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__qQg4M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__veGAy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter___7FkJu.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___0TFbx.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__booleanSelectButtonGroup__wfEj1.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__mcy7C.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__lUk6Y.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput112__adSz0.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__rOuCz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wd3Rb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__q6J7M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__r97Jy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fIo7Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dBfeI.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__rUv7M.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__uprjD.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___2EpFz {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___2EpFz {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___2EpFz {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010Erfassung__formGroup__qAzoD.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__qAzoD.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__gFjhv {
  padding-right: 0px;
}
._010Erfassung__textInput148__dn5F.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ewBMu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__jcdgE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ppNg8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__u8L3W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__uHsLb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___2T4La.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__hSpsZ.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__hSpsZ.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text___6SAYx {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__svg__vU16F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hjMHf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ueyln {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xbbcz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__d3QE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__kSyRv.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__tnLeo.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__tnLeo.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__vafF {
  padding-right: 0px;
}
._010Erfassung__gebkon__bzyjv.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___8PQm3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__i6MQz.__wab_instance {
  position: relative;
}
._010Erfassung__svg__g3Ia {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__wB5F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__tJv2Y.__wab_instance {
  position: relative;
}
._010Erfassung__svg__nxtKh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__o1YD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__dgjgk.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xFw2B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__btujf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___3R7Jr.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xwY4K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__aJikp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__rbb8R.__wab_instance {
  position: relative;
}
._010Erfassung__svg__zRtpr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__iiE7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__rGajy.__wab_instance {
  position: relative;
}
._010Erfassung__svg___8HyvS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__bJB1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___0Gr9M.__wab_instance {
  position: relative;
}
._010Erfassung__svg__bglV1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__g6HDj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__unCWf.__wab_instance {
  position: relative;
}
._010Erfassung__svg__i40Wx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__r9HZw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__ox78A.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__fpbmN.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__fpbmN.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__oVi7J {
  padding-right: 0px;
}
._010Erfassung__dachc___3PUl.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__asBe {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__fRul5.__wab_instance {
  position: relative;
}
._010Erfassung__svg__kbsYi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__pvco9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__g6Dm3.__wab_instance {
  position: relative;
}
._010Erfassung__svg__fbUyI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__aQgj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xZxys.__wab_instance {
  position: relative;
}
._010Erfassung__svg__nbsVq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___75OjF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__yv5YD.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vggBt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ga9VT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__rzwew.__wab_instance {
  position: relative;
}
._010Erfassung__svg___4MsSk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___7JIN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__vrRG.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xm9T.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__xm9T.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__yPn4E {
  padding-right: 0px;
}
._010Erfassung__kellc___1YSn.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__oghuL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__fcpXi.__wab_instance {
  position: relative;
}
._010Erfassung__svg__siaaN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__yxjZi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xujo2.__wab_instance {
  position: relative;
}
._010Erfassung__svg__lgo5F {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__fZa5T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__sNxjy.__wab_instance {
  position: relative;
}
._010Erfassung__svg__oBiIi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___31M8X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__zO7Bo.__wab_instance {
  position: relative;
}
._010Erfassung__svg__viMy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__fus4M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter___8Nsgu.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wpVDs.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__wpVDs.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__tXmYx {
  padding-right: 0px;
}
._010Erfassung__fassadArt__b3NC1.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__kVBe {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option___6SUcM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__rpPb5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__v5Iyw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__vDq2S.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__xvjgl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__vBbhw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__o5TCr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__gh2Av {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__od4F5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__hHoo.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__mii55 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__bCtw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ojKgI.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__tUw7S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__wpKrE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__gGsX.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__zwWs3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___6EUwo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__duhSm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__rbnSz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___902Ks {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___8WO8C.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg___5ZzMt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__tDsnG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__qsZsX.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__rNXxR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__dKbyv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__mo7Hw.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__bIplB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__mubhf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__ba1W.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xk6T3.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__xk6T3.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__mp62 {
  padding-right: 0px;
}
._010Erfassung__textInput158__aOb4V.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__m1AuL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mC0O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__vfnMz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__reH0M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__eMjA8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___02Dqt.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___91Zsn.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup___91Zsn.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__un1M {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__button__lA1Vv.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__et1DB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__e9Rs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__s3Bfn.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__iQHmX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__lHtd9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__dixTq.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__twSo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__f9Olq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter___6Io4E.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__gm1LS.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput165__lUygG.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__yyNl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___3BhrW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__swAk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__aI8ZP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ejqob {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ijy8Y.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__rXtKe.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__cq77F.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__dJTmi {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__dJTmi {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__dJTmi {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__gWce6.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__xTojN {
  padding-right: 0px;
}
._010Erfassung__textInput49__lkmNv.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__eUtlU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nnvgO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aa1Sc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__pef92 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___2FGgd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ebcEd.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___9UCd.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput45___1YHfN.__wab_instance {
  position: relative;
}
._010Erfassung__svg___4QbWq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___07QXg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__gDmJq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__tdY9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__gNaMl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___8XbX.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__g6Hus.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__cZrEi {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__cZrEi {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__cZrEi {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup___5Erd9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput116__vCwXr.__wab_instance {
  position: relative;
}
._010Erfassung__svg__eehn1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__muNn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__yvnch {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ou71 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__su4T9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__zf5Cc.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__s1Uq8.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput95__f6Bm.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ff2Wk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mWBd0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uIerw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dQu99 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ebEbq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__khmBc.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__c80Wx.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput96__sXd77.__wab_instance {
  position: relative;
}
._010Erfassung__svg__bruJ0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fjRka {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___09ZBl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__a7As3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kx6Pr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__g6GvD.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__eyrJ.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput53__uwIux.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__dzMZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gEy7C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pgl0T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__wcsZg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cb0Kq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__sYn2.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__w2Uru.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__ptDHj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__h4HZm {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__h4HZm {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__h4HZm {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__fZOel.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__oUobj {
  padding-right: 0px;
}
._010Erfassung__textInput56__kBgnl.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__o4Y2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wMSu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__h0UVj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qHked {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ruAbx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__amp3.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mocV.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__phbdq {
  padding-right: 0px;
}
._010Erfassung__textInput67__tyek5.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hrmp7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___4Tqdl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__f7JmZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__gQaV8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hBaH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__n8Rfb.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__q3NkW.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__singleSelectButtonGroup__eGctj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__mVm1.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___31HEa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fh7Hk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__wl33Y.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__rmhFi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__s4BmU {
  padding-left: 0px;
}
._010Erfassung__svg__ah62 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__hk7Fy.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__b8YS8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__w7Bo {
  padding-left: 0px;
}
._010Erfassung__svg__kWdV3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rOqb2.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__rUphw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uQnP {
  padding-left: 0px;
}
._010Erfassung__svg__cXmH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__cLgIg.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__xg5Kp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__dia87 {
  padding-left: 0px;
}
._010Erfassung__svg__ebnB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__nboKn.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___89Ffl.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput57__kLgbe.__wab_instance {
  position: relative;
}
._010Erfassung__svg__iYlr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mAaMc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___62Nkz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__orqvZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ordPf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__xiOqA.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___3WZ.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__booleanSelectButtonGroup___4VgIf.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__fUtkp.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wvcf9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__dJn7 {
  padding-right: 0px;
}
._010Erfassung__textInput68__ji4Wn.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__zrAfw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__yYr0D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___9ARPz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___01ST2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___2N6Os {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pYXeo.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dpgSp.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__vQxrA {
  padding-right: 0px;
}
._010Erfassung__textInput69__pwQf3.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ee9Lh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fdfY {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aRzm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__c59Sw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__n2MOi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__jLgKl.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__l6AEr.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__heTau {
  padding-right: 0px;
}
._010Erfassung__textInput70__oBt9X.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__e0Im3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ew05I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ytrhn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__fg9Jp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___79Fg8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__qGYb9.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__y0Wv9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__yfJiW {
  padding-right: 0px;
}
._010Erfassung__textInput55__rAoMx.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__cYsFe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nSkGh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xlLz2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__emzKm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__bGkB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___0RSkg.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__rI1X1.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput62__uq6TJ.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__anAii {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___2EDzR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___18K28 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__v4Y06 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hQw6F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__klOk0.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__g0Iak.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__sWb7H {
  padding-right: 0px;
}
._010Erfassung__textInput58__tXjEi.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ho3V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__cvmeR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jnh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___0Av8Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___0Wj5O {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__xzrl7.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___1Y2Lg.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput63__utXz3.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__rAaAd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__s2Yxc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__h6Xp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__sVrSx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___7NQyl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dZIgM.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__h9Egi.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput59__ob4Sf.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__uAxRa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xZxLk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___9AChH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__yqnVu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___52QE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___39Dw5.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6KwxE.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput64__z1G7.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___59B68 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__eKtQm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__rAi7Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__rVLh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__eJx8F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___7SxH.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__x9BxD.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput60__bbyht.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___1PfvC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___9Iix {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__eCiP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__jXuZ3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xyUdb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___8044Y.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__oa3Ht.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput65__oUjVj.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__aiqSl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__w6Du {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wvMzx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ozp3D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__oCyv4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__vJcSa.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__rTxjs.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput61__xhBtl.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__sJl3E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___0TDif {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___0Zgxc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__upgTd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__jSB5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__xNHnZ.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__z2Al.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput66___1SGpa.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__pOlmm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__d0Ada {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__rmFhi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__yaZyu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___0FMnl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___3Pllo.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___662L.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__wiX7G {
  padding-right: 0px;
}
._010Erfassung__textInput52___6Jrj.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__gNs9W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xo1L2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__irofp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__sgLct {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__jHzk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__z970E.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__lVrOy.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__wfXLj {
  padding-right: 0px;
}
._010Erfassung__textInput54__p07Tp.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__bFd9R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rKdhX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6Hjqi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__x87W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__v7OFo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ks3Iu.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wOVia.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__kmePe {
  padding-right: 0px;
}
._010Erfassung__textInput115__pZ4Uz.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__rrSxx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ivRJs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bzpFv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__gLip {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__bX8G6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cvOAt.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__a3Cfl.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__booleanSelectButtonGroup__ve3Og.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__lnDEd.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wlhwT.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput71__vVFkF.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__jt7C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ikfvH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__vehpV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__bKn1B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___7Y4H5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___8I5Yb.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__ybOyS.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__f0ZhO.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__bPfnG {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__bPfnG {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__bPfnG {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__uzrem.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__nNev3 {
  padding-right: 0px;
}
._010Erfassung__textInput72__gq1PK.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ske7X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hmVj4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uzq7Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qq32 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__uNtAc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yIfp9.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__d8WOy.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__f9Hr9 {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput73__fh6Uq.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__rRa7H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__vGkn2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__boVa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__t1JH0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__h21Aw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__lMxa.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__eDqyB.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__h88F7 {
  padding-right: 0px;
}
._010Erfassung__textInput74__p2P0A.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__fUkZ8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__aaGwO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wP5Ee {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__gU7Eh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__jaUq8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__x6PRq.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__kwMe.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__hXCvL {
  padding-right: 0px;
}
._010Erfassung__textInput75__aRdt.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__fVVo3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nb3Fr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___5JynI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__jy2Jj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__whdUs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__z8VxS.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dXc8.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__mMmkg {
  padding-right: 0px;
}
._010Erfassung__textInput76__sWSk.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___07Hi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nqYyx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___7STjr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___5BdOl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__w09BV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wdDxp.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__br6LN.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__c6Z4B {
  padding-right: 0px;
}
._010Erfassung__textInput77__zjwOc.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__yjKdC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__sNrcT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ic27L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__wgJJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___8QMBj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__lYcj7.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__guKo8.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__knSMe {
  padding-right: 0px;
}
._010Erfassung__textInput117___0Yy5R.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__bcVwk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___56R1D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__kDyiv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__fwn9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xhm1E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pPv9P.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wEXe7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput78__brWzu.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__eyxbe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__voKji {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aQmKz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__nHCyA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hnu43 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___5XO7N.__wab_instance {
  position: relative;
}
._010Erfassung__processCard___1C9FM.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__yZo4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__opKQ {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__opKQ {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__opKQ {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__bgi6V.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__bwA0S {
  padding-right: 0px;
}
._010Erfassung__textInput79___6Ux0D.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__tnViF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hxO6R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__m1Xza {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__fbMd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__a4Ov0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__i7P2G.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cqPp.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__cJcM0 {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput80___5S3Ww.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___2ZxDk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__sdSIt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__kjVSk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mbdHy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__vNw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__sTZxa.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xmK9T.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__hxxtH {
  padding-right: 0px;
}
._010Erfassung__textInput81___5FnD1.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___95IH7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___89AUw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___43Top {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___4KCsv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__uhrjy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___2PvOb.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__zaU8K.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__sTb7X {
  padding-right: 0px;
}
._010Erfassung__textInput82__unkyA.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__o1Pvu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__f2Ijo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mJuwx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mSmL5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__rFaf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cq10.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__q4VZs.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text___17PGm {
  padding-right: 0px;
}
._010Erfassung__textInput83__xzPqF.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__muc1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__qCze {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xvXx1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__zKsRb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__uIkkk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wxAgP.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6KNw9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__c8Pi1 {
  padding-right: 0px;
}
._010Erfassung__textInput84__czrAn.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__et2Hh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__bnU3B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__t5MyJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___5Pt8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__viYd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__rMgsg.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ud2FC.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__xDh9B {
  padding-right: 0px;
}
._010Erfassung__textInput119___5W0B.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__cHkDb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rU8Gx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__slT3Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__kgKgj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__n3ChB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___7Gaf.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__c1R4D.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput86__tiRLo.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__ubx0S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__jv7Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__keXt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__eJcL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___78VZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__e2PN.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__t7LBm.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__bDc4C.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__ndQcu {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__ndQcu {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__ndQcu {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__nZ7XD.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__zkc9 {
  padding-right: 0px;
}
._010Erfassung__textInput87__kSWe.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__sD8Em {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wOz7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__yvfy8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___7Sby2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___4ZPl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__sEyK.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___8Dndy.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__w9O8Q {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput88__eOiJc.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__qiEag {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rwyAx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__a0WR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___9V3TU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___3KpOr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__suIzo.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xRw7.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__fTco5 {
  padding-right: 0px;
}
._010Erfassung__textInput89__dZEhJ.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___0Njcc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___86E71 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6Xg1H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ecD3F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ll1L3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___79WGc.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__uql1Y.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__nz8U {
  padding-right: 0px;
}
._010Erfassung__textInput90___2DgOx.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__z5WRk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___3Mb4L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__qhsW8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mKcE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xakVv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pySys.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___0EWm6.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__ji6Nk {
  padding-right: 0px;
}
._010Erfassung__textInput104___9XRfA.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hfE3A {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rpmJr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__iNbkF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___4EeI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__px1Bs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__oRwda.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__hPCa.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__nR1S {
  padding-right: 0px;
}
._010Erfassung__textInput106__q1Tk.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___5BebV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__iEA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wJtSw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__szaob {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__mgJbA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__zoSm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__xNfwV.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__mvbf {
  padding-right: 0px;
}
._010Erfassung__textInput120__oi815.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__o0Hch {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ez5AP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bMmcb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__oITwa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__pel7F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___79RVi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___3FkWw.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__lIUlW {
  padding-right: 0px;
}
._010Erfassung__textInput121__wOhvb.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__t6RPp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ighX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___4O0Af {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__tDdQh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___1KBtR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__w1RWh.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___3N9Dw.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput107__src6T.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__cPPf4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mo8Da {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__eLbTu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___4WbS2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__dxwln {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__uSjF4.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__d4Xmj.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__j80CK.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__ehzg4 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__ehzg4 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__ehzg4 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__uDoKi.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__whSr0 {
  padding-right: 0px;
}
._010Erfassung__textInput122__vNmSg.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__oOihf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___5XZtu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tt2OT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__hElF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__lEspu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__nmSt.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__a7Izv.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__p0ELc {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput123__lAahs.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___79Cm0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__kOidD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__y0GuI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ahOhG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__g148A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cTdG.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cpoO2.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__vzMac {
  padding-right: 0px;
}
._010Erfassung__textInput124__lQqas.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__yzGa4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__thmWo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wqbMy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__g1T23 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__dn4TH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__zYJD.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__uO24.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__al33L {
  padding-right: 0px;
}
._010Erfassung__textInput125__wycuG.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__evDc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__c9Onb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zc4X3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__pZjyq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ghNm4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__lgAsn.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cZ7Rb.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__yEepq {
  padding-right: 0px;
}
._010Erfassung__textInput126___1OOsB.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__q7Wi5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rySw1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bUwoe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__eAWdu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___9L2Cs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__sn7Ef.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__slTTx.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__a3E4H {
  padding-right: 0px;
}
._010Erfassung__textInput127___1MN1V.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hcFiy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rKnh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__r1U1V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__j9R7H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___6Z61 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dbfCz.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___46O0M.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput130__yxTwO.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__osWdv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___0Di5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__yrPs3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___6MG8R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__o7EFr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__m3C4.__wab_instance {
  position: relative;
}
._010Erfassung__processCard___3JtH6.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection___3QaYr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__rYvxo {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__rYvxo {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__rYvxo {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup___9Pi6O.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__t74J3 {
  padding-right: 0px;
}
._010Erfassung__textInput128___7Nawg.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__acmLt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__d7HRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___7VyFe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___8LCu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__tQYa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__akDmp.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__gHCu.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___3QSqG {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput129___4Lj8.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___71Meq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__qqYt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__nGgDr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ndUtp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__o2Nm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__jX9V8.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__sVdL6.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__pahbR {
  padding-right: 0px;
}
._010Erfassung__textInput131__zJIux.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__qCRsv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___85YAj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ores6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___7JuDt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ppIi2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__fAw56.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__sq40J.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__g1O9L {
  padding-right: 0px;
}
._010Erfassung__textInput132__oLojU.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___4HGc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ftPf8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ts42I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__w7So6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__sFu9D {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__qweRl.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__q1N5.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__c70Ir {
  padding-right: 0px;
}
._010Erfassung__textInput133__ivZh4.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ri2Ts {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__cF87K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__m8Cb2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__kIaLb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ja1Ez {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__zkZ2E.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___052R9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__c63LS {
  padding-right: 0px;
}
._010Erfassung__textInput134___4ReLy.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__yy3Bu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ml5A1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__lpPs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__j5MeM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fyQyF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__m3Qcx.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ihxtt.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__qiGWz {
  padding-right: 0px;
}
._010Erfassung__textInput135__pW4Os.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__vqW5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rIqu9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ii28T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dggmk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___6XzOw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yJiq.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__gg5Ay.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__nqGiK {
  padding-right: 0px;
}
._010Erfassung__textInput136___1DqUo.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__cX65U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___4OqgP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6DxFx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__u7JJf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__sqzGr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aNpmf.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__nqg2K.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__nV2GE {
  padding-right: 0px;
}
._010Erfassung__textInput138__xcEhf.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__d4ZxN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__czjS8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__hsBeR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__tlRf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__jM6X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ujBgc.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cf2LJ.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__kRvoF {
  padding-right: 0px;
}
._010Erfassung__textInput139___7H6Vp.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___2K0PQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ySxRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___1VpKu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__jSbFi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ovFjY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yj9XJ.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___3T82N.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__fe8Wz {
  padding-right: 0px;
}
._010Erfassung__textInput140__wEtlo.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__zrxdj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nflu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__crXeE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___408GP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__vrNjJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cys6X.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ptxdp.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___0OyzZ {
  padding-right: 0px;
}
._010Erfassung__textInput141__ll88A.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___9V2Of {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rzLf0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tzwSk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mrNGd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___05P5Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___6KnH.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__t7DhM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput137__xqO1L.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__iaIk8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__i6C4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tazlo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___9Mej4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__gTbzj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__uKl8H.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__xwqLz.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__awKVp.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__jGnHu {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__jGnHu {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__jGnHu {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__wkzon.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__mL8Co {
  padding-right: 0px;
}
._010Erfassung__textInput142__na6K0.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___9Lfw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__h3Xbe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___2LX6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___6UZPr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___0Bj3V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dfOj1.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mhRl5.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___4Q7U4 {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput143__krbK0.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__aNuK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dJq5V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uacFs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ldcxC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fSto5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pIxkd.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__avvhc.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___6Ed3Z {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput144__ajd6P.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__w4GPc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gpJUt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pLKsX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dFxiA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__k9Dw4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__vJgxg.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__oEfh.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__uoKom {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput145___1Sa7Y.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__kBr9Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xLtus {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ekRjo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__bnBo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__lQbOj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___9NiN.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__nqLsU.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text___6Nu9Z {
  padding-right: 0px;
}
._010Erfassung__textInput146__vQDxE.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__f1Oj5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__pFDnc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mwYNo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__uAxJa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__wYtSr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__c1X8.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__uo3Ff.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__shR93 {
  padding-right: 0px;
}
._010Erfassung__textInput147__glkuV.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__qnHtk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gmUb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ww6M0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xKgwR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__qh4Jj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ugxns.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__mkw6W.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput154__whcOi.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__dGkyq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nPuSj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ad660 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__gleAn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__l80V2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yegf.__wab_instance {
  position: relative;
}
._010Erfassung__processCard___3OHfr.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__xaTiv.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__fqpuo {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__fqpuo {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__fqpuo {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup___1OOcP.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__n0Tyl {
  padding-right: 0px;
}
._010Erfassung__textInput149__bGd7M.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__zvXFo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ypxui {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zF7Oi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dxGBg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kuvKl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aD4L.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__qlgha.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__pSTpr {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput150__aelBr.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___9RFlI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___4CF2I {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oFRo0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___3L4Ye {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cShx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dL5Gf.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dvNYv.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__ij1D {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput151__q8Rxe.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__yrAav {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__zz5X0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___7MLAq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xeQo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__wpLe2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__g6Hwl.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___7JruR.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___61KVx {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput152__pUub.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__zT1Xp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ysRys {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__sh5L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___4Zwla {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fabu5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__cQcI.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__bjBnF.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__pnr51 {
  padding-right: 0px;
}
._010Erfassung__textInput153___40Df.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__dnMwI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rZwn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__arv5J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__m5TR5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__rsmBh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__t13Jn.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__q8Gf3.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__qrK7Y {
  padding-right: 0px;
}
._010Erfassung__textInput156___7SJ1A.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__kRjDh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__afEMw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__eeOn5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__zaw0K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cQcfj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__b51Q4.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__pb82X.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__rZbaG {
  padding-right: 0px;
}
._010Erfassung__textInput157__wry7H.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__jyXiR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__eD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__rw5Am {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___2Kf7H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__qxKvb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__bh34C.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__jLyGg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput155__ad7Ft.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__qrCoe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__n2Kdk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aMofp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__aw9K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__lMg0B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yg9Mg.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__sNDn3.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection___0Kvpc.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__boEr {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__boEr {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__boEr {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__zz8R9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__xycA {
  padding-right: 0px;
}
._010Erfassung__textInput161__adG85.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ta8FH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___6PVsF {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uQoZe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__vxGuQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__euWoh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___4CQaG.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wcEfb.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__hQKel {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput162__d2THn.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ghVas {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rjtZ0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___32NYz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ho43R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ouc69 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yf049.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__naaaL.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__mLywn {
  padding-right: 0px;
}
._010Erfassung__textInput163__oBlOb.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__fdEu4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mK5V {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wqui2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__apmto {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fTcHl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__seV3E.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___0MTrK.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__h0Ul5 {
  padding-right: 0px;
}
._010Erfassung__textInput164__dkCYg.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hj91C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__yuYq8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__dxzGr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__wVf5I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xjFh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__baPu7.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__kXqR8.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__moDnq {
  padding-right: 0px;
}
._010Erfassung__textInput167__aJfln.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__zai8C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mqfOu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__taFhk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__okzOa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___6OoEk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__jTq4X.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__getKh.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__poZlG {
  padding-right: 0px;
}
._010Erfassung__textInput168__rxHhR.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__vDjb3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___8SDme {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oBzpQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__oL1Py {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__vy0Qx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__tV2Og.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wqSut.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__xeGKt {
  padding-right: 0px;
}
._010Erfassung__textInput169__uu461.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__iY0B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ol5D3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__qahYl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__cI2B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__knJlU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__v3Ndp.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__ydL1Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__ipNXf {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__ipNXf {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__ipNXf {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010Erfassung__banner__vvAjg.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 6;
}
@media (max-width: 480px) {
  ._010Erfassung__banner__vvAjg.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__button__gi53I.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__eD5Pl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___2Shra {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__formGroup__p0YXz.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__textInput160__xaGjT.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___6ADog {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__tGHo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__imPez {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qmLql {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__eQwvB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__bhre2.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__sWmU8.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput170__vq6Ot.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hQzOd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__oNkuT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__l8Mzr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__sDevV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cvFAr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___3Dn6Z.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___5H8Ns.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput171__yfhXp.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ttJez {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__cu5Nj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__s1Rz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__mXdV {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__pMsE {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aOrIh.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___28Xim.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput172__zp1Wc.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__ty82C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___5MtyW {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__iWbZu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__skGpm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___1W84J {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wt0LA.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__bfkMz.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput173__vFVaO.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__smKKc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gUFhq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xr70C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__laI8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hvs3Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ma7Vf.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dcatW.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput174__aBIa.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__aHhg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__twYb5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pkBg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__axQln {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__zfLa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__y6WOo.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6MMa.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput175__lkz9L.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__bx3S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___03Xl4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__dVzis {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__fHjSf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__qpEn0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dmvbd.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__bujFl.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput176__k3Mt.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__pwj3Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___1WklA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mEcj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__zDfni {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___6Lpl4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wBHpe.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__tdt5.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput177__zcxgf.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__s1BJw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ipdKw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__etdU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__eF0Tw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__inpHd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ni7Hk.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dQa8V.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__dQa8V.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__button__viZaZ.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__cw2Lh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__yNKrx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__cHbzQ.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__l8Ql3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__s1WZp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rpqjo.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__tcQpw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hzdNc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__wHTe.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__rn4Xh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__vhBjr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__cQ00.__wab_instance {
  position: relative;
}
._010Erfassung__text__zFMe7 {
  padding-right: 0px;
}
._010Erfassung__formGroup__sp6.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput166___6Admx.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__pyfd8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wThB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__es2Rb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__jjvSh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cvrzh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__hebfG.__wab_instance {
  position: relative;
}
._010Erfassung__cardMakroMikrolage__v8EWb.__wab_instance.__wab_instance {
  position: relative;
}
._010Erfassung__text___0TkK3 {
  padding-bottom: 0px;
}
._010Erfassung__processSection__kWKwx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processCard__ez9DL.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__eYlLb.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__mEoCf {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__mEoCf {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__mEoCf {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__l92Tm.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___6W4Po {
  padding-right: 0px;
}
._010Erfassung__textInput211___7DrPz.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___7YKsc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__d2Dp3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___4Sqgh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qCdkv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__z49Gt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__a1Lox.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wX7Pq.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
._010Erfassung__button___0V2N4.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__e4Ekn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__w51Hv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__ifpam.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__nLbk6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__w7D4W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__g5Us.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__zBso {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rrwdw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__h9Xf.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__cjyow {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___4BScl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__nBzft.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__fqbrc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__pd6Dp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__utU0X.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___570FP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__h0Fpt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__rfDs.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__licI.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput179__aPk.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__xaJ6P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__vhQT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__cxlx1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___0FzIe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__eStDw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__de3Ua.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__fhmzJ.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
._010Erfassung__button___7AO1M.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__unb1H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___42WGn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___9W4O.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__sZ92W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fLk0Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___0Ltbi.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__zbLkV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__vtvTl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__skaPa.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__sFzzg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__q19YG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__cq0JZ.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__t5G97.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
._010Erfassung__text__zKxl9 {
  height: auto;
}
._010Erfassung__button__ounpn.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__kgnJu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__he1Fp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__xexL.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__uq1E8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___6DleP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__buXDv.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__iOtUp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hMinT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__lbjog.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___5Xvli {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___9YNha {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___924CI.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__zLsgD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__aoUyz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___9EX8P.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__qiHVh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__q5P5H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rFC.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__ioE4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__jmIqE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__jMwfl.__wab_instance {
  position: relative;
}
._010Erfassung__text__ulHny {
  padding-right: 0px;
}
._010Erfassung__processCardSection__nxpR.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__nJ4De {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__nJ4De {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__nJ4De {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__qdm4G.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
._010Erfassung__text__uy8Bo {
  height: auto;
}
._010Erfassung__button__zYoqB.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__mmtS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__aoO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__aoSbJ.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__zCYwV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fqcuZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__dvRbG.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___5VQs6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ohmXt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___0VlN9.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__y7HyI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wFytQ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__wSrW1.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__wyQf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__m6U5U {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__htryF.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__pejog.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__vyDqv {
  padding-right: 0px;
}
._010Erfassung__textInput180__ugJOg.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___9EKvv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__nklQe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ycTaf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__i4PZf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__oGhI3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__gocMi.__wab_instance {
  position: relative;
}
._010Erfassung__text__shB {
  padding-right: 0px;
}
._010Erfassung__formGroup__mDtt.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput185__gNajd.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg___2PREe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ezsdo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__f1MRq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__uwTjp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__g78K3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__kasUk.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__we0Ge.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__r67Hg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__rIoU4 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__rIoU4 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__rIoU4 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__dynamicEnergyList__kQrx.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__f0NF3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__ey9A9.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem___2Rzj.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__hRzcT.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__wdGKy.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__riBxt.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__wgmht.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem___3KGrv.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__wjpHg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicEnergyListItem__aghwG.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__ktOCj {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010Erfassung__freeBox__ktOCj > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
._010Erfassung__freeBox__ktOCj > .__wab_flex-container > *,
._010Erfassung__freeBox__ktOCj > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__ktOCj > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__ktOCj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
._010Erfassung__freeBox__mw8Nw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
._010Erfassung__text__acOFa {
  padding-right: 0px;
}
._010Erfassung__text__rerjV {
  position: relative;
  font-size: var(--token-lwXLVsVbV9nDvt);
  color: var(--token-HHAuBKYL3pvmHz);
}
._010Erfassung__button__fu0JX.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__kdVtO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gJz8P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__processCardSection__hz5Tq.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__c5H2F {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__c5H2F {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__c5H2F {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__dynamicPowerList__qbGr0.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicPowerListItem__xmr7P.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicPowerListItem__il3EM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dynamicPowerListItem__bce5N.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__text__mIEpF {
  padding-right: 0px;
}
._010Erfassung__processCardSection__eCopb.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___9Zcbx {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___9Zcbx {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___9Zcbx {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__tirmy.__wab_instance {
  position: relative;
  grid-column-end: span 6;
  grid-column-start: 1;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__tirmy.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text__bebwK {
  height: auto;
}
._010Erfassung__button__vObBi.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__mQPr2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___0AaJg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__aTqLm.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__go05S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__sk96N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__tw86A.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__qeus4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___3FOkg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__eDeby.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__jX8T.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text___6OJhR {
  padding-right: 0px;
}
._010Erfassung__textInput183__bIrwe.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__n93Tv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__rfKxP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tk5K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___8V2Qi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__tCBhB {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__diFs4.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__lo2E9.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__text__p2WkE {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__textInput181__wc6VM.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg___4NGzl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__e6Yat {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aMy8P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__s71R {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__mwSGi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__eRjKm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__aVpMy.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__text__ltnGh {
  padding-right: 0px;
  height: auto;
}
._010Erfassung__select10__b5Ytp.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__mGsKj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__bk716.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vFrIy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__oWt4O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ihcxS.__wab_instance {
  position: relative;
}
._010Erfassung__svg__rvLuX {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__z7Ejj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__pYdEe.__wab_instance {
  position: relative;
}
._010Erfassung__svg__qtJdj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__kEu4K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__h3MC.__wab_instance {
  position: relative;
}
._010Erfassung__svg__qQef3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__zfoI {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__t0HVj.__wab_instance {
  position: relative;
}
._010Erfassung__svg__sPNm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__r5PXm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ov6Tn.__wab_instance {
  position: relative;
}
._010Erfassung__svg___2OuB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__aD0Fe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__j63M.__wab_instance {
  position: relative;
}
._010Erfassung__svg__oaqgo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__s1AyA {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__aa0Rp.__wab_instance {
  position: relative;
}
._010Erfassung__svg___47Jdp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__b2RkL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___4VuLi.__wab_instance {
  position: relative;
}
._010Erfassung__svg__rz60H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___9TWbZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__biGit.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ibda {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__w7X1Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ytFxl.__wab_instance {
  position: relative;
}
._010Erfassung__svg__fvMqc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__yLdUd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__pQanJ.__wab_instance {
  position: relative;
}
._010Erfassung__svg__t61Zw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___5GNm1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__kiBo1.__wab_instance {
  position: relative;
}
._010Erfassung__svg__kh9K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__jLrBo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__woi3W.__wab_instance {
  position: relative;
}
._010Erfassung__text__zDRiH {
  padding-right: 0px;
}
._010Erfassung__formGroup__h9GW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput186___7X9SS.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__kBgoi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__lSjwk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__vxAwn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__dhAfw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hfNm5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ytFww.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__zblJs.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__mdW9T.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__rWpSc {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__rWpSc {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__rWpSc {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__mDwfq.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__zfVYt.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__oPgP.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__iG7Z1.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__geakArt__qcy1.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__lxMbu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__llVo4.__wab_instance {
  position: relative;
}
._010Erfassung__svg__bksPy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__pxrxt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ggEfk.__wab_instance {
  position: relative;
}
._010Erfassung__svg___72Fn4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__pxlgN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___2BSbS.__wab_instance {
  position: relative;
}
._010Erfassung__svg__lbMb8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__yi13X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__lnttm.__wab_instance {
  position: relative;
}
._010Erfassung__svg___0BK9C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__eo0K5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__bVex.__wab_instance {
  position: relative;
}
._010Erfassung__svg__sWkIx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xn5Cm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__uNdv3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__tpgAc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__snbLp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter___3S9Dm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__shvlr.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__geakDokumentennummer__w6M5D.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vzNRv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xjbC3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pzajg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xbaNm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__ccSew {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aqQaX.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__nbcVm.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput114__v6Ex8.__wab_instance {
  position: relative;
}
._010Erfassung__svg__odl27 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ecom1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__sf0O7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__pHLb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kSGgi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wPTkH.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___2J7T.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput178__sDg4V.__wab_instance {
  position: relative;
}
._010Erfassung__svg__iDfpo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__eyPo4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___0Vt6T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___6FAa4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__bwjgo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__hBi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__eCgMr.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__aWzpm.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter___0AuAk.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6ZaFc.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
._010Erfassung__singleSelectButtonGroup__yyj6J.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__zetJs.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__pmxn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__uQcW {
  width: auto;
}
._010Erfassung__svg__nijpE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__gkgx6.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__vCqMt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__c88N {
  padding-left: 0px;
}
._010Erfassung__svg__usB7Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__twya3.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__jZRu7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ehyTp {
  padding-left: 0px;
}
._010Erfassung__svg__fiAeC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__hF46D.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__moc5K {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__n2Bbh {
  padding-left: 0px;
}
._010Erfassung__svg__zyrKd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__tKzqa.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__extbN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bppwe {
  padding-left: 0px;
}
._010Erfassung__svg__gyxnL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__bNulZ.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___3Axbv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mBbVp {
  padding-left: 0px;
}
._010Erfassung__svg___5DiRw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___28Qiz.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__s03Wy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__yo7Il {
  padding-left: 0px;
}
._010Erfassung__svg__fthh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__b93AW.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__olEyb.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__geakGeEff___0SvaX.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ccqJg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___028UO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bFjjy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__uZag3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xdJpv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__fb1Op.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__tVPoU.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
._010Erfassung__singleSelectButtonGroup__bwUlU.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__ksv4H.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__iToGg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oGhsE {
  width: auto;
}
._010Erfassung__svg__a30Wd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__zl4TS.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__fUyTt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__z4HEw {
  padding-left: 0px;
}
._010Erfassung__svg__ygTxm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__lEsfl.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__o9DtL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jiQf {
  padding-left: 0px;
}
._010Erfassung__svg__swNx9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__ogEdm.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__hOvwT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__w08Yv {
  padding-left: 0px;
}
._010Erfassung__svg__rDiLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__jCt1T.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__n9Gv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__nWnA {
  padding-left: 0px;
}
._010Erfassung__svg__wmQTu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__mu6J7.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__upOc1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__tsDvs {
  padding-left: 0px;
}
._010Erfassung__svg__um833 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___5Iqab.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__yiZyV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zX42 {
  padding-left: 0px;
}
._010Erfassung__svg__nuvag {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__uBzP.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dayyP.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__geakGhEff2__uD4T5.__wab_instance {
  position: relative;
}
._010Erfassung__svg__v11Bj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___83Zf7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__aoJms {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qdM3A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___0RMrO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__mLLd.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__e3VaX.__wab_instance {
  position: relative;
  grid-column-end: span 4;
  grid-column-start: 1;
}
._010Erfassung__singleSelectButtonGroup__n1UGa.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__uMrih.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__eeqdn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__smUer {
  width: auto;
}
._010Erfassung__svg__ourC3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__vh8Ng.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__yvdvV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__cdwKs {
  padding-left: 0px;
}
._010Erfassung__svg__ivaF9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__pALi.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__fOzY0 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jShvz {
  padding-left: 0px;
}
._010Erfassung__svg__nblrn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___9WaWr.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__bjy7Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__vtFfo {
  padding-left: 0px;
}
._010Erfassung__svg__c8FYv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___3UoHf.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___1QzwK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oPpuN {
  padding-left: 0px;
}
._010Erfassung__svg___4Rbu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__kpWfY.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__sAwuc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__eqJv7 {
  padding-left: 0px;
}
._010Erfassung__svg__iW05 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button___7Oy0C.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__ompqM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pVv3L {
  padding-left: 0px;
}
._010Erfassung__svg__tm14J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__wLuin.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ssbd3.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__geakCo2Aeq2__xIffp.__wab_instance {
  position: relative;
}
._010Erfassung__svg___568Ws {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___0CyZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pIImK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text___8Nw5C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cmQjg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__tFgk3.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__x1CWi.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__geakEbf__rnI7O.__wab_instance {
  position: relative;
}
._010Erfassung__svg__svDfS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ntemu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__wmiWt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__r34U0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__nNmfu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__oc9Bj.__wab_instance {
  position: relative;
}
._010Erfassung__text__fzY3P {
  padding-right: 0px;
}
._010Erfassung__processCardSection__wme7O.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__lk9Wi {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__lk9Wi {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__lk9Wi {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__wRovf.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__booleanSelectButtonGroup__xxYx0.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__id54Q.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__u8CG.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__singleSelectButtonGroup___2WPlR.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__hXt9I.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__vnWhN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__gEwGm {
  height: auto;
}
._010Erfassung__svg__g9E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__rdE5.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__vZDjj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ipzD {
  padding-left: 0px;
}
._010Erfassung__svg__fdMa9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__reChW.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__oHhHd.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__standardMinergie___9J1C4.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__d1Iiw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__jNvcR.__wab_instance {
  position: relative;
}
._010Erfassung__svg__pJzuu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ep2XB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__c1I48.__wab_instance {
  position: relative;
}
._010Erfassung__svg__tpM2C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ca4Pg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__okNqQ.__wab_instance {
  position: relative;
}
._010Erfassung__svg__a3QjC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___5PfnK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__oqe1I.__wab_instance {
  position: relative;
}
._010Erfassung__svg__pUnAp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__pmAGe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__mzuDa.__wab_instance {
  position: relative;
}
._010Erfassung__svg__pdtt1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__sDkfL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__g2C3O.__wab_instance {
  position: relative;
}
._010Erfassung__svg__omhvw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__raYsj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter___2DBvi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dw0Lq.__wab_instance {
  position: relative;
  grid-column-end: span 2;
  grid-column-start: 1;
}
._010Erfassung__minergieNummer__imeAq.__wab_instance {
  position: relative;
}
._010Erfassung__svg___6HJj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dpvAf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___2LhFz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__vhNuJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__wdNjm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__wkgzj.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__krEW.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__textInput182__oOrB5.__wab_instance {
  position: relative;
}
._010Erfassung__svg__z0VJb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__kUaDk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__qCkWy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___2B5Xf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fYtp7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__rermT.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___316Dl.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput187__ijBf6.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__xnN8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__jz24M {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__yAJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___964Px {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___6AhDr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__lTqH.__wab_instance {
  position: relative;
}
._010Erfassung__processCard__p0Hw5.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__xQqrg.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__qw7T0 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__qw7T0 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__qw7T0 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010Erfassung__formGroup__pkl6T.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__eIoH1.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__jwTfv.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ufrY.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__vkTBt {
  height: auto;
}
._010Erfassung__textInput236__oYkmc.__wab_instance {
  position: relative;
}
._010Erfassung__svg__br5Sa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___2AuUq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__blbIr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__xH9Bh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___3L5In {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__gq5Jp.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__fnbv.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__qa111 {
  height: auto;
}
._010Erfassung__textInput237__v4XIb.__wab_instance {
  position: relative;
}
._010Erfassung__svg___3B3Px {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xor7S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__c704U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__iaMlw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xnMwf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___6Rd9F.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cb0To.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__ncSbd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter___3XGo.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ikFsO.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__kaLS {
  height: auto;
}
._010Erfassung__textInput238__yA6Yh.__wab_instance {
  position: relative;
}
._010Erfassung__svg__rlz60 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__cQjre {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__xVpHi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__aeMc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cbKze {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__vk8Ka.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___0NWD.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__m5H9H {
  height: auto;
}
._010Erfassung__textInput239__uYhgq.__wab_instance {
  position: relative;
}
._010Erfassung__svg__zoPsi {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___72Gq5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__gz8Nc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__g5ICe {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___8AIJv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__idD11.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__lz47R.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__o7AXh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__ztoXa.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__d5KWp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__fwFk {
  height: auto;
}
._010Erfassung__textInput240__xzvvd.__wab_instance {
  position: relative;
}
._010Erfassung__svg__cCkzG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__i5K66 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pkqIh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__fRhDv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__igFwy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__pWWsC.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__hguRs.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__orJx {
  height: auto;
}
._010Erfassung__textInput241__uywZu.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dpUfP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__bhSw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__qnzrs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__caPd2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__dNNs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___6OnUi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ylSty.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__d6Kia.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__loiNx.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__st8Nj.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__cR1UW {
  height: auto;
}
._010Erfassung__textInput244__qYvxN.__wab_instance {
  position: relative;
}
._010Erfassung__svg__iwaQq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__s1XDn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__oJePs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__f82Wc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___9Dwf2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__zUea4.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___87YWp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__aA7Bi {
  height: auto;
}
._010Erfassung__textInput245__mzGBp.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xjQ7X {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dk31Z {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___7EJf2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__cs57W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__vsmhW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__aZgY7.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dDpDp.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup___6UZwC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__gCnHg.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___6NzV.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__dQ69Q {
  height: auto;
}
._010Erfassung__textInput246__ohbje.__wab_instance {
  position: relative;
}
._010Erfassung__svg__wRgS {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gtIs8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__puA7K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___0WZvr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__cXLba {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___5P5El.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___3YWf1.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text___40E9W {
  height: auto;
}
._010Erfassung__textInput247__e2TSx.__wab_instance {
  position: relative;
}
._010Erfassung__svg__okbWm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__o5KK3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__soQ7V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__ngO5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kUBo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__q4Ah0.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__ivGo0.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup___6Drcn.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__kU7Zp.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__s38Ed.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__tbrw {
  height: auto;
}
._010Erfassung__textInput248___3Vpc2.__wab_instance {
  position: relative;
}
._010Erfassung__svg__tqBp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__igoiO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__q3BYr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__hLt5I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__dnu8T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__eYpS.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__m1YxL.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__x4Dr {
  height: auto;
}
._010Erfassung__textInput249__eN9Zn.__wab_instance {
  position: relative;
}
._010Erfassung__svg__oLb6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__cGcg2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ybzvT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__qcOdQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___8QsRq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__s32KK.__wab_instance {
  position: relative;
}
._010Erfassung__text__cnUqj {
  padding-right: 0px;
}
._010Erfassung__processCardSection__zVfP5.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__zbYrs {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__zbYrs {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__zbYrs {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010Erfassung__futureEnergyMainCategory__dqlKo.__wab_instance.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__futureEnergyMainCategory__dqlKo.__wab_instance.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__futureEnergySubCategory__z5Lro.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__futureEnergySubCategory__z5Lro.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__formGroup___0Gqjl.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup___0Gqjl.__wab_instance {
    grid-column-start: 1;
  }
}
._010Erfassung__text__zEZ8 {
  height: auto;
}
._010Erfassung__textInput253__qNJ.__wab_instance {
  position: relative;
}
._010Erfassung__svg___906L9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__gq9LD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__eYQcH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__odVfm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__we0WU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__s0Lzm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__f7Nnh.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__ue3W {
  height: auto;
}
._010Erfassung__textInput254__mp0.__wab_instance {
  position: relative;
}
._010Erfassung__svg__id55 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__hUmPb {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__ytcxI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__lI5T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__hh2Gd {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__qExdl.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection___1Dd5K.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___8Yid8 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___8Yid8 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___8Yid8 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
._010Erfassung__formGroup___59YeJ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__f4Bxr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter___0ZgHz.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cm3Dc.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__text__c3J9D {
  height: auto;
}
._010Erfassung__textInput250__q7Gbu.__wab_instance {
  position: relative;
}
._010Erfassung__svg__rVald {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__juzbt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__mmVhZ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___9GVkg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__kjjBv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__eM1X.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__hYnL.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__hYnL.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__text___1McD2 {
  height: auto;
}
._010Erfassung__textInput215__paM9T.__wab_instance {
  position: relative;
}
._010Erfassung__svg__zNpQ3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__vdf5E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pFhd8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__rdJ5W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__gdYQl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___8FuzZ.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__qMjq.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
@media (max-width: 480px) {
  ._010Erfassung__formGroup__qMjq.__wab_instance {
    grid-column-end: span 2;
  }
}
._010Erfassung__textInput234__xLpyr.__wab_instance {
  position: relative;
}
._010Erfassung__svg___01JMr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__t9SK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__pGy8N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___06Xdx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__if6Ix {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ku8Bk.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__o2BV7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___6ZGqm {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___6ZGqm {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___6ZGqm {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__a4Hm.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput212__yeUnm.__wab_instance {
  position: relative;
}
._010Erfassung__svg__zAb72 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__fAj7W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zSbWx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__pQfmS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__iw6Ub {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__nrTsu.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__yhN3K.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput213__oio0O.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vHw0S {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__aKxHw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jvgXi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__lnNnp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__fUkgW {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__oK8Aq.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__cyfsx.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput214__mgfC5.__wab_instance {
  position: relative;
}
._010Erfassung__svg__x2Xo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ydOpL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__i8YTj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__klaU3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__nhMqw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__lrE5I.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dJfde.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput235__aiNuu.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__p7SpH {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___85R4O {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__luO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text___5Q4P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__qXqv3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dRl0.__wab_instance {
  position: relative;
}
._010Erfassung__processCard___9Eoub.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__iUfR1.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__yc9Et {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__yc9Et {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__yc9Et {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__h76MK.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__booleanSelectButtonGroup__nleHc.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__idl8J.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__nvpMt.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__select12___1Pyub.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__hbn7V {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__gPrDu.__wab_instance {
  position: relative;
}
._010Erfassung__svg__z8ZGd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__dofnK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__y4Eq.__wab_instance {
  position: relative;
}
._010Erfassung__svg__cnzIs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xr1Ik {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ixe4N.__wab_instance {
  position: relative;
}
._010Erfassung__svg__kPmp5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__hcVcR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__to987.__wab_instance {
  position: relative;
}
._010Erfassung__svg__rMiIf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___8VpBj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___00X5F.__wab_instance {
  position: relative;
}
._010Erfassung__svg__tBsac {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__yPdG8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___4RzUc.__wab_instance {
  position: relative;
}
._010Erfassung__svg__tyf0N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__rv497 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option___93NdT.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dW84C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__uY2Lm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__yIhR.__wab_instance {
  position: relative;
}
._010Erfassung__svg__itjbv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__k1Gsn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ckzhy.__wab_instance {
  position: relative;
}
._010Erfassung__svg__gpyfO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__zf7U4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__p4J8E.__wab_instance {
  position: relative;
}
._010Erfassung__svg__bto7 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__dywNs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__yhiOo.__wab_instance {
  position: relative;
}
._010Erfassung__svg__lth9R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___4UKzr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__lyedm.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vfl8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___5Yk9P {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__fmfsw.__wab_instance {
  position: relative;
}
._010Erfassung__svg__jfOe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__cgWsy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ol283.__wab_instance {
  position: relative;
}
._010Erfassung__svg__qqSpO {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__nwEkh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__aMc4O.__wab_instance {
  position: relative;
}
._010Erfassung__svg__i6Git {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ftZo6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__wiLuR.__wab_instance {
  position: relative;
}
._010Erfassung__svg___3Gkja {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__s8Wh3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__owf32.__wab_instance {
  position: relative;
}
._010Erfassung__svg__m43 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ks0LG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__p1Mi.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__rcCz4.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput188__ruC7O.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ahOgp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__l2Tbd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__cHT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__sL8F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__iX1Ui {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__vjTex.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__pHNwG.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput189___9NyJo.__wab_instance {
  position: relative;
}
._010Erfassung__svg___8A0XN {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xQqw5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6EhQy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__f56Dc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__j1TtT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___9C0Px.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__fl8Dn.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__select11__d04Qu.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__svg__vsPMi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
._010Erfassung__option__zWlWk.__wab_instance {
  position: relative;
}
._010Erfassung__svg__tcjj8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___5GIu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__g1M4X.__wab_instance {
  position: relative;
}
._010Erfassung__svg__chtM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__faPxo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__yJrif.__wab_instance {
  position: relative;
}
._010Erfassung__svg___4M7J2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__sUkJq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__xvIfi.__wab_instance {
  position: relative;
}
._010Erfassung__svg__xxHe4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__p9Cm6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__zZml9.__wab_instance {
  position: relative;
}
._010Erfassung__svg___4CeKz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___8Q4Es {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__uK6D.__wab_instance {
  position: relative;
}
._010Erfassung__svg__zwZYh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__uo2Te {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ta586.__wab_instance {
  position: relative;
}
._010Erfassung__svg__pnd04 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__zkXpv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__cZdGh.__wab_instance {
  position: relative;
}
._010Erfassung__svg__mfZhD {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__aobQr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__ayeOq.__wab_instance {
  position: relative;
}
._010Erfassung__svg___0PbLq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__lvmaw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__gM7Cc.__wab_instance {
  position: relative;
}
._010Erfassung__svg__nymk4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___1IGVg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__pmtsn.__wab_instance {
  position: relative;
}
._010Erfassung__svg__kENpL {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__quT3Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__sZ2Xg.__wab_instance {
  position: relative;
}
._010Erfassung__svg__um543 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__f913T {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__hSuov.__wab_instance {
  position: relative;
}
._010Erfassung__svg__isws2 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg___96XFe {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__lgDNu.__wab_instance {
  position: relative;
}
._010Erfassung__svg___2VFnR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__xonAo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__tqFn7.__wab_instance {
  position: relative;
}
._010Erfassung__svg__buCk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__ktqps {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__wou5M.__wab_instance {
  position: relative;
}
._010Erfassung__svg__kkHp5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__otOdc {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__option__wh0G.__wab_instance {
  position: relative;
}
._010Erfassung__svg__arcps {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
  flex-grow: 0;
}
._010Erfassung__svg__n2M8L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__zx9Jm.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___74Az8.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput190__hAXI.__wab_instance {
  position: relative;
}
._010Erfassung__svg__hMPyM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__t7Vuy {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__shOFs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__llvrc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__w0NyO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__i3XHg.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___9XZb0.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput191__geCHl.__wab_instance {
  position: relative;
}
._010Erfassung__svg__vKx5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__yrn4D {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__iVyKp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text___68VM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__f7Y9E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__ueGs1.__wab_instance {
  position: relative;
}
._010Erfassung__text__lCmYn {
  padding-right: 0px;
}
._010Erfassung__processCardSection__j49.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox__byBd {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__byBd {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__byBd {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__uNWx.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup___9YbnQ.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__kq6U1.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__yxPgO.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput192__zk2Qo.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dIgBx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wp3Zv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6PeS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text___6Vzfi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__wcvmv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__yZNmH.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__dhtQw.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput193__z24HF.__wab_instance {
  position: relative;
}
._010Erfassung__svg__wNHwz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__tlJbC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__cMXyu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__lb3Lu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__smxHk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__r5AG.__wab_instance {
  position: relative;
}
._010Erfassung__text__w0CVp {
  padding-right: 0px;
}
._010Erfassung__processCardSection__ikMvM.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___5EWgN {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___5EWgN {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___5EWgN {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup___01BU9.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup___20Nip.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter___3DnUs.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__aTyYv.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput194__f3Zsp.__wab_instance {
  position: relative;
}
._010Erfassung__svg__pvzIw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___71EdE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jLeSp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__ahhwj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__xZcUm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__xd9Y.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__sz0W.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput195__kBjJ6.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ytrwu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__f6O3E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text___6Lt4P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__t10JM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__gNtcq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__mijqa.__wab_instance {
  position: relative;
}
._010Erfassung__text__b0UWb {
  padding-right: 0px;
}
._010Erfassung__processCardSection___4BS4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___9HRv2 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___9HRv2 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___9HRv2 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__ghKw0.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__brKes.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter___2Irx0.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__gmAgf.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput196__cz9FP.__wab_instance {
  position: relative;
}
._010Erfassung__svg__aylbK {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__h5Gr {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__v2M7L {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__isj9Y {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___44Ahw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___0Xw0O.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__wKfN.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput197__vvMyJ.__wab_instance {
  position: relative;
}
._010Erfassung__svg__ovSZz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__qT5Mu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__bhGb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__qztve {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___1T46 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter___1ZSsy.__wab_instance {
  position: relative;
}
._010Erfassung__text__g4NfD {
  padding-right: 0px;
}
._010Erfassung__processCardSection__wdnzI.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__freeBox___6Ho47 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox___6Ho47 {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox___6Ho47 {
    grid-template-columns: 1fr;
  }
}
._010Erfassung__formGroup__ds5Kq.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
._010Erfassung__booleanSelectButtonGroup__u0Wl6.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__symbolCounter__eoWj.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__h6MVi.__wab_instance {
  position: relative;
  grid-column-end: span 3;
}
._010Erfassung__textInput198__njCdQ.__wab_instance {
  position: relative;
}
._010Erfassung__svg__dvv0B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__iRt62 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__poRcC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__zxFjX {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__jrMtR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__afwIt.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__uzZcO.__wab_instance {
  position: relative;
  grid-column-end: span 3;
  grid-column-start: 1;
}
._010Erfassung__textInput199__zb2AV.__wab_instance {
  position: relative;
}
._010Erfassung__svg__s1JFs {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__tVjU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__lmdsw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  padding-right: 0px;
  min-width: 0;
}
._010Erfassung__text__t5Xa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___5F9ZK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__kTnRi.__wab_instance {
  position: relative;
}
._010Erfassung__text__scCbk {
  padding-right: 0px;
}
._010Erfassung__formGroup__zo9LC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput200__phr3.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__sfSir {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__p7BTf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__zSlz2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__k9VNc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text___1JnTk {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__v5Sqw.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSocialQuestionnaire__souxd.__wab_instance.__wab_instance {
  position: relative;
}
._010Erfassung__processSection__yCy55.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__processCard__hdWm.__wab_instance {
  position: relative;
}
._010Erfassung__processCardSection__eYeBb.__wab_instance {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__processCardSection__eYeBb.__wab_instance {
    max-width: 100%;
    position: relative;
  }
}
._010Erfassung__formGroup___7CY67.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__jaId.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__jaId.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__yOqC.__wab_instance {
  position: relative;
}
._010Erfassung__freeBox__bt7Kx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010Erfassung__freeBox__bt7Kx > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > *,
._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__bt7Kx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__bt7Kx {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > *,
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > .__wab_slot > *,
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > picture > img,
  ._010Erfassung__freeBox__bt7Kx
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__bt7Kx {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > *,
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > .__wab_slot > *,
  ._010Erfassung__freeBox__bt7Kx > .__wab_flex-container > picture > img,
  ._010Erfassung__freeBox__bt7Kx
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._010Erfassung__freeBox___4Xwps {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
._010Erfassung__freeBox___4Xwps > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
._010Erfassung__freeBox___4Xwps > .__wab_flex-container > *,
._010Erfassung__freeBox___4Xwps > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox___4Xwps > .__wab_flex-container > picture > img,
._010Erfassung__freeBox___4Xwps
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
  margin-top: 12px;
}
._010Erfassung___switch__vbum.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__aGgKp.__wab_instance {
  position: relative;
}
@media (max-width: 480px) {
  ._010Erfassung__button__aGgKp.__wab_instance {
    position: relative;
  }
}
._010Erfassung__svg___9Edjq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__alqPd {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__processCardSection__l9Jz.__wab_instance {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__processCardSection__l9Jz.__wab_instance {
    max-width: 100%;
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__processCardSection__l9Jz.__wab_instance {
    max-width: 100%;
    position: relative;
  }
}
._010Erfassung__freeBox__dm881 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010Erfassung__freeBox__dm881 > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
._010Erfassung__freeBox__dm881 > .__wab_flex-container > *,
._010Erfassung__freeBox__dm881 > .__wab_flex-container > .__wab_slot > *,
._010Erfassung__freeBox__dm881 > .__wab_flex-container > picture > img,
._010Erfassung__freeBox__dm881
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__dm881 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 24px);
    height: calc(100% + 24px);
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > *,
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > .__wab_slot > *,
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > picture > img,
  ._010Erfassung__freeBox__dm881
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__dm881 {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 0;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 480px) {
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > *,
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > .__wab_slot > *,
  ._010Erfassung__freeBox__dm881 > .__wab_flex-container > picture > img,
  ._010Erfassung__freeBox__dm881
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
._010Erfassung__freeBox__feqqg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
._010Erfassung__text__hqt45 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 500;
  min-width: 0;
}
._010Erfassung__button__ivb3W.__wab_instance {
  position: relative;
  align-self: flex-end;
}
@media (max-width: 480px) {
  ._010Erfassung__button__ivb3W.__wab_instance {
    position: relative;
  }
}
._010Erfassung__svg__ggj4Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__kMh99 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__ratingCardBlock__si8Xm.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__si8Xm.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__si8Xm.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__yenLy.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__k0OxJ.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__k0OxJ.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__i2TSb.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__npeEi.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__jpVuh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__l2Po3.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__xcF6K.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__oevYq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__iFg7.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__iFg7.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__iFg7.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__jeEr8.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider___1Dttn.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider___1Dttn.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__scoQs.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__hpss4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__x1Bt4.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__t3A1Z.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment___4Gph.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__sYp1R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock___3X8Nk.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock___3X8Nk.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock___3X8Nk.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__upRl.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__qvMe.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__qvMe.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter___2L2Vi.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__wHvzu.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__pDj2A.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment___35KWu.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__h0VW.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__oJpxj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__tO2Sq.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__tO2Sq.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__tO2Sq.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__vsd1.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__lgaLp.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__lgaLp.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__fnTfo.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__w2ZS.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__cJv5P.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__ypky.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__hhAvL.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__eCmBs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__zagQu.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__zagQu.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__zagQu.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__ktFc4.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__wuwuI.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__wuwuI.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__jglhm.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__bjt65.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__tJAh.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__jdEoA.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__znUjf.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__x6XlU {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__tcbiC.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__tcbiC.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__tcbiC.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__nYd06.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__pxprV.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__pxprV.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__taxm5.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment___6WRnG.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__u6ORk.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__gmCdd.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__xFwaR.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__gvobj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__i8Po.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__i8Po.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__i8Po.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup__gtnL.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__l6IHm.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__l6IHm.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__frxMv.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__zQRjr.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__pFfN7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__vLiGu.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__kqmoC.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__gisJc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__ratingCardBlock__bRy28.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1400px) {
  ._010Erfassung__ratingCardBlock__bRy28.__wab_instance {
    position: relative;
  }
}
@media (max-width: 480px) {
  ._010Erfassung__ratingCardBlock__bRy28.__wab_instance {
    position: relative;
  }
}
._010Erfassung__formGroup___72TD.__wab_instance {
  width: 100%;
  min-width: 0;
}
._010Erfassung__slider__tZcFy.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 480px) {
  ._010Erfassung__slider__tZcFy.__wab_instance {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 0;
  }
}
._010Erfassung__symbolCounter__wXqHa.__wab_instance {
  position: relative;
}
._010Erfassung__dataBarSegment__dSYng.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__bsiO.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment___6UKfu.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__dataBarSegment__oY9Pl.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__svg__gouds {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-D5PfQGq_p2o95u);
  height: 1em;
}
._010Erfassung__formGroup__i2ZSq.__wab_instance {
  position: relative;
}
._010Erfassung__singleSelectButtonGroup__poix7.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__button__qabl6.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__fjjMj {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__dwUak {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__cYfP4.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__lu6D1 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__veWiw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__e0Uvc.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__u60Sg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg___7ZdRm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__jwjBf.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg___7N3BR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__mYumC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__button__v1Rw.__wab_instance {
  max-width: 100%;
}
._010Erfassung__svg__hFv3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__ii6Ap {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__symbolCounter__xt08I.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup__bt7Mo.__wab_instance {
  position: relative;
  width: 100%;
  min-width: 0;
}
._010Erfassung__textInput91__lgSRe.__wab_instance {
  position: relative;
}
._010Erfassung__svg__yeMnq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__xj5Dt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__abAqF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__f2SdH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__miAnp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__e0IUj.__wab_instance {
  position: relative;
}
._010Erfassung__formGroup___51C6P.__wab_instance {
  max-width: 100%;
  position: relative;
}
._010Erfassung__textInput92__cd0VB.__wab_instance {
  position: relative;
  margin-right: 0px;
}
._010Erfassung__svg__lDrgP {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__svg__wr9Yx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
._010Erfassung__text__jnZt1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
._010Erfassung__text__nyXNp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__text__o6Lcg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
._010Erfassung__symbolCounter__dcML.__wab_instance {
  position: relative;
}
._010Erfassung__processStickyNav__kqKOp.__wab_instance {
  max-width: 100%;
  position: fixed;
  left: 0;
  top: auto;
  z-index: 3;
  right: 0;
  bottom: 0;
}
._010Erfassung__footer__j0GLh.__wab_instance {
  max-width: 100%;
  position: relative;
}
