.Footer__root___2OG {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-qecgvVQwVo7jFR);
  padding-bottom: var(--token-x_jRp3a5FpON8z);
  min-width: 0;
}
.Footer__freeBox__hw3D {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: var(--token-YOOgp3GSpDYosY);
  padding-left: var(--token-YOOgp3GSpDYosY);
  min-width: 0;
}
@media (max-width: 480px) {
  .Footer__freeBox__hw3D {
    padding-left: var(--token-P3nFMsZz2JMXx8);
    padding-right: var(--token-P3nFMsZz2JMXx8);
  }
}
.Footer__freeBox__xRYgR {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-fkHbhUQaFbf-Tk);
  padding-bottom: var(--token-fkHbhUQaFbf-Tk);
  grid-row-gap: var(--token-YOOgp3GSpDYosY);
  grid-column-gap: var(--token-YOOgp3GSpDYosY);
  min-width: 0;
  border-top: 2px solid #c1cec924;
}
@media (max-width: 480px) {
  .Footer__freeBox__xRYgR {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.Footer__freeBox__unmFm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
.Footer__freeBox__unmFm > .__wab_flex-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__unmFm > .__wab_flex-container > *,
.Footer__freeBox__unmFm > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__unmFm > .__wab_flex-container > picture > img,
.Footer__freeBox__unmFm > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__unmFm {
    grid-column-end: span 4;
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__unmFm > .__wab_flex-container {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
    height: calc(100% + var(--token-x_jRp3a5FpON8z));
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__unmFm > .__wab_flex-container > *,
  .Footer__freeBox__unmFm > .__wab_flex-container > .__wab_slot > *,
  .Footer__freeBox__unmFm > .__wab_flex-container > picture > img,
  .Footer__freeBox__unmFm
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-x_jRp3a5FpON8z);
  }
}
.Footer__freeBox__dYgNo {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  min-width: 0;
}
.Footer__freeBox__dYgNo > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-7uzqW3y-2Tgb_s));
  width: calc(100% + var(--token-7uzqW3y-2Tgb_s));
}
.Footer__freeBox__dYgNo > .__wab_flex-container > *,
.Footer__freeBox__dYgNo > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__dYgNo > .__wab_flex-container > picture > img,
.Footer__freeBox__dYgNo > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-7uzqW3y-2Tgb_s);
}
.Footer__img__bNsJ {
  object-fit: scale-down;
  max-width: var(--token-HUUwrpE5bq87rt);
}
.Footer__img__bNsJ > picture > img {
  object-fit: scale-down;
}
.Footer__img__pWsy4 {
  object-fit: scale-down;
  max-width: var(--token-qecgvVQwVo7jFR);
}
.Footer__img__pWsy4 > picture > img {
  object-fit: scale-down;
}
.Footer__freeBox__pbQn7 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Footer__freeBox__pbQn7 > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-a0wt3E9LJjO_Nh));
  width: calc(100% + var(--token-a0wt3E9LJjO_Nh));
}
.Footer__freeBox__pbQn7 > .__wab_flex-container > *,
.Footer__freeBox__pbQn7 > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__pbQn7 > .__wab_flex-container > picture > img,
.Footer__freeBox__pbQn7 > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-a0wt3E9LJjO_Nh);
}
.Footer__langSelectItem__bNgA.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Footer__langSelectItem__tvEjj.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Footer__langSelectItem__j5Zfr.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Footer__langSelectItem__nejaz.__wab_instance {
  max-width: 100%;
  position: relative;
}
.Footer__freeBox__hRIiK {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 5;
  min-width: 0;
  display: none;
}
.Footer__freeBox__hRIiK > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__hRIiK > .__wab_flex-container > *,
.Footer__freeBox__hRIiK > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__hRIiK > .__wab_flex-container > picture > img,
.Footer__freeBox__hRIiK > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__hRIiK {
    grid-column-end: span 4;
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__hRIiK {
    grid-column-end: span 3;
  }
}
.Footer__text__dcJUx {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-gBTCEyDxU);
}
.Footer__text__uCn9K {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-xgado2J7YlFS13);
}
.Footer__text__ybiwe {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-xgado2J7YlFS13);
}
.Footer__text__wpUl {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-xgado2J7YlFS13);
}
.Footer__text__q4Jv {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-xgado2J7YlFS13);
}
.Footer__freeBox__q9Ntr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 8;
  min-width: 0;
}
.Footer__freeBox__q9Ntr > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.Footer__freeBox__q9Ntr > .__wab_flex-container > *,
.Footer__freeBox__q9Ntr > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__q9Ntr > .__wab_flex-container > picture > img,
.Footer__freeBox__q9Ntr > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__q9Ntr {
    grid-column-end: span 5;
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__q9Ntr {
    grid-column-end: span 3;
  }
}
.Footer__text__mRnI {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-gBTCEyDxU);
}
.Footer__freeBox__f6EFf {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Footer__freeBox__f6EFf > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - var(--token-ti5ui7Y6hnPLfa));
  width: calc(100% + var(--token-ti5ui7Y6hnPLfa));
}
.Footer__freeBox__f6EFf > .__wab_flex-container > *,
.Footer__freeBox__f6EFf > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__f6EFf > .__wab_flex-container > picture > img,
.Footer__freeBox__f6EFf > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-ti5ui7Y6hnPLfa);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__f6EFf {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__f6EFf > .__wab_flex-container {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
    height: calc(100% + var(--token-x_jRp3a5FpON8z));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__f6EFf > .__wab_flex-container > *,
  .Footer__freeBox__f6EFf > .__wab_flex-container > .__wab_slot > *,
  .Footer__freeBox__f6EFf > .__wab_flex-container > picture > img,
  .Footer__freeBox__f6EFf
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-x_jRp3a5FpON8z);
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__f6EFf > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
    height: calc(100% + var(--token-x_jRp3a5FpON8z));
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__f6EFf > .__wab_flex-container > *,
  .Footer__freeBox__f6EFf > .__wab_flex-container > .__wab_slot > *,
  .Footer__freeBox__f6EFf > .__wab_flex-container > picture > img,
  .Footer__freeBox__f6EFf
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-top: var(--token-x_jRp3a5FpON8z);
  }
}
.Footer__freeBox__vRvdc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.Footer__freeBox__vRvdc > .__wab_flex-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__vRvdc > .__wab_flex-container > *,
.Footer__freeBox__vRvdc > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__vRvdc > .__wab_flex-container > picture > img,
.Footer__freeBox__vRvdc > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.Footer__img___9QxFi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: var(--token-3K3yS7MMaSUwfX);
  max-height: var(--token-x_jRp3a5FpON8z);
  mix-blend-mode: screen;
  filter: saturate(0%);
}
.Footer__img___9QxFi > picture > img {
  object-fit: cover;
}
.Footer__text__tq1Za {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
  padding-bottom: 0px;
}
.Footer__freeBox__vOEtU {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.Footer__freeBox__vOEtU > .__wab_flex-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__vOEtU > .__wab_flex-container > *,
.Footer__freeBox__vOEtU > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__vOEtU > .__wab_flex-container > picture > img,
.Footer__freeBox__vOEtU > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.Footer__img__y90FP {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: var(--token-6rZumor6mC3fF5);
  filter: invert(100%) saturate(0%);
  mix-blend-mode: luminosity;
}
.Footer__img__y90FP > picture > img {
  object-fit: cover;
}
.Footer__text__paboA {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
}
.Footer__freeBox__rK7B {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.Footer__freeBox__rK7B > .__wab_flex-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__rK7B > .__wab_flex-container > *,
.Footer__freeBox__rK7B > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__rK7B > .__wab_flex-container > picture > img,
.Footer__freeBox__rK7B > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.Footer__img__h01No {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: var(--token-3K3yS7MMaSUwfX);
  filter: saturate(0%);
  mix-blend-mode: screen;
}
.Footer__img__h01No > picture > img {
  object-fit: cover;
}
.Footer__text__wy280 {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
}
.Footer__freeBox__fZ5Hg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-end: span 2;
  min-width: 0;
}
.Footer__freeBox__fZ5Hg > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-HUUwrpE5bq87rt));
  height: calc(100% + var(--token-HUUwrpE5bq87rt));
}
.Footer__freeBox__fZ5Hg > .__wab_flex-container > *,
.Footer__freeBox__fZ5Hg > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__fZ5Hg > .__wab_flex-container > picture > img,
.Footer__freeBox__fZ5Hg > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-HUUwrpE5bq87rt);
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__fZ5Hg {
    grid-column-end: span 3;
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__fZ5Hg > .__wab_flex-container {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
    height: calc(100% + var(--token-x_jRp3a5FpON8z));
  }
}
@media (min-width: 0px) and (max-width: 1400px) {
  .Footer__freeBox__fZ5Hg > .__wab_flex-container > *,
  .Footer__freeBox__fZ5Hg > .__wab_flex-container > .__wab_slot > *,
  .Footer__freeBox__fZ5Hg > .__wab_flex-container > picture > img,
  .Footer__freeBox__fZ5Hg
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-x_jRp3a5FpON8z);
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__fZ5Hg {
    grid-column-end: span 3;
  }
}
.Footer__text__gMSi {
  position: relative;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-gBTCEyDxU);
}
.Footer__freeBox__jUP {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Footer__freeBox__jUP > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - var(--token-ti5ui7Y6hnPLfa));
  width: calc(100% + var(--token-ti5ui7Y6hnPLfa));
}
.Footer__freeBox__jUP > .__wab_flex-container > *,
.Footer__freeBox__jUP > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__jUP > .__wab_flex-container > picture > img,
.Footer__freeBox__jUP > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: var(--token-ti5ui7Y6hnPLfa);
}
@media (max-width: 480px) {
  .Footer__freeBox__jUP > .__wab_flex-container {
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: calc(0px - var(--token-x_jRp3a5FpON8z));
    height: calc(100% + var(--token-x_jRp3a5FpON8z));
  }
}
@media (max-width: 480px) {
  .Footer__freeBox__jUP > .__wab_flex-container > *,
  .Footer__freeBox__jUP > .__wab_flex-container > .__wab_slot > *,
  .Footer__freeBox__jUP > .__wab_flex-container > picture > img,
  .Footer__freeBox__jUP > .__wab_flex-container > .__wab_slot > picture > img {
    margin-top: var(--token-x_jRp3a5FpON8z);
  }
}
.Footer__freeBox__tkAud {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
}
.Footer__freeBox__tkAud > .__wab_flex-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.Footer__freeBox__tkAud > .__wab_flex-container > *,
.Footer__freeBox__tkAud > .__wab_flex-container > .__wab_slot > *,
.Footer__freeBox__tkAud > .__wab_flex-container > picture > img,
.Footer__freeBox__tkAud > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.Footer__link__wkkXi {
  position: relative;
}
