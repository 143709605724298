.EsgQuestionGroup__root__wCoVj {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-x_jRp3a5FpON8z);
  padding-bottom: var(--token-x_jRp3a5FpON8z);
  min-width: 0;
  border-bottom: 1px solid #c1cec924;
}
.EsgQuestionGroup__root__wCoVj > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-Wf1GGJSbrxsgS-));
  height: calc(100% + var(--token-Wf1GGJSbrxsgS-));
}
.EsgQuestionGroup__root__wCoVj > .__wab_flex-container > *,
.EsgQuestionGroup__root__wCoVj > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionGroup__root__wCoVj > .__wab_flex-container > picture > img,
.EsgQuestionGroup__root__wCoVj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-Wf1GGJSbrxsgS-);
}
.EsgQuestionGroup__rootisLast__wCoVj4EThl {
  border-bottom-style: none;
}
.EsgQuestionGroup__rootisFirst__wCoVj8LViK {
  padding-top: var(--token-A-OXrd0RfsEv85);
}
.EsgQuestionGroup__freeBox__kNq8T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: -26px;
  min-width: 0;
}
.EsgQuestionGroup__freeBox__kNq8T > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-A-OXrd0RfsEv85));
  width: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.EsgQuestionGroup__freeBox__kNq8T > .__wab_flex-container > *,
.EsgQuestionGroup__freeBox__kNq8T > .__wab_flex-container > .__wab_slot > *,
.EsgQuestionGroup__freeBox__kNq8T > .__wab_flex-container > picture > img,
.EsgQuestionGroup__freeBox__kNq8T
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-A-OXrd0RfsEv85);
}
.EsgQuestionGroup__freeBox__i1P2P {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  left: auto;
  top: auto;
  width: var(--token-C4KuLs5uqsxF8L);
  right: auto;
  flex-shrink: 0;
}
.EsgQuestionGroup__slotTargetQuestionNumber__dCp2O {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  font-weight: 500;
  color: var(--token-SkTYvRtmE);
  text-align: right;
}
.EsgQuestionGroup__slotTargetTitle___9WeHh {
  font-size: var(--token-lwXLVsVbV9nDvt);
  font-weight: 500;
}
.EsgQuestionGroup__freeBox__jvFu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  min-width: 0;
}
.EsgQuestionGroup__formGroup__wRjtD.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-end: span 4;
}
.EsgQuestionGroup__select__ge23Y.__wab_instance {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.EsgQuestionGroup__svg___49VOf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 1em;
}
.EsgQuestionGroup__symbolCounter__jcCTl.__wab_instance {
  position: relative;
}
.EsgQuestionGroup__formGroup__iiLuZ.__wab_instance {
  position: relative;
  grid-column-end: span 2;
}
.EsgQuestionGroup__text___4Ro0Q {
  padding-right: 0px;
}
.EsgQuestionGroup__textInput__wIsbp.__wab_instance {
  position: relative;
}
.EsgQuestionGroup__svg___6ChZa {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionGroup__svg__i9AoG {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.EsgQuestionGroup__text__juJi9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-c8ADL42ppeG1sf);
  text-align: right;
  min-width: 0;
}
.EsgQuestionGroup__text___529A6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionGroup__text__lG1Xi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.EsgQuestionGroup__symbolCounter__rfvYh.__wab_instance {
  position: relative;
}
