.ProcessPageActions__root__kKmB {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  padding-top: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
}
.ProcessPageActions__root__kKmB > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-A-OXrd0RfsEv85));
  height: calc(100% + var(--token-A-OXrd0RfsEv85));
}
.ProcessPageActions__root__kKmB > .__wab_flex-container > *,
.ProcessPageActions__root__kKmB > .__wab_flex-container > .__wab_slot > *,
.ProcessPageActions__root__kKmB > .__wab_flex-container > picture > img,
.ProcessPageActions__root__kKmB
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-A-OXrd0RfsEv85);
}
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  border: 1px solid var(--token-O8yxnKF8d);
}
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn > .__wab_flex-container > *,
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn
  > .__wab_flex-container
  > .__wab_slot
  > *,
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn
  > .__wab_flex-container
  > picture
  > img,
.ProcessPageActions__optionalFieldsSwitcher___5R1Rn
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn
    > .__wab_flex-container
    > *,
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn
    > .__wab_flex-container
    > .__wab_slot
    > *,
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn
    > .__wab_flex-container
    > picture
    > img,
  .ProcessPageActions__optionalFieldsSwitcher___5R1Rn
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.ProcessPageActions__text__e2G6Z {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.ProcessPageActions__freeBox___8Ku1W {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.ProcessPageActions__freeBox___8Ku1W > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessPageActions__freeBox___8Ku1W > .__wab_flex-container > *,
.ProcessPageActions__freeBox___8Ku1W > .__wab_flex-container > .__wab_slot > *,
.ProcessPageActions__freeBox___8Ku1W > .__wab_flex-container > picture > img,
.ProcessPageActions__freeBox___8Ku1W
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.ProcessPageActions__svg__xSgnn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessPageActions__text__rZxAa {
  height: auto;
}
.ProcessPageActions__svg__ivGaJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessPageActions__svg__xfeYo {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessPageActions__text__kchRn {
  height: auto;
}
.ProcessPageActions__svg__rSSx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessPageActions__freeBox__wVsNl {
  background: var(--token-O8yxnKF8d);
  box-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -2px #0000001a;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  border: 1px solid var(--token-O8yxnKF8d);
}
.ProcessPageActions__freeBox__wVsNl > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > *,
.ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > .__wab_slot > *,
.ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > picture > img,
.ProcessPageActions__freeBox__wVsNl
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 480px) {
  .ProcessPageActions__freeBox__wVsNl {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }
}
@media (max-width: 480px) {
  .ProcessPageActions__freeBox__wVsNl > .__wab_flex-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 480px) {
  .ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > *,
  .ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > .__wab_slot > *,
  .ProcessPageActions__freeBox__wVsNl > .__wab_flex-container > picture > img,
  .ProcessPageActions__freeBox__wVsNl
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.ProcessPageActions__text___8RY9M {
  position: relative;
  height: auto;
  color: var(--token-SkTYvRtmE);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  width: auto;
}
.ProcessPageActions__freeBox__hugM {
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
}
.ProcessPageActions__freeBox__hugM > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.ProcessPageActions__freeBox__hugM > .__wab_flex-container > *,
.ProcessPageActions__freeBox__hugM > .__wab_flex-container > .__wab_slot > *,
.ProcessPageActions__freeBox__hugM > .__wab_flex-container > picture > img,
.ProcessPageActions__freeBox__hugM
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.ProcessPageActions__svg__fft7Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessPageActions__text___1Q57S {
  height: auto;
}
.ProcessPageActions__svg__c26Sm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
