.Slider__root__hgoBm {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  min-width: 160px;
  min-height: 36px;
}
.Slider__root__hgoBm > .__wab_flex-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.Slider__root__hgoBm > .__wab_flex-container > *,
.Slider__root__hgoBm > .__wab_flex-container > .__wab_slot > *,
.Slider__root__hgoBm > .__wab_flex-container > picture > img,
.Slider__root__hgoBm > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 16px;
}
.Slider__roothasIntervals__hgoBmVuub4 {
  padding-bottom: 12px;
  min-height: 48px;
}
.Slider__roothasLabels__hgoBmbFlIy {
  min-height: 56px;
  padding-top: 12px;
}
.Slider__freeBox__v94Jt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.Slider__labelsContainer__nhfK {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  width: auto;
  height: auto;
}
.Slider__labelsContainerhasLabels__nhfKbFlIy {
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  display: flex;
}
.Slider__labelsContainerhasIntervals_hasLabels__nhfKvuub4BFlIy {
  left: auto;
  top: auto;
  width: auto;
  align-items: center;
  bottom: auto;
  right: auto;
}
.Slider__slotTargetStartLabel__v8VfN {
  font-size: var(--token-1lkMHpovBhH3r-);
  font-weight: 500;
  color: var(--token-D5PfQGq_p2o95u);
}
.Slider__slotTargetEndLabel__yHQz {
  font-size: var(--token-1lkMHpovBhH3r-);
  font-weight: 500;
  color: var(--token-D5PfQGq_p2o95u);
}
.Slider__sliderBase__d254L.__wab_instance {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.Slider__sliderInput__hCpP.__wab_instance {
  position: relative;
  height: 30px;
  width: 60px;
  flex-shrink: 0;
  display: none;
}
.Slider__sliderInputhasInput__hCpPXtI4U.__wab_instance {
  display: flex;
}
.Slider__svg__pmiLu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Slider__svg__f6Uxw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.Slider__text__lDjSg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  text-align: right;
  min-width: 0;
}
.Slider__text__yVmZc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.Slider__text__pDmBh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
