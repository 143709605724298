.ProcessHeader__root__tiMdN {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-x_jRp3a5FpON8z);
  padding-right: var(--token-x_jRp3a5FpON8z);
  background: var(--token-hEzPFeV5Y);
  min-width: 0;
}
@media (max-width: 480px) {
  .ProcessHeader__root__tiMdN {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.ProcessHeader__freeBox__uzopX {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-TJik2L8OcSV6kD);
  padding-bottom: var(--token-TJik2L8OcSV6kD);
  min-width: 0;
  border-bottom: 2px solid #c1cec924;
}
.ProcessHeader__freeBox__uzopX > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-TJik2L8OcSV6kD));
  width: calc(100% + var(--token-TJik2L8OcSV6kD));
}
.ProcessHeader__freeBox__uzopX > .__wab_flex-container > *,
.ProcessHeader__freeBox__uzopX > .__wab_flex-container > .__wab_slot > *,
.ProcessHeader__freeBox__uzopX > .__wab_flex-container > picture > img,
.ProcessHeader__freeBox__uzopX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-TJik2L8OcSV6kD);
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox__uzopX {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox__uzopX > .__wab_flex-container {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox__uzopX > .__wab_flex-container > *,
  .ProcessHeader__freeBox__uzopX > .__wab_flex-container > .__wab_slot > *,
  .ProcessHeader__freeBox__uzopX > .__wab_flex-container > picture > img,
  .ProcessHeader__freeBox__uzopX
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.ProcessHeader__backButton___5Enpv.__wab_instance {
  max-width: 100%;
  position: relative;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .ProcessHeader__backButton___5Enpv.__wab_instance {
    flex-shrink: 0;
  }
}
.ProcessHeader__svg__p6QJz {
  position: relative;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.ProcessHeader__text__wrS4Y {
  height: auto;
}
.ProcessHeader__svg__lk3XE {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.ProcessHeader__freeBox___3Fl2U {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ProcessHeader__freeBox___3Fl2U > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 48px);
  width: calc(100% + 48px);
}
.ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > *,
.ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > .__wab_slot > *,
.ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > picture > img,
.ProcessHeader__freeBox___3Fl2U
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 48px;
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox___3Fl2U > .__wab_flex-container {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > *,
  .ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > .__wab_slot > *,
  .ProcessHeader__freeBox___3Fl2U > .__wab_flex-container > picture > img,
  .ProcessHeader__freeBox___3Fl2U
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.ProcessHeader__freeBox__blAqZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  max-width: 400px;
}
@media (max-width: 480px) {
  .ProcessHeader__freeBox__blAqZ {
    width: auto;
  }
}
.ProcessHeader__text__zXAaT {
  position: relative;
  width: 100%;
  height: auto;
  color: var(--token-SkTYvRtmE);
  line-height: var(--token-97-B3t__LugLsk);
  min-width: 0;
}
.ProcessHeader__address__mxj5X {
  position: relative;
  width: 100%;
  height: auto;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--token-97-B3t__LugLsk);
  min-width: 0;
}
.ProcessHeader__navLinkGroup__spYq4.__wab_instance {
  width: 100%;
  min-width: 0;
}
