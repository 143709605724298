.SymbolCounter__root___4K7M {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
}
.SymbolCounter__root___4K7M > .__wab_flex-container {
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-u4z6Uru_YfAR62));
  width: calc(100% + var(--token-u4z6Uru_YfAR62));
}
.SymbolCounter__root___4K7M > .__wab_flex-container > *,
.SymbolCounter__root___4K7M > .__wab_flex-container > .__wab_slot > *,
.SymbolCounter__root___4K7M > .__wab_flex-container > picture > img,
.SymbolCounter__root___4K7M
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: var(--token-u4z6Uru_YfAR62);
}
.SymbolCounter__slotTargetValue__rwpC0 {
  color: var(--token-8D7PsQmKPjCWdA);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-97-B3t__LugLsk);
}
.SymbolCounter__text___0VlQt {
  line-height: var(--token-97-B3t__LugLsk);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-8D7PsQmKPjCWdA);
}
.SymbolCounter__slotTargetMaximumValue__fH46X {
  color: var(--token-8D7PsQmKPjCWdA);
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  line-height: var(--token-97-B3t__LugLsk);
}
.SymbolCounter__svg__besHy {
  position: relative;
  object-fit: cover;
  color: var(--token-UQb389MFjCYuwK);
  margin-left: var(--token-a0wt3E9LJjO_Nh) !important;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  display: none;
}
.SymbolCounter__svgwarning__besHyUniq {
  margin-left: var(--token-a0wt3E9LJjO_Nh) !important;
  display: block;
}
