.AddressUserSettings__root__vSi8L {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.AddressUserSettings__addressForm__skOqS {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .AddressUserSettings__addressForm__skOqS {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .AddressUserSettings__addressForm__skOqS {
    grid-template-columns: 1fr;
  }
}
.AddressUserSettings__freeBox__olx26 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.AddressUserSettings__freeBox__olx26 > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.AddressUserSettings__freeBox__olx26 > .__wab_flex-container > *,
.AddressUserSettings__freeBox__olx26 > .__wab_flex-container > .__wab_slot > *,
.AddressUserSettings__freeBox__olx26 > .__wab_flex-container > picture > img,
.AddressUserSettings__freeBox__olx26
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.AddressUserSettings__text__fOd2P {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .AddressUserSettings__text__fOd2P {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.AddressUserSettings__addressFormGroup__x4IlM.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.AddressUserSettings__billingAddressFormCheckbox___1HRom.__wab_instance {
  max-width: 100%;
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
}
.AddressUserSettings__billingAddressForm__jLNkq {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: var(--token-HUUwrpE5bq87rt);
  grid-column-gap: var(--token-HUUwrpE5bq87rt);
  width: 100%;
  padding-top: var(--token-ti5ui7Y6hnPLfa);
  padding-bottom: var(--token-ti5ui7Y6hnPLfa);
  align-self: flex-start;
  min-width: 0;
  border-top: 1px solid #c1cec924;
}
@media (min-width: 0px) and (max-width: 1400px) {
  .AddressUserSettings__billingAddressForm__jLNkq {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .AddressUserSettings__billingAddressForm__jLNkq {
    grid-template-columns: 1fr;
  }
}
.AddressUserSettings__freeBox__qWeY8 {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-end: span 1;
  grid-column-end: span 6;
  min-width: 0;
}
.AddressUserSettings__freeBox__qWeY8 > .__wab_flex-container {
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.AddressUserSettings__freeBox__qWeY8 > .__wab_flex-container > *,
.AddressUserSettings__freeBox__qWeY8 > .__wab_flex-container > .__wab_slot > *,
.AddressUserSettings__freeBox__qWeY8 > .__wab_flex-container > picture > img,
.AddressUserSettings__freeBox__qWeY8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.AddressUserSettings__text__jyHu7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: var(--token-JJM6LNeA-0eMML);
  font-weight: 600;
  line-height: var(--token-lWY7kGeXV7C_yD);
  min-width: 0;
}
@media (max-width: 480px) {
  .AddressUserSettings__text__jyHu7 {
    font-size: var(--token-lwXLVsVbV9nDvt);
  }
}
.AddressUserSettings__addressFormGroup__lG0Ji.__wab_instance {
  position: relative;
  grid-column-end: span 6;
}
.AddressUserSettings__freeBox___9PfQy {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.AddressUserSettings__freeBox___9PfQy > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-P3nFMsZz2JMXx8));
  height: calc(100% + var(--token-P3nFMsZz2JMXx8));
}
.AddressUserSettings__freeBox___9PfQy > .__wab_flex-container > *,
.AddressUserSettings__freeBox___9PfQy > .__wab_flex-container > .__wab_slot > *,
.AddressUserSettings__freeBox___9PfQy > .__wab_flex-container > picture > img,
.AddressUserSettings__freeBox___9PfQy
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: var(--token-P3nFMsZz2JMXx8);
}
.AddressUserSettings__button__z9S5H.__wab_instance {
  max-width: 100%;
  position: relative;
}
.AddressUserSettings__svg__iLqZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.AddressUserSettings__svg__loH2H {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
