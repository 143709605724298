.PortfolioDetailInfo__root__tzm4H {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  position: relative;
  min-width: 0;
}
.PortfolioDetailInfo__slotTargetTitle__eL15B {
  font-size: var(--token-Mt-ps-Nmgx1ErQ);
  color: var(--token-SkTYvRtmE);
}
.PortfolioDetailInfo__slotTargetNumber__idZu {
  color: var(--token--h6FHSrgUAQsDe);
}
.PortfolioDetailInfo__slotTargetSubtitle__dd6Cj {
  font-size: var(--token-1lkMHpovBhH3r-);
  color: var(--token-SkTYvRtmE);
}
