.Select__Overlay__root__ayEyN {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
}
.Select__Overlay__top__jphNk {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-height: 320px;
  border-radius: 1px;
}
.Select__Overlay__toprelativePlacement_bottom__jphNkBlEm9 {
  height: 2px;
  flex-shrink: 0;
}
.Select__Overlay__tophasNoMaxHeight__jphNKnJFyi {
  max-height: none;
}
.Select__Overlay__middle___9GxIr {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  background: var(--token-zw8MjMyAW);
  box-shadow: 0px 8px 32px -8px #18413738, 0px 8px 20px -16px #18413724,
    0px 4px 16px 0px #18413733;
  overflow: auto;
  max-height: 320px;
  min-height: 0;
  border-radius: 1px;
}
.Select__Overlay__middlerelativePlacement_top___9GxIrawxBw {
  box-shadow: 0px 8px 32px -8px #00000038, 0px 8px 20px -16px #00000024;
}
.Select__Overlay__middlehasNoMaxHeight___9GxIrnJFyi {
  max-height: none;
}
.Select__Overlay__left__qCj46 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.Select__Overlay__leftrelativePlacement_right__qCj46Ajmrf {
  width: 2px;
  flex-shrink: 0;
}
.Select__Overlay__main__nstNu {
  position: relative;
  width: 100%;
  overflow: auto;
  align-self: flex-start;
  min-width: 0;
}
.Select__Overlay__right__q4LuE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.Select__Overlay__rightrelativePlacement_left__q4LuEp2GA {
  width: 2px;
  flex-shrink: 0;
}
.Select__Overlay__bottom__bnYmz {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-height: 320px;
  border-radius: 1px;
}
.Select__Overlay__bottomrelativePlacement_top__bnYmzawxBw {
  height: 2px;
  flex-shrink: 0;
}
.Select__Overlay__bottomhasNoMaxHeight__bnYmznJFyi {
  max-height: none;
}
